import React, { PureComponent } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import AuthContainer from "../../../components/AuthContainer";
import StatsCard from "../../../components/Cards/StatsCard";
import Table from "../../../theme/Table";
import SectionTitle from "../../../theme/SectionTitle";
import Axios from "axios";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import UndefinedSenderAlert from "../../../components/Alerts/UndefinedSenderAlert";
import WpUndefinedAlert from "../../../components/Alerts/WpUndefinedAlert";
import RoundedRow from "../../../components/Table/RoundedRow";
import {
  CardMembership,
  Check,
  Close,
  SmsFailed,
  Timer,
  InfoOutlined,
  MailOutline,
  WhatsApp,
  CollectionsOutlined,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import Button from "../../../theme/Button";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";

import Loader from "../../../assets/images/circular.gif";

class SMSIndex extends PureComponent {
  static contextType = AppContext;

  state = {
    tab: 0,
    detailTab: 0,
    available_credit: "",
    filter_expanded: false,
    group_filter_expanded: false,
    showGroupSMSDetail: false,
    showGroupWPDetail: false,
    detailState: 1,

    phone: null,
    api_key: "",
    wp_state: null,
    groupMessageDetailId: "",
    ReportMessageBody: "",

    failedMessage: 0,
    successMessage: 0,
    waitingMessage: 0,

    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),

    table_loaded_1: false,
    table_loaded_2: false,
    table_loaded_3: false,
    table_loaded_4: false,
    showSms: false,
    sms: { content: "", date: "" },
    pagination: {
      page: null,
      total_page: null,
      onChange: (page) => this.getMessageReports(page),
    },

    automatedWPReports: {
      loaded: false,
      pagination: {
        page: null,
        total_page: null,
        onChange: (page) => this.getAutomatedWPReports(page),
      },
      reports: [],
    },
    groupSMS: {
      start: moment().subtract(30, "days").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
      phone: "",

      loaded: false,
      showSms: false,
      sms: { content: "", date: "" },
      pagination: {
        page: null,
        total_page: null,
        onChange: (page) => this.getGroupMessageReports(page),
      },

      reports: [],
    },

    groupWP: {
      start: moment().subtract(30, "days").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
      phone: "",

      loaded: false,
      showSms: false,
      sms: { content: "", date: "" },
      pagination: {
        page: null,
        total_page: null,
        onChange: (page) => this.getGroupWPMessageReports(page),
      },

      reports: [],
    },

    // Grup gönderim sms detay
    singleGroupSMS: {
      start: moment().subtract(30, "days").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),

      loaded: false,
      showSms: false,
      sms: { content: "", date: "" },
      pagination: {
        page: null,
        total_page: null,
        onChange: (page) => this.getSingleGroupMessageReports(page),
      },

      reports: [],
    },

    // grup gönderim WP tekli detay
    singleGroupWP: {
      start: moment().subtract(30, "days").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),

      loaded: false,
      showSms: false,
      sms: { content: "", date: "" },
      pagination: {
        page: null,
        total_page: null,
        onChange: (page) => this.getSingleGroupWPReports(page),
      },

      reports: [],
    },

    reports: [],
    group_sms_detail_modal: false,
    group_sms_detail: {
      phone: "",
      name: null,
      surname: null,
      message: null,
    },
  };

  removeFilter = () => {
    this.setState({
      filter_expanded: false,
      group_filter_expanded: false,
      start: moment().subtract(30, "days").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
      phone: null,
    });
  };

  getContextSenderSettings = () => {
    Axios.get(`${this.context.api_endpoint}/company/itself`).then(
      (response) => {
        const {
          data: { data },
        } = response;
        this.context.dispatch({
          type: "SET_COMPANY_SMS_SETTINGS",
          payload: {
            company_sms_settings: { sender: data.sender },
            company_sms_settings_loaded: true,
          },
        });
      }
    );
  };

  getMessageSettings = () => {
    Axios.get(`${this.context.api_endpoint}/company/message/credit`).then(
      ({ data }) => {
        this.setState({
          available_credit: `${data.data.credit} SMS`,
        });
        this.context.dispatch({
          type: "SET_SMS_CREDIT",
          payload: { sms_credit: data.data.credit },
        });
      }
    );
  };
  checkWp = async () => {
    await Axios.get(
      `${this.context.api_endpoint}/company/get/wp/message/settings`
    )
      .then(async (ress) => {
        if (ress.data.data.api_key === "") {
        } else {
          this.setState({
            api_key: ress.data.data.api_key,
          });
          await Axios.post(
            `${this.context.api_endpoint}/salon/wp/login/check/active/${ress.data.data.device_key}`
          )
            .then((ress) => {
              if (ress.status === 200) {
                this.setState({
                  wp_state: ress.data.is_connect,
                  wp_phone: ress.data.data.device_number,
                });
              }
            })
            .catch(() => {
              // toast.warn("Cihaz aktif değil lütfen güncelleyiniz!");
              this.setState({
                wp_state: false,
                wp_phone: null,
              });
            });
        }
      })
      .catch((err) => console.log(err));
  };

  creditPayback = () => {
    Axios.post(`${this.context.api_endpoint}/company/message/credit/payback`)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.data);
          this.getMessageReports();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // otomatik wp göndeirm raporları
  getAutomatedWPReports = async (page = 1) => {
    this.setState({
      table_loaded_3: false,
    });
    const { start, end, phone, filter_expanded } = this.state;
    await Axios.get(
      `${this.context.api_endpoint}/company/message/reports`,
      filter_expanded
        ? {
          params: {
            start: start,
            end: end,
            phone: phone ? phone : "",
            page: page,
            report_type: 2,
          },
        }
        : {
          params: {
            page: page,
            report_type: 2,
          },
        }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          this.setState({
            automatedWPReports: {
              loaded: true,
              reports: [
                ...data.records.map((item) => {
                  return {
                    ...item,
                    date: moment(item.date).tz("Europe/Istanbul").format("LLL"),
                    state:
                      item.state === "1"
                        ? "DELIVERED"
                        : item.state === "2"
                          ? "UNDELIVERED"
                          : "",
                  };
                }),
              ],
              pagination: {
                ...this.state.automatedWPReports.pagination,
                page: data.page,
                total_page: data.records.length,
              },
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      })
      .finally(() =>
        this.setState({
          table_loaded_3: true,
        })
      );
  };

  // get name and surname for group sms detail
  getCustomerName = async (phone) => {
    await Axios.get(
      `${this.context.api_endpoint}/company/customer/single/data?phone=${phone}`
    ).then(({ data }) =>
      this.setState({
        group_sms_detail: {
          ...this.state.group_sms_detail,
          name: data.data.name,
          surname: data.data.surname,
        },
      })
    );
  };

  // otomatik sms gönderim raporları
  getMessageReports = (page = 1) => {
    this.setState({
      table_loaded_1: false,
    });
    const { start, end, phone, filter_expanded, pagination } = this.state;
    Axios.get(
      `${this.context.api_endpoint}/company/message/reports`,
      filter_expanded
        ? {
          params: {
            start: start,
            end: end,
            phone: phone ? phone : "",
            page: page,
            report_type: 1,
          },
        }
        : {
          params: {
            page: page,
            report_type: 1,
          },
        }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          this.setState({
            loaded: true,
            reports: [
              ...data.records.map((item) => {
                return {
                  ...item,
                  date: moment(item.date).tz("Europe/Istanbul").format("LLL"),
                  state: item.has_paid === 1 ? "PAYBACK_COMPLETED" : item.state,
                };
              }),
            ],
            pagination: {
              ...pagination,
              page: data.page,
              total_page: data.records.length,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      })
      .finally(() =>
        this.setState({
          table_loaded_1: true,
        })
      );
  };

  // Grup-tekli sms gönderimleri
  getGroupMessageReports = (page = 1) => {
    this.setState({
      table_loaded_2: false,
    });
    const { groupSMS, group_filter_expanded } = this.state;

    Axios.get(
      `${this.context.api_endpoint}/company/message/reports/group`,
      group_filter_expanded
        ? {
          params: {
            start: groupSMS.start,
            end: groupSMS.end,
            phone: groupSMS.phone ? groupSMS.phone : "",
            page: page,
            report_type: 1,
          },
        }
        : {
          params: {
            page: page,
            report_type: 1,
          },
        }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;

          this.setState({
            groupSMS: {
              ...this.state.groupSMS,
              loaded: true,
              reports: [
                ...data.records.map((item) => {
                  return {
                    ...item,
                    date: moment(item.send_time)
                      .tz("Europe/Istanbul")
                      .format("LLL"),
                    waiting: item.count - (item.fail + item.success),
                    beforeCredit: item.credit_before,
                  };
                }),
              ],
              pagination: {
                ...groupSMS.pagination,
                page: data.page,
                total_page: data.records.length,
              },
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      })
      .finally(() => {
        this.setState({
          table_loaded_2: true,
        });
      });
  };

  // Grup-tekli wp gönderimleri
  getGroupWPMessageReports = (page = 1) => {
    this.setState({
      table_loaded_4: false,
    });
    const { groupWP, group_filter_expanded } = this.state;

    Axios.get(
      `${this.context.api_endpoint}/company/message/reports/group`,
      group_filter_expanded
        ? {
          params: {
            start: groupWP.start,
            end: groupWP.end,
            phone: groupWP.phone ? groupWP.phone : "",
            page: page,
            report_type: 2,
          },
        }
        : {
          params: {
            page: page,
            report_type: 2,
          },
        }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;

          this.setState({
            groupWP: {
              ...this.state.groupSMS,
              loaded: true,
              reports: [
                ...data.records.map((item) => {
                  return {
                    ...item,
                    date: moment(item.send_time)
                      .tz("Europe/Istanbul")
                      .format("LLL"),
                    waiting: item.count - (item.fail + item.success),
                    beforeCredit: item.credit_before,
                  };
                }),
              ],
              pagination: {
                ...groupWP.pagination,
                page: data.page,
                total_page: data.records.length,
              },
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      })
      .finally(() => {
        this.setState({
          table_loaded_4: true,
        });
      });
  };
  custtomWhatsAppIcon = () => {
    return <WhatsApp color="green" />;
  };

  // Grup-tekli sms gönderimleri detaylı bakış
  getSingleGroupMessageReports = (page = 1) => {
    const { singleGroupSMS } = this.state;

    Axios.get(
      `${this.context.api_endpoint}/company/message/reports/group/${this.state.groupMessageDetailId}`,
      this.state.detailState === 1
        ? {
          params: {
            page: page,
            state: "delivered",
            report_type: 1,
          },
        }
        : this.state.detailState === 2
          ? {
            params: {
              page: page,
              state: "waiting",
              report_type: 1,
            },
          }
          : {
            params: {
              page: page,
              state: "undeliverable",
              report_type: 1,
            },
          }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          this.setState({
            singleGroupSMS: {
              loaded: true,
              reports: [
                ...data.records.map((item) => {
                  return {
                    ...item,
                    message: this.state.ReportMessageBody,
                  };
                }),
              ],
              pagination: {
                ...singleGroupSMS.pagination,
                page: data.page,
                total_page: data.records.length,
              },
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      });
  };

  // grup-tekli wp gönderim detalı bakış
  getSingleGroupWPReports = (page = 1) => {
    const { singleGroupWP } = this.state;

    Axios.get(
      `${this.context.api_endpoint}/company/message/reports/group/${this.state.groupMessageDetailId}`,
      this.state.detailState === 1
        ? {
          params: {
            page: page,
            state: 1,
            report_type: 2,
          },
        }
        : this.state.detailState === 2
          ? {
            params: {
              page: page,
              state: "waiting",
              report_type: 2,
            },
          }
          : {
            params: {
              page: page,
              state: 2,
              report_type: 2,
            },
          }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          this.setState({
            singleGroupWP: {
              loaded: true,
              reports: [
                ...data.records.map((item) => {
                  return {
                    ...item,
                    message: this.state.ReportMessageBody,
                  };
                }),
              ],
              pagination: {
                ...singleGroupWP.pagination,
                page: data.page,
                total_page: data.records.length,
              },
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      });
  };

  componentDidMount() {
    this.getContextSenderSettings();
    this.getMessageSettings();
    this.getMessageReports();
    this.getAutomatedWPReports();
    this.getGroupMessageReports();
    this.getGroupWPMessageReports();
    this.checkWp();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.phone !== this.state.phone) {
      if (this.state.phone.length === undefined) {
        this.getMessageReports();
      }
    }
  }

  render() {
    const TabMenuItem = this._TabMenuItem;
    const TabMenuItem1 = this._TabMenuItem1;
    const DetailTabMenuItem = this._DeteailTabMenuItem;
    const SingleSMSReports = this._SingleSMSReports;
    const SingleWPReports = this._SingleWPReports;
    const GroupSMSReports = this._GroupSMSReports;
    const GroupWPReports = this._GroupWPReports;
    const SingleGroupSMSReports = this._SingleGroupSMSReports;
    const SingleGroupWPReports = this._SingleGroupWPReports;

    const { tab, available_credit, showSms, sms } = this.state;

    return (
      <AuthContainer authorities={[1]} limited_permission="sms">
        {(this.state.sender === "" || this.state.sender === null) &&
          !this.state.wp_state ? (
          <>
            <UndefinedSenderAlert />
            <WpUndefinedAlert />
          </>
        ) : (
          <></>
        )}
        {this.context.state.company_sms_settings_loaded === false && (
          <Grid>
            <Column className="xs-12 sm-12 mb-2">
              <Alert severity="info">
                <b>{this.context.t(`sms.alertBoldText`)}</b>
                <br />
                {this.context.t(`sms.alertText`)}
              </Alert>
            </Column>
          </Grid>
        )}
        <Grid>
          <Column className="xs-12 sm-8 lg-9">
            {/* Tab Menu */}

            <TabMenuItem
              tab={0}
              btnProps={{
                iconComponent: MailOutline,
                title: this.context.t(`sms.singleSMSReports`),
                onClick: () => {
                  this.setState({
                    tab: 0,
                    showGroupSMSDetail: false,
                    showGroupWPDetail: false,
                  });
                  this.removeFilter();
                },
              }}
            />
            <TabMenuItem
              tab={1}
              btnProps={{
                iconComponent: MailOutline,
                title: this.context.t(`sms.groupSMSReports`),
                onClick: () => {
                  this.setState({
                    tab: 1,
                    showGroupWPDetail: false,
                  });
                  this.removeFilter();
                },
              }}
            />
            <TabMenuItem1
              tab={2}
              btnProps={{
                iconComponent: WhatsApp,
                title: this.context.t(`sms.singleWPReports`),

                onClick: () => {
                  this.setState({
                    tab: 2,
                    showGroupSMSDetail: false,
                    showGroupWPDetail: false,
                  });
                  this.removeFilter();
                },
              }}
            />
            <TabMenuItem1
              tab={3}
              btnProps={{
                iconComponent: WhatsApp,
                title: this.context.t(`sms.groupWPReports`),
                onClick: () => {
                  this.setState({
                    tab: 3,
                    showGroupSMSDetail: false,
                  });
                  this.removeFilter();
                },
              }}
            />
          </Column>

          <Column className="xs-12 sm-4 lg-3">
            <Button
              className="elevation-1"
              iconComponent={CardMembership}
              title={this.context.t(`sms.creditPaybackButtonTitle`)}
              fullWidth
              onClick={() => this.creditPayback()}
            />
          </Column>

          <Column className="xs-12 sm-12">
            <Grid className="no-gutters-all">
              <Column className="xs-12 sm-12 md-9 lg-12">
                {/* Tab Area */}
                {tab === 0 && <SingleSMSReports />}
                {tab === 1 && <GroupSMSReports />}
                {tab === 2 && <SingleWPReports />}
                {tab === 3 && <GroupWPReports />}
              </Column>
            </Grid>
          </Column>

          {this.state.showGroupSMSDetail && (
            <>
              <Column
                className="xs-12 sm-12"
                style={{ border: "1px solid #b2b2b2", margin: "5vh 0" }}
              ></Column>
              <Column className="xs-12 sm-12">
                <Grid className="no-gutters-all">
                  {/* Detail Tab Menu */}
                  <Column
                    className="xs-12 sm-12 md-3 lg-12"
                    style={{ display: "flex" }}
                  >
                    <DetailTabMenuItem
                      detailTab={0}
                      btnProps={{
                        iconComponent: Check,
                        title: `${this.context.t(
                          `sms.bottom_table_titles.successful`
                        )} ${this.state.successMessage} `,
                        onClick: async () => {
                          await this.setState({
                            detailTab: 0,
                            detailState: 1,
                          });
                          this.getSingleGroupMessageReports();
                        },
                      }}
                    />

                    <DetailTabMenuItem
                      detailTab={2}
                      btnProps={{
                        iconComponent: SmsFailed,
                        title: `${this.context.t(
                          `sms.bottom_table_titles.unsuccessful`
                        )} ${this.state.failedMessage}`,
                        onClick: async () => {
                          await this.setState({
                            detailTab: 2,
                            detailState: 3,
                          });
                          this.getSingleGroupMessageReports();
                        },
                      }}
                    />
                  </Column>

                  {/* Tab Area */}
                  <Column className="xs-12 sm-12 md-9 lg-12">
                    <SingleGroupSMSReports />
                  </Column>
                </Grid>
              </Column>
            </>
          )}
          {this.state.showGroupWPDetail && (
            <>
              <Column
                className="xs-12 sm-12"
                style={{ border: "1px solid #b2b2b2", margin: "5vh 0" }}
              ></Column>
              <Column className="xs-12 sm-12">
                <Grid className="no-gutters-all">
                  {/* Detail Tab Menu */}
                  <Column
                    className="xs-12 sm-12 md-3 lg-12"
                    style={{ display: "flex" }}
                  >
                    <DetailTabMenuItem
                      detailTab={0}
                      btnProps={{
                        iconComponent: Check,
                        title: `${this.context.t(
                          `sms.bottom_table_titles.successful`
                        )}  ${this.state.successMessage}`,
                        onClick: async () => {
                          await this.setState({
                            detailTab: 0,
                            detailState: 1,
                          });
                          this.getSingleGroupWPReports();
                        },
                      }}
                    />

                    <DetailTabMenuItem
                      detailTab={2}
                      btnProps={{
                        iconComponent: SmsFailed,
                        title: `${this.context.t(
                          `sms.bottom_table_titles.unsuccessful`
                        )}  ${this.state.failedMessage}`,
                        onClick: async () => {
                          await this.setState({
                            detailTab: 2,
                            detailState: 3,
                          });
                          this.getSingleGroupWPReports();
                        },
                      }}
                    />
                  </Column>

                  {/* Tab Area */}
                  <Column className="xs-12 sm-12 md-9 lg-12">
                    <SingleGroupWPReports />
                  </Column>
                </Grid>
              </Column>
            </>
          )}
        </Grid>

        <AlertDialog
          maxWidth="sm"
          fullWidth={true}
          open={showSms}
          closeHandler={() =>
            this.setState({ showSms: false, sms: { content: "", date: "" } })
          }
          title={this.context.t(`sms.dialogTitle`)}
          buttons={[
            {
              title: this.context.t(`sms.buttonApprove`),
              icon: "close",
              backgroundColor: "primary-opacity",
              textColor: "primary",
            },
          ]}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography className="mt-1 mb-1">{sms.content}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="mt-1 mb-1 float-right">
                {sms.date}
              </Typography>
            </Grid>
          </Grid>
        </AlertDialog>

        <AlertDialog
          maxWidth="sm"
          open={this.state.group_sms_detail_modal}
          closeHandler={() =>
            this.setState({
              group_sms_detail_modal: false,
            })
          }
          title={"Ayrıntılar"}
          buttons={[
            {
              title: this.context.t(`sms.buttonApprove`),
              icon: "close",
              backgroundColor: "primary-opacity",
              textColor: "primary",
            },
          ]}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography className="mt-1 mb-1 float-right">
                {this.state.group_sms_detail.name}
              </Typography>
              <Typography className="mt-1 mb-1 float-right">&nbsp;</Typography>
              <Typography className="mt-1 mb-1 float-right">
                {this.state.group_sms_detail.surname}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="mt-1 mb-1 float-right">
                {this.state.group_sms_detail.phone.slice(1)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="mt-1 mb-2">
                {this.state.group_sms_detail.message}
              </Typography>
            </Grid>
          </Grid>
        </AlertDialog>
      </AuthContainer>
    );
  }

  _DeteailTabMenuItem = ({ btnProps, detailTab }) => {
    return (
      <Button
        {...btnProps}
        iconSize={20}
        style={{ borderRadius: "0px !important" }}
        containerStyle={{ justifyContent: "flex-start" }}
        backgroundColor={
          this.state.detailTab === detailTab && detailTab === 0
            ? "green-opacity"
            : this.state.detailTab === detailTab && detailTab === 1
              ? "orange-opacity"
              : this.state.detailTab === detailTab && detailTab === 2
                ? "red-opacity"
                : ""
        }
        textColor={
          this.state.detailTab === detailTab && detailTab === 0
            ? "green"
            : this.state.detailTab === detailTab && detailTab === 1
              ? "orange"
              : this.state.detailTab === detailTab && detailTab === 2
                ? "red"
                : "grey"
        }
        size="lg"
        fullWidth
      />
    );
  };

  _TabMenuItem = ({ btnProps, tab }) => {
    return (
      <Button
        {...btnProps}
        iconSize={20}
        iconColor="orange"
        style={{
          borderRadius: "0px !important",
          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        containerStyle={{ justifyContent: "flex-start" }}
        backgroundColor={this.state.tab === tab ? "primary-opacity" : ""}
        textColor={this.state.tab === tab ? "primary" : "grey"}
        size="lg"
      />
    );
  };
  _TabMenuItem1 = ({ btnProps, tab }) => {
    return (
      <Button
        {...btnProps}
        iconSize={20}
        iconColor="green"
        style={{
          borderRadius: "0px !important",
          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        containerStyle={{ justifyContent: "flex-start" }}
        backgroundColor={this.state.tab === tab ? "primary-opacity" : ""}
        textColor={this.state.tab === tab ? "primary" : "grey"}
        size="lg"
      />
    );
  };

  // otomatik sms gönderim raporları
  _SingleSMSReports = () => {
    const { reports, loaded, pagination, filter_expanded, start, end, phone } =
      this.state;

    return (
      <>
        {/* Form Control Swicthbox */}
        <Column className={`xs-12 sm-12`}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={filter_expanded}
                onChange={async (e) => {
                  await this.setState({
                    filter_expanded: e.target.checked,
                  });
                  this.getMessageReports();
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{this.context.t(`receipts.filter.title`)}</span>
              </FCLabel>
            }
          />
        </Column>

        {/* Filter Area */}
        {filter_expanded && (
          <Grid className="mt-2 mb-2">
            <Column className="xs-12 sm-12 md-12 lg-4">
              <DatePickerInput
                className="mb-0"
                label={this.context.t(`receipts.filter.startInputLabel`)}
                value={moment(start).format("YYYY-MM-DD")}
                onChange={async (start) => {
                  await this.setState({
                    loaded: false,
                    start: moment(start).format("YYYY-MM-DD"),
                  });
                  this.getMessageReports();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4">
              <DatePickerInput
                className="mb-0"
                label={this.context.t(`receipts.filter.endInputLabel`)}
                value={end}
                onChange={async (end) => {
                  await this.setState({
                    loaded: false,
                    end: moment(end).format("YYYY-MM-DD"),
                  });
                  this.getMessageReports();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4">
              <MaskedInput
                label={this.context.t(`['customers/add'].phoneInputLabel`)}
                maskChar={""}
                mask="999 999 9999"
                startAdornment="0"
                value={phone !== null ? phone : ""}
                placeholder="500 000 0000"
                onChange={async (e) => {
                  await this.setState({
                    loaded: false,
                    phone:
                      e.target.value.substring(0, 1) === "5" &&
                      e.target.value.replace(/\s/g, ""),
                  });
                  if (this.state.phone !== undefined) {
                    if (this.state.phone.length >= 5) {
                      this.getMessageReports();
                    }
                  }
                }}
              />
            </Column>
          </Grid>
        )}
        <Column className="xs-12 sm-12">
          {this.state.table_loaded_1 ? (
            <Table
              refreshAction={() =>
                this.getMessageReports(this.state.pagination.page)
              }
              headings={{
                date: {
                  label: this.context.t(`sms.headings.date`),
                  limited_line: 1,
                  style: { width: 200 },
                },
                phone: {
                  label: this.context.t(`sms.headings.phone`),
                  style: { maxWidth: 200 },
                },
                state: {
                  label: this.context.t(`sms.headings.status`),
                  style: { width: 200 },
                },
                content: {
                  label: this.context.t(`sms.headings.content`),
                  limited_line: 1,
                  style: { maxWidth: 400 },
                },
                _: {
                  label: this.context.t(`component.actionHeadingText`),
                },
              }}
              buttons={[
                {
                  icon: "sms",
                  title: this.context.t(`sms.sendButtonText`),
                  textColor: "green",
                  onClick: (row) => {
                    this.setState({
                      showSms: true,
                      sms: { content: row.content, date: row.date },
                    });
                  },
                },
              ]}
              rows={reports}
              loaded={loaded}
              replacements={{
                state: {
                  DELIVERED: (
                    <RoundedRow color="green">
                      <Check /> {this.context.t(`sms.replacements.status[0]`)}
                    </RoundedRow>
                  ),
                  UNDELIVERED: (
                    <RoundedRow color="red">
                      <Close /> {this.context.t(`sms.replacements.status[1]`)}
                    </RoundedRow>
                  ),
                  UNDELIVERABLE: (
                    <RoundedRow color="red">
                      <SmsFailed />{" "}
                      {this.context.t(`sms.replacements.status[2]`)}
                    </RoundedRow>
                  ),
                  PAYBACK_COMPLETED: (
                    <RoundedRow color="purple">
                      <CardMembership />{" "}
                      {this.context.t(`sms.replacements.status[3]`)}
                    </RoundedRow>
                  ),
                  EXPIRED: (
                    <RoundedRow color="red">
                      <SmsFailed />{" "}
                      {this.context.t(`sms.replacements.status[5]`)}
                    </RoundedRow>
                  ),
                  "": (
                    <RoundedRow color="orange">
                      <Timer /> {this.context.t(`sms.replacements.status[4]`)}
                    </RoundedRow>
                  ),
                },
              }}
              pagination={pagination}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={Loader} width="100" height="100" alt="loading" />
            </div>
          )}
        </Column>
      </>
    );
  };

  // tekli-grup (manuel) sms gönderim raporları
  _GroupSMSReports = () => {
    const { groupSMS, group_filter_expanded } = this.state;

    return (
      <>
        {/* Form Control Swicthbox */}
        <Column className={`xs-12 sm-12`}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={group_filter_expanded}
                onChange={async (e) => {
                  await this.setState({
                    group_filter_expanded: e.target.checked,
                  });
                  this.getGroupMessageReports();
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{this.context.t(`receipts.filter.title`)}</span>
              </FCLabel>
            }
          />
        </Column>

        {/* Filter Area */}
        {group_filter_expanded && (
          <Grid className="mt-2 mb-2">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <DatePickerInput
                className="mb-0"
                label={this.context.t(`receipts.filter.startInputLabel`)}
                value={moment(groupSMS.start).format("YYYY-MM-DD")}
                onChange={async (start) => {
                  await this.setState({
                    groupSMS: {
                      ...this.state.groupSMS,
                      start: moment(start).format("YYYY-MM-DD"),
                    },
                  });
                  this.getGroupMessageReports();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <DatePickerInput
                className="mb-0"
                label={this.context.t(`receipts.filter.endInputLabel`)}
                value={groupSMS.end}
                onChange={async (end) => {
                  await this.setState({
                    groupSMS: {
                      ...this.state.groupSMS,
                      end: moment(end).format("YYYY-MM-DD"),
                    },
                  });
                  this.getGroupMessageReports();
                }}
              />
            </Column>
          </Grid>
        )}
        <Column className="xs-12 sm-12">
          {this.state.table_loaded_2 ? (
            <Table
              refreshAction={() =>
                this.getGroupMessageReports(groupSMS.pagination.page)
              }
              headings={{
                date: {
                  label: this.context.t(`sms.table_titles.date`),
                  limited_line: 1,
                  style: { width: 200 },
                },
                count: {
                  label: this.context.t(`sms.table_titles.total`),
                  style: { maxWidth: 100 },
                },
                success: {
                  label: this.context.t(`sms.table_titles.successful`),
                  style: { maxWidth: 100 },
                },
                fail: {
                  label: this.context.t(`sms.table_titles.unsuccessful`),
                  style: { maxWidth: 100 },
                },

                beforeCredit: {
                  label: this.context.t(`sms.table_titles.post_postCredit`),
                  style: { maxWidth: 100 },
                },
                message: {
                  label: this.context.t(`sms.table_titles.content`),
                  limited_line: 1,
                  style: { maxWidth: 400 },
                },
                _: {
                  label: this.context.t(`component.actionHeadingText`),
                },
              }}
              buttons={[
                {
                  icon: "sms",
                  title: this.context.t(`sms.sendButtonText`),
                  textColor: "green",
                  onClick: async (row) => {
                    await this.setState({
                      detailTab: 0,
                      detailState: 1,
                      showGroupSMSDetail: true,
                      groupMessageDetailId: row.id,
                      ReportMessageBody: row.message,
                      successMessage: row.success,
                      failedMessage: row.fail,
                      waitingMessage: row.count - (row.success + row.fail),
                    });
                    this.getSingleGroupMessageReports();
                    setTimeout(() => {
                      window.scrollTo({
                        top: document.body.scrollHeight,
                        left: 0,
                        behavior: "smooth",
                      });
                    }, 150);
                  },
                },
              ]}
              rows={groupSMS.reports}
              loaded={groupSMS.loaded}
              replacements={{
                state: {
                  DELIVERED: (
                    <RoundedRow color="green">
                      <Check /> {this.context.t(`sms.replacements.status[0]`)}
                    </RoundedRow>
                  ),
                  UNDELIVERED: (
                    <RoundedRow color="red">
                      <Close /> {this.context.t(`sms.replacements.status[1]`)}
                    </RoundedRow>
                  ),
                  UNDELIVERABLE: (
                    <RoundedRow color="red">
                      <SmsFailed />{" "}
                      {this.context.t(`sms.replacements.status[2]`)}
                    </RoundedRow>
                  ),
                  PAYBACK_COMPLETED: (
                    <RoundedRow color="purple">
                      <CardMembership />{" "}
                      {this.context.t(`sms.replacements.status[3]`)}
                    </RoundedRow>
                  ),
                  "": (
                    <RoundedRow color="orange">
                      <Timer /> {this.context.t(`sms.replacements.status[4]`)}
                    </RoundedRow>
                  ),
                },
              }}
              pagination={groupSMS.pagination}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={Loader} width="100" height="100" alt="loading" />
            </div>
          )}
        </Column>
      </>
    );
  };

  // otomatik wp gönderim raporları
  _SingleWPReports = () => {
    const { filter_expanded, start, end, phone, automatedWPReports } =
      this.state;
    return (
      <>
        <Column className={`xs-12 sm-12`}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={filter_expanded}
                onChange={async (e) => {
                  await this.setState({
                    filter_expanded: e.target.checked,
                  });
                  this.getAutomatedWPReports();
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{this.context.t(`receipts.filter.title`)}</span>
              </FCLabel>
            }
          />
        </Column>
        {/* Filter Area */}
        {filter_expanded && (
          <Grid className="mt-2 mb-2">
            <Column className="xs-12 sm-12 md-12 lg-4">
              <DatePickerInput
                className="mb-0"
                label={this.context.t(`receipts.filter.startInputLabel`)}
                value={moment(start).format("YYYY-MM-DD")}
                onChange={async (start) => {
                  await this.setState({
                    automatedWPReports: {
                      ...this.state.automatedWPReports,
                      loaded: false,
                    },
                    start: moment(start).format("YYYY-MM-DD"),
                  });
                  this.getAutomatedWPReports();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4">
              <DatePickerInput
                className="mb-0"
                label={this.context.t(`receipts.filter.endInputLabel`)}
                value={end}
                onChange={async (end) => {
                  await this.setState({
                    automatedWPReports: {
                      ...this.state.automatedWPReports,
                      loaded: false,
                    },
                    end: moment(end).format("YYYY-MM-DD"),
                  });
                  this.getAutomatedWPReports();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4">
              <MaskedInput
                label={this.context.t(`['customers/add'].phoneInputLabel`)}
                maskChar={""}
                mask="999 999 9999"
                startAdornment="0"
                value={phone !== null ? phone : ""}
                placeholder="500 000 0000"
                onChange={async (e) => {
                  await this.setState({
                    automatedWPReports: {
                      ...this.state.automatedWPReports,
                      loaded: false,
                    },
                    phone:
                      e.target.value.substring(0, 1) === "5" &&
                      e.target.value.replace(/\s/g, ""),
                  });
                  if (this.state.phone !== undefined) {
                    if (this.state.phone.length >= 5) {
                      this.getAutomatedWPReports();
                    }
                  }
                }}
              />
            </Column>
          </Grid>
        )}
        <Column className="xs-12 sm-12">
          {this.state.table_loaded_3 ? (
            <Table
              refreshAction={() =>
                this.getAutomatedWPReports(
                  this.state.automatedWPReports.pagination.page
                )
              }
              headings={{
                date: {
                  label: this.context.t(`sms.headings.date`),
                  limited_line: 1,
                  style: { width: 200 },
                },
                phone: {
                  label: this.context.t(`sms.headings.phone`),
                  style: { maxWidth: 200 },
                },
                state: {
                  label: this.context.t(`sms.headings.status`),
                  style: { width: 200 },
                },
                content: {
                  label: this.context.t(`sms.headings.content`),
                  limited_line: 1,
                  style: { maxWidth: 400 },
                },
                _: {
                  label: this.context.t(`component.actionHeadingText`),
                },
              }}
              buttons={[
                {
                  icon: "sms",
                  title: this.context.t(`sms.sendButtonText`),
                  textColor: "green",
                  onClick: (row) => {
                    this.setState({
                      showSms: true,
                      sms: { content: row.content, date: row.date },
                    });
                  },
                },
              ]}
              rows={automatedWPReports.reports}
              loaded={automatedWPReports.loaded}
              replacements={{
                state: {
                  DELIVERED: (
                    <RoundedRow color="green">
                      <Check /> {this.context.t(`sms.replacements.status[0]`)}
                    </RoundedRow>
                  ),
                  UNDELIVERED: (
                    <RoundedRow color="red">
                      <Close /> {this.context.t(`sms.replacements.status[1]`)}
                    </RoundedRow>
                  ),
                  UNDELIVERABLE: (
                    <RoundedRow color="red">
                      <SmsFailed />{" "}
                      {this.context.t(`sms.replacements.status[2]`)}
                    </RoundedRow>
                  ),
                  PAYBACK_COMPLETED: (
                    <RoundedRow color="purple">
                      <CardMembership />{" "}
                      {this.context.t(`sms.replacements.status[3]`)}
                    </RoundedRow>
                  ),
                  "": (
                    <RoundedRow color="orange">
                      <Timer /> {this.context.t(`sms.replacements.status[4]`)}
                    </RoundedRow>
                  ),
                },
              }}
              pagination={automatedWPReports.pagination}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={Loader} width="100" height="100" alt="loading" />
            </div>
          )}
        </Column>
      </>
    );
  };

  // tekli-grup (manuel) wp gönderim raporları
  _GroupWPReports = () => {
    const { groupWP, group_filter_expanded } = this.state;

    return (
      <>
        {/* Form Control Swicthbox */}
        <Column className={`xs-12 sm-12`}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={group_filter_expanded}
                onChange={async (e) => {
                  await this.setState({
                    group_filter_expanded: e.target.checked,
                  });
                  this.getGroupMessageReports();
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{this.context.t(`receipts.filter.title`)}</span>
              </FCLabel>
            }
          />
        </Column>

        {/* Filter Area */}
        {group_filter_expanded && (
          <Grid className="mt-2 mb-2">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <DatePickerInput
                className="mb-0"
                label={this.context.t(`receipts.filter.startInputLabel`)}
                value={moment(groupWP.start).format("YYYY-MM-DD")}
                onChange={async (start) => {
                  await this.setState({
                    groupWP: {
                      ...this.state.groupWP,
                      start: moment(start).format("YYYY-MM-DD"),
                    },
                  });
                  this.getGroupMessageReports();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <DatePickerInput
                className="mb-0"
                label={this.context.t(`receipts.filter.endInputLabel`)}
                value={groupWP.end}
                onChange={async (end) => {
                  await this.setState({
                    groupWP: {
                      ...this.state.groupWP,
                      end: moment(end).format("YYYY-MM-DD"),
                    },
                  });
                  this.getGroupMessageReports();
                }}
              />
            </Column>
          </Grid>
        )}
        <Column className="xs-12 sm-12">
          {this.state.table_loaded_4 ? (
            <Table
              refreshAction={() =>
                this.getGroupWPMessageReports(
                  this.state.groupWP.pagination.page
                )
              }
              headings={{
                date: {
                  label: this.context.t(`sms.table_titles.date`),
                  limited_line: 1,
                  style: { width: 200 },
                },
                count: {
                  label: this.context.t(`sms.table_titles.total`),
                  style: { maxWidth: 100 },
                },
                success: {
                  label: this.context.t(`sms.table_titles.successful`),
                  style: { maxWidth: 100 },
                },
                fail: {
                  label: this.context.t(`sms.table_titles.unsuccessful`),
                  style: { maxWidth: 100 },
                },

                beforeCredit: {
                  label: this.context.t(`sms.table_titles.post_postCredit`),
                  style: { maxWidth: 100 },
                },
                message: {
                  label: this.context.t(`sms.table_titles.content`),
                  limited_line: 1,
                  style: { maxWidth: 400 },
                },
                _: {
                  label: this.context.t(`component.actionHeadingText`),
                },
              }}
              buttons={[
                {
                  icon: "sms",
                  title: this.context.t(`sms.sendButtonText`),
                  textColor: "green",
                  onClick: async (row) => {
                    await this.setState({
                      detailTab: 0,
                      detailState: 1,
                      showGroupWPDetail: true,
                      groupMessageDetailId: row.id,
                      ReportMessageBody: row.message,
                      successMessage: row.success,
                      failedMessage: row.fail,
                      waitingMessage: row.count - (row.success + row.fail),
                    });
                    this.getSingleGroupWPReports();
                    setTimeout(() => {
                      window.scrollTo({
                        top: document.body.scrollHeight,
                        left: 0,
                        behavior: "smooth",
                      });
                    }, 150);
                  },
                },
              ]}
              rows={groupWP.reports}
              loaded={groupWP.loaded}
              replacements={{
                state: {
                  DELIVERED: (
                    <RoundedRow color="green">
                      <Check /> {this.context.t(`sms.replacements.status[0]`)}
                    </RoundedRow>
                  ),
                  UNDELIVERED: (
                    <RoundedRow color="red">
                      <Close /> {this.context.t(`sms.replacements.status[1]`)}
                    </RoundedRow>
                  ),
                  UNDELIVERABLE: (
                    <RoundedRow color="red">
                      <SmsFailed />{" "}
                      {this.context.t(`sms.replacements.status[2]`)}
                    </RoundedRow>
                  ),
                  PAYBACK_COMPLETED: (
                    <RoundedRow color="purple">
                      <CardMembership />{" "}
                      {this.context.t(`sms.replacements.status[3]`)}
                    </RoundedRow>
                  ),
                  "": (
                    <RoundedRow color="orange">
                      <Timer /> {this.context.t(`sms.replacements.status[4]`)}
                    </RoundedRow>
                  ),
                },
              }}
              pagination={groupWP.pagination}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={Loader} width="100" height="100" alt="loading" />
            </div>
          )}
        </Column>
      </>
    );
  };

  // tekli-grup (manuel) sms detay raporları
  _SingleGroupSMSReports = () => {
    // this.getSingleGroupMessageReports(this.state.groupMessageDetailId);
    const { singleGroupSMS } = this.state;

    return (
      <Column className="xs-12 sm-12">
        <Table
          isExist={false}
          headings={{
            phone: {
              label: this.context.t(`sms.bottom_table_titles.phone`),
              limited_line: 1,
              style: { width: 200 },
            },
            state: {
              label: this.context.t(`sms.bottom_table_titles.status`),
              style: { width: 300 },
            },
            message: {
              label: this.context.t(`sms.bottom_table_titles.message_content`),
              style: { width: 800 },
              limited_line: 1,
            },
            _: {
              label: this.context.t(`sms.bottom_table_titles.details`),
              style: { width: 1 },
            },
          }}
          buttons={[
            {
              icon: "visibility",
              textColor: "green",
              onClick: (row) => {
                this.getCustomerName(row.phone.slice(2));
                setTimeout(() => {
                  this.setState({
                    group_sms_detail_modal: true,
                    group_sms_detail: {
                      ...this.state.group_sms_detail,
                      message: row.message,
                      phone: row.phone,
                    },
                  });
                }, 250);
              },
            },
          ]}
          rows={singleGroupSMS.reports}
          loaded={singleGroupSMS.loaded}
          replacements={{
            state: {
              DELIVERED: (
                <RoundedRow color="green">
                  <Check /> {this.context.t(`sms.replacements.status[0]`)}
                </RoundedRow>
              ),
              UNDELIVERED: (
                <RoundedRow color="red">
                  <Close /> {this.context.t(`sms.replacements.status[1]`)}
                </RoundedRow>
              ),
              UNDELIVERABLE: (
                <RoundedRow color="red">
                  <SmsFailed /> {this.context.t(`sms.replacements.status[2]`)}
                </RoundedRow>
              ),
              PAYBACK_COMPLETED: (
                <RoundedRow color="purple">
                  <CardMembership />{" "}
                  {this.context.t(`sms.replacements.status[3]`)}
                </RoundedRow>
              ),
              EXPIRED: (
                <RoundedRow color="red">
                  <SmsFailed /> {this.context.t(`sms.replacements.status[5]`)}
                </RoundedRow>
              ),
              "": (
                <RoundedRow color="orange">
                  <Timer /> {this.context.t(`sms.replacements.status[4]`)}
                </RoundedRow>
              ),
            },
          }}
          pagination={singleGroupSMS.pagination}
        />
      </Column>
    );
  };

  // tekli-grup (menuel) wp detay raporları
  _SingleGroupWPReports = () => {
    const { singleGroupWP } = this.state;

    return (
      <Column className="xs-12 sm-12">
        <Table
          isExist={false}
          headings={{
            phone: {
              label: this.context.t(`sms.bottom_table_titles.phone`),
              limited_line: 1,
              style: { width: 200 },
            },
            state: {
              label: this.context.t(`sms.bottom_table_titles.status`),
              style: { width: 300 },
            },
            message: {
              label: this.context.t(`sms.bottom_table_titles.message_content`),
              style: { width: 800 },
              limited_line: 1,
            },
            _: {
              label: this.context.t(`sms.bottom_table_titles.details`),
              style: { width: 1 },
            },
          }}
          buttons={[
            {
              icon: "visibility",
              textColor: "green",
              onClick: (row) => {
                this.getCustomerName(row.phone.slice(2));
                setTimeout(() => {
                  this.setState({
                    group_sms_detail_modal: true,
                    group_sms_detail: {
                      ...this.state.group_sms_detail,
                      message: row.message,
                      phone: row.phone,
                    },
                  });
                }, 250);
              },
            },
          ]}
          rows={singleGroupWP.reports}
          loaded={singleGroupWP.loaded}
          replacements={{
            state: {
              DELIVERED: (
                <RoundedRow color="green">
                  <Check /> {this.context.t(`sms.replacements.status[0]`)}
                </RoundedRow>
              ),
              UNDELIVERED: (
                <RoundedRow color="red">
                  <Close /> {this.context.t(`sms.replacements.status[1]`)}
                </RoundedRow>
              ),
              UNDELIVERABLE: (
                <RoundedRow color="red">
                  <SmsFailed /> {this.context.t(`sms.replacements.status[2]`)}
                </RoundedRow>
              ),
              PAYBACK_COMPLETED: (
                <RoundedRow color="purple">
                  <CardMembership />{" "}
                  {this.context.t(`sms.replacements.status[3]`)}
                </RoundedRow>
              ),
              EXPIRED: (
                <RoundedRow color="red">
                  <SmsFailed /> {this.context.t(`sms.replacements.status[5]`)}
                </RoundedRow>
              ),
              "": (
                <RoundedRow color="orange">
                  <Timer /> {this.context.t(`sms.replacements.status[4]`)}
                </RoundedRow>
              ),
            },
          }}
          pagination={singleGroupWP.pagination}
        />
      </Column>
    );
  };
}

export default SMSIndex;
