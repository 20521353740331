import React, { useContext, useState, useEffect } from "react";
import { Column, Grid } from "../../../../theme/Grid";
import Button from "../../../../theme/Button";
import AppContext from "../../../../context/store";
import { WhatsApp } from "@material-ui/icons";
import PhoneIcon from "@material-ui/icons/Phone";
import styled from "styled-components";
import logo from "../../../../assets/images/logo_en.png";
import { GiSandsOfTime } from "react-icons/gi";

function TimeIsUp() {
  const context = useContext(AppContext);
  const [whatsAppMessage, setWhatsAppMessage] = useState("");

  useEffect(() => {
    setWhatsAppMessage(
      `https://api.whatsapp.com/send?phone=902127060510&text=Merhabalar,%20size%20kampanya%20sayfanız%20%C3%BCzerinden%20ula%C5%9F%C4%B1yorum.`
    );
    console.log(context);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonWrapper>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <img src={logo} style={{ width: "200px" }} />
        </div>
        <div
          style={{
            fontSize: "18px",
            width: "100%",
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          "İlk Alıma Özel" size tanımlanan kampanyanın 24 saatlik süresi
          maalesef doldu. Lütfen size sunabileceğimiz faydalar ve kampanyalar
          ile ilgili, destek uzmanlarımız ile iletişime geçiniz.⌛☹️
        </div>
        <Grid style={{ width: "100%" }}>
          <Column className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => window.open(whatsAppMessage)}
                className="enter_to_branch_btn"
                style={{
                  width: "100%",
                  padding: "20px",
                  backgroundColor: "green",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  border: "none",
                  borderRadius: "30px",
                }}
              >
                <WhatsApp /> <div>WhatsApp ile iletişime geç</div>
              </button>
            </div>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="enter_to_branch_btn"
                onClick={() => window.open(`tel:0${2127060510}`)}
                style={{
                  width: "100%",
                  padding: "20px",
                  backgroundColor: "#1976d2",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  border: "none",
                  borderRadius: "30px",
                }}
              >
                <PhoneIcon />{" "}
                <div>
                  Hemen Ara -{" "}
                  <span style={{ fontSize: "16px" }}>0(212) 706 05 10</span>
                </div>
              </button>
            </div>
          </Column>
        </Grid>
      </ButtonWrapper>
    </div>
  );
}

export default TimeIsUp;

const ButtonWrapper = styled.div`
  width: 40%;
  /* display: flex;
  flexdirection: column;
  gap: 50px;
 */
  @media only screen and (max-width: 576px) {
    width: 80%;
  }
`;
/*
  <Button
              icon="call"
              title={context.t(`businessURL.callButtonTitle`, {
                phone: "5369558897",
              })}
              textColor="white"
              backgroundColor="primary"
              size="md"
              fullWidth
              onClick={() => window.open(`tel:0${5369558897}`)}
            /> */

/* <a href={whatsAppMessage} target="_blank">
                <WhatsApp fontSize="large" color="green" /> WhatsApp ile
                iletişime geç
              </a> */

/*
                 <Button
                title="WhatsApp ile iletişime geç"
                textColor="green"
                backgroundColor="green-opacity"
                size="md"
                fullWidth
                onClick={() => window.open(whatsAppMessage)}
              >
                <WhatsApp />
              </Button> */
