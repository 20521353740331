const langTR = {
  langText: "Türkçe",

  /**
   * İlk açılan pop-up içeriği
   */

  definePopup: {
    title: "Sizin İçin En Uygun Özellikleri Tanımlıyoruz.",
    description:
      "İşletmenizde Randevu Takvimine Ek Olarak, Adisyon ve Muhasebe Özelliğimizi de Kullanmak İster Misiniz?",
    buttons: {
      approve: "Evet",
      cancel: "Hayır",
    },

    succesMessage:
      "Tercihiniz başarıyla kaydedilmiştir. Lütfen tekrar giriş yapınız.",
  },

  /**
   * Giriş sayfasının bulunduğu key.
   * https://app.salonrandevu.com/login
   */
  login: {
    title: "Giriş Yap",
    mailPhoneInputLabel: "E-Posta / Telefon Numarası",
    passInputLabel: "Şifre",
    loginButtonTitle: "Giriş Yap",
    registerButtonTitle: "Hesabınız yok mu? Hemen kayıt olun!",
    forgotPassButtonTitle: "Şifremi Unuttum",
    rememberMe: "Beni Hatırla",

    license: {
      // Modal
      title: "Lisans Kullanım Süreniz Bitti!",
      description:
        "Sayın {company}, panel kullanım süreniz sona ermiştir. İşletmeniz için uygun olacağını düşündüğünüz paketi satın alarak paneli kullanmaya devam edebilirsiniz.",
      buttonTitle: "Paket Satın Almak İçin Bizi Arayın - 0(212) 706 0510",

      standart: {
        type: "KÜÇÜK ÖLÇEKLİ İŞLETMELER",
        title: "STANDART",
        items: [
          "Sınırsız Randevu",
          "Size Özel Randevu Sayfası",
          "Randevu Yönetim Paneli",
          "Firma Profili",
          "Personel Profili",
        ],
      },

      premium: {
        type: "ORTA VE BÜYÜK ÖLÇEKLİ İŞLETMELER",
        title: "PREMIUM",
        items: [
          "Standart Paketin Tüm Özellikleri",
          "Adisyon Yönetimi",
          "Gelir - Gider Takibi",
          "Danışan Puan Sistemi",
          "Danışan Memnuniyet Analizi",
        ],
      },
    },

    downloadApp: "QR Kodu Okutarak Hemen Uygulamayı İndir.",
  },

  /**
   * Kayıt sayfasının bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  register: {
    title: "Kayıt Ol",
    trialText: "7 Gün tüm özellikler tamamen ÜCRETSİZ!",
    noPaymentText: "Ödeme yok, Taahhüt yok, İptal ücreti yok.",
    privacyPolicyText: "Gizlilik Sözleşmesini okudum ve kabul ediyorum.",

    continueButtonTitle: "Devam Et",
    completeButtonTitle: "Kaydı Tamamla",
    alreadyUserButtonTitle: "Zaten üye misiniz? Giriş yapın!",

    companyNameInputLabel: "İşletme Adı",
    phoneInputLabel: "Telefon Numarası",
    campaingCodeInputLabel: "Kampanya kodunuz varsa giriniz",
    staffNameInputLabel: "Yetkili Adı",
    staffSurnameInputLabel: "Yetkili Soyadı",
    staffMailInputLabel: "E-Posta Adresi",
    passwordInputLabel: "Şifre",
  },

  /**
   * Şifremi unuttum sayfasının bulunduğu key.
   * https://app.salonrandevu.com/forgot-pass
   */
  forgotPass: {
    title: "Şifremi Unuttum & Sıfırla",

    stepText: "ADIM",
    firstStepText:
      "Bu adımda personel profilinize bağlı olan telefon numaranız aşağıdaki alana girilmelidir. Telefonunuza göndereceğimiz SMS'teki doğrulama kodunu sonraki aşamada girerek yeni şifrenizi oluşturabilirsiniz.",
    secondStepText:
      "Girmiş olduğunuz telefon numarasına doğrulama kodunu SMS olarak gönderdik. Aşağıdaki alanlara yeni şifrenizi ve size gönderilen doğrulama kodunu girerek şifrenizi güncelleyebilirsiniz.",

    continueButtonTitle: "Devam Et",
    backToHomeButtonTitle: "Giriş Sayfasına Dön",
    updatePassButtonTitle: "Şifreyi Güncelle",

    phoneInputLabel: "Telefon Numarası",
    verificationCodeInputLabel: "Doğrulama Kodu",
    newPassInputLabel: "Yeni Şifreniz",

    verificationSuccessfullySentToast: "Doğrulama kodu başarıyla gönderildi.",
    verificationErrorToast: "Doğrulama kodu gönderilemedi.",
    validPhoneErrorToast: "Telefon numaranızı eksiksiz giriniz.",
    passwordSuccessfullyChangedToast: "Şifreniz başarıyla değiştirildi.",
    warningStatusToast: "Hatalı durum, şifreniz değiştirilemedi.",
  },

  /**
   * Panel içerisindeki sol menü ve alt elemanların bulunduğu key.
   */
  menuItems: {
    dashboard: { title: "Genel Bakış" },
    profile: { title: "Profil Yönetimi" },
    wallet: { title: "Bakiye İşlemleri" },
    notify: { title: "Ödeme Bildirimi" },
    todos: { title: "Yapılacaklar Listesi" },

    appointment: {
      title: "Randevu Takvimi",
      children: {
        index: "Randevu Takvimi",
        filter: "Randevuları Filtrele",
        online: "Online Randevu Talepleri",
        add: "+ Yeni Randevu Oluştur",
      },
    },
    receipt: {
      title: "Adisyon Yönetimi",
      children: {
        index: "Adisyonları Listele",
        recordAdd: "Kayıtlı Danışan İçin Adisyon Oluştur",
        newAdd: "Yeni Danışan İçin Adisyon Oluştur",
      },
    },
    packages: {
      title: "Paket Yönetimi",
      children: {
        sales: "Paket Satışları Listesi",
        salesAdd: "Paket Satışı Yap",
        list: "Paket Listesi",
        add: "+ Yeni Paket Oluştur",
        unbookedPackages: "Randevusu Oluşturulmamış Paketler",
      },
    },
    products: {
      title: "Stok Yönetimi",
      children: {
        index: "Ürün Listesi",
        add: "+ Yeni Ürün Oluştur",
      },
    },
    customers: {
      title: "Danışan İşlemleri",
      children: {
        active: "Danışan Listesi",
        passive: "Pasif Danışanlar",
        risky: "Kayıp Danışanlar",
        rating: "Danışan Yorumları",
        add: "+ Yeni Danışan Oluştur",
        addBulk: "+ Excel ile Toplu Danışan Oluştur",
      },
    },
    services: {
      title: "Hizmet Yönetimi",
      children: {
        index: "Hizmet Listesi",
        add: "+ Yeni Hizmet Oluştur",
        requests: "Hizmet Adı Talepleri",
        bounty: "Özel Prim Oluştur",
      },
    },
    employees: {
      title: "Personel Yönetimi",
      children: {
        index: "Personelleri Listele",
        add: "+ Yeni Personel Oluştur",
      },
    },
    sms: {
      title: "Mesaj Yönetimi",
      children: {
        index: "Mesaj Raporları",
        sendSelective: "Toplu Mesaj Gönder",
        sendFilter: "Filtreli Mesaj Gönder",
        sendGroup: "Gruplara Mesaj Gönder",
        settings: "SMS Ayarları",
        blacklist: "SMS Kara Liste",
        template: "Şablon Ayarları",
        wpSettings: "WhatsApp Ayarları",
      },
    },
    accounting: {
      title: "Muhasebe Yönetimi",
      children: {
        income: "Gelir İşlemleri",
        debt: "Borç İşlemleri",
        bounty: "Prim İşlemleri",
        expense: "Gider İşlemleri",
        expenseTypes: "Gider Kalemleri",
        credit: "Alacaklandırma",
        debit: "Borçlandırma",
      },
    },
    reports: {
      title: "Gelişmiş Raporlar",
      children: {
        company: "İşletme Raporları",
        service: "Hizmet Raporları",
        stock: "Ürün Raporları",
        staff: "Personel Raporları",
        customer: "Danışan Raporları",
        appointment: "Randevu Raporları",
      },
    },
    platform: {
      title: "Platform Yönetimi",
      children: {
        photo: "Vitrin Fotoğrafı",
        settings: "Platform Ayarları",
        category_settings: "Kategori Ayarları",
      },
    },
    settings: {
      title: "Sistem Ayarları",
      children: {
        index: "İşletme Ayarları",
        hours: "Çalışma Saatleri",
        gallery: "İşletme Galerisi",
        service_gallery: "Hizmet Galerisi",
        logs: "İşlem Kayıtları",
        branchAdd: "Yeni Şube Oluştur",
        giveAReferans: "Referans Ol",
      },
    },
    gallery: {
      title: "Galeri Yönetimi",
      children: {
        platform_photo: "Vitrin Fotoğrafı",
        enterprise_gallery: "İşletme Galerisi",
        service_gallery: "Hizmet Galerisi",
      },
    },
    agreement: {
      settings: "Sözleşme Ayarları",
    },
    support: {
      title: "Canlı Destek",
    },
  },

  /**
   * Panel içerisindeki üst menü ve açılan popuplarının bulunduğu key.
   */
  scaffold: {
    translateViewHeading: "SİSTEM DİLİ",
    logsViewHeading: "SİSTEM LOGLARI",
    notificationViewHeading: "BİLDİRİMLER",
    quickActionsViewHeading: "HIZLI İŞLEMLER",

    logsViewAllButtonTitle: "Tümünü Gör",

    notificationFound: "Yeni bildirimleriniz var!",
    notFoundNotification: "Henüz bir bildiriminiz bulunmuyor!",

    QAItems: {
      createCustomer: "Yeni Danışan Oluştur",
      createAppointment: "Yeni Randevu Oluştur",
      createReceipt: "Yeni Adisyon Oluştur",
      createPackage: "Yeni Paket Oluştur",
      createPackageSale: "Yeni Paket Satışı",
      createService: "Yeni Hizmet Oluştur",
      createEmployee: "Yeni Personel Oluştur",
      showIncomes: "Gelirleri Görüntüle",
      showExpenses: "Giderleri Görüntüle",
      showDebts: "Borçları Görüntüle",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/branches
   */
  branches: {
    heroHeadingText: "Salonrandevu Çoklu Şube Yönetimi",
    greetingText: "Hoşgeldin",
    greetingBottomText:
      "Aşağıdaki alanda yönetiminizde olan şubeleriniz listelenmektedir.",
    greetingBottomText2:
      "Tablonun sağ tarafında bulunan buttonlar ile herhangi bir şubenin giriş bilgilerini güncelleyebilir ya da ilgili şubeye giriş yapabilirsiniz.",

    logoutButtonTitle: "Çıkış Yap",
    loginBranchButtonTitle: "Şubeye Giriş",
    editStaffButtonTitle: "Bilgileri Düzenle",
    completeEditingButtonTitle: "Düzenlemeyi Tamamla",
    discardEditingButtonTitle: "Vazgeç",
    createBranchButtonTitle: "Şubeyi Oluştur",
    licenseExpiredToast: "Lisans Süresi Dolmuş Kullanıcı",

    tabBranchListTitle: "Şubeleri Listele",
    tabBranchReportsTitle: "Şubele Raporları",
    tabBranchAddTitle: "+ Yeni Şube Oluştur",

    headings: {
      listTable: {
        id: "İşletme Kodu",
        companyName: "İşletme/Şube Adı",
        staffFullName: "Yetkili Adı Soyadı",
        staffPhoneNumber: "Yetkili Telefonu",
        staffMail: "Yetkili E-Postası",
        licenseEndDate: "Lisans Bitişi",
        refresh: "Yenile"
      },
      reportsTable: {
        companyName: "İşletme/Şube Adı",
        staffCount: "Personel Sayısı",
        appointmentCount: "Randevu Sayısı",
        receiptCount: "Adisyon Sayısı",
        serviceIncome: "Hizmet Geliri",
        saleIncome: "Ürün Satış Geliri",
        staffBounty: "Ödenen Prim",
        totalIncome: "Toplam Gelir",
        totalExpense: "Toplam Gider",
        totalCount: "Net Gelir",
      },
    },

    createBranch: {
      companyNameInputLabel: "İşletme Adı",
      staffNameInputLabel: "Yetkili Adı",
      staffSurnameInputLabel: "Yetkili Soyadı",
      commonMailInputLabel: "E-Posta Adresi",
      commonPhoneInputLabel: "Telefon Numarası",
      passInputLabel: "Şifre",
    },

    licenseExpiredLoginToast:
      "Giriş yapmak istenilen hesabın lisans süresi sona ermiş.",
    licenseExpiredEditToast:
      "Düzenlenmek istenilen hesabın lisans süresi sona ermiş.",
    staffUpdateToast: "Personel başarıyla güncellendi.",
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/give-reference
   */
  referance: {
    createReferanceButtonTitle: "Referans Ol",
    createReferance: {
      companyNameInputLabel: "İşletme Adı",
      staffNameInputLabel: "Yetkili Adı ve Soyadı",
      commonMailInputLabel: "E-Posta Adresi",
      commonPhoneInputLabel: "Telefon Numarası",
    },
  },

  /**
   * Genel Bakış sayfasının bulunduğu key.
   * https://app.salonrandevu.com/dashboard
   */
  dashboard: {
    accountInstallCard: {
      heading:
        "Hesap kurulumunuzu aşağıdaki basamaklardan takip edebilirsiniz!",
      description:
        "İlgili adımın üzerine tıklayarak işlem sayfasına gidebilirsiniz.",

      setShift: "Mesai saatlerinizi ayarlayın",
      createService: "Hizmetlerinizi oluşturun",
      setServicePicked: "Personellerin sunduğu hizmetleri seçin",
      createCustomer: "Danışanlarınızı oluşturun",
    },

    tutorialModal: {
      startHeading: "Hesap Kurulumuna Başla",
      startDescription:
        "Sadece üç temel adımda sistem gereksinimlerini tamamlayarak hesap kurulumunuzu tamamlayın.",

      startTutorialButton: "Kurulumu Başlat",
      dontShowAgainButton: "Bir Daha Gösterme",
    },

    descriptionTutorial: {
      prevButtonTitle: "Önceki",
      nextButtonTitle: "Sonraki",
      finishButtonTitle: "Bitir",
    },

    businessURLCard: {
      heading:
        "İşletme adresinizi Danışanlarınızla paylaşarak randevularınızı kolayca yönetin!",
      inputLabel: "İşletme Randevu URL'si",
      openBrowserButton: "Tarayıcıda Aç",
      copyLinkButton: "Linki Kopyala",
      copySuccessToast: "Link başarıyla kopyalandı!",
    },

    usageCard: {
      heading: "Kalan kullanımlarınızı aşağıdan kolayca takip edebilirsiniz.",

      licenseDetail: "Lisans Detayı",
      packageText: "PAKET",
      licenseRemaining: "gün kaldı",

      smsDetail: "Kalan SMS Kredisi",

      avaible_appointment_credit: "Kalan Randevu Kredisi",
    },

    stats: {
      appointment: {
        heading: "Randevu İstatistikleri",
        createdAppointments: "Oluşturulmuş Randevular",
        successAppointments: "Sonuçlanan Randevular",
        closedAppointments: "Sonuçlanmayan Randevular",
      },

      customer: {
        heading: "Danışan İstatistikleri",
        newCustomers: "Gelen Yeni Danışanlar",
        oldCustomers: "Gelen Eski Danışanlar",
        appointmentCustomers: "Randevulu Danışanlar",
      },

      accounting: {
        heading: "Kasa İstatistikleri",
        income: "Gelir Toplamı (₺)",
        expense: "Gider Toplamı (₺)",
        debt: "Borç Toplamı (₺)",

        licenseUpgradeText: "Hesabınızı Yükseltin!",
      },

      meta: {
        total: "Toplam Randevu",
        arrived: "Geldi",
        notArrived: "Gelmedi",
        waiting: "Yeni",
      },
    },

    cardTitle: {
      receipt_income_card_title: "Kasa İstatistikleri",
      appointment_calender_card_title: "Randevu Takvimi",
      appointments_card_title: "Randevu Ayrıntıları",
    },

    cardDescription: {
      favorites_description:
        "En sık kullandığınız sekmeleri yıldızlayarak kendinize özel kısayollar oluşturabilirsiniz. Ayrıca sürükle bırak ile cardların yerlerini değiştirebilirsiniz.",
      receipt_income_card_description:
        "Günlük, 7 günlük ya da 30 günlük kasa istatiğinizi gelir grafiği üzerinden takip edebilirsiniz.",
      appointment_calender_card_description:
        "Randevu ayrıntılarını görüntülemek istediğiniz günün üzerine tıklayınız.",
      appointment_statistic_card_description:
        "Platform, işletme linki ve panel üzerinden oluşturulan randevu sayısını günlük, 7 günlük ya da 30 gün arayla istatistiksel veriler aracılığıyla raporlayabilirsiniz.",
      appointments_card_description:
        "İşletmenizin günlük, 7 günlük ya da 30 gün arayla aldığı randevu sayısını ve danışanlarınız gelip/gelmediği bilgisini takip edebilirsiniz.",
      online_appointment_request_card_description:
        "İşletme linkiniz üzerinden danışanlarınızdan gelen online randevu taleplerini takip ederek randevuyu onaylayabilir ya da reddedebilirsiniz.",
    },
    PlatformLink: {
      btnText: "Randevu Alma Sayfası",
      title: "Randevu Alma Sayfası",
      description:
        "Bu alandan danışanlarınıza işletmenize randevu talebi oluşturması için gerekli olan linke ulaşabilirsiniz. Bu linkten danışanlarınızın personel, hizmet, gün ve saat bilgilerini girerek randevu talebi oluşturabilir.",
      copyLink: "Linki Kopyala",
      goToLink: "Sayfaya Git",
      linkCopied: "Link Kopyalandı.",
      Qr: "QR Kodu Gör",
    },

    favoritePage: {
      sectionHeading: "Favoriler",
    },

    report: {
      company: {
        typeCASH: "Nakit",
        typeCARD: "Kart",
        typeEFT: "Havale/EFT",
        totalIncome: "Toplam Gelir",
        noDataText:
          "Nakit, Kredi/Banka Kartı ve Havale/EFT gelirleriniz mevcut olmadığı için grafik görüntülenemiyor.",
      },
    },

    appointmentAnalys: {
      platform: "SalonRandevu.com",
      company: "İşletme Websitesi",
      panel: "İşletme Tarafından",
      packageWarningText:
        "Randevu İstatistikleri' ni, sadece Premium pakete sahip hesaplar görüntüleyebilir.",
      upgradePackage: "Paket Yükselt",
    },

    waitingAppointments: {
      sectionHeading: "Online Randevu Talepleri",
      headings: {
        appointmentStartDate: "Randevu Saati",
        customerFullName: "İlgilenilecek Danışan",
        staffFullName: "İlgilenecek Personel",
        serviceName: "Yapılacak İşlem",
      },

      buttons: {
        openReceiptTitle: "Adisyonu Aç",
      },
    },

    onlineAppointments: {
      headings: {
        appointmentStartDate: "Tarih - Saat",
        customerFullName: "İlgili Danışan",
        customerPhone: "Telefon",
        staffFullName: "Personel",
        serviceName: "Hizmet",
        note: "Not",
        customerType: "Danışan Tipi",
        statistics: "İstatistik",
      },

      approveButtonTitle: "Onayla",
      rejectButtonTitle: "Reddet",

      rejectConfirm: {
        title: "Randevu iptalini onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",

        alertBoldText: "Bu işlem geri alınamaz!",
        alertText:
          "Bu isteği reddetmeniz durumunda bir daha bu randevu isteğine erişemezsiniz.",
      },

      approveToast: "Randevu isteği başarıyla onaylandı!",
      rejectToast: "Randevu isteği başarıyla reddedildi!",
    },
    openAppointments: {
      headings: {
        date: "Tarih - Saat",
        customerFullName: "İlgili Danışan",
        products: "Ürün Adet",
        serviceName: "Hizmet Adet",
        totalPayment: "Toplam Tutar",
      },
      title: "Açık Adisyonlar",
      approveButtonTitle: "Onayla",
      rejectButtonTitle: "Reddet",

      rejectConfirm: {
        title: "Randevu iptalini onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",

        alertBoldText: "Bu işlem geri alınamaz!",
        alertText:
          "Bu isteği reddetmeniz durumunda bir daha bu randevu isteğine erişemezsiniz.",
      },

      approveToast: "Randevu isteği başarıyla onaylandı!",
      rejectToast: "Randevu isteği başarıyla reddedildi!",
    },
    creditors: {
      headings: {
        customerFullName: "İlgili Danışan",
        type: "Tip",
        paymentDate: "Planlanan Ödeme Tarihi",
        totalPayment: "Toplam Tutar",
      },

      title: "Alacaklılar",
      approveButtonTitle: "Onayla",
      rejectButtonTitle: "Reddet",

      rejectConfirm: {
        title: "Randevu iptalini onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",

        alertBoldText: "Bu işlem geri alınamaz!",
        alertText:
          "Bu isteği reddetmeniz durumunda bir daha bu randevu isteğine erişemezsiniz.",
      },

      approveToast: "Randevu isteği başarıyla onaylandı!",
      rejectToast: "Randevu isteği başarıyla reddedildi!",
    },
    birthDay: {
      headings: {
        customerFullName: "İlgili Danışan",
        customerPhone: "Telefon Numarası",
        birthDate: "Doğum Tarihi",
      },
      title: "Yaklaşan Doğum Günleri",
    },
    openReceiptOnSuccessToast: "Randevuya ait adisyon başarıyla oluşturuldu!",
  },

  /**
   * Randevu tablosun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments
   */
  appointments: {
    dayInputLabel: "Gösterilecek Randevuların Tarihi",
    beforeDayButtonTitle: "Önceki Gün",
    afterDayButtonTitle: "Sonraki Gün",
    today: "Bugün",
    allStaff: "Bütün Personeller",
    dayWeekSelectLabel: "Gün-Hafta-Aylık",

    // Salon Randevu
    definitionGreenText:
      "Telefon ya da link üzerinden katılım onayı alınmış randevular.",
    definitionRedText:
      "Randevu saati geçmiş ve gelinmemiş randevuları temsil etmektedir.",
    definitionYellowText:
      "Randevu saati henüz geçmemiş ve bekleyen randevuları temsil etmektedir.",

    // En Randevu
    definitionGreenText_en:
      '"Geldi" olarak işaretlenen randevuları temsil eder.',
    definitionRedText_en:
      '"Gelmedi" olarak işaretlenen randevuları temsil eder.',
    definitionYellowText_en:
      'Henüz işlem yapılmamış , "Bekleme" durumunda olan randevuları temsil eder.',

    create: {
      title: "Seçili Tarihe Randevu Oluştur",
      completeButtonTitle: "Tamamla",
      completePaymentButtonTitle: "Ödemeyi Tamamla",
      discardButtonTitle: "Vazgeç",

      selectedTimeInputLabel: "Randevu Günü ve Saati",
      selectedStaffInputLabel: "Personel Seçimi",
      selectedServiceInputLabel: "Personelin Sunduğu Hizmetler",
      selectedPackageInputLabel: "Personelin Sunduğu Paketler",
      selectedCustomerInputLabel: "Randevu Verilecek Danışan",
      selectedCustomerInputPlaceholder:
        "Danışan ismini veya telefon numarasını bu alana giriniz.",
      createNewCustomerButtonTitle: "Yeni Danışan Oluştur",
      createNewWalkinCustomerButtonTitle: "Kayıtsız Danışan Oluştur",

      selecting_service: "Hizmet Seçimi",
      selecting_package: "Paket Seçimi",

      closeAllDayTitle: "Tüm Günü Kapat",

      // sendSmsInputLabel: "Bilgilendirme SMS Gönderimi",
      sendSmsInputLabel: "Bilgilendirme Mesajı Gönder",
      sendSmsTrue: "Gönderilsin",
      sendSmsFalse: "Gönderilmesin",
      description: "Açıklama",
      defaultValue: "Danışan Seçiniz",


      groupAppointment: "Toplu Randevu Oluşturma",
      clockOff: "Saat Kapatma",
      addEmployeeButtonTitle: "Personel + Hizmet Ekle",

      noteInputLabel: "Randevu Notu (İsteğe Bağlı)",

      onWarningToast:
        "Danışan seçimi ve servis seçimi alanlarını eksiksiz giriniz!",

      noDataText:
        "Toplu Randevu oluşturmak için en az 2 personel ve hizmet seçmelisiniz.",
    },

    detail: {
      title: "Randevu Detayı",
      deleteButtonTitle: "Randevuyu İptal Et",
      closeButtonTitle: "Kapat",

      deleteButton: "Sil",
      editButton: "Düzenle",
      clockOffDetail: "Saat Kapatma Detayı",
      clockOffDateInputLabel: "Kapanan Tarih/Saati",
      clockOffStaffInputLabel: "İlgili Personel",
      clockOffNoteInputLabel: "Açıklama",

      appointmentTime: "Randevu Süresi (DK)",
      appointmentTimeExtension: "Randevu Süresini Uzatın",
      appointmentalAlertMessage:
        "Randevu saatiniz uzatılmıştır. Güncel randevu saatini Randevu Takviminden görüntüleyebilirsiniz.",
      appointmentalWarningMessage: "Lütfen randevu süresini giriniz.",

      dateInputLabel: "Randevu Tarih/Saati",
      serviceInputLabel: "Uygulanacak Hizmet",
      staffInputLabel: "İlgilenecek Personel",
      customerInputLabel: "Danışan Adı-Soyadı",
      customerPhoneInputLabel: "Danışan Telefon Numarası",
      noteInputLabel: "Randevu Notu",
      customerNote: "Danışan Notu",
      appointmentStatus: {
        title: "Randevu Durumu",
        arrived: "Geldi",
        notArrived: "Gelmedi",
        waiting: "Bekleniyor",

        changeStatusSuccessToast: "Randevu durumu başarıyla değiştirilmiştir.",
      },
    },

    deleteConfirm: {
      title: "Randevu İptal Onayı",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "SMS gönderim ayarlarınızın açık olması ve SMS bakiyenizin olması durumunda danışana randevunun iptali konusunda bilgi SMS'i gönderilecektir.",
    },

    pastCreateErrorToast:
      "Geçmiş bir tarih-saate randevu oluşturmayı denediniz.",
    onMouseOverCardToast:
      "Randevu detayını görmek istediğiniz kartın üzerine tıklayabilirsiniz.",
    insertToast: "Randevu başarıyla oluşturuldu.",
    deleteToast: "Randevu başarıyla silindi.",

    weeklyAppointmentsStaffErrorToast:
      "Haftalık randevular için lütfen bir personel seçiniz.",
    weeklyAppointmentsDisplayErrorToast:
      "Randevu alabilmek için günlük randevuları görüntüleyiniz.",

    checkButton: "Anladım",
    firstLoginAppointmentsTitle: "BİLGİLENDİRME",
    firstLoginAppointmentsDescription:
      "Sizin için örnek personel bilgisi ve randevu kartı oluşturulmuştur. Personel bilgilerinizi ve randevularınızı mevcut sekmelerin üzerine tıklayarak güncelleyebilirsiniz.",

    updateAppointment: {
      title: "Randevu personelini ve/veya saatini değiştirmek istiyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Mevcut randevuyu güncellemeniz halinde personel ve/veya saat bilgisi değişecektir.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      service: "Hizmet Adı",
      staff: "Personel",
      time: "Saat",
      customer: "Danışan",

      updateAppointmentSuccessToast: "Randevu başarıyla güncellenmiştir.",
    },

    orderStaffsButtonTitle: "Personelleri Sırala",

    rightButtonTooltip: "Tabloyu sağa sürükle",
    leftButtonTooltip: "Tabloyu sola sürükle",

    createAppointmentButtonTitle: "Randevu Oluştur",
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/filter
   */
  "appointments/filter": {
    alertBoldText:
      "Aşağıdaki alanları kullanarak randevularınızı filtreleyebilirsiniz.",
    alertText:
      "Alanlarda değişiklik yapmanız durumunda otomatik olarak filtreleme işlemi yapılmaktadır.",

    startInputLabel: "Başlangıç Tarihi",
    endInputLabel: "Bitiş Tarihi",
    staffInputLabel: "Personel Seçimi",
    allStaffsText: "Tüm Personeller",
    serviceInputLabel: "Hizmet Adı",
    customerInputLabel: "Danışan Adı Soyadı",

    tabMenuLeft: {
      customerBill: "Danışan Bilgileri",
      customerAppointment: "Adisyonlar",
      customerDetails: "Randevular",
      customerPhotos: "Fotoğraflar",
      customerContract: "Sözleşmeler",
      updateCustomerDetailsTrue: "Danışan bilgileri başarıyla güncellendi!",
      updateCustomerDetailsFalse: "Danışan bilgileri güncellenemedi!",
    },

    statusInputLabel: "Randevu Durumu Seçimi",
    statusInputItems: [
      "Tüm Randevular",
      "Gelinen Randevular",
      "Gelinmeyen Randevular",
      "Bekleyen Randevular",
      "İptal Edilen Randevular",
    ],

    filterTitle: "Tarih Filtresi",

    canceled: "İptal Edilmiş",
    situations: "Durumlar",

    headings: {
      appointmentStartDate: "Randevu Tarihi",
      customerFullName: "Danışan",
      serviceName: "Hizmet",
      appointmentNote: "Randevu Notu",
      staffFullName: "İlgilenecek Personel",
      customerPhone: "Danışan Telefonu",
      createdAt: "Randevu Oluşturulma Tarihi",
    },
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/online
   */
  "appointments/online": {
    alertText:
      "Aşağıdaki alanda onaylanması durumunda işlemi sizin uygulayacağınız online randevu talepleri listeletilmektedir. Bu randevuları yalnızca salon yetkilisi onaylayıp/reddedebilir.",
    headings: {
      appointmentStartDate: "Tarih - Saat",
      customerFullName: "İlgili Danışan",
      customerPhone: "Danışan Telefonu",
      staffFullName: "İlgilenecek Personel",
      serviceName: "Hizmet",
      note: "Danışanın Randevu Notu",
      customerType: "Danışan Tipi",
    },

    approveButtonTitle: "Onayla",
    rejectButtonTitle: "Reddet",

    rejectConfirm: {
      title: "Randevu iptalini onaylıyor musunuz?",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu isteği reddetmeniz durumunda bir daha bu randevu isteğine erişemezsiniz.",
    },

    approveToast: "Randevu isteği başarıyla onaylandı!",
    rejectToast: "İşlem başarıyla gerçekleştirildi!",
    blackListWarning:
      " Numara kara listeye eklendikten sonra bu numara ile randevu talebinde bulunulamaz.",
    addToNumberBlackList: " Bu numarayı kara listeye eklemek istiyor musunuz?",
  },

  /**
   * Randevu Oluştur sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/add
   */
  "appointments/add": {
    staffInputLabel: "Personel Seçimi",
    serviceInputLabel: "Personelin Sunduğu Hizmetler",
    customerInputLabel: "Randevu Verilecek Danışan",
    customerInputPlaceholder: "Danışan ismini bu alana giriniz",
    //sendSmsInputLabel: "Bilgilendirme SMS Gönderimi",
    sendSmsInputLabel: "Bilgilendirme SMS' i Gönder",
    sendSmsTrue: "Gönderilsin",
    sendSmsFalse: "Gönderilmesin",
    noteInputLabel: "Randevu Notu (İsteğe Bağlı)",

    availableHoursButtonTitle: "Uygun Randevu Saatlerini Getir",
    confirmButtonTitle: "Randevu Seçimini Onayla",
    confirmButtonHoursTitle: "Saat Seçimini Onayla",
    createNewCustomerButtonTitle: "Yeni Danışan Oluştur",
    createNewWalkinCustomerButtonTitle: "Yeni Danışan Oluştur",

    insertToast: "Randevu başarıyla oluşturuldu.",
  },

  /**
   * Adisyonların listeletildiği sayfaların bulunduğu key.
   * https://app.salonrandevu.com/receipts
   * https://app.salonrandevu.com/packages/sales
   */
  receipts: {
    filter: {
      title: "Tarih ve Danışan Bazlı Filtreleme",
      startInputLabel: "Başlangıç Tarihi",
      endInputLabel: "Bitiş Tarihi",
      typeOfSales: "Satış Türü",

      allSales: "Tüm Satışlar",
      openTitle: "Hepsi",
      open: "Açık Satışlar",
      close: "Kapalı Satışlar",
    },

    previous: "Önceki Sayfa",
    next: "Sonraki Sayfa",

    headings: {
      id: "Adisyon No",
      type: "Satış Türü",
      billType: "Adisyon Türü",
      createdAt: "Açılma Tarihi",
      customerFullName: "Danışan Adı",
      info: "Adisyon İçeriği",
      allAmount: "Toplam",
      paid: "Ödenen",
      debtTotal: "Kalan",
      processDate: "İşlem Tarihi",
    },

    replacements: {
      undefinedCustomer: "Tanımlanmamış Danışan",
      allSales: "Tüm Satışlar",
      packageSale: "Paket",
      standartSale: "Standart",
    },

    add: {
      foreingPhoneNumberPlaceholder: "Yabancı Telefon Numarasını Giriniz.",
    },

    showButtonTitle: "Görüntüle",
    deleteButtonTitle: "Sil",

    detailButtons: {
      confirmButton: "Onayla",
      closeButton: "Kapat",
    },
    alert_receipt_installment:
      "Adisyonda, ödeme beklenen tarihi geçmiş taksit bulunmaktadır.",
  },

  packages: {
    filter: {
      title: "Paket Bazlı Filtreleme",
      packageInputLabel: "Paket Adı",
    },
  },

  /**
   * Adisyonların oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/receipts/add
   */
  "receipts/add": {
    searchCustomerInputLabel: "Danışan Ara",
    searchCustomerInputPlaceholder: "Danışan isim soyismini bu alana giriniz",
    receiptNoteInputLabel: "Adisyon Notu (İsteğe Bağlı)",

    openReceiptBySelectedButtonTitle: "Adisyonu Oluştur",
    openReceiptByNewCustomerButtonTitle:
      "Yeni Danışan Üzerinden Adisyon Oluştur",
    openReceiptByRandomCustomerButtonTitle:
      "Kayıtsız Danışan Üzerinden Adisyon Oluştur",
    openReceiptWithCreatedNewCustomerButtonTitle: `Danışan ve Adisyonu Oluştur`,

    insertToast: "Adisyon başarıyla oluşturuldu!",
  },

  /**
   * Tekli adisyonun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/receipts/detail/:id
   */
  "receipt/detail/id": {
    customerPoint: "Danışan Puanı",
    remainingDebt: "Toplam Borç",
    saveChangesButtonTitle: "Değişiklikleri Kaydet",

    applyDiscountButtonTitle: "İndirim Uygula",
    addPackageSaleButtonTitle: "Paket Satışı Ekle",
    addServiceTransactionButtonTitle: "Hizmet Satışı Ekle",
    addStockSaleButtonTitle: "Yeni Ürün Satışı Ekle",
    addPaymentButtonTitle: "Yeni Ödeme Ekle",

    installmentButtonTitle: "Taksitli Ödeme Ekle",
    installmentCountInputLabel: "Taksit Sayısı",
    installmentProcess: "Taksitlendirme işlemi",
    addPaymentTitle: "Peşin Ödeme",

    deleteReceiptButtonTitle: "Adisyonu Sil",
    alertText:
      "Mesaj kaydedilmiştir fakat mesajın iletilmesi için öncelikle adisyonu kaydetmelisiniz.",

    sms: {
      sendButtonTitle: "SMS'i Kaydet",
      discardButtonTitle: "İptal",
      addSmsButtonTitle: "Tahsilat Mesajı Gönder",
      smsButtonTitle: "Borç Bilgilendirme / Tahsilat Mesajı Gönder",
    },

    creationdate: "Oluşturulma Tarihi",

    point: "puan",
    packageInner: {
      title: "Paket İçeriği",
      headings: {
        // Table Headings
        name: "Hizmet Adı",
        count: "Toplam Seans",
        completed: "Tamamlanan Seans",
        pending: "Bekleyen Seans",
      },
    },

    serviceTransactions: {
      // Section
      print: "Yazdır",
      contract: "Sözleşme",
      soldTitle: "Yeni Hizmet Satışı",
      title: "Hizmet Satışları",
      packageTitle: "Paket Satışları",
      packageTotalAmount: "Paket Toplamı",
      dateInputLabel: "İşlem Tarihi",
      packageDateInputLabel: "Paket Başlangıcı",
      nameInputLabel: "İşlem Adı",
      transactionStatus: "Durum",
      staffInputLabel: "Personel",
      amountInputLabel: "Ücret",
      totalAmount: "Hizmet Toplamı",
      noDataText: "Adisyona henüz bir hizmet satışı eklenmemiş!",
    },

    stockSales: {
      // Section
      title: "Ürün Satışları",
      nameInputLabel: "Ürün Adı",
      deletedName: "Silinmiş Ürün",
      staffInputLabel: "Satışı Yapan",
      amountInputLabel: "Birim Fiyat",
      countInputLabel: "Adet",
      totalAmount: "Ürün Toplamı",
      noDataText: "Adisyona henüz bir ürün satışı eklenmemiş",
      selectWithBarcode: "Barkod İle Ürün Seç",
      selectStock: "Ürünü Seç",
      barcodeCheckAlert:
        "Ücretli içerik aktif değil. Aktivasyon için temsilci ile iletişime geçiniz.",
    },
    barcodeAlert: {
      pleaseEnterACode: "Lütfen Bir Ürün Kodunu Girin",
    },

    productTransactions: {
      productSaleTitle: "Yeni Ürün Satışı",
      successAdd: "Ürün Başarıyla Eklendi",
    },

    payments: {
      // Section
      title: "Ödemeler",
      dateInputLabel: "Ödeme Tarihi",
      typeInputLabel: "Ödeme Yöntemi",
      amountInputLabel: "Tutar",
      pointInputLabel: "Kullanılacak Puan",

      typeCASH: "Nakit",
      typeCARD: "Kredi / Banka Kartı",
      typeEFT: "Havale/EFT",
      typePOINT: "Danışan Puanı",

      totalAmount: "Adisyon Toplamı",
      totalPaidAmount: "Ödenen Tutar",
      totalDebtAmount: "Borç Tutarı",

      noDataText: "Adisyona henüz bir ödeme yöntemi eklenmemiş!",
    },

    installment: {
      title: "Taksitli Ödemeler",
    },

    notes: {
      // Section
      title: "Notlar",
      receipt: "Adisyon Notu",
      customer: "Danışan Açıklaması",
      appointment: "Randevu Notu",
    },

    applyDiscount: {
      // Modal
      title: "İndirim Uygula",
      typeInputLabel: "İndirim Tipi",
      typeCASH: " Bazında İndirim",
      typePERCENT: "Yüzde Bazında İndirim",
      amountInputLabel: "İndirim Tutarı",
      discardButtonTitle: "Vazgeç",
    },

    packageSale: {
      // Modal
      title: "Yeni Paket Satışı",
      completeButtonTitle: "Satışı Tamamla",

      packageNameInputLabel: "Paket Seçimi",
      serviceInputLabel: "Hizmet Seçimi",
      staffInputLabel: "Personel Seçimi",
      startInputLabel: "Başlangıç Tarihi",
      periodInputLabel: "Seans Sayısı",
      periodBetweenInputLabel: "Seans Aralığı",
      periodAmountInputLabel: "Seans Fiyatı",

      noDataText: "Paket satışına henüz bir hizmet-seans eklenmemiş!",
      totalAmount: "Paket Toplamı",

      servicePickToast: "Hizmet seçimlerinizi eksiksiz tamamlayın!",
      customerPickToast: "Danışan seçimini eksiksiz tamamlayın!",

      packageSaleSuccess: "Seçtiğiniz paket başarıyla adisyona eklendi.",
    },

    serviceTransaction: {
      // Modal
      title: "Yeni Hizmet Satışı",
      addButtonTitle: "Ekle",
      discardButtonTitle: "Vazgeç",

      staffInputLabel: "Personel Seçimi",
      serviceInputLabel: "Hizmet Seçimi",
      customerStateInputLanel: "Danışan Durumu",
      successAdd: "Hizmet Başarıyla Eklendi",
    },

    stockSale: {
      // Modal
      title: "Yeni Ürün Satışı",
      addButtonTitle: "Ekle",
      discardButtonTitle: "Vazgeç",

      staffInputLabel: "Satışı Yapan Personel",
      stockInputLabel: "Ürün Seçimi",
    },

    delete: {
      // Modal
      title: "Silmeyi onaylıyor musunuz?",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      alertText:
        "Bu işlemin geri dönüşü yoktur! Bu adisyonu silmeniz durumunda içerisinde yer alan işlemler de beraber silincektir. Muhasebe kayıtlarınıza etki edebilir!",
    },

    discountBeforePaymentToast:
      "İndirim uygulayabilmek için ödemeleri kaldırmanız gerekmektedir.",
    saveChangesToast: "Değişiklikler başarıyla kaydedildi.",
  },

  "packages/sales": {
    filter: {
      title: "Tarih Bazlı Filtreleme",
    },
  },

  /**
   * Paket satışının yapıldığı sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/sales/add
   */
  "packages/sales/add": {
    searchPackegeInputLabel: "Paket Ara",
    customerInputLabel: "Satış Yapılacak Danışan",
    customerInputPlaceholder: "Danışan ismini bu alana giriniz",

    importPackageButtonTitle: "Paketi İçe Aktar",
    addServiceButtonTitle: "Pakete Hizmet Ekle",
    completeButtonTitle: "Satışı Tamamla",

    enterPackageName: "Paket Adını Giriniz",

    serviceInputLabel: "Hizmet Seçimi",
    staffInputLabel: "Personel Seçimi",
    startInputLabel: "Başlangıç Tarihi",
    periodInputLabel: "Seans Sayısı",
    periodBetweenInputLabel: "Seans Aralığı",
    periodAmountInputLabel: "Seans Fiyatı",
    totalInputLabel: "Paket Toplamı",

    noDataText: "Paket satışına henüz bir hizmet-seans eklenmemiş!",

    modal: {
      title: "Hazırlanmış Paketi İçe Aktar",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      packageInputLabel: "Paket Seçimi",
    },
  },

  /**
   * Paketlerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/list
   */
  "packages/list": {
    headings: {
      name: "Paket Adı",
      amount: "Paket Tutarı",
    },
  },

  /**
   * Paketlerin oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/add
   */
  "packages/add": {
    name: "Paket Adı",
    givenName: "Yeni Paket...",
    completeButtonTitle: "Paketi Oluştur",
    addServiceButtonTitle: "Pakete Yeni Hizmet Ekle",
    pickCostumerAndPackageSale: "Danışan Seç ve Paket Satışı Yap",
    CompletePackageSaleButtonTitle: "Paket Satışı Yap",
    discardButtonTitle: "Vazgeç",

    serviceInputLabel: "Hizmet Seçimi",
    periodInputLabel: "Seans Sayısı",
    periodAmountInputLabel: "Seans Birim Fiyatı",
    totalInputLabel: "Toplam Paket Fiyatı",

    noDataText: "Pakete henüz bir hizmet-seans eklenmemiş!",

    validateServiceToast: "Hizmet seçimlerinizi eksiksiz tamamlayın!",
    insertToast: "Paket başarıyla oluşturuldu.",
  },

  /**
   * Paket detayı sayfasının bulunduğu key.
   * https://app.salonrandevu.com/packages/detail/:id
   */
  "packages/detail/id": {
    updatePackageButtonTitle: "Paketi Güncelle",
    deletePackageButtonTitle: "Paketi Sil",
    addServiceButtonTitle: "Pakete Yeni Hizmet Ekle",

    nameInputLabel: "Paket Adı",
    serviceInputLabel: "Hizmet Seçimi",
    countInputLabel: "Seans Sayısı",
    periodAmountInputLabel: "Seans Birim Fiyatı",

    totalInputLabel: "Toplam Paket Fiyatı (₺)",
    noDataText: "Pakete henüz bir hizmet-seans eklenmemiş!",

    delete: {
      title: "Paketi Silmeyi Onayla",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Vazgeç",
      alertText:
        "Paketi silmeyi onaylıyor musunuz? Daha önceden yapmış olduğunuz satışlar, adisyonlar ve muhasebe kayıtları etkilenmeyecektir.",
    },

    validateServiceToast: "Hizmet seçimlerinizi eksiksi tamamlayın!",
    deleteToast: "Paket başarıyla silindi.",
    updateToast: "Paket başarıyla güncellendi",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  products: {
    deleteButtonTitle: "Ürünü Sil",

    headings: {
      name: "Ürün Adı",
      amount: "Ürün Ücreti",
      limit: "Stok Adeti",
      bounty: "Prim Yüzdesi",
    },
    delete: {
      // Modal
      title: "Ürün Silme Onayı",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Silmeniz durumunda bir daha bu ürün üzerinden satış gerçekleştiremezsiniz!",
    },

    deleteToast: "Ürün başarıyla silindi.",
    stockName: "Stok Adı",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  "products/add": {
    searchProductInputLabel: "Ürün Ara",
    searchProductInputPlaceholder: "Ürün adını giriniz",

    createButtonTitle: "Ürünü Oluştur",

    nameInputLabel: "Ürün Adı",
    nameInputPlaceholder:
      "Yönetim uygulamanızda listelenecek olan ürünün adını giriniz.",
    amountInputLabel: "Ürün Ücreti",
    amountInputHint:
      "Belirlediğiniz tutar varsayılan tutardır. Adisyona eklenme esnasında işlem fiyatını değiştirebilirsiniz.",
    bountyInputLabel: "Prim Yüzdesi (%)",
    bountyInputHint:
      "Personellerinizin sabit prim yüzdesi tanımlı değilse belirtilecek yüzde tutarınca prim kazanacaklar.",
    pointInputLabel: "Kazanılacak Puan",
    addBarcode: "Barkode Kodu İle Ürün Oluştur",
    addBarcodeExplain: "Ürüne barkod oluşturmak için kod giriniz.",
    pointInputHint: "100 danışan puanı 1TL'ye denk gelmektedir.",
    limitInputLabel: "Stok Adeti",
    limitInputHint:
      "Ürünün stok adetini giriniz. Bu değeri daha sonra değiştirebilirsiniz.",

    insertToast: "Ürün başarıyla oluşturuldu.",
    barcodeCheckTitle:
      "Ücretli içerik aktif değil. Aktivasyon için temsilci ile iletişime geçiniz.",
  },

  /**
   * Ürün detay sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products/detail/:id
   */
  "products/detail/id": {
    updateButtonTitle: "Ürünü Güncelle",

    nameInputLabel: "Ürün Adı",
    nameInputPlaceholder:
      "Yönetim uygulamanızda listelenecek olan ürünün adını giriniz.",
    amountInputLabel: "Ürün Ücreti",
    amountInputHint:
      "Belirlediğiniz tutar varsayılan tutardır. Adisyona eklenme esnasında işlem fiyatını değiştirebilirsiniz.",
    bountyInputLabel: "Prim Yüzdesi (%)",
    bountyInputHint:
      "Personellerinizin sabit prim yüzdesi tanımlı değilse belirtilecek yüzde tutarınca prim kazanacaklar.",
    pointInputLabel: "Kazanılacak Puan",
    pointInputHint: "100 danışan puanı 1TL'ye denk gelmektedir.",
    limitInputLabel: "Stok Adeti",
    limitInputHint:
      "Ürünün stok adetini giriniz. Bu değeri daha sonra değiştirebilirsiniz.",

    updateToast: "Ürün başarıyla güncellendi.",
  },

  /**
   * Aktif müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers
   */
  customers: {
    alertTExt:
      "Danışan listenizde ekli olan danışanlarınızın sms gönderimlerini açıp-kapama ayarıdır. Bu işlemi gerçekleştirdiğinizde ekli olan tüm danışanlarınızın sms gönderim ayarı güncellenecektir.",
    filter: {
      title: "Danışan Arama Filtresi",
      inputLabel: "İsim Soyisim ya da Telefon Numarası",
    },
    agreement: {
      date: "Tarih",
      agreementName: "Sözleşme Adı",
      bytes: "Boyutu",
      operators: "İşlemler",
    },
    amount: {
      openSmsSendingStatus: "SMS Gönderim Durumu Açın",
      closeSmsSendingStatus: "SMS Gönderim Durumu Kapatın",
      title: "Tutarları Görüntüle",
      updateSmsStatus: "SMS Durumunu Güncelle",
      allCustomerSmsUpdate: "Bütün Danışanların SMS Gönderim Durumu Değişecek!",
    },
    headings: {
      code: "Danışan Kodu",
      fullName: "İsim Soyisim",
      phone: "Telefon Numarası",
      colorSelect: "Renk Seçimi",
      sex: "Cinsiyet",
      incomeAll: "Kazandırdığı Tutar",
      debtAll: "Borç Tutarı",
      message_state: "Mesaj Gönderim Durumu",
      message_open: "Açık",
      message_close: "Kapalı",
    },
    replacements: {
      totalCustomerNumber: "Toplam Danışan Sayısı",
      sex: {
        undef: "Belirtilmemiş",
        male: "Erkek",
        female: "Kadın",
      },
    },
    passiveAll: {
      passiveAllButtonTitle: "Hepsini Pasif Yap",
      title: "Bütün danışanlarınızı pasif yapmayı onaylıyor musunuz?",
      alertText:
        "Bütün danışanları pasif hale getirmeniz durumunda bir daha randevu/adisyon gibi alanlarda danışanları göremeyeceksiniz.İstediğiniz her zaman Pasif Danışanlar sekmesinden danışanların bilgilerine erişebilirsiniz.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      successToast: "Bütün danışanlar başarıyla pasif hale getirildi.",
    },
    passiveSelected: {
      passiveSelectedButtonTitle: "Seçilenleri Pasif Yap",
      title: "Seçilen danışanları pasif yapmayı onaylıyor musunuz?",
      alertText:
        "Seçilen danışanları pasif hale getirmeniz durumunda bir daha randevu/adisyon gibi alanlarda danışanları göremeyeceksiniz.İstediğiniz her zaman Pasif Danışanlar sekmesinden danışanların bilgilerine erişebilirsiniz.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      successToast: "Seçilen danışanlar başarıyla pasif hale getirildi.",
    },
  },

  "logs/index": {
    filter: {
      title: "Gelişmiş Arama Filtresi",
      form: {
        description: "İşlem Detayı / Danışan Adı / Personel Adı",
        startDate: "Başlangıç Tarihi",
        endDate: "Bitiş Tarihi",
        allDate: "Tüm Zamanlar",
        findCustomer: {
          label: "Danışan adı",
          placeholder: "Danışan arayın",
          helper: "Yalnızca belirli danışana göre filtremele",
        },
      },
    },
  },

  /**
   * Riskli müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/risky
   */
  "customers/risky": {
    offsetInputLabel: "Gün Aralığı Seçimi",
    offsetItem15: "15 günden fazladır işlemde bulunmayanlar",
    offsetItem30: "30 günden fazladır işlemde bulunmayanlar",
    offsetItem45: "45 günden fazladır işlemde bulunmayanlar",
    offsetItem60: "60 günden fazladır işlemde bulunmayanlar",

    headings: {
      code: "Danışan Kodu",
      fullName: "İsim Soyisim",
      phone: "Telefon Numarası",
      sex: "Cinsiyet",
      incomeAll: "Kazandırdığı Tutar",
      debtAll: "Borç Tutarı",
      lastTransaction: "Son İşlemin Üstünden Geçen Süre",
    },

    replacements: {
      sex: {
        undef: "Belirtilmemiş",
        male: "Erkek",
        female: "Kadın",
      },
    },

    sendSMSButtonTitle: "Kampanya SMS' i Gönder",
    selectedAllButtonTitle: "Hepsini Seç",
    cancelAllButtonTitle: "Hepsini Kaldır",
  },

  /**
   * Değerlendirme anketlerinin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/rating
   */
  "customers/rating": {
    headings: {
      createdAt: "Değerlendirme Tarihi",
      staffFullName: "İlgilenen Personel",
      serviceName: "Uygulanan Hizmet",
      companyStar: "İşletme Puanı",
      staffStar: "Personel Puanı",
      description: "Açıklama",
    },

    deleteButtonTitle: "Değerlendirmeyi Sil",
    replyToComment: "Yanıtla",
    viewToComment: "Görüntüle",
    delete: {
      title: "Silmeyi onaylıyor musunuz?",
      alertText:
        "Bu işlemin geri dönüşü yoktur! Bu değerlendirme anketini silmeniz durumunda bir daha erişemeyeceksiniz.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
    },

    message: {
      title: "Yorumu Yanıtla",
      comment: "Yapılan Yorum",
      alertText: "Yorumunuzu yazınız.",
      confirmButtonTitle: "Yayınla",
      discardButtonTitle: "İptal",
    },

    deleteToast: "Belirtilen değerlendirme başarıyla silindi!",
    deleteBeforePlanUpgradeToast:
      "Danışan yorumunu silmek için paketinizi premium paket olarak yükseltmeniz gerekmektedir.",
  },

  /**
   * Müşterilerin eklendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/add
   */
  "customers/add": {
    nameInputLabel: "Danışan Adı",
    nameInputPlaceholder: "Danışan adını giriniz",

    surnameInputLabel: "Danışan Soyadı",
    surnameInputPlaceholder: "Danışan soyadı giriniz",

    phoneInputLabel: "Cep Telefonu",

    genderInputLabel: "Cinsiyet",
    genderMale: "Erkek",
    genderFemale: "Kadın",

    birthdayInputLabel: "Doğum Tarihi",
    birthdayInputInvalidMessage:
      "Lütfen istenilen formatta giriş yapınız. Gün/Ay/Yıl",

    maritalStatusInputLabel: "Medeni Hali",
    maritalStatusMarried: "Evli",
    maritalStatusSingle: "Bekar",

    secondPhoneInputLabel: "İkinci Telefon Numarası",
    secondPhoneInputPlaceholder: "Varsa ikinci telefon numarasını giriniz.",

    mailInputLabel: "E-Posta Adresi",
    mailInputPlaceholder: "Varsa E-Posta adresini giriniz.",

    descriptionInputLabel: "Ek Açıklamalar",

    workStatusInputLabel: "Çalışma Durumu",
    workStatusWorking: "Çalışıyor",
    workStatusUnemployed: "Çalışmıyor",
    workStatusEmployer: "İşveren",
    workStatusStudent: "Öğrenci",
    workStatusHouseWife: "Ev Hanımı",

    relatedBusinessInputLabel: "İlgili Kurum",
    relatedBusinessInputPlaceholder:
      "Danışanın hangi kurum üzerinden geldiğini varsa belirtiniz",

    sendSmsInputLabel: "SMS Gönderim Durumu",
    sendSmsTrue: "Gönderilsin",
    sendSmsFalse: "Gönderilmesin",

    selectPhoneNumberForMessage: "Mesajların gönderileceği numarayı seç",
    firstPhoneNumber: "Birinci telefon numarası",
    secondPhoneNumber: "İkinci telefon numarası",
    allPhoneNumber: "Her ikisi de",

    createNewCustomerButtonTitle: "Danışanı Oluştur",
  },

  "customers/passive": {
    makeActiveButtons: {
      selectedButtonTitle: "Seçilenleri Aktif Yap",
      allButtonTitle: "Hepsini Aktif Yap",
    },

    selectedCustomersSuccessToast:
      "Seçilen danışanlar başarıyla aktif edilmiştir",
    allCustomersSuccessToast: "Tüm danışanlar başarıyla aktif edilmiştir",
  },

  /**
   * Toplu müşteri ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/customers/add/bulk
   */
  "customers/add/bulk": {
    orginzeProcess: "Kişiler düzenleniyor. Lütfen Bekleyiniz.",
    uploadAreaText: "Excel dosyasını yüklemek için bu alana tıklayın",
    stepsHeading: "Excel İle Toplu Danışan Yükleme Adımları",
    steps: [
      "Excel üzerinden sisteme danışanlarınızı eklemeniz için hazırladığımız excel formatında verilerinizi yüklemeniz gerekmektedir.",
      "Sonraki adımdaki button'a tıklayarak Excel şablonunu indirin.",
      "Excel Formatını İndir",
      "Danışan verilerinizi bir önceki adımda indirdiğiniz excel formatına uygun hale getirin ve sisteme yükleyin.",
    ],
    boldText:
      "ÖNEMLİ: Kayıpsız ve hatasız veri aktarımı için Excel şablonundaki kolonların yerlerinin, isimlerinin değiştirilmemesi gerekmektedir!",

    preview: {
      heading: "Excel Yükleme Önizlemesi",
      completeButtonTitle: "Toplu Danışan Kaydını Tamamla",

      nameInputLabel: "İsim",
      surnameInputLabel: "Soyisim",
      nationalID: "TC Kimlik No",
      phoneInputLabel: "Cep Telefonu",
      sendSmsInputLabel: "SMS Gönderimi",
      sendSmsTrue: "Açık",
      sendSmsFalse: "Kapalı",

      genderInputLabel: "Cinsiyet",
      genderMale: "Erkek",
      genderFemale: "Kadın",

      mailInputLabel: "E-Posta Adresi",
      birthdayInputLabel: "Doğum Tarihi",

      maritalStatusInputLabel: "Medeni Hali",
      maritalStatusMarried: "Evli",
      maritalStatusSingle: "Bekar",
    },

    duplicateRecords: "%s adet mükerrer kayıt silindi.",

    insertToast:
      "Toplu yükleme işleminiz başarıyla sıraya alındı! Liste sayfasına yönlendiriliyorsunuz.",
  },

  /**
   * Hizmet listesinin bulunduğu key.
   * https://app.salonrandevu.com/services
   */
  services: {
    filter: {
      title: "Hizmet Arama Filtresi",
      inputLabel: "Hizmet Adı",
    },

    headings: {
      name: "Hizmet Adı",
      processTime: "İşlem Süresi",
      bounty: "Prim Yüzdesi",
      amount: "Hizmet Ücreti",
      showAtUrl: "Online Randevu Ayarı",
    },

    replacements: {
      showAtUrlTrue: "Açık",
      showAtUrlFalse: "Kapalı",
    },

    deleteButtonTitle: "Hizmeti Sil",
    delete: {
      title: "Hizmet silmeyi onaylıyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu hizmeti silmeniz durumunda adisyon yönetiminde işlem gerçekleştiremeyecek, randevu alırken/tanımlarken bir daha bu hizmet üzerinden randevu alamayacaksınız. Bu hizmete ait mevcut randevularınız korunacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
    },

    dataNotFound: "Veri Bulunamadı!",

    addAllService: {
      warningTitle: "UYARI!",
      addButtonTitle: "Toplu Hizmet Ekle",
      informationDialog: {
        addServiceDescription:
          "Aktarmış olduğunuz hizmetlerin süreleri ve fiyat bilgisi sistem tarafından otomatik olarak atanmıştır. Lütfen işletmenizde vermiş olduğunuz hizmet süresi ve fiyat bilgilerinizi güncelleyiniz.",
        confirmButtonTitle: "Anladım",
      },
    },

    deleteAll: {
      title: "Hizmetleri silmeyi onaylıyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bütün hizmetleri silmeniz durumunda adisyon yönetiminde işlem gerçekleştiremeyecek, randevu alırken/tanımlarken bir daha bu hizmetler üzerinden randevu alamayacaksınız. Bu hizmetlere ait mevcut randevularınız korunacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      deleteAllServicesToast: "Hizmetler başarıyla silindi",
      deleteAllButtonTitle: "Hepsini Sil",
    },

    deleteSelectedServices: {
      title: "Seçilen hizmetleri silmeyi onaylıyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Seçilen hizmetleri silmeniz durumunda adisyon yönetiminde işlem gerçekleştiremeyecek, randevu alırken/tanımlarken bir daha bu hizmetler üzerinden randevu alamayacaksınız. Bu hizmetlere ait mevcut randevularınız korunacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      deleteSelectedButtonTitle: "Seçilen Hizmetleri Sil",
    },

    deleteToast: "Hizmet başarıyla silindi.",
  },

  /**
   * Hizmet detay/ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/services/add
   * https://app.salonrandevu.com/services/detail/id
   */
  "services/detail": {
    selcetAllEmployees: "Tüm Personelleri Seç",

    serviceName: "Aradığınız",
    lookingFor: " Hizmet adı",
    ourList: "listemizde yok mu?",
    createRequest: "Talep Oluşturun.",
    newCreateRequest: "Hizmet Talebi Oluştur",
    createNewRequest: "Yeni Talep Oluşturun.",

    nameInputLabel: "Hizmet Adı",
    nameInputHint:
      "Yönetim uygulamanızda ve online randevu sayfanızda listelenecek olan hizmetin adını giriniz.",

    amountInputLabel: "Hizmet Ücreti",
    amountInputHint:
      "Belirlediğiniz tutar varsayılan tutardır. Adisyona eklenme esnasında işlem fiyatını değiştirebilirsiniz.",

    bountyInputLabel: "Prim Yüzdesi (%)",
    bountyInputHint:
      "Personellerinizin sabit prim yüzdesi tanımlı değilse bu hizmetten belirtilen yüzde oranında prim kazanacaklar.",

    pointInputLabel: "Kazanılacak Puan",
    pointInputHint: "100 danışan puanı 1₺'ye karşılık gelmektedir.",

    timeInputLabel: "Ortalama İşlem Süresi (dk)",
    timeInputHint:
      "İşlem süresi danışan randevu alırken gösterilecek olan bir bilgidir. Bu hizmetin işlemini yapan personel işlem süresi boyunca yeni bir randevu alamayacaktır.",

    repeatInputLabel: "Tekrar Süresi (gün)",
    repeatInputHint:
      "Bu hizmet tekrar gerektiren bir işlemse, danışanlarınıza tekrar edilmesi gereken süre geldiğinde SMS/E-Posta olarak bilgilendirme yapmanızı sağlayacaktır.",

    urlInputLabel: "Online Randevu Ayarı",
    urlInputHint:
      "İşlemin işletme URL'sinden alınacak online randevularda gösterilip/gösterilmeyeceği ayarıdır.",

    urlInputTrue: "Açık",
    urlInputFalse: "Kapalı",

    staffSectionTitle: "Hizmeti Uygulayacak Personeller",
    timeWarnAlertText:
      "Yapacağınız değişiklik yeni oluşturulacak randevulardan itibaren geçerli olacaktır, oluşturulmuş olan randevulara etki etmeyecektir.",

    createButtonTitle: "Hizmeti Oluştur",
    updateButtonTitle: "Hizmeti Güncelle",
    deleteButtonTitle: "Hizmeti Sil",

    delete: {
      title: "Hizmet silmeyi onaylıyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu hizmeti silmeniz durumunda adisyon yönetiminde işlem gerçekleştiremeyecek, randevu alırken/tanımlarken bir daha bu hizmet üzerinden randevu alamayacaksınız. Bu hizmete ait mevcut randevularınız korunacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
    },

    lastTransactions: {
      headings: {
        createdAt: "İşlem Tarihi",
        staffFullName: "İlgilenen Personel",
        amount: "İşlem Tutarı",
      },
      showOnReceiptButton: "Adisyonda Göster",
    },

    serviceCategoryInput: "Hizmet Kategorisi",
    serviceNameInput: "Hizmet Adı",

    serviceCategoryHint:
      "Seçeceğiniz hizmet kategorisi ve hizmet adı, Salon Randevu platformunda kullanılacaktır.",

    serviceNameHint:
      "Hizmet adını seçebilmek için öncelikle Hizmet Kategorisi bölümünden bir kategori seçmeniz gerekmektedir.",

    deleteToast: "Hizmet başarıyla silindi.",
    insertToast: "Hizmet başarıyla oluşturuldu.",
    updateToast: "Hizmet başarıyla güncellendi.",

    newServiceInputTitle: "Yeni Hizmet Adı",
    newServiceRequestToast: "Yeni Hizmet talebiniz başarıyla oluşturuldu.",
    newServiceRequestErrorToast: "Yeni Hizmet Adı boş bırakılamaz.",
    newServiceRequestDialogTitle: "Yeni Hizmet Talebi",
    newServiceCategoryHint: "Yeni hizmetin bağlı olacağı Hizmet Kategorisi.",
    createNewServiceRequest: "Talebi Oluştur",
    cancelNewServiceRequest: "İptal",

    setCampaignTitle: "Kampanya Olarak Ayarla",
    campaignNameTitle: "Kampanya Adı",
    campaignHint:
      "Kampanya olarak işaretlenen ürünler, işletme sayfanızdaki Kampanya alanında gözükecektir.",

    serviceDescriptionLabel: "Hizmet Açıklaması",
    serviceDescriptionHint:
      "Hizmet açıklaması, danışanlarınızın sunduğunuz hizmeti daha yakından tanımasını sağlar.",
  },

  "payment-notify": {
    copyIban: "IBAN'ı Kopyala",
    ibanSuccess: "IBAN başarıyla kopyalandı.",
    depositBank: "Havale Yapılacak Banka",
    depositAmonut: "Havale Miktarı",
    depositDate: "Havale Tarihi",
    makePaymentNotice: "Ödeme Bildirimi Yap",
  },

  "services/requests": {
    headings: {
      time: "İşlem Tarihi",
      name: "Hizmet Adı",
      state: "Durum",
      category: "Kategori",
      actionHeadingText: "İşlemler",
      stateCompleted: "Talep Onaylandı",
      stateIncompleted: "Talep Onaylanamadı",
      stateWaiting: "Talep Onayı Bekleniyor",
    },

    deletedRequestToast: "Talep isteği silinmiştir",

    cancel: {
      cancelButton: "İptal Et",
      cancelTitle: "Yeni Hizmet Talebini Silmek İstiyor Musunuz?",
      cancelConfirmButtonTitle: "Evet",
      cancelDiscardButtonTitle: "Hayır",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu talebi iptal etmeniz durumunda işlem tarafımızca gerçekleştirelemeyecek ve yeni hizmet kontrolü yapılmayacaktır.",
    },
  },

  "services/bounty": {
    addBountyTitle: "Prim Ekle",

    bountyDescription:
      "Özel prim tanımlama seçeneği ile sabit prim yüzdesi olmayan hizmetleriniz ve personellerinizin için buradan özel prim ekleyebilirsiniz.",

    staffInputLabel: "Prim Eklenecek Personel",
    categoryInputLabel: "Hizmet Kategorisi",
    serviceInputLabel: "Hizmet Adı",
    bountyAmountInputLabel: "Prim Yüzdesi",

    headings: {
      bounty: "Prim Yüzdesi(%)",
      serviceName: "Hizmetin İsmi",
      staffName: "Personelin İsmi",
      deleteBountyButtonTitle: "Primi Sil",
    },

    deleteBountyDialog: {
      title: "Primi Silmek İstediğinizden Emin Misiniz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu primi silmeniz durumunda çalışınınız için bu hizmete bağlı prim hesabı olmayacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
    },

    addBountySuccessToast: "Hizmete özel personel primi başarıyla eklenmiştir.",
    deleteBountySuccessToast:
      "Hizmete bağlı personel primi başarıyla silinmiştir.",
  },

  /**
   * Çalışanların listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees
   */
  employees: {
    editButtonTitle: "Personeli Düzenle",

    createNewEmployee: "Yeni Personel Oluştur",
    orderStaffs: "Personelleri Listele",
  },

  /**
   * Çalışan profil sayfalarının listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees/detail/id
   * https://app.salonrandevu.com/employees/add
   * https://app.salonrandevu.com/profile
   */
  profile: {
    detail: {
      //Section
      title: "Personel Bilgileri",
      profileTitle: "Profil Bilgileri",

      nameInputLabel: "İsim",
      surnameInputLabel: "Soyisim",

      genderInputLabel: "Cinsiyet",
      genderMale: "Erkek",
      genderFemale: "Kadın",

      birthdayInputLabel: "Doğum Tarihi",
      passwordLength: "Şifreniz en az 6 karakterden oluşmalıdır.",

      jobTitleInputLabel: "Ünvan",
      bountyInputLabel: "Sabit Prim Yüzdesi",

      permissionInputLabel: "Panel Yetki Durumu",
      permissionOwner: "Yetkili",
      permissionStaff: "Personel",
      permissionLimited: "Sınırlı Yetkili",

      sendSmsInputLabel: "SMS Gönderimi",
      sendSmsTrue: "Gönderilsin",
      sendSmsFalse: "Gönderilmesin",

      urlInputLabel: "İşletme URL'sinde Gösterim",
      showAtUrlTrue: "Gösterilsin",
      showAtUrlFalse: "Gösterilmesin",

      branch_list: "Şube Listesi",
      branch_change: "Şube Değiştir",
      branch_enter: "Şubeye Geç",
      branch_change_alert: "Şube değişikliği başarıyla gerçekleştirildi.",
    },

    contact: {
      //Section
      title: "İletişim Bilgileri",

      mailInputLabel: "E-Posta Adresi",
      phoneInputLabel: "Cep Telefonu",
      passwordInputLabel: "Panel Şifresi",
      newPasswordInputLabel: "Yeni Panel Şifresi",
    },

    services: {
      //Section
      title: "Sunduğu Hizmetler",

      alertBoldText:
        "Personelin sunduğu hizmetler aşağıdaki servislerin üzerine tıklayarak seçilmedilir!",
      alertText:
        "Aksi halde işletmenin randevu adresinde personelin sunduğu hizmetler boş gelecektir.",
    },
    packages: {
      title: "Sunduğu Paketler",

      alertBoldText:
        "Personelin sunduğu paketler aşağıdaki kutucukların üzerine tıklanarak seçilmedilir!",
      alertText:
        "Aksi halde işletmenin randevu adresinde personelin sunduğu paketler boş gelecektir.",
    },

    booking: {
      //Section
      title: "Randevu Saatleri",
      alertBoldText: "Randevu sıklığınızı düzenleyin!",
      week: [
        "Pazartesi",
        "Salı",
        "Çarşamba",
        "Perşembe",
        "Cuma",
        "Cumartesi",
        "Pazar",
      ],
      dayOpenText: "Günü randevu alımına aç",
      dayCloseText: "Günü randevu alımına kapat",
    },

    photo: {
      //Section
      uploadButtonTitle: "Fotoğraf Yükle",
      deleteButtonTitle: "Fotoğrafı Kaldır",
    },

    createButtonTitle: "Personeli Oluştur",
    updateButtonTitle: "Değişiklikleri Kaydet",
    deleteButtonTitle: "Personeli Sil",

    logoutButtonTitle: "Çıkış Yap",

    delete: {
      //Modal
      title: "Silmeyi onaylıyor musunuz?",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      alertBoldText: "Bu işlemin geri dönüşü yoktur!",
      alertText:
        "Bu personeli silmeniz durumunda personel profiline ve diğer bilgilerine bir daha erişemeyebilirsiniz.",
    },

    photoUploadToast: "Fotoğraf başarıyla yüklendi.",
    photoDeleteToast: "Fotoğraf başarıyla kaldırıldı.",
    insertToast: "Personel başarıyla oluşturuldu.",
    updateToast: "Personel başarıyla güncellendi.",
    deleteToast: "Personel başarıyla silindi.",

    limited_permission_label: {
      no_permission: "Erişemez",
      display: "Görüntülüyebilir",
      full_permission: "Tam Yetkili",
    },
  },

  /**
   * SMS Yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms
   */
  sms: {
    foundWpDevice: "Hesabınıza kayıtlı whatsapp cihazı bulundu.",
    noFoundWpDevice:
      "Hesabınıza kayıtlı herhangi bi whatsapp cihazı bulunamadı.",
    creditSMSLabel: "Kullanılabilir/Kalan SMS Kredisi",
    creditWPLabel: "Kullanılabilir/Kalan WP Kredisi",
    senderLabel: "Gönderici Adı",

    sendType: {
      label: "Gönderim yöntemi",
      sendSMS: "SMS gönder",
      sendWP: "Whatsapp mesajı gönder",
      both: "Her ikisini de gönder",
    },

    credit: {
      sms: "Sms Kredisi",
      wp: "WhatsApp Kredisi",
    },
    loginWithQr: "QR Kod Tara",
    loginWithNo: "Telefon No Gir",

    addDevice: "Cihaz Ekle",
    removeDevice: "Cihazı Kaldır",

    deviceStatus: "Durumu",
    wpPhone: "WhatsAPP Numarası",
    wpCredits: "WhatsAPP Kredisi",

    phoneNotConnected: "Numaranız Bağlı Değil",

    deviceConnected: "Cihaz Bağlı",
    deviceNotConnected: "Cihaz Bağlı Değil",

    wpQrTutorial1: "WhatsAPP uygulamasını açın, ardından ︙  tıklayın.",
    wpQrTutorial2: `"Cihaz Bağla" butonuna tıklayın`,
    wpQrTutorial3: "QR kodunuzu kameranıza okutun.",

    qrPopupTitle: "Qr kodu okut.",
    qrPopupContext:
      "Uygulamaya bağlanmak için cihazınızın kamerasını kullanarak QR kodunu okutun.",

    withPhoneNumberTitle: "WhatsApp Kodu Almak İstediğiniz Numarayı Giriniz.",
    codeForThisPhone: "numarası için WhatsApp onay kodu",

    alertBoldText: "SMS gönderici bilgileriniz alınıyor...",
    alertText: "Bu işlem biraz sürebilir, lütfen bekleyiniz.",

    undefinedAlerts: {
      wp_heading: "Hesabınızda WhatsApp mesaj gönderimi için cihaz bulunamadı",
      wp_content:
        " İşletme adınızla gönderim yapabileceğiniz bir WhatsApp başlığı bulunmamaktadır. Platformun sabit bilgilendirme mesajları haricinde danışanlarınıza özel WhatsApp gönderimleri yapabilmek istiyorsanız",
      wp_span: "  hesabınıza cihaz tanımlayınız.",
      sms_heading:
        "Özelleştirilmiş gönderimler için SMS başlığı tanımlanmamış!",
      sms_content:
        "   İşletme adınızla gönderim yapabileceğiniz bir SMS başlığı bulunmamaktadır. Platformun sabit bilgilendirme mesajları haricinde danışanlarınıza özel SMS gönderimleri yapabilmek istiyorsanız SMS hizmeti satın almanız gerekmektedir.",
    },

    sendButtonText: "Görüntüle",
    dialogTitle: "Sms Görüntüleme",
    buttonApprove: "Kapat",

    lastSendingsTitle: "SMS Gönderimleri",
    smsReports: "SMS Raporları",

    groupSMSReports: "Grup SMS Raporları",
    singleSMSReports: "Otomatik SMS Raporları",
    groupWPReports: "Grup WhatsApp Raporları",
    singleWPReports: "Otomatik WhatsApp Raporları",

    creditPaybackButtonTitle: "Kredi İadesi Al",
    headings: {
      date: "Tarih",
      sender: "Gönderici Adı",
      phone: "Telefon Numarası",
      status: "Gönderim Durumu",
      content: "Mesaj İçeriği",
    },
    table_titles: {
      date: "Tarih",
      total: "Toplam",
      successful: "Başarılı",
      unsuccessful: "Başarısız",
      post_postCredit: "Gönderim Öncesi Kredi",
      content: "İçerik",
    },
    bottom_table_titles: {
      phone: "Telefon Numarası",
      status: "Durumu",
      message_content: "Mesaj içeriği",
      details: "Ayrıntılar",
      successful: "Başarılı",
      unsuccessful: "Başarısız",
      data_not_found: "Veri Bulunamadı!",
    },
    replacements: {
      status: [
        "Gönderildi",
        "İletilmedi",
        "Geçersiz Numara",
        "İade Yapıldı",
        "Beklemede",
        "Süresi Geçmiş",
      ],
    },

    send: {
      // Section
      messageInputLabel: "Mesaj İçeriği",
      charType: "Karakter Tipi",
      charTR: "Türkçe",
      charNormal: "Normal",
      buttonTitle: "Mesaj Gönder",
      discardButtonTitle: "Kapat",
      sendDate: "Gönderim Tarihi",
      futureDate: "İleri Tarihli SMS",

      autoText: {
        nameSurname: "Ad Soyad",
        amountPaid: "Ödenen Tutar",
        remainingDebt: "Kalan Borç",
        businessUrl: "İşletme URL",
        shortCode: "Kısa kod",
        forSmsSelection: "SMS iptali için",
        writeIt: "yazın 4607'a gönderin.",
      },

      check: {
        // Modal
        title: "Bu işlemi onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",
        alertText:
          "Onayınızın ardından kredinizin yeterli olması durumunda gönderim işlemlerine başlanacaktır. Bu işlemin geri dönüşü yoktur!",
      },

      template: "Şablon Seçimi",

      nameSurnameShortcut: "Ad Soyad",
      successToast: "SMS gönderimi başarıyla başlatıldı!",
    },
  },

  /**
   * Seçimli SMS Gönder sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/selective
   */
  "sms/selective": {
    continueButtonTitle: "Devam Et",
    backToSelectButtonTitle: "Seçimlere Geri Dön",

    warningText:
      "Gönderilecek Listesi Harici Tutulacak Listesi ile aynı olamaz!",

    customer: {
      // Section
      title: "Danışan Listesi",
      headings: {
        fullName: "İsim Soyisim",
        phone: "Telefon Numarası",
      },

      searchInputLabel: "Danışan Ara",
      selectAll: "Tüm Danışanları Seç",
      unselectAll: "Tümünü Seçimi Kaldır",
    },

    approved: {
      // Section
      title: "Gönderilecekler Listesi",
      notFound: {
        alertBoldText: "Gönderilecek danışanlar içerisinden seçim yapılmamış!",
        alertText:
          "Danışan listesi içerisinden tik button'una tıklayarak danışanlarınızı seçmeye başlayabilirsiniz.",
      },

      alertBoldText: "Tüm danışanlarınız başarıyla seçildi!",
    },

    excepted: {
      // Section
      title: "Hariç Tutulacaklar Listesi",
      notFound: {
        alertBoldText: "Gönderimden hariç tutulacak danışanlar seçilmemiş!",
        alertText:
          "Danışanlarınızın içerisinden gönderilmeyecek istisna danışanlarınızın varsa tablodaki blok button'una tıklayarak danışanı gönderimden hariç tutabilirsiniz.",
      },
    },
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/filter": {
    applyFilterButtonTitle: "Filtreyi Uygula",
    continueButtonTitle: "Devam Et",
    backToFilterButtonTitle: "Filtrelemeye Geri Dön",

    tabs: {
      // Section
      debt: "Borç Durumu",
      debtTrue: "Borçlu",
      debtFalse: "Borçsuz",

      risk: "Risk Durumu",
      risk15: "Son 15 günde işlemi olmayanlar",
      risk30: "Son 30 günde işlemi olmayanlar",
      risk45: "Son 45 günde işlemi olmayanlar",
      risk60: "Son 60 günde işlemi olmayanlar",

      work: "Çalışma Durumu",
      workWorking: "Çalışıyor",
      workUnemployed: "Çalışmıyor",
      workEmployer: "İşveren",
      workStudent: "Öğrenci",
      workHouseWife: "Ev Hanımı",

      gender: "Cinsiyet",
      genderMale: "Erkek",
      genderFemale: "Kadın",

      marital: "Medeni Hal",
      maritalMarried: "Evli",
      maritalSingle: "Bekar",

      service: "Alınan Hizmet",
      staff: "İşlem Uygulamış Personel",
      age: "Yaş Aralığı",
    },

    customer: {
      // Section
      title: "Danışan Listesi",
      headings: {
        fullName: "İsim Soyisim",
        phone: "Cep Telefonu",
        gender: "Cinsiyet",
      },
      replacements: {
        genderNull: "Belirtilmemiş",
        genderFemale: "Kadın",
        genderMale: "Erkek",
      },
    },

    except: {
      title: "Filtrelemeden Hariç Tutulacaklar Listesi",
      alertBoldText: "Gönderimden hariç tutulacak danışanlar seçilmemiş!",
      alertText:
        "Filtreleme sonrası danışan listeniz içerisinden gönderilmeyecek istisna danışanlarınızın varsa tablodaki blok button'una tıklayarak danışanı gönderimden hariç tutabilirsiniz.",
    },
  },

  "sms/group": {
    continueButtonTitle: "Devam Et",
    backToSelectButtonTitle: "Seçimlere Geri Dön",

    newGroupButtonTitle: "Grup Oluştur",

    group: {
      title: "Grup Listesi",

      headings: {
        groupName: "Grup Adı",
        customerCount: "Danışan Sayısı",
      },

      searchInputLabel: "Grup Ara",
      searchInputPlaceholder: "Grup adını giriniz ",
      selectAll: "Tüm Grupları Seç",
      unselectAll: "Tüm Seçimi Kaldır",
    },

    approved: {
      title: "Gönderilecekler Listesi",
      notFound: {
        alertBoldText: "Gönderilecek gruplar içerisinden seçim yapılmamış!",
        alertText:
          "Grup listesi içerisinden tik button'una tıklayarak gruplarınızı seçmeye başlayabilirsiniz.",
      },

      alertBoldText: "Tüm gruplarınız başarıyla seçildi!",
    },

    excepted: {
      title: "Hariç Tutulacaklar Listesi",
      notFound: {
        alertBoldText: "Gönderimden hariç tutulacak gruplar seçilmemiş!",
        alertText:
          "Gruplarınız içerisinden gönderilmeyecek istisna gruplarınız varsa tablodaki blok button'una tıklayarak grubu gönderimden hariç tutabilirsiniz.",
      },
    },

    newGroup: {
      title: "Yeni Grup",

      groupName: "Grup Adı",
      groupEditButtonTitle: "Düzenle",

      customersTitle: "Danışanlarınızın",
      selectedCustomersTitle: "Seçili Danışanlar",

      searchCustomer: "Danışan Ara",
      searchCustomerDescription: "Danışan adını veya numarasını yazınız",
      selectionDescription:
        "İstediğiniz danışanı seçerek veya sürükle bırak metodu ile aktarabilirsiniz.",
      notFoundCustomer: "Aradığınız danışan(lar) bulunamadı.",

      selectAllButtonTitle: "Bütün Danışanları Aktar",
      unselectAllButtonTitle: "Bütün Danışanları Kaldır",
      deleteAllButtonTitle: "Hepsini Sil",

      alertBoldText: "Tüm danışanlarınız başarıyla seçildi!",

      createButtonTitle: "Oluştur",
      cancelButtonTitle: "İptal",

      successToast: "Yeni grup başarıyla oluşturuldu.",
      emptyGroupName: "Grup adı boş olamaz.",

      edit: {
        editGroupTitle: "Grup Güncelle",
        editButtonTitle: "Güncelle",
        deleteGroupButtonTitle: "Grubu Sil",

        updateSuccessToast: "Grup başarıyla güncellenmiştir.",
        deleteSuccessToast: "Grup başarıyla silinmiştir.",
      },
    },

    successToast: "Mesajınız başarıyla gönderildi.",
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/settings": {
    updateButtonTitle: "Ayarları Güncelle",
    customerSwitchTitle: "Danışan",
    staffSwitchTitle: "Personel",
    onOffSwitchTitle: "Açık/Kapalı",

    padiContent:
      "Ücretli içerik aktif değil. Lütfen danışan temsilciniz ile iletişime geçin.",

    reminder: {
      title: "Randevu Hatırlatma",
      description:
        "Randevu hatırlatmalarına dair SMS gönderimlerinin gitsin/gitmesin ayarıdır.",
    },

    longTermReminder: {
      title: "Uzun Süreli Randevu Hatırlatma",
      description:
        "Randevu tarihine bir günden fazla gün varsa, randevu tarihinden bir gün öncesinden SMS gitsin/gitmesin ayarıdır.",
    },

    requestInfo: {
      title: "Randevu Talebi Bilgilendirme",
      description:
        "Gelen yeni bir online randevu talebi/isteği geldiğinde SMS gitsin/gitmesin ayarıdır.",
    },

    whenApproved: {
      title: "Randevu Talebi Onaylandığında",
      description:
        "Gelen online randevu talebi/isteği onaylandığında SMS gitsin/gitmesin ayarıdır.",
    },

    whenRejected: {
      title: "Randevu Talebi Reddedildiğinde",
      description:
        "Gelen online randevu talebi/isteği reddedildiğinde SMS gitsin/gitmesin ayarıdır.",
    },

    whenCreated: {
      title: "Randevu Oluşturulduğunda",
      description: "Randevu oluşturulduğu esnada SMS gitsin/gitmesin ayarıdır.",
    },

    whenCancelled: {
      title: "Aktif Randevu İptalinde",
      description:
        "Oluşturulan randevu iptal edildiğinde SMS gitsin/gitmesin ayarıdır.",
    },

    whenBirthday: {
      title: "Doğum Günü Gönderimi",
      description:
        "Doğum günü olan danışanlarınıza kutlama SMS'i gitsin/gitmesin ayarıdır. Bu ayar işletmenize/kendinize özel gönderici adınızın olması durumunda çalışmaktadır.",
    },

    whenCustomerCreate: {
      title: "Danışan Eklendiğinde",
      description:
        "Danışan kaydı sonrasında danışanlarınıza işletmenizin danışan listesine kaydedildiğine dair bilgilendirme SMS'i gitsin/gitmesin ayarıdır.",
    },

    dragAndDrop: {
      title: "Randevu Sürükle Ve Bırak",
      description:
        "Randevu sürükle ve bırakıldığında müsteriye SMS gitsin/gitmesin ayarıdır.",
    },

    smsShortcode: {
      title: "Sms Kısa Kod",
      description:
        "Müsteri numarası kara listeye eklendiginde SMS gitsin/gitmesin ayarıdır.",
    },

    reminderNoncomerCustomer: {
      title: "Randevuya Gelmeyen Danışana Hatırlatma",
      description: [
        "60 gün boyunca işletmenizi ziyaret etmemiş danışanlarınıza otomatik hatırlatma",
        "SMS'i gönderilir.",
        "Örnek Mesaj İçeriği : Sayın Figen Çelik , sizi çok özledik !",
        "60 gündür işlem yapmadığınızı farkettik. Tekrar görüşmek dileğiyle, Mutlu günler dileriz..",
        "Randevu almak için : https://app.salonrandevu.com/isletme/2037",
      ],
    },

    appointmentUpdate: {
      title: "Randevu Güncelleme",
      description:
        "Güncellenen randevu saati ve tarihini SMS ile gönder/gönderme ayarıdır.",
    },

    smsAttendedApproval: {
      title: "SMS'den Randevu Katılımı İçin Link Gönderimi",
      description:
        "Danışanın oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link danışanın randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    afterAppointment: {
      title: "Randevu Sonrası Değerlendirme",
      description:
        "Randevu sonrasında değerlendirme SMS'i gitsin/gitmesin ayarıdır.",
    },

    reminderHour: {
      title: "Randevu Hatırlatma Gönderim Saati",
      description:
        "Randevu öncesi gönderilecek SMS kaç saat önce gönderileceği ayarıdır.",

      inputLabel: "Kaç saat önce gönderilecek?",
      options: [
        "Kapalı",
        "1 saat",
        "2 saat",
        "3 saat",
        "4 saat",
        "5 saat",
        "6 saat",
        "7 saat",
        "8 saat",
        "9 saat",
        "10 saat",
        "11 saat",
        "12 saat",
        "13 saat",
        "14 saat",
        "15 saat",
        "16 saat",
        "17 saat",
        "18 saat",
        "19 saat",
        "20 saat",
        "21 saat",
        "22 saat",
        "23 saat",
        "24 saat",
      ],
    },

    updateToast: "SMS ayarları başarıyla güncellendi.",
  },
  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/wp/settings": {
    updateButtonTitle: "Ayarları Güncelle",
    customerSwitchTitle: "Danışan",
    staffSwitchTitle: "Personel",
    onOffSwitchTitle: "Açık/Kapalı",

    padiContent:
      "Ücretli içerik aktif değil. Lütfen danışan temsilciniz ile iletişime geçin.",

    reminder: {
      title: "Randevu Hatırlatma",
      description:
        "Randevu hatırlatmalarına dair WhatsApp gönderimlerinin gitsin/gitmesin ayarıdır.",
    },

    longTermReminder: {
      title: "Uzun Süreli Randevu Hatırlatma",
      description:
        "Randevu tarihine bir günden fazla gün varsa, randevu tarihinden bir gün öncesinden WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    requestInfo: {
      title: "Randevu Talebi Bilgilendirme",
      description:
        "Gelen yeni bir online randevu talebi/isteği geldiğinde WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenApproved: {
      title: "Randevu Talebi Onaylandığında",
      description:
        "Gelen online randevu talebi/isteği onaylandığında WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenRejected: {
      title: "Randevu Talebi Reddedildiğinde",
      description:
        "Gelen online randevu talebi/isteği reddedildiğinde WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenCreated: {
      title: "Randevu Oluşturulduğunda",
      description:
        "Randevu oluşturulduğu esnada WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenCancelled: {
      title: "Aktif Randevu İptalinde",
      description:
        "Oluşturulan randevu iptal edildiğinde WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenBirthday: {
      title: "Doğum Günü Gönderimi",
      description:
        "Doğum günü olan danışanlarınıza kutlama WhatsApp mesajı gitsin/gitmesin ayarıdır. Bu ayar işletmenize/kendinize özel gönderici adınızın olması durumunda çalışmaktadır.",
    },

    whenCustomerCreate: {
      title: "Danışan Eklendiğinde",
      description:
        "Danışan kaydı sonrasında danışanıza işletmenizin danışan listesine kaydedildiğine dair bilgilendirme WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    dragAndDrop: {
      title: "Randevu Sürükle Ve Bırak",
      description:
        "Randevu sürükle ve bırakıldığında müsteriye WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    smsShortcode: {
      title: "WhatsApp Kısa Kod",
      description:
        "Müsteri numarası kara listeye eklendiginde WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    smsApprovalLink: {
      title: "WhatsApp'dan Randevu Katılımı İçin Link Gönderimi",
      description:
        "Danışanın oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link danışanın randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    reminderNoncomerCustomer: {
      title: "Randevuya Gelmeyen Danışana Hatırlatma",
      description: [
        "60 gün boyunca işletmenizi ziyaret etmemiş danışanlarınıza otomatik hatırlatma",
        "WhatsApp mesajı gönderilir.",
        "Örnek Mesaj İçeriği : Sayın Figen Çelik , sizi çok özledik !",
        "60 gündür işlem yapmadığınızı farkettik. Tekrar görüşmek dileğiyle, Mutlu günler dileriz..",
        "Randevu almak için : https://app.salonrandevu.com/isletme/2037",
      ],
    },

    appointmentUpdate: {
      title: "Randevu Güncelleme",
      description:
        "Güncellenen randevu saati ve tarihini WhatsApp ile gönder/gönderme ayarıdır.",
    },

    smsAttendedApproval: {
      title: "SMS'den Randevu Katılımı İçin Link Gönderimi",
      description:
        "Danışanın oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link danışanın randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    afterAppointment: {
      title: "Randevu Sonrası Değerlendirme",
      description:
        "Randevu sonrasında değerlendirme WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    reminderHour: {
      title: "Randevu Hatırlatma Gönderim Saati",
      description:
        "Randevu öncesi gönderilecek WhatsApp mesajı kaç saat önce gönderileceği ayarıdır.",

      inputLabel: "Kaç saat önce gönderilecek?",
      options: [
        "Kapalı",
        "1 saat",
        "2 saat",
        "3 saat",
        "4 saat",
        "5 saat",
        "6 saat",
        "7 saat",
        "8 saat",
        "9 saat",
        "10 saat",
        "11 saat",
        "12 saat",
        "13 saat",
        "14 saat",
        "15 saat",
        "16 saat",
        "17 saat",
        "18 saat",
        "19 saat",
        "20 saat",
        "21 saat",
        "22 saat",
        "23 saat",
        "24 saat",
      ],
    },

    updateToast: "WhatsApp mesaj ayarları başarıyla güncellendi.",
  },

  "sms/template": {
    createTemplateButtonTitle: "Yeni Şablon Oluştur",

    templatesHeadings: {
      name: "İsim",
      content: "İçerik",
    },

    deleteButtonTitle: "Şablonu Sil",

    templateDialog: {
      addTitle: "Yeni Şablon",
      updateTitle: "Şablon Detay/Düzenle",

      templateTitleInputLabel: "Şablon Adı",
      templateTitleInputPlaceholder: "Şablon adını giriniz",

      templateContentInputLabel: "Şablon İçeriği",

      createButtonTitle: "Oluştur",
      updateButtonTitle: "Güncelle",
      discardButtonTitle: "İptal",
    },

    deleteConfirm: {
      title: "Şablonu silmeyi onaylıyor musunuz?",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu telefon numarasını kara listeden kaldırdığınızda Toplu mesaj gönderimlerinde kişiye SMS gönderimi sağlanacaktır.",
    },

    addSuccessToast: "Şablon başarıyla oluşturuldu.",
    updateSuccessToast: "Şablon başarıyla güncellendi.",
    deleteSuccessToast: "Şablon başarıyla silindi.",
  },

  "sms/blacklist": {
    totalNumber: "Toplam Numara sayısı: ",
    blacklistHeadings: {
      number: "Numara",
      addDate: "Eklenme Tarihi",
    },
    deleteUserBlacklist: "Numarayı Listeden Kaldır",
    numberInputLabel: "Engellemek Istediğiniz Numaraları Girin",
    addToBlacklist: "Kara Listeye Ekle",
    addToBlacklistError:
      "Lütfen geçerli numara giriniz birden fazla numara eklemek için ',' kullanın",
    addSuccessToast: "Numara başarıyla eklendi.",

    deleteConfirm: {
      title: "Numarayı listeden silmeyi onaylıyor musunuz?",
      // alertText:"Bu numarayi silmeniz durumunda bir daha erişiminiz olmayacaktır.",
    },
  },

  /**
   * Muhasebe Yönetimindeki ortak kullanımda olan key.
   * https://app.salonrandevu.com/accounting/income
   * https://app.salonrandevu.com/accounting/debt
   */
  accounting: {
    showOnReceipt: "Adisyonda Göster",

    startInputLabel: "Başlangıç Tarihi",
    endInputLabel: "Bitiş Tarihi",
    typeInputLabel: "Ödeme Yöntemi",
    customerName: "Danışan Adı",

    typeCustomer: "Tüm Danışanlar",
    typeALL: "Tüm Yöntemler",
    typeCASH: "Nakit",
    typeCARD: "Kredi / Banka Kartı",
    typeEFT: "Havale/EFT",

    totalIncome: "Toplam Gelir",
    totalDebt: "Toplam Borç",

    alertBoldText: "Aşağıdaki alanları kullanarak {desc} filtreleyebilirsiniz.",
    alertText:
      "Alanlarda değişiklik yapmanız durumunda otomatik olarak filtreleme işlemi yapılmaktadır.",
  },

  /**
   * Gelir işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/income
   */
  "accounting/income": {
    headings: {
      createdAt: "Belirtilen Ödeme Tarihi",
      customerFullName: "Danışan Adı",
      amount: "İşlem Tutarı",
      type: "Ödeme Yöntemi",
    },
  },

  /**
   * Borç işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debt
   */
  "accounting/debt": {
    headings: {
      createdAt: "İşlem Tarihi",
      customerFullName: "Danışan Adı",
      amount: "Toplam",
      paid: "Ödenen",
      remaining: "Kalan",
    },
  },

  /**
   * Prim işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/bounty
   */
  "accounting/bounty": {
    payButtonTitle: "Prim Öde",
    allPayButtonTitle: "Hepsini Öde",

    headings: {
      staffFullName: "Personel Adı Soyadı",
      bounty: "Prim Tutarı",
    },

    check: {
      title: "Prim Ödemesi",
      confirmButtonTitle: "Ödemeyi Yap",
      discardButtonTitle: "Vazgeç",
      alertBoldText:
        "{selected_employee} personeli için birikmiş {bounty}₺ prim bulunmaktadır. İşlem tarihi ve ödeme yöntemi seçeneklerini doldurararak prim ödemesini gerçekleştirebilirsiniz.",
    },
  },

  /**
   * Gider işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense
   */
  "accounting/expense": {
    add: {
      title: "Yeni Gider Ekle",
      typeInputLabel: "Gider Kalemi",
      amountInputLabel: "Tutar",
      amountInputPlaceholder: "Bu alana ilgili gider tutarı giriniz.",
      descriptionInputLabel: "Açıklama",
      descriptionInputHint:
        "Bu alana ilgili işlem hakkında eklemek istediğiniz notları girebilirsiniz.",

      buttonTitle: "Gider Ekle",
      paymentType: "Ödeme Yöntemi",

      processDate: "İşlem Tarihi",

      spending: "Harcayan",
      spendingPlaceholder: "Harcayanınızı giriniz.",
    },

    table: {
      title: "Gider İşlemleri",
      headings: {
        createdAt: "Oluşturulma Tarihi",
        transactionDate: "İşlem Tarihi",
        type: "Gider Kalemi",
        description: "Gider Açıklaması",
        amount: "Tutar",
      },
      deleteButtonTitle: "Gideri Sil",
    },

    deleteToast: "Gider kaydı başarıyla silindi.",
    insertToast: "Gider başarıyla oluşturuldu.",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense/types
   */
  "accounting/expense/types": {
    alertBoldText: "Gider Kalemleri Hakkında",
    alertText:
      "Bu alanda işletmenize özgü gider kalemlerini tanımlayarak gider işlemlerinizi oluşturduğunuz kalemler üzerinden gerçekleştirebilirsiniz.",

    saveChangesButtonTitle: "Değişiklikleri Kaydet",
    typeInputLabel: "{i}. Özel Gider Kalemi",

    insertToast: "İşlem başarıyla gerçekleştirildi.",

    moreButtonTitle: "Daha Fazla",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/credit
   */
  "accounting/credit": {
    filter: {
      title: "Tarih ve Danışan Bazlı Filtreleme",
      satrtDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      name: "Danışan Adı",
    },

    add: {
      customerSelection: "Danışan Seçimi",
      scheduledPaymentDate: "Planlanan Ödeme Tarihi",
      enterAmount: "Tutarı Giriniz",
      explanation: "Açıklama",
      saveReceivable: "Alacağı Kaydet",
      updateReceivable: "Alacağı Güncelle",
      collectPayment: "Ödemeyi Tahsil Et",
      paymentDialogTitle: "Ödeme Bilgileri",
      savePaymnet: "Ödemeyi Kaydet",
      closePayment: "Ödemeyi Kapat",

      date: "Tarih",
      amount: "Tutar",
      paymentType: "Ödeme Yöntemi",
      actions: "İşlemler",
      delete: "Sil",
      update: "Güncelle",

      start_amount: "Başlangıç Tutarı",
      end_amount: "Bitiş Tutarı",
    },

    payments: {
      typeInputLabel: "Ödeme Yöntemi",
      typeInputPlaceholder: "Ödeme Yöntemini Seçiniz",

      amountInputLabel: "Tutar Giriniz",

      typeCASH: "Nakit",
      typeCARD: "Kredi / Banka Kartı",
      typeEFT: "Havale/EFT",
      typePOINT: "Danışan Puanı",
    },

    addCredit: "Alacak Ekle",
    paid: "Ödendi",
    notPaid: "Ödenmedi",

    headings: {
      createdAt: "Oluşturulma Tarihi",
      scheduledPaymentDate: "Planlanan Ödeme Tarihi",
      nameSurname: "İsim-Soyisim",
      all_amount: "Toplam",
      paid: "Ödenen",
      debt_total: "Kalan",
      view: "Detay & Görüntüle",
      delete: "Sil",
      transactions: "İşlemler",
      is_paid: "Ödeme Durumu",
    },
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debit
   */
  "accounting/debit": {
    filter: {
      title: "Tarih ve Danışan Bazlı Filtreleme",
      satrtDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      name: "Danışan Adı",
    },

    add: {
      customerSelection: "Danışan İsmi",
      scheduledPaymentDate: "Planlanan Ödeme Tarihi",
      enterAmount: "Tutarı Giriniz",
      explanation: "Açıklama",
      saveReceivable: "Borcu Kaydet",
      updateReceivable: "Borcu Güncelle",
      collectPayment: "Ödemeyi Tahsil Et",
      paymentDialogTitle: "Ödeme Bilgileri",
      savePaymnet: "Ödemeyi Kaydet",
      closePayment: "Ödemeyi Kapat",

      date: "Tarih",
      amount: "Tutar",
      paymentType: "Ödeme Yöntemi",
      actions: "İşlemler",
      delete: "Sil",
      update: "Güncelle",

      start_amount: "Başlangıç Tutarı",
      end_amount: "Bitiş Tutarı",
    },

    payments: {
      typeInputLabel: "Ödeme Yöntemi",
      typeInputPlaceholder: "Ödeme Yöntemini Seçiniz",

      typeCASH: "Nakit",
      typeCARD: "Kredi / Banka Kartı",
      typeEFT: "Havale/EFT",
      typePOINT: "Danışan Puanı",
    },

    addCredit: "Borç Ekle",
    paid: "Ödendi",
    notPaid: "Ödenmedi",

    headings: {
      createdAt: "Oluşturulma Tarihi",
      scheduledPaymentDate: "Planlanan Ödeme Tarihi",
      nameSurname: "İsim-Soyisim",
      all_amount: "Toplam",
      paid: "Ödenen",
      debt_total: "Kalan",
      view: "Detay & Görüntüle",
      delete: "Sil",
      transactions: "İşlemler",
      is_paid: "Ödeme Durumu",
    },
  },

  /**
   * Raporlar modülünün genel kullanımlarının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   * https://app.salonrandevu.com/reports/services
   * https://app.salonrandevu.com/reports/stock
   * https://app.salonrandevu.com/reports/staff
   * https://app.salonrandevu.com/reports/customer
   */
  reports: {
    startInputLabel: "Başlangıç Tarihi",
    endInputLabel: "Bitiş Tarihi",

    headings: {
      stockName: "Ürün Adı",
      serviceName: "Ürün Adı",
      transactionCount: "İşlem Sayısı",
      soldCount: "Satış Sayısı",
      income: "Elde Edilen Gelir",
    },

    selectedStaffInputLabel: "Personel Seçimi",
    selectedPageCountInputLabel: "Sayfa Sayısı",
  },

  /**
   * İşletme raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   */
  "reports/company": {
    soldItem: "Satılan Ürün",
    soldService: "Uygulanan Hizmet",

    serviceIncome: "Hizmet Geliri",
    itemIncome: "Ürün Geliri",

    generalIncome: "Ödenen Gelir",
    maxExpense: "Toplam Gider",
    netIncome: "Net Gelir",
    totalIncome: "Toplam Gelir",

    typeCASH: "Nakit",
    typeCARD: "Kredi / Banka Kartı",
    typeEFT: "Havale/EFT",

    _maxAppointment: "Toplam Randevu Sayısı",
    _maxReceipt: "Toplam Adisyon Sayısı",
    _serviceIncome: "Hizmetlerden Elde Edilen Gelir",
    _itemIncome: "Ürünlerden Elde Edilen Gelir",
    _paidBounty: "Personellere Ödenen Prim",

    alertText:
      "Hizmet geliri ve ürün geliri olarak belirtilen değerler adisyonu tamamiyle ödenmiş, borçsuz adisyonlar üzerinden hesaplanmıştır.",
  },

  /**
   * Çalışan raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/staff
   */
  "reports/staff": {
    name: "Personel Adı",
    serviceCount: "Sunduğu Hizmet Sayısı",
    soldItemCount: "Sattığı Ürün Sayısı",

    onlyService: "Hizmet Cirosu",
    bountyService: "Hizmet Primi",
    productSum: "Ürün Cirosu",
    bountyProduct: "Ürün Primi",
    packageSum: "Paket Cirosu",
    bountyPackage: "Paket Primi",

    endorsement: "Toplam Cirosu",
    bounty: "Toplam Primi",
    survey: "Değerlendirmesi",
  },

  /**
   * Müşteri raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/customer
   */
  "reports/customer": {
    name: "Danışan Adı",
    appointmentCount: "Randevu Sayısı",
    appointmentStatus: "Geldi / Bekleniyor / Gelmedi",

    transactionCount: "Yapılan İşlem Sayısı",
    productCount: "Satılan Ürün Sayısı",
    totalEarned: "Toplam Kazandırdığı",
  },

  /**
   * Yapılacaklar listesi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/todos
   */
  todos: {
    toastSuccess: "Görev başarıyla eklendi!",
    toastWarningMessage: "Görev içeriği boş olamaz",
    toastWarningProcess: "Görev ekleme işlemi başarısız.",

    addButtonTitle: "Görevi Oluştur",

    addTodoTitle: "Görev Ekle",
    todoInputLabel: "Görev İçeriği",
    todoInputPlaceholder:
      "Bu alana yapılacak işlerinize dair notlarınızı, görevlerinizi girebilirsiniz.",

    dateTitle: "Tarihli Görevler",
    notFoundText: "Tarihi için kaydedilmiş bir görev bulunmuyor.",

    statusWaiting: "BEKLENİYOR",
    statusCompleted: "TAMAMLANDI",

    updateToast: "Görev durumu başarıyla güncellendi.",
    insertToast: "Görev başarıyla eklendi.",
    deleteToast: "Görev başarıyla silindi.",

    deleteTaskQuestion: "Görevi Silmek İstiyor musnuz?",
    deleteTaskDescription: "Görev listenizden silinecek.",
  },

  /**
   * Cüzdan sayfasının bulunduğu key.
   * https://app.salonrandevu.com/wallet
   */
  wallet: {
    cardTitle: "Toplam Bakiyeniz",
    amountInputLabel: "Yüklenecek Tutar (₺)",
    amountButtonTitle: "Bakiye Yükle",

    remainingSMS: "Kalan SMS Kredisi",
    licenseDetail: "Kullanılan Paket",
    licenseRemaining: "Lisans Bitimine Kalan",
    appointmentRemaining: "Kalan Randevu Kredisi",
    paymentForm: "Banka Hesapları / Ödeme Bildirim Formu",

    licenseHeading: "Lisans Satın Al",
    smsHeading: "SMS Paketi Satın Al",
    appointmentHeading: "Randevu Kredisi Al",

    withEFT: "HAVALE İLE",
    buyButtonTitle: "Satın Al",

    licenses: "Lisanslar",
    message_packages: "Mesaj Paketleri",
    extra_license: "Ek Paketler",

    bardcode_extra_package: "Barkod Okuyucu",
    whatsapp_extra_package: "WhatsApp Anlık Mesaj",
    online_link_extra_package: "SMS'den Randevu Katılımı İçin Link Gönderimi",

    bardcode_extra_package_tooltip:
      "Stok takibi yaparken ürünleri barkodunu okutarak ekleyebilir ve uygulamamız sayesinde cep telefonu kamerasını kullanarak barkodlarını kolayca okutup satışlarını yapabilirsiniz.",
    whatsapp_extra_package_tooltip:
      "WhatsApp hesabınızla anlık bağlantı kurulur ve üye özelinde iletmek istediğiniz mesajları WhatsApp ikonuna tıklayarak web tarafından kullanıyorsanız Whatsapp masaüstü uygulaması ile mesaj yollarsınız mobil tarafta kullanıyorsanız WhatsApp uygulaması ile hızlıca iletişime geçebilirsiniz.",
    online_link_extra_package_tooltip:
      "Danışanın oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link danışanın randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",

    package_prices: "Paket Fiyatlarına",
    kdv: "Kdv Dahil Değildir",

    allLicenceFeatures: "Lisans Özellikleri",

    sms_price: "SMS + WhatsApp Paket Fiyatlarımıza",
    sms_package: "SMS Paketleri",
    wp_package: "WhatsApp Paketleri",

    see_more: "Daha fazla göster",
    see_less: "Daha az göster",

    includesAll: "Bütün Lisanslarda Bulunanlar",

    buy: "Satın Al",

    load_balance: "Bakiye Yükle",

    monthly_payment: "Aylık Ödeme",
    yearly_payment: "12 Aylık Öde",
    monthly: "Aylık",
    check: {
      title: "Satın alımı onaylıyor musunuz?",
      confirmButtonTitle: "Onayla",
      discardButtonTitle: "Vazgeç",

      alertText:
        "Mevcut bakiyenizle {credit_card_amount}₺ tutarındaki {package_name} paketini satın almayı onaylıyor musunuz?",
      packageChangeAlert:
        "{active_package} paketinizden kalan {remaining_day} gününüz, birim fiyatına göre hesaplanıp {formula} gün olarak {new_package} paketinize eklenecektir.",
    },

    paymentType: {
      title: "Lütfen Ödeme Yöntemini Seçiniz",
      creditCard: "Kredi Kartı",
      eft: "Havale/EFT",
    },

    balanceErrorToast: "Lütfen yüklenecek tutarı giriniz!",
  },

  "platform/photo": {
    photoUploadToast: "Fotoğraf yükleme işleminiz başladı.",
    insertToast: "Fotoğraf yükleme başarıyla tamamlandı.",
    deleteToast: "Fotoğraf başarıyla silindi.",
    anexample: "Örnek Bir Gösterim",

    photoUploadErrorToast: "Fotoğraf ekleme işlemi başarısız oldu.",
    deletePhotoErrorToast: "Fotoğraf silinemedi.",

    photoInfo:
      "Arama ve listeleme ekranlarında gösterilecek fotoğrafı temsil eder.",
  },

  "platform/settings": {
    offOn: "Kapalı / Açık",
    chooseColor: "Renk Seç",
    platformVisibleTitle: "İşletme Görünürlüğü",
    platformVisibleDescription:
      "İşletmenizin Salon Randevu Platformu’nda danışan aramalarında görünüp/görünmemesi ayarıdır.Bu ayar kapalı olunca Salon Randevu Platformu’nda işletmeniz aramalarda çıkmayacaktır.",

    platformPriceTitle: "Hizmet Ücretleri Görünürlüğü",
    platformPriceDescription:
      "salonandevu.com ve işletmenize özel randevu alma sayfanızda hizmet ücretlerinizin gizlenmesi ayarıdır. Bu ayar kapalı olduğunda her iki alanda hizmetlerinizin ücreti görünmeyecektir.",

    platformCommentTitle: "Danışan Yorumları Görünürlüğü",
    platformCommentDescription:
      "Salon Randevu Platformu’nda işletmenize yapılan değerlendirmelerin gizlenmesi ayarıdır.Bu ayar kapalı olduğunda Salon Randevu Platformu’nda işletmenize dair herhangi bir danışan yorumu görünmeyecektir.",

    platfromAppointmentTitle: "Otomatik Randevu Onayı",
    platfromAppointmentDescription:
      "Randevu taleplerinizin otomatik onay ayarıdır. Bu ayar açık olduğunda size gelen randevu talepleri otomatik olarak onaylanır.",
    platformColorSelectionTitle: "Platform Renk Ayarı",
    platformColorSelectionDescription:
      "İşletmenize özel randevu alma sayfanızın rengini bu menüden değiştirebilirsiniz. ",
    platformVideoURLTitle: "Platform Video URL",
    platformVideoURLDescription:
      "Girdiğiniz Youtube video URL adresi, işletmenize özel randevu alma sayfanızda ziyaret edenlere gösterilecektir.",
    platformVideoURLInputLabel: "Video URL",
    platformVideoURLInputPlaceholder: "Video URL' sini giriniz",

    colorDialogTitle: "Renk Seçimi",
    colorDialogCancelTitle: "Rengi İptal Et",
    colorDialogSelectTitle: "Tamam",

    appointmentVerificationCode: "Randevu Onay Kodu",
    appointmentVerificationCodeDescription:
      "Bu, platformdan alınan randevular için doğrulama kodu ayarıdır. Bu ayar etkinleştirildiğinde platformdan randevu alındığında danışana doğrulama kodu gönderilecektir.",
    appointmentVerificationCodeDeliveryType: "Randevu Onay Kodu Gönderim Türü",
    appointmentVerificationCodeDeliveryTypeDescription:
      "Bu ayar, platformdan alınan randevular için doğrulama kodlarının gönderilmesi için kullanılır. Bu ayar etkinleştirildiğinde, bir randevu platformdan alındığında danışana doğrulama kodunun hangi gönderim türüyle gönderileceğini belirtir.",
  },

  "platform/categories": {
    howto: {
      title: "Nasıl Yapılır?",
      content: [
        "Sol tarafta oluşturduğunuz hizmetler(mevcut hizmetleriniz) , sağ tarafta ise platformda görünecek olan hizmetler bulunmaktadır.",
        "Platforma eklemek istediğiniz hizmetlerinizi seçin.",
        "Seçtiğiniz hizmetler Platformda hangi kategori altında görünecekse o kategoriyi genişletin.",
        "Seçtiğiniz hizmetler , genişlettiğiniz kategorinin altındakihizmetlerden hangisi ile eşleşiyorsa o hizmeti seçip, Aktar butonuna tıklayın.",
      ],
    },
    knowrequired: {
      title: "Bilmeniz Gerekenler",
      content: [
        "Birden fazla hizmeti aynı anda seçip taşıyabilirsiniz.",
        "Platforma taşıdığınız hizmetleriniz artık Mevcut Hizmetleriniz sekmesinde görünmeyecektir.",
        "Bir hizmeti birden fazla kategori-hizmet adıyla eşleştiremezsiniz.",
        "Seçtiğiniz hizmetler , genişlettiğiniz kategorinin altındaki hizmetlerden hangisi ile eşleşiyorsa o hizmeti seçip, Aktar butonuna tıklayın.",
        "Yanlış eşleştirdiğiniz hizmetleri, hizmet adının yanında bulunan çarpıyı kullanarak o hizmeti tekrar Mevcut Hizmetleriniz sekmesine kaldırabilirsiniz.",
        "Yeni hizmet eklerken platform için kategori-hizmet seçimi “Yeni Hizmet Ekle” sayfasına ayrıca eklendi.",
      ],
    },
  },

  /**
   * İşletme Ayarları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings
   */
  settings: {
    howToSteps: {
      firstStep: "1. Adım",
      secondStep: "2. Adım",
      thirdStep: "3. Adım",
      fourthStep: "4. Adım",
      next: "İleri",
      back: "Geri",
    },
    idInputLabel: "Salon Kodu",
    nameInputLabel: "Salon Adı",
    mailInputLabel: "Salon E-Posta Adresi",
    phoneInputLabel: "Salon Telefon Numarası",
    aboutCompanyLabel: "İşletme Hakkında",

    cityInputLabel: "İl Seçimi",
    districtInputLabel: "İlçe Seçimi",
    addressInputLabel: "Açık Adres",
    mapsInputLabel: "Google Maps İşletme Koordinatları",
    mapsInputHint: "Örneğin: 41.009336, 28.660129",
    currentLocationButtonTitle: "Anlık Konumumu Kullan",

    instagramInputLabel: "Instagram Kullanıcı Adı",
    facebookInputLabel: "Facebook Sayfası Kullanıcı Adı",
    websiteInputLabel: "İnternet Sayfası Url'i",
    withoutAtHint: "Başında @ işareti olmadan giriniz",

    priceInputLabel: "Fiyat Bilgisi Ayarı",
    priceInputHint:
      "İşletme URL'niz üzerinden randevu talebinde bulunacak danışanlarınıza seçtiği işlemin fiyat bilgisinin gösterilip/gösterilmeyeceği ayarıdır.",

    commentsInputLabel: "Danışan Yorumları Ayarı",
    commentsInputHint:
      "İşletme URL'nizde danışanlarınızın anketlerde yaptığı yorumların gösterilip/gösterilmeyeceği ayarıdır.",
    systemLanguage:
      "Sistem Dili Değişikliği İngilize/Türkçe olarak ayarlanabilir.",
    inputOn: "Açık",
    inputOff: "Kapalı",

    urlInputLabel: "İşletme Randevu URL'si",
    openOnBrowserButtonTitle: "Tarayıcıda Aç",
    copyLinkButtonTitle: "Linki Kopyala",
    copyLinkToast: "Link başarıyla kopyalandı.",
    updateButtonTitle: "Bilgileri Güncelle",

    geolocationErrorToast: "Tarayıcınızın Geolocation desteği bulunmuyor.",
    locationErrorToast:
      "Konumunuz alınamadı, lütfen daha sonra tekrar deneyin.",
    updateToast: "Bilgiler başarıyla güncellendi.",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/hours
   */
  "settings/hours": {
    businessCategory: "Firma Kategorisi",
    startInputLabel: "Mesai Başlangıcı",
    endInputLabel: "Mesai Bitişi",
    periodBetweenInputLabel: "Randevu Sıklığı (Dk)",
    startInputLabelHour: "Başlangıç Saat",
    startInputLabelMinute: "Başlangıç Dakika",

    startHour: "Başlangıç Saati",
    endHour: "Bitiş Saati",

    endInputLabelHour: "Bitiş Saat",
    endInputLabelMinute: "Bitiş Dakika",

    updateButtonTitle: "Bilgileri Güncelle",

    updateToast: "Bilgiler başarıyla güncellendi.",
    compareErrorToast:
      "Bitiş Saati alanı Başlangıç Saati alanından küçük olamaz.",
  },

  /**
   * İşletme Galerisi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/gallery
   */
  "settings/gallery": {
    numberPhoto: "Toplam Yükleyebileceğiniz Fotoğraf Sayısı",
    uploadButtonTitle: "Fotoğraf yüklemek için bu alana tıklayın.",
    anexample: "Örnek Bir gösterim",
    nextPhoto: "Sonraki Fotoğraf",

    checkAllButtonTitle: "Tüm Fotoğrafları Seç",
    deleteSelectedButtonTitle: "Seçilen Fotoğrafları Sil ({count})",

    delete: {
      // Modal
      title: "Silme işlemini onaylıyor musunuz?",
      confirmButtonTitle: "Onayla",
      discardButtonTitle: "Vazgeç",

      alertBoldText: "Bu işlemi onaylıyor musunuz?",
      alertText:
        "Silme işlemini gerçekleştirmeniz durumunda silinen fotoğraflar ilişkili bütün sayfalardan kaldırılacaktır.",
    },

    photoUploadToast: "Fotoğraf yükleme işleminiz başladı.",
    insertToast: "Fotoğraf yükleme başarıyla tamamlandı.",
    deleteToast: "Fotoğraf başarıyla silindi.",

    photoMorePictureError: "8 fotoğraftan fazla yükleme yapılamaz.",

    enterprise_gallery_info:
      "Bu alandan işletmenize ait mekan fotoğrafları ekleyebilirsiniz.Bu görseller Salon Randevu Platformu ve işletme sayfanızda görüntülenecektir.",
  },

  "settings/service-gallery": {
    numberPhoto: "Toplam Yükleyebileceğiniz Fotoğraf Sayısı",
    uploadButtonTitle: "Fotoğraf yüklemek için bu alana tıklayın.",
    anexample: "Örnek Bir gösterim",

    photoDelete: "Silinecek Fotoğrafı Seçiniz",

    checkAllButtonTitle: "Tüm Fotoğrafları Seç",
    deleteSelectedButtonTitle: "Seçili Fotoğrafları Sil ({count})",

    delete: {
      // Modal
      title: "Silme işlemini onaylıyor musunuz?",
      confirmButtonTitle: "Onayla",
      discardButtonTitle: "Vazgeç",

      alertBoldText: "Bu işlemi onaylıyor musunuz?",
      alertText:
        "Silme işlemini gerçekleştirmeniz durumunda silinen fotoğraflar ilişkili bütün sayfalardan kaldırılacaktır.",
    },

    photoUploadToast: "Fotoğraf yükleme işleminiz başladı.",
    insertToast: "Fotoğraf yükleme başarıyla tamamlandı.",
    deleteToast: "Fotoğraf başarıyla silindi.",

    photoMoreUploadError: "8 fotoğraftan fazla yükleme yapılamaz.",

    service_photo_info:
      "Bu alandan verdiğiniz hizmetlerle ilgili görseller ekleyebilirsiniz.Bu görseller Salon Randevu Platformu ve işletme sayfanızda görüntülenecektir.",
  },

  /**
   * Sistem Logları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/logs
   */
  "settings/logs": {
    headings: {
      createdAt: "İşlem Tarihi",
      message: "İşlem Açıklaması",
      detail: "İşlem Detayı",
      ip: "IP Adresi",
    },
  },

  /**
   * Sözleşme sayfanın bulunduğu key.
   * https://app.salonrandevu.com/settings/agreement/index
   */

  "settings/agreements": {
    createAgreement: {
      personalInfo: "Kişisel Bilgiler",
      paymentInfo: "Ödeme Bilgileri",
      clauseInfo: "Madde Bilgileri",
    },
    personal_info: {
      name_and_surname: "Ad Soyad",
      phone: "Telefon Numarası",
      birth_date: "Doğum Tarihi",
      job: "Meslek",
      id_number: "T.C No",
      tax_number: "Vergi No.",
      address: "Adres",
    },
    payment_info: {
      staff_name: "Personel Adı",
      service: "Hizmet",
      total_amount: "Toplam Tutar",
    },
  },

  /**
   * 404 sayfasının bulunduğu key.
   * https://app.salonrandevu.com/404
   */
  notFound: {
    heroHeadingText: "Maalesef, bulamadık!",
    boldText:
      "Maalesef aradığınız ya da gitmeye çalıştığınız sayfayı bulamadık.",
    text: "Silinmiş, taşınmış ya da oluşturulmamış olabilir.",
  },

  /**
   * Paket yükselt uyarısının bulunduğu key.
   */
  planUpgrade: {
    heroHeadingText: "Paketinizi yükseltin!",
    boldText: " Bu modülü kullanmak için paket yükseltmeniz gerekmektedir.",
    text: "Bir problem olduğunu düşünüyorsanız bize iletişim kanallarımız üzerinden ulaşablirsiniz.",
    showPackagesButtonTitle: "Paketleri Görüntüle",
    callUsButtonTitle: "Bizi Arayın - 0(212) 706 0510",
  },

  /**
   * İşletme URL'si sayfasının bulunduğu key.
   * https://app.salonrandevu.com/isletme/22
   */
  businessURL: {
    showLess: "daha az göster",
    showMore: "daha fazla göster",

    callButtonTitle: "Hemen Ara (0{phone})",
    instagramHeading: "İnstagram'da Takip Edin!",
    instagramText: "Instagram'da @{username}",

    facebookHeading: "Facebook'ta Takip Edin!",
    facebookText: "Facebook'ta @{username}",

    aboutHeading: "İşletme Bilgileri",
    phoneText: "Telefon",
    mailText: "E-Posta",
    addressText: "Adres",

    wrong_code: "Hatalı kod girdiniz. Tekrar Deneyiniz.",

    appointment: {
      // Tab
      tab: "Randevu Oluştur",
      title: "Randevu Talebi Oluştur",

      nameInputLabel: "Adınız",
      surnameInputLabel: "Soyadınız",
      phoneInputLabel: "Cep Telefonu",
      serviceInputLabel: "Hizmet Seçimi",
      serviceInputHint: "Hizmetler arasından seçim yapınız",
      staffInputLabel: "Personel Seçimi",
      staffInputHint: "Randevunuzda sizinle ilgilenecek olan personeli seçiniz",
      staffInputCompanyHint:
        "Randevunuzda sizinle ilgilenecek olan personeli seçiniz. (Personel listesinde , seçmiş olduğunuz hizmeti sağlayan personeller listelenir.)",
      dateInputLabel: "Randevu Tarihi",
      dateInputHint:
        "Randevu tarihini ister elle yazabilir, ister takvim ikonuna tıklayarak seçebilirsiniz",

      availableHoursButtonTitle: "Uygun Saatleri Ara",

      check: {
        // Modal
        title: "Randevu Talebini Gönder",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",

        alertBoldText: "Sayın {name} {surname}",
        alertText:
          "{date} günü, saat {time} için randevunuzun oluşturulmasını onaylıyor musunuz?",

        priceText:
          "Seçmiş olduğunuz {service} işleminin genel ücreti işletme tarafından {amount}₺ olarak belirlenmiştir.",
        priceBoldText:
          "Bu fiyat bilgilendirme amacıyla verilmekte olup, kesin bir işlem tutarı niteliği taşımamaktadır.",

        noteInputLabel: "Randevu Notu (İsteğe Bağlı)",
        noteInputHint: "/255 Karakter",
      },

      insertToast: "Randevu talebiniz başarıyla oluşturuldu.",

      appointment_button: "Randevu Al",
    },

    servicesGallery: {
      title: "Hizmet Galerisi",
    },

    services: {
      // Tab
      tab: "Hizmet Listesi",
      title: "Hizmet Listesi",
      processTime: "İşlem Süresi: {time} dakika",
    },

    staffs: {
      //Tab
      tab: "Personel Listesi",
      title: "Personel Listesi",
    },

    comments: {
      // Tab
      tab: "Danışan Yorumları",
      title: "Yorumlar",
    },
  },

  "support/live_support": {
    liveSupportRequestTitle: "Canlı Destek Talebi",
    appointmentDate: "Randevu Tarihi ve Saati",
    appointmentRequestTitle: "Destek Talepleri",
    createAppointmentButtonTitle: "Randevu Al",

    name: "Adınız",
    surname: "Soyadınız",
    supportrequestsubject: "Destek Talebi Konusu",
    supportrequestdetail: "Destek Talebi Detayı",
    hours: "Saat",

    nameInputLabel: "İsim",
    nameInputHint: "Lütfen isminizi giriniz",

    surnameInputLabel: "Soyisim",
    surnameInputHint: "Lütfen soyisminizi giriniz",

    phoneNumberInputLabel: "Telefon Numarası",

    supportRequestAboutLabel: "Destek Talebi Konusu",
    supportRequestAboutHint: "Destek talebi konusunu yazınız",

    dateInputLabel: "Randevu Tarihi",
    noAvailableHours: "Seçebileceğiniz mevcut bir saat bulunamadı.",

    supportRequestDetailLabel: "Destek Talebi Detayı",
    supportRequestDetailHint:
      "Destek talebinde bulunduğunuz konu ile ilgili lütfen ayrıntılı açıklama yapınız.",
    supportRequestDetailHelperText:
      "Konu ile ilgili yaptığınız işlemleri ve yaşadığınız sorunu lütfen ayrıntıları ile açıklayınız. Vereceğiniz detaylar size en hızlı ve doğru şekilde yardımcı olmamızı sağlayacaktır.",

    appointmentCancelDescription:
      "Önemli: Destek saatinden önce sorununuz çözüldüyse randevunuzu iptal etmeyi unutmayın lütfen.",

    headings: {
      appointmentDate: "Randevu Tarihi",
      appointmentAbout: "Konu",
      appointmentDetail: "Detay",
      name: "İsim Soyisim",
      phoneNumber: "Telefon",
      appointmentStatus: "Rnadevu Durumu",
      cancelButtonTitle: "İptal Et",

      replacements: {
        status: {
          waiting: "Bekleniyor",
          done: "Görüşüldü",
          cancel: "İptal Edildi",
        },
      },
    },

    alertInfo: {
      text1:
        " Seçtiğiniz zamanda 02121060510 numaralı telefonumuzdan sizi arayacağız. Lütfen uzak bağlantı ihtiyacına karşın",
      text2: "ALPEMIX uzaktan bağlantı programını",
      text3: "indirip çalışır vaziyete getiriniz. İstediğiniz her zaman",
      text4: "Pasif Danışanlar",
      text5: "sekmesinden danışan bilgilerine erişebilirsiniz.",
    },

    createLiveSupportRequestToast:
      "Randevu talebiniz başarıyla oluşturulmuştur",
    cancelLiveSupportRequestToast: "Randevu talebi başarıyla iptal edilmiştir.",
  },

  /**
   * Birden fazla yerde kullanılan sabit tanımlar bu alanda yazılmaktadır.
   */
  component: {
    actionHeadingText: "İşlemler",
    actionHeadingTextRating: "Yorum İşlemleri",
    detailEditButtonTitle: "Detay & Düzenle",
    validationErrorText: "Alanları istenilen formatta düzenleyin",
    whatsapp: "Whatsapp ile iletişime geç",
    loadingText: "Yükleniyor...",

    "customer/customer_detail": {},

    smartBanner: {
      // Loginde mobil uygulama yayında banner'ı
      onAirText: "Uygulamamız Yayında!",
    },

    timerButton: {
      waitText: "Bekleyin",
    },

    excelButton: {
      title: "Excel Olarak İndir",
    },

    datePickerInput: {
      // Datepicker Input
      cancelLabel: "İPTAL",
      clearLabel: "TEMİZLE",
      okLabel: "TAMAMLA",
    },

    dateRangeStats: {
      // Dashboard'daki istatistik kartları
      yesterday: "Dün",
      today: "Bugün",
      week: "Bu Hafta",
      month: "Bu Ay",
      lastWeek: "Son 7 gün",
      last_15_days: "Son 15 gün",
      lastMonth: "Son 30 gün",
    },

    CADialog: {
      // Customer Add Dialog
      title: "Hızlı Danışan Oluştur",
      createButtonTitle: "Danışanı Oluştur",
      discardButtonTitle: "Vazgeç",

      nameInputLabel: "Danışan Adı",
      nameInputPlaceholder: "Danışanı adını giriniz",

      surnameInputLabel: "Danışan Soyadı",
      surnameInputPlaceholder: "Danışan soyadı giriniz",

      phoneInputLabel: "Cep Telefonu",

      updateCustomerDetails: "Danışanı Güncelle",
      changeCutomertoActive: "Danışanı Aktif Hale Getir",
      changeCutomertoPassive: "Danışanı Pasif Hale Getir",

      genderInputLabel: "Cinsiyet",
      genderMale: "Erkek",
      genderFemale: "Kadın",

      birthdayInputLabel: "Doğum Tarihi",
      birthdayInputInvalidMessage:
        "Lütfen istenilen formatta giriş yapınız. Gün/Ay/Yıl",

      maritalStatusInputLabel: "Medeni Hali",
      maritalStatusMarried: "Evli",
      maritalStatusSingle: "Bekar",

      delete: {
        title: "Silmeyi onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",
        alertText: "Bu işlemin geri dönüşü yoktur!",
        alertTitleText:
          "Bu danışanı silmeniz durumunda danışanın bilgilerine bir daha erişemeyebilirsiniz.",
      },
      all_delete: {
        title: "Tüm danışanları silme işlemini onaylıyor musunuz?",
        alertTitleText:
          "Tüm danışankları silmeniz durumunda danışanların bilgilerine bir daha erişemeyebilirsiniz.",
      },

      checkingForeingNumber: "Yabancı Numara Olarak İşaretle",

      confirmChangeCustomerTitle: "Pasif ayarlamayı onaylıyor musunuz?",
      confirmCustomerTitle:
        "Bu danışanı pasif hale getirmeniz durumunda bir daha randevu/adisyon gibi alanlarda danışanı göremeyeceksiniz.",
      confirmTitle1: "İstediğiniz her zaman",
      confirmTitle2: "Pasif Danışanlar",
      confirmTitle3: "sekmesindendanışan bilgilerine erişebilirsiniz.",

      secondPhoneInputLabel: "İkinci Telefon Numarası",
      secondPhoneInputPlaceholder: "Varsa ikinci telefon numarasını giriniz.",

      mailInputLabel: "E-Posta Adresi",
      mailInputPlaceholder: "Varsa E-Posta adresini giriniz.",

      descriptionInputLabel: "Ek Açıklamalar",

      workStatusInputLabel: "Çalışma Durumu",
      workStatusWorking: "Çalışıyor",
      workStatusUnemployed: "Çalışmıyor",
      workStatusEmployer: "İşveren",
      workStatusStudent: "Öğrenci",
      workStatusHouseWife: "Ev Hanımı",

      relatedBusinessInputLabel: "İlgili Kurum",
      relatedBusinessInputPlaceholder:
        "Danışanın hangi kurum üzerinden geldiğini varsa belirtiniz",

      sendSmsInputLabel: "SMS Gönderim Durumu",
      sendSmsTrue: "Gönderilsin",
      sendSmsFalse: "Gönderilmesin",

      advancedOptions: "Gelişmiş Seçenekler",
      advancedOptionsHide: "Gizle",
      advancedOptionsShow: "Göster",

      insertToast: "Danışan kaydı başarıyla oluşturuldu!",
    },

    appointment_notification: {
      description: "Yeni bir randevunuz oluştu. İyi çalışmalar dileriz.",
      closeButtonTitle: "Tamam",
      appointmentsButtonTitle: "Randevular",
    },

    allServicesDialog: {
      serviceCategoryTitle: "Hizmet Kategorileri",
      selectedServicesTitle: "Seçtiğiniz Hizmetler",
      loading: "Yükleniyor...",
      noSelectedService: "Seçilen hizmet bulunmamaktadır.",
      notFoundService: "Aradığınız hizmet bulunamamıştır.",
      transferButtonTitle: "Seçilen Hizmetleri Aktar",

      successToast: "Hizmetleriniz başarıyla aktarılmıştır.",
    },

    sms_review: {
      smsReviewTitle: "Mesaj Önizleme",
      totalNumberTitle: "Toplam Gönderilecek Kişi Sayısı:",
      totalWPCreditTitle: "Toplam WhatsApp Kredisi:",
      totalSMSCreditTitle: "Toplam SMS Kredisi:",
      smsContentTitle: "Mesaj İçeriği:",

      inUseSMSCredit: "Kullanılacak Toplam SMS Kredisi:",
      inUseWPCredit: "Kullanılacak Toplam WhatsApp Kredisi:",

      sendButtonTitle: "Gönder",
      discardButtonTitle: "İptal",
    },

    staffOrderDialog: {
      staffOrderTitle: "Personelleri Sırala",
      closeButtonTitle: "Kapat",
    },

    NADDialog: {
      createAppointment: "Randevu Oluştur",
    },

    OARDialog: {
      title: "Online Randevu Talepleri",
    },

    filterAppointmentDialog: {
      title: "Randevuları Filtrele",
    },

    dayWeekButton: {
      day: "Gün",
      week: "Hafta",
    },

    semiCirclePie: {
      noDisplayChartDescription:
        "Değerler sıfıra eşit olduğu için grafik gösterilmiyor.",
    },
  },

  panel: {
    licenseRemaining: "gün kaldı",
    sms_credit: "SMS",
    wp_credit: "WhatsApp",
    remainingAppointment: "randevu kaldı",
    changeLanguage: "Dil Değiştir",
  },

  panelTabs: {
    moreFavoriteErrorToast: "En fazla 7 tane favori ekleyebilirsiniz.",
  },
  licenseDetails: {
    currentPackage: "(mevcut paket)",
    features: "Özellikler",
    silver: [
      "3 Personele Kadar ",
      'Yeni Üyelere Özel "50 Randevu Hediye"',
      'Yeni Üyelere Özel "100 SMS Hediye"',
      "100 Yeni Randevu Sadece 219₺",
    ],
    gold: [
      "5 Personele Kadar",
      "Sınırsız Randevu",
      "Çoklu Şube Yönetimi",
      "Özel Danışan Temsilcisi",
      'Yeni Üyelere Özel "250 SMS Hediye"',
    ],
    platinum: [
      "7 Personele Kadar",
      "Adisyon İşlemleri",
      "Paket Yönetimi",
      "Stok Yönetimi",
      "Prim İşlemleri",
      'Yeni üyelere özel "500 SMS hediye"',
      "Gelişmiş Raporlar",
      "Sınırsız Randevu",
      "Online Randevu Sitesi",
      "Çoklu Şube Yönetimi",
      "Özel Danışan Temsilcisi",
    ],
    diamond: [
      "10 Personele Kadar",
      "E-İmza Eklentisi",
      "Sözleşme Yönetimi",
      "Adisyon İşlemleri",
      "Paket Yönetimi",
      'Yeni üyelere özel "2000 SMS Hediye"',
      "Stok Yönetimi",
      "Prim İşlemleri",
      "Gelişmiş Raporlar",
      "Sınırsız Randevu",
      "Online Randevu Sitesi",
      "Çoklu Şube Yönetimi",
      "Özel Danışan Temsilcisi",
      "WhatsApp Entegrasyonu",
      "Barkod Okuyucu",
      "Randevuya Gelme Onay Linki",
      "Online Randevu Linki",
    ],
    includesAllLicence: [
      "İşletme Yönetimi",
      "Danışan Yönetimi",
      "Personel Yönetimi",
      "SMS Gönderimi",
      "Randevu Hatırlatma",
      "Randevu Yönetimi",
      "İşletme Galerisi",
      "Yapılacaklar Listesi",
    ],
  },
  tutorial: {
    description: "Görevleri Tamamla ve Hediye Kazan",
    is_success: "Ödülleri Toplayın",
    title: "Görevler",
    button_wheel: "Çarkı Çevir",
    wheel_title: "Çarkı Çevir Hediyeni Kazan!",
    wheel_prize: "Kazandınız Ödülü almak için görevleri Tamamlayınız!",
  },
};

export default langTR;
