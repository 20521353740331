import React, { Component, useEffect } from "react";
import AppContext from "../../../context/store";
import styled from "styled-components";
import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import { Update, Launch, ExitToApp } from "@material-ui/icons";
import Button from "../../../theme/Button";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import AuthWithToken from "../../../functions/AuthWithToken";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Input from "../../../theme/CustomMUI/Input";
import { ButtonBase } from "@material-ui/core";
import BranchAdd from "./InPanelAdd";
import AuthContainer from "../../../components/AuthContainer";

class BranchesIndex extends Component {
  static contextType = AppContext;

  state = {
    hero_loaded: false,
    loaded: false,
    TAB_TYPE: 0,

    branches: [
      ...this.context.state.company_branches?.map((item) => ({
        ...item,
        license: {
          ...item.license,
          end_date_text: item.license?.end_date
            ? moment(item.license.end_date).local().format("DD/MM/YYYY HH:mm")
            : null,
        },
      })),
    ],
    selected_branch: {},

    branch_reports: [],

    branch_permitted: {
      staff_id: null,
      staff_name: null,
      staff_surname: null,
      staff_phone: null,
      staff_mail: null,
      staff_password: null,
    },
    branch_permitted_modal: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true,
        hero_loaded: true,
      });
    }, 250);
  }

  logInBranch = (staff_id) => {
    const date = new Date();
    if (date.getMinutes() === 0) date.setMinutes(1);
    if (date.getSeconds() === 0) date.setSeconds(1);

    Axios.post(`${this.context.api_endpoint}/auth/staff?auth=staff-by-id`, {
      owner_id: this.context.state.user.id * date.getMinutes(),
      staff_id: parseInt(staff_id) * date.getSeconds(),
      time: `${moment(date).format("YYYY-MM-DD")}T${moment(date).format(
        "HH:mm:ss"
      )}Z`,
    })
      .then(({ data }) => {
        AuthWithToken(data.data.token, this.context).then(() => {
          this.props.history.push("/dashboard");
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  branchPermittedUpdate = () => {
    const { branch_permitted } = this.state;
    Axios.put(`${this.context.api_endpoint}/company/dealer/update`, {
      ...branch_permitted,
    })
      .then((response) => {
        response.status === 201 &&
          toast.success("Personel bilgileri başarıyla güncellendi!");
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  render() {
    const { hero_loaded } = this.state;
    const TabNavigator = this._TabNavigatorBuilder;
    return (
      <AuthContainer>
        <BranchView>
          <Hero
            style={{
              background: `url(${require("./../../../assets/images/textures/1.png")})`,
            }}
          >
            <HeroOverlay />
            <HeroInner className={hero_loaded ? "show" : undefined}>
              <h4>{this.context.t(`branches.heroHeadingText`)}</h4>
              <h1>
                {this.context.t(`branches.greetingText`)}{" "}
                {this.context.state.user.full_name}!
              </h1>
              <p className="mb-1">
                {this.context.t(`branches.greetingBottomText`)}
              </p>
              <p className="mb-1">
                {this.context.t(`branches.greetingBottomText2`)}
              </p>
              <Button
                iconComponent={ExitToApp}
                title={this.context.t(`branches.logoutButtonTitle`)}
                backgroundColor="grey-opacity"
                textColor="grey"
                onClick={async () => {
                  await this.context.dispatch({ type: "LOGOUT_USER" });
                  await this.props.history.push("/");
                }}
              />
            </HeroInner>
          </Hero>
          <TabNavigator />
        </BranchView>
      </AuthContainer>
    );
  }

  /**
   * Hero'nun altında tab navigator oluşturan ve
   * ilgili tab'in component'ini sayfaya dahil eden builderfonksiyonudur..
   */
  _TabNavigatorBuilder = () => {
    const Tab = this._NavigatorSingleTabBuilder;

    // Components
    const BranchList = this._BranchListBuilder;
    // const BranchReports = this._BranchReportsBuilder;

    // Navigator Settings
    const TabNavigator = [
      {
        title: this.context.t(`branches.tabBranchListTitle`),
        component: <BranchList />,
      },
      // {
      //   title: this.context.t(`branches.tabBranchReportsTitle`),
      //   component: <BranchReports />,
      // },
      {
        title: this.context.t(`branches.tabBranchAddTitle`),
        component: (
          <BranchAdd onSuccess={() => this.setState({ TAB_TYPE: 0 })} />
        ),
      },
    ];

    return (
      <>
        <Grid>
          <Column className="xs-12 sm-12">
            <TNavigator className="mb-3">
              {TabNavigator.filter((_tab) => _tab !== undefined).map(
                (_tab, index) => (
                  <Tab
                    key={index}
                    title={_tab.title}
                    control={this.state.TAB_TYPE === index ? "active" : ""}
                    onClick={() =>
                      this.setState({ TAB_TYPE: index, loaded: true })
                    }
                  />
                )
              )}
            </TNavigator>
          </Column>
        </Grid>
        {TabNavigator[this.state.TAB_TYPE].component}
      </>
    );
  };

  /**
   * _TabNavigatorBuilder içerisinde kullanılacak olan tekli tab buttonun builder fonksiyonudur.
   */
  _NavigatorSingleTabBuilder = ({ title, control, onClick }) => {
    return (
      <TNavigatorTab>
        <ButtonBase className={control} onClick={onClick}>
          {title}
        </ButtonBase>
      </TNavigatorTab>
    );
  };

  _BranchListBuilder = () => {
    const {
      loaded,
      branches,
      selected_branch,
      branch_permitted,
      branch_permitted_modal,
    } = this.state;
    return (
      <BranchContainer>
        <Grid>
          <Column className="xs-12 sm-12">
            <Table
              isExist={false}
              loaded={loaded}
              rows={[this.context.state.company_branch_first, ...branches]}
              headings={{
                id: { label: this.context.t(`branches.headings.listTable.id`) },
                name: {
                  label: this.context.t(
                    `branches.headings.listTable.companyName`
                  ),
                },
                "staffs[0].full_name": {
                  label: this.context.t(
                    `branches.headings.listTable.staffFullName`
                  ),
                },
                "staffs[0].detail.phone": {
                  label: this.context.t(
                    `branches.headings.listTable.staffPhoneNumber`
                  ),
                },
                "staffs[0].detail.mail": {
                  label: this.context.t(
                    `branches.headings.listTable.staffMail`
                  ),
                },
                "license.end_date_text": {
                  label: this.context.t(
                    `branches.headings.listTable.licenseEndDate`
                  ),
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              buttons={[
                {
                  iconComponent: Launch,
                  title: this.context.t(`branches.loginBranchButtonTitle`),
                  textColor: "blue",
                  onClick: (row) => {
                    this.logInBranch(row.staffs[0].id);
                  },
                },
                {
                  iconComponent: Update,
                  title: this.context.t(`branches.editStaffButtonTitle`),
                  textColor: "green",
                  onClick: async (row) => {
                    if (new Date(row.license.end_date) > new Date()) {
                      await this.setState({
                        selected_branch: { ...row },
                        branch_permitted: {
                          staff_id: row.staffs[0].id,
                          staff_name: row.staffs[0].name,
                          staff_surname: row.staffs[0].surname,
                          staff_phone: row.staffs[0].detail.phone,
                          staff_mail: row.staffs[0].detail.mail,
                          staff_password: "",
                        },
                      });
                      await this.setState({ branch_permitted_modal: true });
                    } else
                      toast.warning(
                        this.context.t(`branches.licenseExpiredEditToast`)
                      );
                  },
                },
              ]}
            />
          </Column>
        </Grid>

        <AlertDialog
          title={`${selected_branch?.name} Yetkilisini Düzenle`}
          open={branch_permitted_modal}
          maxWidth="md"
          fullWidth
          closeHandler={() => this.setState({ branch_permitted_modal: false })}
          disableBackdropClick={false}
          buttons={[
            {
              icon: "check",
              title: this.context.t(`branches.completeEditingButtonTitle`),
              backgroundColor: "blue-opacity",
              onClick: () => this.branchPermittedUpdate(),
            },
            {
              icon: "close",
              title: this.context.t(`branches.discardEditingButtonTitle`),
              textColor: "grey",
            },
          ]}
        >
          <Input
            label="Yetkili Adı"
            value={branch_permitted.staff_name}
            onChange={(e) =>
              this.setState({
                branch_permitted: {
                  ...branch_permitted,
                  staff_name: e.target.value,
                },
              })
            }
          />
          <Input
            label="Yetkili Soyadı"
            value={branch_permitted.staff_surname}
            onChange={(e) =>
              this.setState({
                branch_permitted: {
                  ...branch_permitted,
                  staff_surname: e.target.value,
                },
              })
            }
          />
          <Input
            label="Yetkili Maili"
            value={branch_permitted.staff_mail}
            onChange={(e) =>
              this.setState({
                branch_permitted: {
                  ...branch_permitted,
                  staff_mail: e.target.value,
                },
              })
            }
          />
          <Input
            label="Yetkili Telefonu"
            value={branch_permitted.staff_phone}
            onChange={(e) =>
              this.setState({
                branch_permitted: {
                  ...branch_permitted,
                  staff_phone: e.target.value,
                },
              })
            }
          />
        </AlertDialog>
      </BranchContainer>
    );
  };

  // _BranchReportsBuilder = () => {
  //   const { loaded, branch_reports } = this.state;

  //   useEffect(() => {
  //     this.setState({ loaded: false });
  //     Axios.get(`${this.context.api_endpoint}/company/dealer/reports`)
  //       .then(({ data: { data } }) => {
  //         this.setState({
  //           loaded: true,
  //           branch_reports: [
  //             ...data.map((branch) => ({
  //               ...branch,
  //               staff_count: `${branch.staff_count}`,
  //               appointment_count: `${branch.appointment_count}`,
  //               receipt_count: `${branch.receipt_count}`,
  //               service_income: `${branch.service_income?.toFixed(2)}`,
  //               sale_income: `${branch.sale_income?.toFixed(2)}`,
  //               staff_bounty: `${branch.staff_bounty?.toFixed(2)}`,
  //               total_income: `${branch.total_income?.toFixed(2)}`,
  //               total_expense: `${branch.total_expense?.toFixed(2)}`,
  //               total_count: `${(
  //                 branch.total_income - branch.total_expense
  //               )?.toFixed(2)}`,
  //             })),
  //           ],
  //         });
  //       })
  //       .catch((e) => {
  //         if (e.response.status === 401) {
  //           toast.error(e.response.data.message);
  //         } else {
  //           toast.warning(e.response.data.message);
  //         }
  //       });
  //   }, []);

  //   return (
  //     <BranchContainer>
  //       <Grid>
  //         <Column className="xs-12 sm-12">
  //           <Table
  //             loaded={loaded}
  //             rows={[
  //               ...branch_reports,
  //               {
  //                 company_name: "Toplam",
  //                 staff_count: this.sum("staff_count"),
  //                 appointment_count: this.sum("appointment_count"),
  //                 receipt_count: this.sum("receipt_count"),
  //                 service_income: this.sum("service_income"),
  //                 sale_income: this.sum("sale_income"),
  //                 staff_bounty: this.sum("staff_bounty"),
  //                 total_income: this.sum("total_income"),
  //                 total_expense: this.sum("total_expense"),
  //                 total_count: this.sum("total_count"),
  //               },
  //             ]}
  //             headings={{
  //               company_name: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.companyName`
  //                 ),
  //               },
  //               staff_count: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.staffCount`
  //                 ),
  //               },
  //               appointment_count: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.appointmentCount`
  //                 ),
  //               },
  //               receipt_count: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.receiptCount`
  //                 ),
  //               },
  //               service_income: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.serviceIncome`
  //                 ),
  //                 suffix: "₺",
  //               },
  //               sale_income: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.saleIncome`
  //                 ),
  //                 suffix: "₺",
  //               },
  //               staff_bounty: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.staffBounty`
  //                 ),
  //                 suffix: "₺",
  //               },
  //               total_income: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.totalIncome`
  //                 ),
  //                 suffix: "₺",
  //               },
  //               total_expense: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.totalExpense`
  //                 ),
  //                 suffix: "₺",
  //               },
  //               total_count: {
  //                 label: this.context.t(
  //                   `branches.headings.reportsTable.totalCount`
  //                 ),
  //                 suffix: "₺",
  //               },
  //             }}
  //           />
  //         </Column>
  //       </Grid>
  //     </BranchContainer>
  //   );
  // };

  sum = (key) => {
    const { branch_reports } = this.state;
    return `${branch_reports
      .map((branch) => parseFloat(branch[key]))
      .reduce((a, b) => a + b, 0)}` !== "NaN"
      ? `${branch_reports
        .map((branch) => parseFloat(branch[key]))
        .reduce((a, b) => a + b, 0)
        .toFixed(2)}`
      : `0.00`;
  };
}

export default BranchesIndex;

const BranchView = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 40px;
`;

const Hero = styled.div`
  width: 100vw;
  min-height: 300px;
  padding: 64px 0 24px;
  box-sizing: border-box;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-size: 22px;
    font-weight: 600;
  }

  p {
    font-weight: 500;
  }
`;

const HeroOverlay = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0.88) 100%
  );
`;

const HeroInner = styled.div`
  z-index: 999;
  text-align: center;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: -40px;
  opacity: 0;

  &.show {
    position: relative;
    top: 0;
    opacity: 1;
    transition: top ease 0.8s, opacity 1.6s ease;
  }
`;

const BranchContainer = styled.div`
  padding: 0 16px;
`;

const TNavigator = styled.ul`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  background: #fff;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TNavigatorTab = styled.li`
  width: auto;
  flex: none;
  text-align: center;

  button {
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    text-decoration: none;
    display: block;
    padding: 16px;
    line-height: 26px;
    color: #303030;

    &.active {
      color: var(--primary);
      border-bottom: 2px solid var(--primary);
    }
  }
`;
