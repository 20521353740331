import React, { Component } from "react";
import AppContext from "../../../../context/store";
import AuthContainer from "../../../../components/AuthContainer";
import { Grid, Column } from "../../../../theme/Grid";
import Input from "../../../../theme/CustomMUI/Input";
import Button from "../../../../theme/Button";
import Select from "../../../../theme/CustomMUI/Select";
import Axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import AutocompleteInput from "../../../../theme/CustomMUI/AutoComplete";
import moment from "moment";
import { InputAdornment } from "@material-ui/core";
import Loader from "../../../../assets/images/circular.gif";
import { flushSync } from "react-dom";
import RemainingDebtRed from "../../../../assets/images/svg/remaining_debt_red.svg";
import CustomerAddDialog from "../../../../components/Dialogs/CustomerAddDialog";
import { Group, LocalMall } from "@material-ui/icons";
import AutoCompleteInputWithoutEndPoint from "../../../../theme/CustomMUI/AutoCompleteInputWithoutEndPoint";

let typingTimeout = null;

class PackageSalesAdd extends Component {
	static contextType = AppContext;
	state = {
		customers: [],
		packages: [],
		services: [],
		staffs: [],
		tutorial: false,
		selected_customer: this.props.customer ? this.props.customer : null,
		new_package: {
			services: {},
		},

		create_button_disabled: false,

		__package: null,
		// selected_package: null,
		package_modal: false,

		typingTimeout: null,

		total: 0,
		total_loader: false,
		customer_add_modal: false,

		ready_to_sale_packet: this.props.location?.state?.packet_id
			? this.props.location?.state?.packet_id
			: null,
	};

	searchCustomerByKey = async (key) => {
		let arr = [];
		await Axios.get(`${this.context.api_endpoint}/company/customers`, {
			params: { key },
		})
			.then(({ data }) => (arr = [...data.data.records]))
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.warning(e.response.data.message);
				}
			});
		return arr.map((item) => {
			return {
				id: item.id,
				full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
			};
		});
	};

	// Add costumer popup

	CustomerDialog = () => {
		const [setCustomerAddModal] = React.useState(false);

		React.useEffect(() => {
			flushSync(() => {
				this.setState({
					customer_add_modal: this.state.customer_add_modal,
				});
			});
		}, [this.state.customer_add_modal]);
		return (
			<CustomerAddDialog
				disabledAdvanedOptions={true}
				open={this.state.customer_add_modal}
				onClick={(data) => {
					this.setState({
						selected_customer: { ...data },
					});
				}}
				createdCustomerHandler={async (data) => {
					await this.setState({
						selected_customer: {
							...data,
							full_name: `${data.name} ${data.surname}`,
						},
					});
					await this.setState({ create_appointment_modal: true });
				}}
				closeHandler={() =>
					this.setState({
						customer_add_modal: false,
					})
				}
				setOpen={setCustomerAddModal}
			/>
		);
	};

	getReqArrays = () => {
		Axios.get(`${this.context.api_endpoint}/company/packets/nop`)
			.then(({ data }) => {
				this.setState({
					packages: [...data.data.packets],
					services: [...data.data.services],
					staffs: [
						...data.data.staffs.map((item) => ({
							...item,
							full_name: `${item.name} ${item.surname}`,
						})),
					],
				});
				if (this.state.ready_to_sale_packet) {
					setTimeout(() => {
						this.setState({
							__package: this.state.packages.filter(
								(item) => item.id === this.state.ready_to_sale_packet
							)[0],
						});
					}, 250);
					setTimeout(() => {
						this.addPreparedPackageInSale();
					}, 500);
					setTimeout(() => {
						this.setState({
							total: this._total().amount,
						});
					}, 750);
				}
			})
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.warning(e.response.data.message);
				}
			});
	};

	checkTutorial = async () => {
		Axios.get(`${this.context.api_endpoint}/company/gift/wheel/`)
			.then((data) => {
				const item = data?.data?.data;
				if (item.is_done === false) {
					Axios.get(
						`${this.context.api_endpoint}/company/gift/wheel/user/missions`
					)
						.then((data) => {
							const itemTutorial = data?.data?.data;
							if (itemTutorial.length > 4) {
								if (localStorage.getItem("is_tutorial")) {
									this.setState({
										tutorial: true,
									});
									localStorage.removeItem("is_tutorial");
								}
							} else {
								this.setState({
									tutorial: false,
								});
							}
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					this.setState({
						tutorial: false,
					});
				}
				console.log(item);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handlerServiceSelect = (item, service) => {
		this.fieldChanger("services", item, {
			service_id: parseInt(service.id),
			amount: service.amount,
			name: service.name,
		});
	};

	handlerPacckageServie = (__package) => {
		this.setState({ __package });
		setTimeout(() => {
			this.addPreparedPackageInSale();
			this.setState({
				total: this._total().amount,
			});
		}, 250);
	};

	componentDidMount() {
		this.getReqArrays();
		this.checkTutorial();
	}

	createPackageSale = () => {
		const { new_package, selected_customer } = this.state;
		const newPacket = [];
		const CleanArray = Object.keys(new_package.services).map(
			(key) => new_package.services[key]
		);
		const staffs = [];
		CleanArray.map((item) => {
			if (staffs.includes(`${item?.staff_id}${item?.packet_id}`) === false) {
				staffs.push(`${item?.staff_id}${item?.packet_id}`);
				newPacket.push(item);
			}
		});
		this.setState({
			create_button_disabled: true,
		});

		const fPacket = newPacket.map((item) => ({
			...item,
			amount: `${CleanArray.filter(
				(el) => el.staff_id === item.staff_id && el.packet_id === item.packet_id
			)
				.map((el) => el.amount * el.period)
				.reduce((a, b) => a + b, 0)}`,
		}));

		this.validatePackage() &&
			Axios.post(
				`${this.context.api_endpoint}/company/packet/${this.props.inReceipt ? "to" : "open"
				}/receipt`,
				{
					receipt_id: this.props.receipt_id,
					services: [],
					process_date: fPacket.filter((item) => item !== undefined)[0]
						.period_start,
					customer_id: selected_customer?.id,
					packets: fPacket
						.filter((item) => item !== undefined)
						.map((data_packet, index) => {
							return {
								process_date: data_packet?.period_start,
								staff_id: data_packet?.staff_id,
								amount: parseInt(data_packet?.amount),
								packet_id: data_packet?.packet_id,
								note: data_packet?.note ?? "",
								name: data_packet?.name,
								services: [
									...Object.keys(new_package.services)
										.filter(
											(key) =>
												new_package.services[key] !== undefined &&
												new_package.services[key].staff_id ===
												data_packet?.staff_id &&
												new_package.services[key].packet_id ===
												data_packet?.packet_id
										)
										.map((item) =>
											Object.assign(
												{
													service_id: new_package?.services[item]?.service_id,
													amount: new_package?.services[item]?.amount,
													period: new_package?.services[item]?.period,
													period_between:
														new_package?.services[item]?.period_between,
													period_start:
														new_package?.services[item]?.period_start,
													process_type: 1,
												},
												{}
											)
										),
								],
							};
						}),
				}
			)
				.then((response) => {
					const {
						data: { data },
					} = response;

					if (response.status === 201) {
						this.setState({
							create_button_disabled: false,
						});
						if (this.props.history) {
							this.props.history.push(`/receipts/detail/${data.id}`);
						} else {
							toast.success(response.data.data);
							this.props.getUpdatedReceipt();
							this.props.goBack();
						}
					}
				})
				.catch((e) => {
					if (e.response.status === 401) {
						toast.error(e.response.data.message);
					} else {
						toast.warning(e.response.data.message);
					}
				});
	};

	render() {
		const { new_package, total_loader, create_button_disabled } = this.state;

		return (
			<AuthContainer
				makePlanUpgrade={[2]}
				authorities={[0, 1]}
				limited_permission="sales"
			>
				{!this.props.inReceipt ? (
					<Grid>
						<Column className="xs-12 sm-12 md-12 lg-9 xl-auto">
							<AutocompleteInput
								label={this.context.t(
									`['packages/sales/add'].customerInputLabel`
								)}
								placeholder={this.context.t(
									`['packages/sales/add'].customerInputPlaceholder`
								)}
								labelKey="full_name"
								valueKey="id"
								selected={
									this.state.selected_customer !== null
										? this.state.selected_customer
										: ""
								}
								returnFullObject
								selectedHandler={(selected_customer) => {
									this.setState({ selected_customer });
								}}
								asyncDataService={async (keyword) =>
									this.searchCustomerByKey(keyword)
								}
							/>
						</Column>
						<Column className="xs-12 sm-12 md-12 lg-3 xl-3">
							<Button
								icon={<Group />}
								title={this.context.t(
									`['appointments/add'].createNewCustomerButtonTitle`
								)}
								backgroundColor="primary-opacity"
								textColor="primary"
								fullWidth={true}
								size="lg"
								onClick={() => {
									this.setState({
										customer_add_modal: true,
									});
									console.log(this.state.customer_add_modal);
									// this.props.history.push("/customers/add");
									// console.log(this.props.history);
								}}
							/>
						</Column>
					</Grid>
				) : (
					<></>
				)}
				<Grid>
					<Column className="xs-12 sm-12 md-12 lg-9">
						<AutoCompleteInputWithoutEndPoint
							value={this.state.__package ? this.state.__package.id : null}
							component={"PackageAdd"}
							label={this.context.t(
								`['packages/sales/add'].modal.packageInputLabel`
							)}
							items={[...this.state.packages]}
							handlerPackageSelect={(__package) =>
								this.handlerPacckageServie(__package)
							}
						/>
					</Column>
					<Column className="xs-12 sm-12 md-12 lg-3 xl-3">
						<Button
							// startIcon={<GroupAdd />}
							icon={<LocalMall />}
							title={this.context.t(
								`['packages/sales/add'].addServiceButtonTitle`
							)}
							backgroundColor="secondary-opacity"
							textColor="secondary"
							size="lg"
							fullWidth
							onClick={this.addServiceInPackageSale}
						/>
					</Column>
				</Grid>
				<SectionContainer>
					{Object.keys(new_package.services).filter(
						(key) => new_package.services[key] !== undefined
					).length > 0 ? (
						Object.keys(new_package.services)
							.filter((key) => new_package.services[key] !== undefined)
							.map((item, index) => (
								<Grid key={index} className="no-gutters-row">
									<Column className="xs-12 sm-12 md-12 lg-12 xl-3">
										<AutoCompleteInputWithoutEndPoint
											value={new_package.services[item].service_id ?? null}
											component={"PackageAdd"}
											selected={true}
											label={this.context.t(
												`['packages/sales/add'].serviceInputLabel`
											)}
											items={[...this.state.services]}
											handlerPackageSelect={(service) =>
												this.handlerServiceSelect(item, service)
											}
										/>
									</Column>
									<Column className="xs-12 sm-12 md-12 lg-12 xl-2">
										<Select
											label={this.context.t(
												`['packages/sales/add'].staffInputLabel`
											)}
											items={[...this.state.staffs]}
											labelKey="full_name"
											valueKey="id"
											selected={new_package.services[item].staff_id ?? null}
											handler={(staff_id) =>
												this.fieldChanger("services", item, {
													staff_id: parseInt(staff_id),
												})
											}
										/>
									</Column>
									<Column className="xs-12 sm-12 md-12 lg-12 xl-2">
										<DatePickerInput
											label={this.context.t(
												`['packages/sales/add'].startInputLabel`
											)}
											error={false}
											value={
												new Date(
													moment(new_package.services[item].period_start)
												)
											}
											onChange={(period_start) =>
												this.fieldChanger("services", item, {
													period_start:
														moment(period_start).format("YYYY-MM-DD"),
												})
											}
										/>
									</Column>
									<Column className="xs-12 sm-12 md-12 lg-2 xl-auto">
										<Select
											label={this.context.t(
												`['packages/sales/add'].periodInputLabel`
											)}
											items={[...new Array(50).keys()].map((i) => ({
												value: i + 1,
											}))}
											labelKey="value"
											valueKey="value"
											selected={new_package.services[item].period ?? null}
											handler={(period) =>
												this.fieldChanger("services", item, {
													period: parseInt(period),
												})
											}
										/>
									</Column>
									<Column className="xs-12 sm-12 md-12 lg-2 xl-auto">
										<Select
											label={this.context.t(
												`['packages/sales/add'].periodBetweenInputLabel`
											)}
											items={[...new Array(31).keys()]
												.filter((item) => item !== 0)
												.map((i) => ({
													value: i,
													label: `${i} Gün`,
												}))}
											labelKey="label"
											valueKey="value"
											selected={
												new_package.services[item].period_between ?? null
											}
											handler={(period_between) => {
												period_between === -1 &&
													this.fieldChanger("services", item, {
														period_between: 0,
													});
												period_between !== -1 &&
													this.fieldChanger("services", item, {
														period_between: parseInt(period_between),
													});
											}}
										/>
									</Column>
									<Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
										<Input
											label={
												this.context.t(
													`['packages/sales/add'].periodAmountInputLabel`
												) +
												(this.context.state.currency
													? " (" + this.context.state.currency + ")"
													: "(₺)")
											}
											type="number"
											value={
												new_package.services[item].amount
													? parseFloat(
														new_package.services[item].amount
													).toFixed(2)
													: 0
											}
											onChange={async (e) => {
												await this.fieldChanger("services", item, {
													amount:
														e.target.value && /[0-9,.]+/.test(e.target.value)
															? parseFloat(e.target.value)
															: 0,
												});
											}}
										/>
									</Column>
									<Column>
										<Button
											icon="delete_forever"
											iconSize={20}
											backgroundColor="red-opacity"
											textColor="red"
											size="lg"
											fullWidth
											onClick={() => this.deleteServiceInPackageSale(item)}
										/>
									</Column>
								</Grid>
							))
					) : (
						<NoDataContainer>
							<img
								src={require("../../../../assets/images/manypixels/click_action.svg")}
								alt="Malesef bulunamadı!"
							/>
							<p>{this.context.t(`['packages/sales/add'].noDataText`)}</p>
						</NoDataContainer>
					)}

					{Object.keys(new_package.services).filter(
						(key) => new_package.services[key] !== undefined
					).length > 0 && (
							<>
								<Grid className="no-gutters-row">
									<Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
									<Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
									<Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
									<Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
									<Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
									<Column className="xs-12 sm-12 md-12 lg-12 xl-2">
										<CustomerBoxDiv>
											<img src={RemainingDebtRed} alt="customer_point" />
											<CustomerPointBox>
												<Input
													label={
														this.context.t(
															`['packages/sales/add'].totalInputLabel`
														) +
														(this.context.state.currency
															? " (" + this.context.state.currency + ")"
															: "(₺)")
													}
													value={this.state.total}
													onChange={async (e) => {
														await this.setState({
															total:
																e.target.value && e.target.value !== "0"
																	? parseFloat(e.target.value)
																	: 1,
															total_loader: true,
														});
													}}
													InputProps={{
														style: {
															fontWeight: "bold",
														},
														endAdornment: total_loader && (
															<InputAdornment
																position="end"
																style={{ marginRight: 0 }}
															>
																<img
																	src={Loader}
																	width="40"
																	height="40"
																	alt="loading"
																/>
															</InputAdornment>
														),
														onKeyUp: (e) => {
															const value = e.target.value;
															clearTimeout(typingTimeout);
															typingTimeout =
																value.length > 0 &&
																setTimeout(() => this.editTotal(), 1250);
														},
													}}
												/>
											</CustomerPointBox>
										</CustomerBoxDiv>
									</Column>
								</Grid>
								<Grid>
									<Column className="sx-12 sm-12 md-12 lg-12">
										<Button
											disabled={
												create_button_disabled &&
												Object.keys(new_package.services).filter(
													(key) => new_package.services[key] !== undefined
												).length === 0
											}
											icon="local_mall"
											title={this.context.t(
												`['packages/sales/add'].completeButtonTitle`
											)}
											backgroundColor="teal-opacity"
											textColor="teal"
											size="lg"
											fullWidth
											onClick={this.createPackageSale}
										/>
									</Column>
								</Grid>
							</>
						)}
				</SectionContainer>
				<AlertDialog
					maxWidth="sm"
					fullWidth
					open={this.state.package_modal}
					closeHandler={() => this.setState({ package_modal: false })}
					title={this.context.t(`['packages/sales/add'].modal.title`)}
					buttons={[
						{
							title: this.context.t(
								`['packages/sales/add'].modal.confirmButtonTitle`
							),
							icon: "check",
							backgroundColor: "primary-opacity",
							textColor: "primary",
							onClick: () => {
								this.addPreparedPackageInSale();
								this.setState({
									total: this._total().amount,
								});
							},
						},
						{
							title: this.context.t(
								`['packages/sales/add'].modal.discardButtonTitle`
							),
							icon: "close",
							backgroundColor: "grey-opacity",
							textColor: "grey",
						},
					]}
				>
					<Select
						label={this.context.t(
							`['packages/sales/add'].modal.packageInputLabel`
						)}
						items={[...this.state.packages]}
						selected={this.state.__package ? this.state.__package.id : null}
						labelKey="name"
						valueKey="id"
						returnFullObject
						handler={(__package) => {
							this.setState({ __package });
							this.addPreparedPackageInSale();
							this.setState({
								total: this._total().amount,
							});
						}}
					/>
				</AlertDialog>
				<this.CustomerDialog />
			</AuthContainer>
		);
	}

	addPreparedPackageInSale = () => {
		const { new_package, __package } = this.state;
		__package
			? flushSync(() => {
				this.setState({
					new_package: {
						...new_package,
						services: {
							...new_package.services,
							...Object.assign(
								...(__package.packet_details
									? __package.packet_details.map((item, index) => ({
										[(index + 1) *
											(Object.keys(new_package.services).length + 1)]: {
											service_id: item.service.id,
											staff_id: this.context.state.user.id,
											packet_id: item.packet_id,
											amount: item.amount,
											period: item.period,
											period_between: 7,
											period_start: moment().format("YYYY-MM-DD"),
											name: __package.name,
										},
									}))
									: {}),
								{}
							),
						},
					},
				});
			})
			: toast.warning(
				"İçeri aktarmak istediğiniz paketin seçimini yaptığınızdan emin olun!"
			);
	};

	addServiceInPackageSale = () => {
		const { new_package } = this.state;
		this.setState({
			new_package: {
				...new_package,
				services: {
					...new_package.services,
					[Object.keys(new_package.services).pop() + 1]: {
						service_id: null,
						staff_id: this.context.state.user.id,
						amount: null,
						period: 1,
						period_between: 7,
						period_start: moment().format("YYYY-MM-DD"),
						packet_id: null,
						name: null,
					},
				},
			},
		});
	};

	deleteServiceInPackageSale = (key) => {
		const { new_package } = this.state;
		delete new_package.services[key];
		this.setState({
			new_package: {
				...new_package,
				services: {
					...new_package.services,
				},
			},
		});
	};

	validatePackage = () => {
		const { new_package, selected_customer } = this.state;
		let undefined_service_count = 0;
		Object.keys(new_package.services).filter(
			(key) =>
				new_package.services[key] !== undefined &&
				new_package.services[key].service_id === null &&
				++undefined_service_count
		);

		if (undefined_service_count > 0) {
			toast.warning("Hizmet seçimlerinizi eksiksiz tamamlayın!");
			return false;
		}

		if (!this.props.inReceipt) {
			if (selected_customer === null) {
				toast.warning("Danışan seçimini eksiksiz tamamlayın!");
				return false;
			}
		}

		return true;
	};

	_total = () => {
		const { new_package } = this.state;

		let total_amount = Object.keys(new_package.services)
			.filter((key) => new_package.services[key] !== undefined)
			.map((key) =>
				new_package.services[key].amount !== null
					? new_package.services[key].amount * new_package.services[key].period
					: 0
			)
			.reduce((a, b) => a + b)
			.toFixed(2);
		// console.log("total", total_amount);

		return {
			amount: Math.round(total_amount),
			getRatioList: () => {
				const SingleRatio = {};
				Object.keys(new_package.services)
					.filter((key) => new_package.services[key] !== undefined)
					.map(
						(key) =>
						(SingleRatio[key] =
							new_package.services[key].amount !== null
								? (new_package.services[key].amount *
									new_package.services[key].period) /
								this._total().amount
								: 0)
					);
				return SingleRatio;
			},
		};
	};

	editTotal = () => {
		const { new_package, total } = this.state;

		// console.log("total", this._total().getRatioList());

		if (total !== 0 || total !== null) {
			let services = { ...new_package.services };

			[...Object.keys(this._total().getRatioList())].map(
				(key) =>
				(services = {
					...services,
					[key]: {
						...new_package.services[key],
						// * this._total().getRatioList()[key]
						amount: parseFloat(
							(total * this._total().getRatioList()[key]) /
							new_package.services[key].period
						),
					},
				})
			);

			this.setState({
				total_loader: false,
				new_package: {
					...new_package,
					services,
				},
			});
		}
	};

	fieldChanger = async (section, key, payload) => {
		const { new_package } = this.state;
		// console.log("fieldChanger", payload);
		await this.setState(
			{
				new_package: {
					...new_package,
					[section]: {
						...new_package[section],
						[key]: {
							...new_package[section][key],
							...payload,
						},
					},
				},
			},
			() => {
				this.setState({
					total: Math.round(this._total().amount),
				});
			}
		);
	};
}

export default PackageSalesAdd;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.04);
`;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 60px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #aaa;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;