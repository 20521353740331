import React, { useState, useContext, useEffect, useCallback } from "react"; // eslint-disable-next-line no-unused-expressions
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Route, NavLink, withRouter, useHistory } from "react-router-dom";
import AppContext from "../../context/store";
import { WhatsApp } from "@material-ui/icons";
import PanelRoutes from "./PanelRoutes";
import NavigationItem from "../Navigation/NavigationItem";
import "react-toastify/dist/ReactToastify.css";
import { ButtonBase, Grid, IconButton, Tooltip } from "@material-ui/core";
import MenuIconButton from "./MenuIconButton";
import QuickActionView from "./QuickActionView";
import NotificationsView from "./NotificationsView";
import SystemLogsView from "./SystemLogsView";
import {
  Add,
  Notifications,
  InsertInvitation,
  CalendarToday,
  MailOutline,
  Store,
  Event,
  StarOutlined,
  Search,
  Error,
  Warning
} from "@material-ui/icons";
import AppointmentNotificationView from "./AppointmentNotificationView";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import Axios from "axios";
import { toast } from "react-toastify";
import Avatar from "@material-ui/core/Avatar";
import cLicense from "../../functions/CalculateLicense";
import ChatBox from "../ChatBox";
import AutocompleteInput from "../../theme/CustomMUI/AutoComplete";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import TimeLeft from "../../views/Unauthenticated/Campaigns/components/TimeLeft";
import TimeLeftRenewal from "../../views/Unauthenticated/Renewal/component/TimeLeftRenewal";


const PanelScaffold = (props) => {
  const context = useContext(AppContext);
  const [packageNo, setPackageNo] = useState("");
  const [menuStatus, setMenuStatus] = useState(false);
  const [themeTypes, setThemeTypes] = useState("dark");
  const [LogStatus, setLogStatus] = useState(false);
  const [QAStatus, setQAStatus] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [wpBalance, setWpBalance] = useState(0);


  const [NotificationStatus, setNotificationStatus] = useState(false);
  const [UnreadNotificiationCount, setUnreadNotificationCount] = useState(0);

  // device key
  const [deviceKey, setDeviceKey] = useState("");

  const [AppointmentStatus, setAppointmentStatus] = useState(false);
  const [UnreadAppointmentCount, setUnreadAppointmentCount] = useState(0);

  const [favoritePages, setFavoritePages] = useState([]);

  const [notificationId, setNotificationId] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [url, setUrl] = useState(null);
  const [renewalUrl, setRenewalUrl] = useState(null);
  const [renewalTimeLeft, setRenewalTimeLeft] = useState(null);



  const history = useHistory();

  const themeColor = {
    dark: {
      background: "#ffa000",
      color: "#fff",
      boxShadow: "5px 0px 10px -5px #122639",
    },
    light: {
      background: "#FEFEFE",
      color: "#A07BE5",
      boxShadow: "5px 0px 10px -5px #dddddd",
    },
    purple: {
      background: "#122639",
      color: "#fff",
      boxShadow: "5px 0px 10px -5px #A07BE5",
    },
  };

  const leftNavigationThemeColor = {
    background: `
      ${themeTypes === "dark"
        ? themeColor.dark.background
        : themeTypes === "light"
          ? themeColor.light.background
          : themeTypes === "purple"
            ? themeColor.purple.background
            : ""
      }`,
    boxShadow: `
    ${themeTypes === "dark"
        ? themeColor.dark.boxShadow
        : themeTypes === "light"
          ? themeColor.light.boxShadow
          : themeTypes === "purple"
            ? themeColor.purple.boxShadow
            : ""
      }`,
  };

  const searchCustomerByKey = async (key) => {
    let arr = [];
    if (key.length < 3) {
      return [];
    } else {
      await Axios.get(`${context.api_endpoint}/company/customers`, {
        params: { key },
      })
        .then(({ data }) => (arr = [...data.data.records]))
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
      return arr.map((item) => {
        return {
          id: item.id,
          full_name: item.full_name,
        };
      });
    }
  };

  const setTheme = (themeType) => {
    Axios.put(`${context.api_endpoint}/staff/theme`, {
      theme: themeType,
    });
  };

  const getFavoritePages = useCallback(() => {
    Axios.get(`${context.api_endpoint}/staff/favorites`)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setFavoritePages(
            response.data.data.data.split(
              ",",
              response.data.data.data.split(",").length - 1
            )
          );
          if (response.data.data.theme === 0) {
            setThemeTypes("dark");
          } else if (response.data.data.theme === 1) {
            setThemeTypes("light");
          } else {
            setThemeTypes("purple");
          }
        } else {
          setFavoritePages([]);
        }
      })
      .catch((error) => {
        setFavoritePages([]);
      });
  }, []);

  const updateFavoritePages = (favoritePageIndex) => {
    const isIndexExists = favoritePages.includes(favoritePageIndex);

    if (isIndexExists) {
      const pageIndex = favoritePages.indexOf(favoritePageIndex);
      favoritePages.splice(pageIndex, 1);
    } else {
      if (favoritePages.length === 7) {
        return toast.error(context.t(`panelTabs.moreFavoriteErrorToast`));
      }
      favoritePages.push(favoritePageIndex);
    }

    Axios.put(`${context.api_endpoint}/staff/favorite/save`, {
      data: favoritePages.length > 0 ? `${favoritePages.toString()},` : null,
    }).then((response) => {
      getFavoritePages();
    });
  };

  const getRemainigAppoinment = () => {
    Axios.get(`${context.api_endpoint}/company/remaining/appointment`).then(
      ({ data }) => {
        context.dispatch({
          type: "SET_REMAINING_APPOINTMENT",
          payload: { remaining_appointment: data.data.remaining_appointment },
        });
      }
    );
  };

  const getPackageNo = async () => {
    await Axios.get(`${context.api_endpoint}/company/license/packetno`)
      .then((data) => setPackageNo(data.data.data))
      .catch((err) => console.log(err));
  };

  const getWpDetails = async () => {
    await Axios.get(`${context.api_endpoint}/company/get/wp/message/settings`)
      .then((response) => setDeviceKey(response.data.data.device_key))
      .catch((error) => console.log(error));
  };

  const checkTest = async () => {
    try {
      const response = await Axios.post(`${context.api_endpoint}/first`, {
        id: context.state.company_id,
      });

      const data = response.data;
      setTimeLeft(Number(data.data.ttl));
      setUrl(data.data.url);
    } catch (err) {
      console.error("An error occurred while fetching the data:", err);
      if (err.response) {
        console.error("Server responded with a status:", err.response.status);
        console.error("Response data:", err.response.data);

        if (err.response.status === 404) {
          console.log(
            "Campaign has expired. Please contact your representative for other campaigns."
          );
        }
      } else if (err.request) {
        console.error("No response received. Request details:", err.request);
      } else {
        console.error("Error setting up the request:", err.message);
      }
    }
  };

  const getDisCountedPackages = async () => {
    try {
      const response = await Axios.post(`${context.api_endpoint}/renewal`, {
        id: context.state.company_id,
      });
      setRenewalUrl(response.data.data.url);
      setRenewalTimeLeft(response.data.data.ttl);
    } catch (err) {
      console.error("An error occurred while fetching the data:", err);
      if (err.response) {
        console.error("Server responded with a status:", err.response.status);
        console.error("Response data:", err.response.data);

        if (err.response.status === 404) {
          console.log(
            "Campaign has expired. Please contact your representative for other campaigns."
          );
        }
      } else if (err.request) {
        console.error("No response received. Request details:", err.request);
      } else {
        console.error("Error setting up the request:", err.message);
      }
    }
  };

  // useEffect called when component is loaded once
  useEffect(() => {
    window.addEventListener("click", () => {
      setQAStatus(false);
      setLogStatus(false);
      setNotificationStatus(false);
      setAppointmentStatus(false);
    });
    getFavoritePages();

    if (context.state.company_license.appointment_limitation > 0) {
      getRemainigAppoinment(); // Get only when lowest package TODO
    }

    getPackageNo();
    getBalance();
    getWpDetails();
    checkTest();
    getDisCountedPackages()
  }, []);

  const getBalance = async () => {
    Axios.get(`${context.api_endpoint}/company/balance`)
      .then((data) => {
        setWpBalance(data.data.data.wp_credit);
        context.dispatch({
          type: "SET_BALANCE",
          payload: { balance: data.data },
        });
      })
      .catch((err) => console.log(err));
  };








  return (
    <>
      {/* Next to root this is the base div panel-scaffold */}
      <div className="panel-scaffold">
        <ChatBox />
        {/* Nav / Top Bar shown in each page */}
        {url && timeLeft && (
          <TimeLeft timeLeft={timeLeft} url={url} setTimeLeft={setTimeLeft} />
        )}
        {renewalUrl && renewalTimeLeft && (
          <Grid item xs={2} sm>
            <TimeLeftRenewal
              url={renewalUrl}
              timeLeft={renewalTimeLeft}
              setTimeLeft={setRenewalTimeLeft}
              renewal={true}
            />
          </Grid>
        )}
        <div className="top-bar">
          <Grid container>
            <Grid item xs={6} sm style={{ position: "relative" }}>
              <TopMenuLeft menuActive={menuStatus}>

                <TopAppLogoContainer
                  menuStatus={menuStatus}
                  onClick={() => history.push("/dashboard")}
                  onMouseEnter={() => {
                    setMenuStatus(true);
                  }}
                  onMouseLeave={() => {
                    setMenuStatus(false);
                  }}
                  style={{
                    backgroundColor: `${themeTypes === "dark"
                      ? themeColor.dark.background
                      : themeTypes === "light"
                        ? themeColor.light.background
                        : themeTypes === "purple"
                          ? themeColor.purple.background
                          : ""
                      }`,
                    boxShadow: ` ${themeTypes === "dark"
                      ? themeColor.dark.boxShadow
                      : themeTypes === "light"
                        ? themeColor.light.boxShadow
                        : themeTypes === "purple"
                          ? themeColor.purple.boxShadow
                          : ""
                      }`,
                    width: `${menuStatus ? "220px" : "53px"}`,
                  }}
                >
                  {!menuStatus ? (
                    <div className="app-logo">

                      {themeTypes === "dark" && (
                        <img
                          className="second-logo"
                          style={{ height: 40 }}
                          src={require(`../../assets/images/en-icon(beyaz).png`)}
                          alt="En Randevu"
                        />
                      )}
                      {themeTypes === "light" && (
                        <img
                          className="second-logo"
                          style={{ height: 40 }}
                          src={require(`../../assets/images/en-icon(turuncu).png`)}
                          alt="En Randevu"
                        />
                      )}
                      {themeTypes === "purple" && (
                        <img
                          className="second-logo"
                          style={{ height: 40 }}
                          src={require(`../../assets/images/en-icon(turuncu).png`)}
                          alt="En Randevu"
                        />
                      )}
                    </div>
                  ) : (
                    <div className="app-logo">
                      {themeTypes === "dark" && (
                        <img
                          className="second-logo"
                          style={{ height: 40 }}
                          src={require(`../../assets/images/enrandevu-beyaz.png`)}
                          alt="En Randevu"
                        />
                      )}
                      {themeTypes === "light" && (
                        <img
                          className="second-logo"
                          style={{ height: 40 }}
                          src={require(`../../assets/images/logo_en.png`)}
                          alt="En Randevu"
                        />
                      )}
                      {themeTypes === "purple" && (
                        <img
                          className="second-logo"
                          style={{ height: 40 }}
                          src={require(`../../assets/images/logo_en_white.png`)}
                          alt="En Randevu"
                        />
                      )}
                    </div>
                  )}
                </TopAppLogoContainer>



                <AccountContainer menuStatus={menuStatus}>
                  <div className="company-id">
                    <Store
                      style={{
                        fontSize: "30px",
                        marginBottom: "10px",
                        marginRight: "5px",
                      }}
                    />
                    <p>İşletme No: {context.state.company_id}</p>
                  </div>
                  <div className="divider"></div>
                  <div className="info-card">
                    <CalendarToday />
                    {cLicense(
                      context.state.company_license
                    ).getRemainingDaysFromNow()}{" "}
                    {context.t(`panel.licenseRemaining`)}
                  </div>
                  <div className="divider"></div>
                  <div className="info-card">

                    <MailOutline
                      style={{
                        color:
                          context.state.balance?.data?.credit < 50
                            ? context.state.balance?.data?.credit == 0
                              ? "red"
                              : "orange"
                            : "",
                      }}
                    />
                    <Tooltip
                      title={
                        context.state.balance?.data?.credit < 50 ? (
                          context.state.balance?.data?.credit == 0 ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <Error style={{ color: "red" }} />
                              <div style={{ fontSize: "14px" }}>
                                SMS krediniz bitti!
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <Warning style={{ color: "orange" }} />
                              <div style={{ fontSize: "14px" }}>
                                {" "}
                                SMS krediniz tükenmek üzere!
                              </div>
                            </div>
                          )
                        ) : (
                          ""
                        )
                      }
                    >
                      <div
                        onClick={() =>
                          (context.state.balance?.data?.credit < 50 ||
                            context.state.balance?.data?.credit == 0) &&
                          history.push({
                            pathname: "/wallet",
                            data: 2,
                          })
                        }
                        style={{
                          cursor: "pointer",
                          color:
                            context.state.balance?.data?.credit < 50
                              ? context.state.balance?.data?.credit == 0
                                ? "red"
                                : "orange"
                              : "",
                        }}
                      >
                        {context.state.balance?.data?.credit}{" "}
                        {context.t(`panel.sms_credit`)}{" "}
                      </div>
                    </Tooltip>

                  </div>
                  {context.state.company_license.appointment_limitation > 0 && (
                    <>
                      <div className="divider"></div>
                      <div className="info-card">
                        <Event />
                        {context.state.remainingAppointment ?? 0}{" "}
                        {context.t(`panel.remainingAppointment`)}
                      </div>
                    </>
                  )}
                  {deviceKey !== "" ? (
                    <>
                      <div className="divider"></div>
                      <div className="info-card">
                        <WhatsApp
                          style={{
                            color:
                              wpBalance < 50
                                ? wpBalance == 0
                                  ? "red"
                                  : "orange"
                                : "",
                          }}
                        />{" "}
                        <Tooltip
                          title={
                            wpBalance < 50 ? (
                              wpBalance == 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Error style={{ color: "red" }} />
                                  <div style={{ fontSize: "14px" }}>
                                    WhatsApp krediniz bitti!
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Warning style={{ color: "orange" }} />
                                  <div style={{ fontSize: "14px" }}>
                                    {" "}
                                    WhatsApp krediniz tükenmek üzere!
                                  </div>
                                </div>
                              )
                            ) : (
                              ""
                            )
                          }
                        >
                          <div
                            onClick={() =>
                              (wpBalance < 50 || wpBalance == 0) &&
                              history.push({
                                pathname: "/wallet",
                                data: 2,
                              })
                            }
                            style={{
                              cursor: "pointer",
                              color:
                                wpBalance < 50
                                  ? wpBalance == 0
                                    ? "red"
                                    : "orange"
                                  : "",
                            }}
                          >
                            {wpBalance} {context.t(`panel.wp_credit`)}
                          </div>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </AccountContainer>
              </TopMenuLeft>
            </Grid>

            <Grid item xs={2} sm className="menu-right">
              <div className="mt-2">
                <AutocompleteInput
                  placeholder="Danışan ara"
                  style={{
                    width: "300px",
                    boxShadow: "0 3px 4px 0 rgba(0,0,0,0.05)",
                    borderRadius: "25px",
                  }}
                  onRemove={() => { }}
                  labelKey="full_name"
                  valueKey="id"
                  selected={selectedCustomer !== null ? selectedCustomer : ""}
                  returnFullObject
                  selectedHandler={(e) => {
                    setSelectedCustomer(e);
                    setTimeout(() => {
                      if (e.id) {
                        history.push(`/customers/detail/${e.id}`);
                        window.location.reload();
                      }
                    }, 250);
                  }}
                  asyncDataService={async (keyword) =>
                    searchCustomerByKey(keyword)
                  }
                  startAdornment={
                    <div style={{ marginTop: "15px" }}>
                      <Search />
                    </div>
                  }
                />
              </div>
              <MenuIconButton
                icon={StarOutlined}
                label={context.t(`menuItems.settings.children.giveAReferans`)}
                className={"fc-blue mr-1"}
                radius={100}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/settings/give-referance");
                }}
              />

              <MenuIconButton
                icon={InsertInvitation}
                className={`${AppointmentStatus ? "bg-orange-opacity" : ""
                  } fc-orange mr-1`}
                badgecontent={UnreadAppointmentCount}
                radius={100}
                onClick={(e) => {
                  e.stopPropagation();
                  // setTranslateStatus(false);
                  setQAStatus(false);
                  setLogStatus(false);
                  setNotificationStatus(false);
                  setAppointmentStatus(!AppointmentStatus);
                }}
              />
              <MenuIconButton
                icon={Notifications}
                className={`${NotificationStatus ? "bg-orange-opacity" : ""
                  } fc-orange mr-1`}
                badgecontent={UnreadNotificiationCount}
                radius={100}
                onClick={(e) => {
                  e.stopPropagation();
                  // setTranslateStatus(false);
                  setQAStatus(false);
                  setLogStatus(false);
                  setAppointmentStatus(false);
                  setNotificationStatus(!NotificationStatus);
                }}
              />

              <MenuIconButton
                icon={Add}
                className={`${QAStatus ? "bg-green-opacity" : ""
                  } fc-green mr-1`}
                radius={100}
                onClick={(e) => {
                  e.stopPropagation();
                  // setTranslateStatus(false);
                  setNotificationStatus(false);
                  setLogStatus(false);
                  setAppointmentStatus(false);
                  setQAStatus(!QAStatus);
                }}
              />
              <IconButton
                onClick={() => {
                  history.push("/profile");
                }}
              >
                <Avatar
                  className="mr-1"
                  alt={context.state.user.full_name}
                  src={
                    context.state.user.profile_photo
                      ? `${context.api_endpoint.replace("api/v1", "")}${context.state.company_id
                      }/${context.state.user.profile_photo}`
                      : require("../../assets/images/profile_photo.svg")
                  }
                />
              </IconButton>

            </Grid>
          </Grid>
        </div>


        <SystemLogsView
          state={LogStatus}
          closeHandler={() => setLogStatus(false)}
          stopPropagation={(e) => e.stopPropagation()}
        />
        <NotificationsView
          state={NotificationStatus}
          unreadCountHandler={(count) => setUnreadNotificationCount(count)}
          closeHandler={() => setNotificationStatus(false)}
          stopPropagation={(e) => e.stopPropagation()}
          notificationId={notificationId}
          setNotificationId={(id) => setNotificationId([...id])}
        />
        <AppointmentNotificationView
          state={AppointmentStatus}
          unreadCountHandler={(count) => setUnreadAppointmentCount(count)}
          closeHandler={() => setAppointmentStatus(false)}
          stopPropagation={(e) => e.stopPropagation()}
        />
        <QuickActionView
          state={QAStatus}
          closeHandler={() => setQAStatus(false)}
          stopPropagation={(e) => e.stopPropagation()}
          packageNo={packageNo}
        />

        <div className={`app-wrapper hide`}>
          <nav
            className={`left-navigation ${menuStatus ? "active" : "hide"}`}
            onMouseEnter={() => setMenuStatus(true)}
            onMouseLeave={() => setMenuStatus(false)}
            style={leftNavigationThemeColor}
          >
            {(context.current_flag & context.state.FLAGS.EN_RANDEVU &&
              PanelRoutes(context).map(
                (item, index) =>
                  ((item.authorities.includes(context.state.user.permission) &&
                    context.state.user.permission === 2
                    ? context.state.limited_permission[
                    item.limited_permission_path
                    ] > 0
                    : true) ||
                    item.path === "/dashboard") &&
                  item.includepackage.includes(packageNo) && (
                    <NavigationItem
                      menuStatus={menuStatus}
                      disableRipple={true}
                      themeType={themeTypes}
                      disabled={item.disabled}
                      key={index}
                      to={item.path}
                      data-cy={item.title}
                      prefixTitle={"En Randevu"}
                      title={item.title}
                      icon={item.icon}
                      onClick={() => {
                        context.dispatch({
                          type: "SET_MENU_PATH",
                          payload: { menu_path: item.title },
                        });
                      }}
                    />
                  )
              )) ||
              Object.values(
                PanelRoutes(context).reduce(function (ebx, obj) {
                  let isExpected = context.expected_en_randevu.filter(
                    (exp) => obj.path === exp
                  );

                  if (!ebx[isExpected]) {
                    ebx[isExpected] = [];
                  }
                  ebx[isExpected].push(obj);
                  return ebx;
                }, {})
              )[0].map(
                (item, index) =>
                  item.authorities.includes(context.state.user.permission) && (
                    <NavigationItem
                      themeType={themeTypes}
                      disabled={item.disabled}
                      key={index}
                      to={item.path}
                      data-testid={item.title}
                      prefixTitle={context.app.NAME}
                      title={item.title}
                      icon={item.icon}
                      onClick={() => { }}
                    />
                  )
              )}
            <div className="switchBox">
              <NavBottomThemeSwitchBox
                themeColor={themeColor}
                themeType={themeTypes}
                menuStatus={menuStatus}
              >
                <div
                  className="switcher-box"
                  onMouseEnter={() => {
                    setMenuStatus(true);
                  }}
                >
                  <div
                    className={`theme-type dark ${themeTypes === "dark" ? "active" : ""
                      }`}
                    onClick={() => {
                      setThemeTypes("dark");
                      setTheme(0);
                    }}
                  ></div>
                  <div
                    className={`theme-type light ${themeTypes === "light" ? "active" : ""
                      }`}
                    onClick={() => {
                      setThemeTypes("light");
                      setTheme(1);
                    }}
                  ></div>
                  <div
                    className={`theme-type purple ${themeTypes === "purple" ? "active" : ""
                      }`}
                    onClick={() => {
                      setThemeTypes("purple");
                      setTheme(2);
                    }}
                  ></div>
                </div>
              </NavBottomThemeSwitchBox>
            </div>
          </nav>

          {window.screen.width <= 576 && (
            <div
              className={`overlay ${menuStatus ? "visible" : "hidden"}`}
            // onClick={() => setMenuStatus(false)}
            />
          )}

          <div className="panel-container">

            <Grid container>
              {(context.current_flag & context.state.FLAGS.SALON_RANDEVU &&
                PanelRoutes(context).map((item, index) => (
                  <Route key={index} path={item.path}>
                    {item.children && item.children.length > 0 && (
                      <Grid item xs={12} sm={12}>
                        <ul className="sub-navigation elevation-1 mb-2">
                          {item.children
                            .filter((item) => item !== undefined)
                            .filter(
                              (item) =>
                                (context.current_flag &
                                  context.state.FLAGS.SALON_RANDEVU &&
                                  item) ||
                                !item.to.includes("risky")
                            )
                            .map(
                              (child, index) =>
                                child.includepackage.includes(packageNo) && (
                                  <li key={index}>
                                    {window.location.pathname.includes(
                                      `${item.path}${child.to}`
                                    ) && (
                                        <Helmet>
                                          <title>
                                            {context.app.NAME} -{" "}
                                            {child.label ?? ""}
                                          </title>
                                        </Helmet>
                                      )}
                                    <ButtonBase
                                      style={{
                                        fontFamily:
                                          '"Inter", sans-serif !important',
                                      }}
                                      onClick={() => {
                                        if (child.to === "/logs") {
                                          context.dispatch({
                                            type: "LOG_TYPE",
                                            payload: { log_type: null },
                                          });
                                          context.dispatch({
                                            type: "LOG_ID",
                                            payload: {
                                              log_id: null,
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      <NavLink
                                        title={child.index}
                                        to={`${item.path}${child.to}`}
                                        activeClassName="active"
                                        exact={true}
                                        children={
                                          <div>
                                            {child.label}
                                            {child.index !== undefined && (
                                              <IconButton
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  updateFavoritePages(
                                                    `${child.index}`
                                                  );
                                                }}
                                              >
                                                {favoritePages.includes(
                                                  child.index.toString()
                                                ) ? (
                                                  <StarIcon
                                                    style={{ color: "green" }}
                                                  />
                                                ) : (
                                                  <StarBorderIcon />
                                                )}
                                              </IconButton>
                                            )}
                                          </div>
                                        }
                                      />
                                    </ButtonBase>
                                  </li>
                                )
                            )}
                        </ul>
                      </Grid>
                    )}
                  </Route>
                ))) ||
                Object.values(
                  PanelRoutes(context).reduce(function (ebx, obj) {
                    let isExpected = context.expected_en_randevu.filter(
                      (exp) => obj.path === exp
                    );

                    if (!ebx[isExpected]) {
                      ebx[isExpected] = [];
                    }
                    ebx[isExpected].push(obj);
                    return ebx;
                  }, {})
                )[0].map(
                  (item, index) =>
                    item.authorities.includes(
                      context.state.user.permission
                    ) && (
                      <Route key={index} path={item.path}>
                        {item.children && item.children.length > 0 && (
                          <Grid item xs={12} sm={12}>
                            <ul className="sub-navigation elevation-1 mb-2">
                              {item.children
                                .filter((item) => item !== undefined)
                                .filter(
                                  (item) =>
                                    (context.current_flag &
                                      context.state.FLAGS.SALON_RANDEVU &&
                                      item) ||
                                    !item.to.includes("risky")
                                )
                                .map((child, index) => (
                                  <li key={index}>
                                    {window.location.pathname.includes(
                                      `${item.path}${child.to}`
                                    ) && (
                                        <Helmet>
                                          <title>
                                            {context.app.NAME} -{" "}
                                            {child.label ?? ""}
                                          </title>
                                        </Helmet>
                                      )}
                                    <ButtonBase
                                      style={{
                                        fontFamily:
                                          '"Inter", sans-serif !important',
                                      }}
                                    >
                                      <NavLink
                                        to={`${item.path}${child.to}`}
                                        activeClassName="active"
                                        exact={true}
                                        children={child.label}
                                      />
                                    </ButtonBase>
                                  </li>
                                ))}
                            </ul>
                          </Grid>
                        )}
                      </Route>
                    )
                )}

              <Grid item xs={12} sm={12}>
                <div className="panel-card">{props.children}</div>
              </Grid>
            </Grid>
            <AlertDialog
              title={context.t(`definePopup.title`)}
              open={!context.state.license_choosen}
              disableEscapeKeyDown={true}
              maxWidth="sm"
              disableBackdropClick={true}
              closeHandler={async () => {
                await context.dispatch({
                  type: "SET_LICENSE_CHOOSEN_TRUE",
                });
              }}
              buttons={[
                {
                  title: `${context.t(`definePopup.buttons.approve`)}`,
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: async () => {
                    Axios.post(
                      `${context.api_endpoint}/company/license/start`,
                      {
                        type: 2,
                      }
                    )
                      .then((data) => {
                        if (data.data.status === 200) {
                          toast.success(context.t(`definePopup.succesMessage`));
                          setTimeout(async () => {
                            await context.dispatch({
                              type: "LOGOUT_USER",
                            });
                            await props.history.push("/");
                            localStorage.removeItem("state2");
                          }, 500);
                        }
                      })
                      .catch((err) => console.log(err));
                  },
                },
                {
                  title: `${context.t(`definePopup.buttons.cancel`)}`,
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: async () => {
                    Axios.post(
                      `${context.api_endpoint}/company/license/start`,
                      {
                        type: 1,
                      }
                    )
                      .then((data) => {
                        if (data.data.status === 200) {
                          toast.success(context.t(`definePopup.succesMessage`));
                          setTimeout(async () => {
                            await context.dispatch({
                              type: "LOGOUT_USER",
                            });
                            await props.history.push("/");
                            localStorage.removeItem("state2");
                          }, 500);
                        }
                      })
                      .catch((err) => console.log(err));
                  },
                },
              ]}
            >
              {context.t(`definePopup.description`)}
            </AlertDialog>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(PanelScaffold);

const SiteHeadingContainer = styled.div`
  display: flex;
  width: ${(props) => (props.menuActive ? "220px" : "43px")};
  @media only screen and (max-width: 620px) {
    display: none;
  }
`;

const TopMenuLeft = styled.div`
  flex: 0;
  width: "53px";
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    background: #fff;
  }
`;

const TopAppLogoContainer = styled.div`
  height: 60px;
  width: ${(props) => (props.menuStatus ? "220px" : "53px")};
  transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s;
  cursor: pointer;
`;

const AccountContainer = styled.div`
  position: absolute;
  margin-left: ${(props) => (props.menuStatus ? "227px" : "70px")};
  display: flex;
  align-items: center;
  transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s;

  .company-id {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 10px;
    font-weight: bold;
  }

  .info-card {
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    gap: 5px;
  }

  .divider {
    width: 1px;
    height: 30px;
    margin: 5px;
    background: grey;
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const NavBottomThemeSwitchBox = styled.div`
  opacity: ${(props) => (props.menuStatus ? "1" : "0")};
  transition: all ease 0.25s;

  .switcher-box {
    background-color: #eee;
    border: 1px solid #ddd;
    width: 56px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 0px 0px 1px rgba(238, 238, 238, 1);

    .theme-type {
      margin: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transition: all ease 0.1s;
    }

    .dark {
      background: radial-gradient(
        circle,
        #ffa000 50%,
        rgba(68, 68, 68, 1) 100%
      );
    }
    .light {
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 50%,
        rgba(68, 68, 68, 1) 100%
      );
    }
    .purple {
      background: radial-gradient(
        circle,
        rgba(26, 38, 58, 1) 50%,
        rgba(68, 68, 68, 1) 100%
      );
    }

    .active {
      transform: scale(0.9);
    }
  }
`;
