import Axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import AuthContainer from "../../../../components/AuthContainer";
import AppContext from "../../../../context/store";
import Button from "../../../../theme/Button";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import Input from "../../../../theme/CustomMUI/Input";
import { Column, Grid } from "../../../../theme/Grid";
import Table from "../../../../theme/Table";
import Alert from "@material-ui/lab/Alert";
import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";

class Template extends React.Component {
  static contextType = AppContext;

  state = {
    loaded: false,
    pagination: {
      total_page: null,
      page: null,
      onChange: (page) => this.getTemplates(page),
    },
    templates: [],

    newTemplate: {
      id: null,
      title: null,
      content: "",
    },

    delete_id: null,

    open_template_dialog: false,
    open_delete_template_dialog: false,
  };

  getTemplates = (page = 1) => {
    Axios.get(
      `${this.context.api_endpoint}/company/sms/templates?page${page}`
    ).then(({ data }) => {
      this.setState({
        loaded: true,
        pagination: {
          ...this.state.pagination,
          total_page: data.data.records.length,
          page: data.page,
        },
        templates: [...data.data.records],
      });
    });
  };

  addTemplate = () => {
    Axios.post(`${this.context.api_endpoint}/company/sms/template/insert`, {
      name: this.state.newTemplate.title,
      content: this.state.newTemplate.content,
    })
      .then(async ({ data }) => {
        await this.setState({
          newTemplate: {
            ...this.state.newTemplate,
            id: null,
            title: null,
            content: "",
          },
          open_template_dialog: false,
        });
        toast.success(this.context.t(`['sms/template'].addSuccessToast`));
        this.getTemplates();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  updateTemplate = () => {
    Axios.put(`${this.context.api_endpoint}/company/sms/template/update`, {
      id: this.state.newTemplate.id,
      name: this.state.newTemplate.title,
      content: this.state.newTemplate.content,
    })
      .then(async ({ data }) => {
        await this.setState({
          newTemplate: {
            ...this.state.newTemplate,
            id: null,
            title: null,
            content: "",
          },
          open_template_dialog: false,
        });
        toast.success(this.context.t(`['sms/template'].updateSuccessToast`));
        this.getTemplates();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  deleteTemplate = (id) => {
    Axios.post(`${this.context.api_endpoint}/company/sms/template/delete`, {
      id: id,
    })
      .then(async ({ data }) => {
        await this.setState({
          delete_id: null,
          open_delete_template_dialog: false,
        });
        toast.success(this.context.t(`['sms/template'].deleteSuccessToast`));
        this.getTemplates();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  componentDidMount() {
    this.getTemplates();
  }

  render() {
    const { templates, newTemplate } = this.state;
    return (
      <AuthContainer authorities={[1]} limited_permission="sms">
        <Grid>
          <Grid className="mb-2">
            <Button
              title={this.context.t(
                `['sms/template'].createTemplateButtonTitle`
              )}
              icon="add"
              backgroundColor="primary"
              textColor="white"
              onClick={() => {
                this.setState({ open_template_dialog: true });
              }}
            />
          </Grid>
          <Column className="xs-12 sm-12 md-12">
            <Table
              refreshAction={() => this.getTemplates()}
              loaded={this.state.loaded}
              headings={{
                name: {
                  label: this.context.t(
                    `['sms/template'].templatesHeadings.name`
                  ),
                },
                content: {
                  label: this.context.t(
                    `['sms/template'].templatesHeadings.content`
                  ),
                  limited_line: 1,
                  style: {
                    maxWidth: "350px",
                  },
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              rows={templates}
              pagination={this.state.pagination}
              showAlwaysPagination
              buttons={[
                {
                  title: this.context.t(`component.detailEditButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  pushEffect: true,
                  onClick: (row) => {
                    this.setState({
                      open_template_dialog: true,
                      newTemplate: {
                        ...newTemplate,
                        id: row.id,
                        title: row.name,
                        content: row.content,
                      },
                    });
                  },
                },
                {
                  title: this.context.t(`['sms/template'].deleteButtonTitle`),
                  icon: "delete_forever",
                  transitionEffect: true,
                  textColor: "red",
                  onClick: (row) => {
                    this.setState({
                      delete_id: row.id,
                      open_delete_template_dialog: true,
                    });
                  },
                },
              ]}
            />
          </Column>

          <AlertDialog
            title={this.context.t(
              `['sms/template'].templateDialog.${
                newTemplate.id ? "updateTitle" : "addTitle"
              }`
            )}
            open={this.state.open_template_dialog}
            fullWidth={true}
            disableBackdropClick={false}
            buttons={[
              {
                title: this.context.t(
                  `['sms/template'].templateDialog.${
                    newTemplate.id ? "updateButtonTitle" : "createButtonTitle"
                  }`
                ),
                textColor: "white",
                backgroundColor: "orange",
                icon: "check",
                onClick: () => {
                  // newTemplate.id
                  //   ? this.updateTemplate()
                  //   : newTemplate.title && newTemplate.content
                  //   ? this.addTemplate()
                  //   : toast.warning("Gerekli alanları doldurunuz.");
                  if (newTemplate.id) {
                    if (newTemplate.title && newTemplate.content) {
                      this.updateTemplate();
                    } else {
                      toast.warning("Gerekli alanları doldurunuz.");
                      this.setState({ open_template_dialog: true });
                    }
                  } else {
                    if (newTemplate.title && newTemplate.content) {
                      this.addTemplate();
                    } else {
                      this.setState({
                        open_template_dialog: true,
                      });
                      toast.warning("Gerekli alanları doldurunuz.");
                    }
                  }
                },
              },
              {
                title: this.context.t(
                  `['sms/template'].templateDialog.discardButtonTitle`
                ),
                icon: "close",
                textColor: "black",
              },
            ]}
            closeHandler={() => {
              this.setState({
                open_template_dialog: false,
              });
            }}
          >
            <Input
              label={this.context.t(
                `['sms/template'].templateDialog.templateTitleInputLabel`
              )}
              placeholder={this.context.t(
                `['sms/template'].templateDialog.templateTitleInputPlaceholder`
              )}
              value={newTemplate.title ?? ""}
              onChange={(e) => {
                this.setState({
                  newTemplate: {
                    ...newTemplate,
                    title: e.target.value,
                  },
                });
              }}
            />
            <Input
              label={this.context.t(
                `['sms/template'].templateDialog.templateContentInputLabel`
              )}
              multiline={true}
              rows={10}
              value={newTemplate.content ?? ""}
              helperText={CalculateMessageCharCount(newTemplate.content, 1)}
              onChange={(e) => {
                this.setState({
                  newTemplate: {
                    ...newTemplate,
                    content: e.target.value,
                  },
                });
              }}
            />
          </AlertDialog>
          <AlertDialog
            title={this.context.t(`['sms/template'].deleteConfirm.title`)}
            open={this.state.open_delete_template_dialog}
            disableBackdropClick={false}
            buttons={[
              {
                title: this.context.t(
                  `['sms/template'].deleteConfirm.confirmButtonTitle`
                ),
                disabledHandleClose: true,
                textColor: "white",
                backgroundColor: "orange",
                icon: "check",
                onClick: () => this.deleteTemplate(this.state.delete_id),
              },
              {
                title: this.context.t(
                  `['sms/template'].deleteConfirm.discardButtonTitle`
                ),
                textColor: "black",
                icon: "close",
              },
            ]}
            closeHandler={() => {
              this.setState({
                delete_id: null,
                open_delete_template_dialog: false,
              });
            }}
          >
            <Alert severity="warning">
              <b>
                {this.context.t(`['sms/template'].deleteConfirm.alertBoldText`)}
              </b>
              <br />
              {this.context.t(`['sms/template'].deleteConfirm.alertText`)}
            </Alert>
          </AlertDialog>
        </Grid>
      </AuthContainer>
    );
  }
}

export default Template;
