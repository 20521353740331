import React, { Component } from "react";
import AppRouter from "./AppRouter";
import "./assets/style/Main.scss";
import { ToastContainer, Slide } from "react-toastify";
import AppContext from "./context/store";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";

let theme = createTheme({
	typography: {
		fontFamily: '"Inter", sans-serif !important',
	},
	palette: {
		mode: "light",
		primary: {
			main: "#ffa000", //color.primary
		},
	},
});

class App extends Component {
	static contextType = AppContext;

	componentDidMount() {
		window.onblur = () => {
			document.title = "🕐 Zamanını Doğru Yönet! - " + this.context.app.NAME;
		};

		window.onfocus = () => {
			document.title = this.context.app.NAME;
		};
	}

	render() {
		return (
			<>
				<ThemeProvider theme={theme}>
					<AppRouter />

					<ToastContainer
						position="top-center"
						autoClose={3000}
						closeButton={<></>}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick={true}
						pauseOnVisibilityChange={false}
						draggable={false}
						transition={Slide}
						pauseOnHover
					></ToastContainer>
				</ThemeProvider>
			</>
		);
	}
}

export default App;
