import React, { Component } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Dialog, DialogContent } from "@material-ui/core";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import AuthContainer from "../../../components/AuthContainer";
import ConvertTimeToZulu from "../../../functions/ConvertTimeToZulu";
import moment from "moment";
import Select from "../../../theme/CustomMUI/Select";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Loader from "../../../assets/images/circular.gif";

class BountyTransactions extends Component {
  static contextType = AppContext;
  static contextType = AppContext;

  state = {
    loaded: false,
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.getBountyList(page),
    },
    data: [],
    amount: null,
    transaction_date: ConvertTimeToZulu(moment().unix()), //now
    selected_payment: null,
    payment_modal: false,
    selected_employee: null,
    details_pop_up: false,
    details_data: [],
  };

  getBountyList = (page = 1) => {
    this.setState({
      loaded: false,
    });
    Axios.get(
      `${this.context.api_endpoint}/company/accounting/bounty?page=${page}`
    )
      .then(({ data: { data } }) => {
        this.setState({
          loaded: true,
          data: [
            ...data.records.map((item) => ({
              ...item,
              full_name: `${item.name} ${item.surname}`,
            })),
          ],
        });
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          toast.error(e?.response?.data?.message);
        } else {
          toast.warning(e?.response?.data?.message);
        }
      });
  };

  payBounty = () => {
    Axios.put(`${this.context.api_endpoint}/company/staff/bounty/set`, {
      staff_id: this.state.selected_employee.id,
      payment_type: this.state.selected_payment
        ? parseInt(this.state.selected_payment.value)
        : null,
      transaction_date: this.state.transaction_date
        ? this.state.transaction_date
        : null,
      amount: this.state.amount
        ? parseFloat(this.state.amount) >
          parseFloat(this.state.selected_employee?.bounty)
          ? parseFloat(this.state.selected_employee?.bounty)
          : parseFloat(this.state.amount)
        : null,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.data);
          this.getBountyList();
          this.setState({
            selected_employee: null,
          });
          this.props.history.push("/accounting/expense");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  componentDidMount() {
    this.getBountyList();
  }

  render() {
    const { payment_modal, selected_employee } = this.state;
    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[1]}
        limited_permission="income_expense"
      >
        <Grid>
          <Column className="xs-12 sm-12">
            {this.state.loaded ? (
              <Table
                refreshAction={() => this.getBountyList()}
                loaded={this.state.loaded}
                headings={{
                  full_name: {
                    label: this.context.t(
                      `['accounting/bounty'].headings.staffFullName`
                    ),
                  },
                  bounty: {
                    label: this.context.t(
                      `['accounting/bounty'].headings.bounty`
                    ),
                    suffix: this.context.state.currency
                      ? this.context.state.currency
                      : "₺",
                  },
                  _: { label: this.context.t(`component.actionHeadingText`) },
                }}
                rows={this.state.data}
                pagination={this.state.pagination}
                buttons={[
                  {
                    icon: "payment",
                    title: this.context.t(
                      `['accounting/bounty'].payButtonTitle`
                    ),
                    disabled: (row) => (row.bounty ? row.bounty === 0 : true),
                    onClick: (row) =>
                      this.setState({
                        payment_modal: true,
                        selected_employee: row,
                      }),
                  },
                  {
                    title: `${this.context.t(
                      `["accounting/credit"].headings.view`
                    )}`,
                    icon: "launch",
                    textColor: "primary",
                    disabled: (row) => (row.bounty ? row.bounty === 0 : true),
                    onClick: (row) =>
                      this.setState({
                        details_data: this.state.data.filter(
                          (m) => m.id === row.id
                        ),
                        details_pop_up: true,
                      }),
                  },
                ]}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img src={Loader} width="100" height="100" alt="loading" />
              </div>
            )}

            <AlertDialog
              open={payment_modal}
              title={this.context.t(`['accounting/bounty'].check.title`)}
              closeHandler={() => this.setState({ payment_modal: false })}
              textType
              buttons={[
                {
                  icon: "check",
                  title: this.context.t(
                    `['accounting/bounty'].check.confirmButtonTitle`
                  ),
                  onClick: () => {
                    this.state.selected_payment !== null
                      ? this.payBounty()
                      : toast.warning("Lütfen ödeme tipini seçiniz.");
                  },
                },
                {
                  icon: "close",
                  title: this.context.t(
                    `['accounting/bounty'].check.discardButtonTitle`
                  ),
                  textColor: "grey",
                },
              ]}
            >
              <b>
                {this.context.t(`['accounting/bounty'].check.alertBoldText`, {
                  selected_employee: selected_employee?.full_name,
                  bounty: selected_employee?.bounty ?? "0",
                })}
              </b>
              <Grid>
                <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                  <DatePickerInput
                    label={this.context.t(
                      `['accounting/expense'].add.processDate`
                    )}
                    onChange={async (process_date) => {
                      await this.setState({
                        transaction_date: ConvertTimeToZulu(
                          moment(process_date).unix()
                        ),
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
                  <Select
                    label={this.context.t(
                      `['accounting/credit'].payments.typeInputLabel`
                    )}
                    placeholder={this.context.t(
                      `['accounting/credit'].payments.typeInputPlaceholder`
                    )}
                    items={[
                      {
                        label: this.context.t(
                          `['accounting/credit'].payments.typeCASH`
                        ),
                        value: "0",
                      },
                      {
                        label: this.context.t(
                          `['accounting/credit'].payments.typeCARD`
                        ),
                        value: "1",
                      },
                      {
                        label: this.context.t(
                          `['accounting/credit'].payments.typeEFT`
                        ),
                        value: "2",
                      },
                      {
                        label: this.context.t(
                          `['accounting/credit'].payments.typePOINT`
                        ),
                        value: "3",
                      },
                    ]}
                    selected={
                      this.state.selected_payment &&
                      this.state.selected_payment.value
                    }
                    labelKey="label"
                    valueKey="value"
                    handler={async (payment_type) => {
                      await this.setState({
                        selected_payment: {
                          ...this.state.selected_payment,
                          value: payment_type,
                        },
                      });
                    }}
                  />
                </Column>
                <Grid>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-8">
                    <Input
                      label={this.context.t(
                        `['accounting/credit'].payments.amountInputLabel`
                      )}
                      type="number"
                      value={this.state.amount}
                      onChange={(e) =>
                        e.target.value.trim() > -1 &&
                        this.setState({
                          amount:
                            parseFloat(e.target.value) >
                              parseFloat(this.state.selected_employee?.bounty)
                              ? parseFloat(this.state.selected_employee?.bounty)
                              : parseFloat(e.target.value),
                        })
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
                    <Button
                      icon="add"
                      style={{
                        height: "78%",
                      }}
                      title={this.context.t(
                        `['accounting/bounty'].allPayButtonTitle`
                      )}
                      backgroundColor="primary-opacity"
                      textColor="primary"
                      size="sm"
                      fullWidth
                      onClick={() =>
                        this.setState({
                          amount: this.state.selected_employee?.bounty,
                        })
                      }
                    />
                  </Column>
                </Grid>
              </Grid>
            </AlertDialog>
            <AlertDialog
              open={this.state.details_pop_up}
              maxWidth="sm"
              fullWidth={true}
              closeHandler={() => this.setState({ details_pop_up: false })}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  icon="close"
                  iconColor="black"
                  iconSize={25}
                  transitionEffect={true}
                  onClick={() => {
                    this.setState({
                      details_pop_up: false,
                    });
                  }}
                />
              </div>

              <table
                style={{
                  width: "100%",
                }}
              >
                <thead style={{ marginBottom: "15px" }}>
                  <tr>
                    <th style={{ textAlign: "center" }}>Danışan Adı Soyadı</th>
                    <th style={{ textAlign: "center" }}>
                      Ödenen Tutar (
                      {this.context.state.currency
                        ? this.context.state.currency
                        : "₺"}
                      )
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Prim Tutarı (
                      {this.context.state.currency
                        ? this.context.state.currency
                        : "₺"}
                      )
                    </th>
                    <th style={{ textAlign: "center" }}>İşlemler</th>
                  </tr>
                </thead>
                {this.state.details_data.length === 1 &&
                  this.state.details_data[0]?.bounty_details.map((m, index) => (
                    <tbody key={index}>
                      <tr style={{ marginBottom: "10px" }}>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {m?.customer_name === "" ? (
                            <p
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              -
                            </p>
                          ) : (
                            <p
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              {m?.customer_name}
                            </p>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <p>{m?.paid_amount}</p>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <p>{m?.bounty}</p>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            icon="launch"
                            transitionEffect={true}
                            title="Adisyona Git"
                            onClick={() => {
                              if (m.receipt_id !== 0) {
                                this.props.history.push(
                                  `/receipts/detail/${m.receipt_id}`
                                );
                              } else {
                                toast.warn("something went wrong!");
                              }
                            }}
                          ></Button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </AlertDialog>
          </Column>
        </Grid>
      </AuthContainer>
    );
  }

  validateBountyInput = () => {
    return Validate([
      {
        field: "Ödenecek Tutar",
        value: this.state.bountyInput,
        condition: Number,
      },
    ]);
  };
}

export default BountyTransactions;
