import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import Input from "../../../theme/CustomMUI/Input";
import Loader from "../../../assets/images/loader.gif";

const ProductsIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [delete_product_modal, setDeleteProductModal] = useState(false);
  const [_item, setItem] = useState(null);

  const [pagination, setPagination] = useState({
    total_page: null,
    page: null,
    onChange: (page) => getProducts(page),
  });

  const [products, setProducts] = useState([]);

  const [product_name, setProductName] = useState(null);

  const [typingTimeout] = useState(null);

  const getProducts = (page) => {
    setLoaded(false);
    const urlPage = parseInt(
      new URLSearchParams(window.location.search).get("page")
    );
    Axios.get(`${context.api_endpoint}/company/stock/items`, {
      params: {
        page: page ? page : urlPage ? urlPage : 1,
        stockname: product_name ?? "",
      },
    })
      .then(({ data }) => {
        setLoaded(true);
        setProducts([...data.data.records]);
        setPagination({
          ...pagination,
          page: data.data.page,
          total_page: data.data.records.length,
        });
      })
      .catch((e) => {
        if (e.response.data.code === 401) {
          history.push("/wallet");
        }
        toast.error(e.response.message);
      });
  };

  const deleteProductByID = () => {
    Axios.delete(
      `${context.api_endpoint}/company/stock/item/delete/${_item.id}`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`products.deleteToast`));
          getProducts(1);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.data.error);
        }
      });
  };

  const unlisten = history.listen((location, action) => {
    pagination.page !== null && action === "POP" && getProducts();
  });

  useEffect(() => {
    getProducts();
  }, [product_name]);

  useEffect(() => {
    unlisten();
  }, []);

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[1]}
      limited_permission="sales"
    >
      <Grid className="mb-2">
        <Column className={`xs-12 sm-12 md-6`}>
          <Grid className="xs-12 sm-12 md-12">
            <Input
              label={context.t(`[products/add].searchProductInputLabel`)}
              placeholder={context.t(
                `[products/add].searchProductInputPlaceholder`
              )}
              style={{ width: "100%" }}
              fullWidth={true}
              onChange={async (e) => {
                if (typingTimeout) {
                  clearTimeout(typingTimeout);
                }
                setProductName(e.target.value);
              }}
            />
          </Grid>
        </Column>
        <Column className="xs-12 sm-12 md-6">
          <ExcelButton route="excel/stock/items" withGrid />
        </Column>
      </Grid>
      <Grid>
        <Column className="xs-12 sm-12">
          {loaded ? (
            <Table
              refreshAction={() => getProducts(pagination.page)}
              headings={{
                name: {
                  label: context.t(`products.headings.name`),
                },
                amount: {
                  label: context.t(`products.headings.amount`),
                  suffix: context.state.currency ? context.state.currency : "₺",
                  sortable: {
                    0: [{ field: "`stock_items`.`amount`", order: "ASC" }],
                    1: [{ field: "`stock_items`.`amount`", order: "DESC" }],
                  },
                },
                limit: {
                  label: context.t(`products.headings.limit`),
                  sortable: {
                    0: [{ field: "`stock_items`.`limit`", order: "ASC" }],
                    1: [{ field: "`stock_items`.`limit`", order: "DESC" }],
                  },
                },
                bounty: {
                  label: context.t(`products.headings.bounty`),
                  prefix: "%",
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              rows={products}
              loaded={loaded}
              pagination={pagination}
              buttons={[
                {
                  title: context.t(`component.detailEditButtonTitle`),
                  icon: "launch",
                  transitionEffect: true,
                  onClick: (row) =>
                    history.push({
                      pathname: `/products/detail/${row.id}`,
                      state: { row: row },
                    }),
                },
                {
                  title: context.t(`products.deleteButtonTitle`),
                  icon: "delete_forever",
                  textColor: "red",
                  transitionEffect: true,
                  pushEffect: true,
                  onClick: (row) => {
                    setDeleteProductModal(true);
                    setItem(row);
                  },
                },
              ]}
            />
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={Loader} width="80" height="80" alt="loading" />
            </div>
          )}
        </Column>
      </Grid>

      <AlertDialog
        maxWidth="sm"
        fullWidth={true}
        title={context.t(`products.delete.title`)}
        open={delete_product_modal}
        closeHandler={() => setDeleteProductModal(false)}
        buttons={[
          {
            title: context.t(`products.delete.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => deleteProductByID(),
          },
          {
            title: context.t(`products.delete.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>{context.t(`products.delete.alertBoldText`)}</b>
          <br />
          {context.t(`products.delete.alertText`)}
        </Alert>
      </AlertDialog>
    </AuthContainer>
  );
};

export default ProductsIndex;