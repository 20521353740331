import React, { useContext, useEffect, useState } from "react";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Column, Grid } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import RoundedRow from "../../../components/Table/RoundedRow";
import { toast } from "react-toastify";
import moment from "moment";

const Requests = () => {
  const context = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    total_page: null,
    page: 1,
    onChange: (page) => getRequests(page),
  });
  const [requests, setRequests] = useState([]);
  const [deletedId, setDeletedId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const getRequests = (page = 1) => {
    Axios.get(
      `${context.api_endpoint}/company/services/request/list?page=${page}`
    ).then(({ data }) => {
      setLoaded(true);
      setRequests(
        data.data.records.map((request) => ({
          ...request,
          categoryList: request?.categories?.name,
          created_at: moment(request.created_at)
            .local()
            .format("DD/MM/YYYY HH:mm"),
        }))
      );
      setPagination((prev) => ({
        ...prev,
        total_page: data.data.records.length,
        page: data.data.page,
      }));
    });
  };

  const cancelRequest = () => {
    Axios.post(`${context.api_endpoint}/company/services/request/cancel`, {
      id: deletedId,
    })
      .then(({ data }) => {
        toast.success(context.t(`['services/requests'].deletedRequestToast`));
        getRequests();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="services">
      <Grid>
        <Grid>
          <Column className="xs-12 sm-12">
            <Table
              refreshAction={() => getRequests()}
              headings={{
                created_at: {
                  label: context.t(`['services/requests'].headings.time`),
                },
                categoryList: {
                  label: "Kategori",
                },
                name: {
                  label: context.t(`['services/requests'].headings.name`),
                },
                state: {
                  label: context.t(`['services/requests'].headings.state`),
                },
                _: {
                  label: context.t(
                    `['services/requests'].headings.actionHeadingText`
                  ),
                },
              }}
              rows={requests}
              loaded={loaded}
              replacements={{
                state: {
                  0: (
                    <RoundedRow color="yellow">
                      {context.t(`['services/requests'].headings.stateWaiting`)}
                    </RoundedRow>
                  ),
                  1: (
                    <RoundedRow color="green">
                      {context.t(
                        `['services/requests'].headings.stateCompleted`
                      )}
                    </RoundedRow>
                  ),
                  2: (
                    <RoundedRow color="red">
                      {context.t(
                        `['services/requests'].headings.stateIncompleted`
                      )}
                    </RoundedRow>
                  ),
                },
              }}
              pagination={pagination.page !== null ? pagination : undefined}
              buttons={[
                {
                  title: context.t(`['services/requests'].cancel.cancelButton`),
                  icon: "delete_forever",
                  transitionEffect: true,
                  textColor: "red",
                  disabled: (row) => (row.state === 0 ? false : true),
                  onClick: (row) => {
                    setDeletedId(row.id);
                    setDeleteConfirm(true);
                  },
                },
              ]}
            />

            <AlertDialog
              title={context.t(`['services/requests'].cancel.cancelTitle`)}
              open={deleteConfirm}
              closeHandler={() => setDeleteConfirm(false)}
              buttons={[
                {
                  title: context.t(
                    `['services/requests'].cancel.cancelConfirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  timer: 5,
                  onClick: () => cancelRequest(),
                },
                {
                  title: context.t(
                    `['services/requests'].cancel.cancelDiscardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>{context.t(`['services/requests'].cancel.alertBoldText`)}</b>
                <br />
                {context.t(`['services/requests'].cancel.alertText`)}
              </Alert>
            </AlertDialog>
          </Column>
        </Grid>
      </Grid>
    </AuthContainer>
  );
};

export default Requests;