import React from "react";
import "../assets/style/views/Pricing.scss";
import Button from "../theme/Button";

const SinglePlan = (props) => {
  return (
    <div className={`single-pricing ${props.backgroundColor}`}>
      {props.leadingImage}
      <div className="pricing-header">
        <h5 className="text-black-50">{props.type}</h5>
        <p className="price">
          {props.title.substring(0, props.title.indexOf("("))}
        </p>
        <p className="time">
          {props.title.substring(props.title.indexOf("("))}
        </p>
        <span className={`year ${props.textColor}`}>{props.price}₺</span>
      </div>
      <div className="pricing-list">
        {props.items && (
          <ul>
            {props.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </div>

      {props.button && (
        <div className="mt-2">
          <Button size="lg" {...props.button} fullWidth />
        </div>
      )}
    </div>
  );
};

export default SinglePlan;
