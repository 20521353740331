import React from "react";
import AppContext from "../../../../context/store";
import AuthContainer from "../../../../components/AuthContainer";
import Axios from "axios";

import { Grid, Column } from "../../../../theme/Grid";
import Input from "../../../../theme/CustomMUI/Input";
import Select from "../../../../theme/CustomMUI/Select";
import Button from "../../../../theme/Button";

import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";
import ModalDialog from "../../../../theme/CustomMUI/AlertDialog";
import Alert from "@material-ui/lab/Alert";
import SectionTitle from "../../../../theme/SectionTitle";
import Table from "../../../../theme/Table";
import { Checkbox, Chip, FormGroup, TextField } from "@material-ui/core";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../../theme/FCLabel";
import { Check, Block, FeaturedPlayList, WhatsApp } from "@material-ui/icons";
import UndefinedSenderAlert from "../../../../components/Alerts/UndefinedSenderAlert";
import WpUndefinedAlert from "../../../../components/Alerts/WpUndefinedAlert";
import SenderFetchAlert from "../../../../components/Alerts/SenderFetchAlert";
import CalculateMessageCount from "../../../../functions/CalculateMessageCount";
import StatsCard from "../../../../components/Cards/StatsCard";
import SMSReview from "../../../../components/Dialogs/SMSReview";
import { phoneNumberDisplay } from "../../../../functions/PhoneNumberDisplay";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import moment from "moment";
import TimeSelect from "../../../../theme/TimeSelect";
import BetweenTimeGenerator from "../../../../functions/BetweenTimeGenerator";
import UploadInput from "../../../../components/UploadInput";

class SendSelective extends React.Component {
  static contextType = AppContext;

  state = {
    step: 0,
    check_modal: false,
    search_key: "",
    sender: "",

    customers: {
      loaded: false,
      data: [],
      pagination: {
        total_page: null,
        page: 1,
        onChange: (page) =>
          this.state.search_key && this.state.search_key.length
            ? this.getCustomers(this.state.search_key, page)
            : this.getCustomers("", page),
      },
    },

    approved_customers: [],
    excepted_customers: [],

    sms_request_status: false,
    request: {
      all: false, //Tüm müşterilere gönderilecek mi?
      id: [], //Müşteri ID listesi,
      except: [], //Tüm müşteriler true ise hariç müşterilerin ID listesi,
      message: "", //Gönderilecek mesaj içeriği
      send_date: new Date(), //Gönderilecek tarih
      selected_time: null, //Gönderilecek saat
      hours: [...BetweenTimeGenerator(this.context.state.company_shift)], //Gönderilecek saat aralığı
      future_date: false,
      file: null,
    },

    templates: [],
    selected_template: null,
    available_credit: null,
    shortCode: null,

    // Checknox for sms and wp
    checkedSMS: true,
    checkedWP: null,

    // whatsapp or sms
    sms_prefer: 1,

    // WPState and WP phone
    wp_state: null,
    wp_phone: null,
  };

  // checking dor wp accounts
  checkWp = async () => {
    await Axios.get(
      `${this.context.api_endpoint}/company/get/wp/message/settings`
    )
      .then(async (ress) => {
        if (ress.data.data.device_key !== "") {
          await Axios.post(
            `${this.context.api_endpoint}/salon/wp/login/check/active/${ress.data.data.device_key}`
          )
            .then((ress) => {
              if (ress.status === 200) {
                this.setState({
                  wp_state: ress.data.is_connect,
                  wp_phone: ress.data.data.device_number,
                  checkedWP: true,
                });
              }
            })
            .catch(() => {
              this.setState({
                wp_state: false,
                wp_phone: null,
                checkedWP: false,
              });
            });
        } else {
          this.setState({
            checkedWP: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getCustomers = (key, page = 1) => {
    Axios.get(`${this.context.api_endpoint}/company/customers/blacklist`, {
      params: {
        page: page ? page : 1,
        key: key ? key : null,
      },
    }).then(({ data }) => {
      this.setState({
        customers: {
          ...this.state.customers,
          loaded: true,
          data: [
            ...data.data.records.map((item) => {
              return {
                ...item,
                phone:
                  this.context.state.user.permission === 2 &&
                    this.context.state.limited_permission.customer_info === 0
                    ? phoneNumberDisplay(item.phone)
                    : `0${item.phone.substring(0, 3)} ${item.phone.substring(
                      3,
                      6
                    )} ${item.phone.substring(6, 10)}`,
              };
            }),
          ],
          // pagination: {
          //   ...this.state.customers.pagination,
          //   total_page: data.data.records.length,
          //   page: data.data.page,
          // },
        },
      });
    });
  };

  getContextSenderSettings = () => {
    Axios.get(`${this.context.api_endpoint}/company/itself`).then(
      (response) => {
        const {
          data: { data },
        } = response;
        this.context.dispatch({
          type: "SET_COMPANY_SMS_SETTINGS",
          payload: {
            company_sms_settings: { ...{ sender: data.sender } },
            company_sms_settings_loaded: true,
          },
        });
      }
    );
  };

  sendSelectiveSms = () => {
    const { request, approved_customers, excepted_customers } = this.state;
    this.setState({ sms_request_status: true });

    let appointment_date = null;
    if (request.future_date === true) {
      let appointment_time = request.selected_time.split(":");
      appointment_date = `${moment(request.send_date).format("YYYY-MM-DD")}T${appointment_time[0]
        }:${appointment_time[1]}:00Z`;
    }

    let sms_prefer = (() => {
      if (this.state.checkedSMS && this.state.checkedWP) {
        return 3;
      }
      if (this.state.checkedSMS) {
        return 1;
      } else {
        if (this.state.checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error("Gönderim yöntemlerinden birisini seçin");
    }

    const formData = new FormData();
    approved_customers.forEach((item) => {
      formData.append("id", item.id);
    });

    formData.append("all", this.state.request.all);
    excepted_customers.forEach((item) => {
      formData.append("except", item.id);
    });

    formData.append("message", this.state.request.message);

    this.state.request.future_date &&
      formData.append(
        "send_date",
        this.state.request.future_date === true ? appointment_date : null
      );

    formData.append("sms_prefer", sms_prefer);

    formData.append("file", this.state.request.file);

    Axios.post(
      `${this.context.api_endpoint}/company/message/to/customers`,
      formData
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          this.getBalance();
          setTimeout(() => {
            this.setState({
              step: 0,
              approved_customers: [],
              request: {
                message: "",
              },
            });
          }, 500);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          setTimeout(() => {
            this.setState({
              step: 0,
              approved_customers: [],
              request: {
                message: "",
              },
            });
          }, 500);
        } else {
          toast.warning(e.response.data.message);
          setTimeout(() => {
            this.setState({
              step: 0,
              approved_customers: [],
              request: {
                message: "",
              },
            });
          }, 500);
        }
      });
  };
  getBalance = async () => {
    Axios.get(`${this.context.api_endpoint}/company/balance`)
      .then((data) => {
        // setWpBalance(data.data.data.wp_credit);
        this.context.dispatch({
          type: "SET_BALANCE",
          payload: { balance: data.data },
        });
      })
      .catch((err) => console.log(err));
  };
  getTemplates = () => {
    Axios.get(`${this.context.api_endpoint}/company/sms/template/all`).then(
      ({ data }) => {
        this.setState({
          templates: [...data.data],
        });
      }
    );
  };

  getMessageSettings = () => {
    Axios.get(`${this.context.api_endpoint}/company/message/credit`).then(
      ({ data }) => {
        this.setState({
          available_credit: `${data.data.credit} SMS`,
          sender: data.data.settings.sender,
        });
        if (
          data.data.settings.sender === "" ||
          data.data.settings.sender === null
        ) {
          this.setState({
            checkedSMS: false,
          });
        }
      }
    );
  };

  getSMSReview = () => {
    let sms_prefer = (() => {
      if (this.state.checkedSMS && this.state.checkedWP) {
        return 3;
      }
      if (this.state.checkedSMS) {
        return 1;
      } else {
        if (this.state.checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error("Gönderim yöntemlerinden birisini seçin");
    }
    Axios.post(
      `${this.context.api_endpoint}/company/message/to/customer/preview`,
      {
        all: this.state.request.all,
        id: !this.state.request.all
          ? [...this.state.approved_customers.map((item) => item.id)]
          : [],
        except: this.state.request.all
          ? [...this.state.excepted_customers.map((item) => item.id)]
          : [],
        message: this.state.request.message,
        sms_prefer,
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          await this.setState({
            total_number: response.data.data.total_numbers,
            total_sms_credit: response.data.data.total_sms_credit,
            total_wp_credit: response.data.data.total_wp_credit,
            open_sms_review_dialog: true,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getShortcode = () => {
    Axios.get(`${this.context.api_endpoint}/company/shortcode/list/keyword`)
      .then(async (response) => {
        if (response.status === 200) {
          await this.setState({
            shortCode: response.data.data.keyword,
          });
        }
      })
      .catch((e) => { });
  };

  fileHandlerProp = (value) => {
    this.setState({
      request: {
        ...this.state.request,
        file: value,
      },
    });
  };

  componentDidMount = async () => {
    await this.getContextSenderSettings();
    await this.getCustomers();
    await this.getTemplates();
    await this.getMessageSettings();
    await this.getShortcode();
    this.checkWp();
  };

  render() {
    const { step, check_modal } = this.state;
    return (
      <AuthContainer authorities={[1]} limited_permission="sms">
        <Grid className="mb-2">
          <Column className="xs-12 sm-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-orange bg-orange-opacity">
                    <FeaturedPlayList
                      style={{
                        fontSize: "2em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>SMS Mesajı</StatsHeader>
                    <StatsValue>{this.state.sender}</StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>WhatsApp Mesajı</StatsHeader>
                    <StatsValue>{this.state.wp_phone}</StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      this.state.sender === "" ? false : this.state.checkedSMS
                    }
                    onChange={(e) => {
                      this.setState({
                        checkedSMS: e.target.checked,
                      });
                    }}
                    color="primary"
                  />
                }
                label={this.context.t(`sms.sendType.sendSMS`)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checkedWP}
                    onChange={(e) => {
                      if (!this.state.wp_state) {
                        this.props.history.push("/settings/wp");
                      }
                      this.setState({
                        checkedWP: e.target.checked,
                      });
                    }}
                    color="primary"
                  />
                }
                label={this.context.t(`sms.sendType.sendWP`)}
              />
            </FormGroup>
          </Column>
          <Column className="xs-12 sm-12 md-2">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-orange bg-orange-opacity">
                    <FeaturedPlayList
                      style={{
                        fontSize: "2em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>
                      {this.context.t(`sms.credit.sms`)}
                    </StatsHeader>
                    <StatsValue>
                      {" "}
                      {this.context.state.balance?.data?.credit}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column className="xs-12 sm-12 md-2">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>{this.context.t(`sms.credit.wp`)}</StatsHeader>
                    <StatsValue>
                      {" "}
                      {this.context.state.balance?.data?.wp_credit}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
        </Grid>

        {(this.state.sender === "" || this.state.sender === null) &&
          !this.state.wp_state ? (
          <>
            <UndefinedSenderAlert />
            <WpUndefinedAlert />
          </>
        ) : (
          <></>
        )}

        {step === 0 && <this.CustomerSettingsStep />}

        {step === 1 && <this.SmsSettingsStep />}

        <ModalDialog
          title={this.context.t(`sms.send.check.title`)}
          open={check_modal}
          textType={true}
          closeHandler={() => this.setState({ check_modal: false })}
          buttons={[
            {
              title: this.context.t(`sms.send.check.confirmButtonTitle`),
              onClick: () => this.getSMSReview(),
            },
            { title: this.context.t(`sms.send.check.discardButtonTitle`) },
          ]}
        >
          <Alert severity="warning">
            {this.context.t(`sms.send.check.alertText`)}
          </Alert>
        </ModalDialog>
        <SMSReview
          open={this.state.open_sms_review_dialog}
          closeHandler={() => {
            this.setState({
              open_sms_review_dialog: false,
            });
          }}
          sendSMS={this.sendSelectiveSms}
          sms_content={this.state.request.message}
          total_number={this.state.total_number}
          total_sms_credit={this.context.state.balance?.data?.credit}
          in_use_sms_credit={this.state.total_sms_credit}
          total_wp_credit={this.context.state.balance?.data?.wp_credit}
          in_use_wp_credit={this.state.total_wp_credit}
          check_wp={this.state.checkedWP}
          check_sms={this.state.checkedSMS}
        />
      </AuthContainer>
    );
  }

  Sidebar = (props) => {
    const { step, request, approved_customers, excepted_customers } =
      this.state;
    return (
      <>
        <div>
          <div className={request.all ? "mb-5" : ""}>
            <SectionTitle className="mb-1">
              {this.context.t(`['sms/selective'].approved.title`)}
            </SectionTitle>
            {!request.all ? (
              <ChipContainer>
                {approved_customers.length > 0 ? (
                  approved_customers.map((item) => (
                    <Chip
                      style={{ height: 46, borderRadius: 5 }}
                      className="mr-2 mb-2 bg-green-opacity fc-green fw-500"
                      label={item.full_name}
                      onDelete={() =>
                        this.setState({
                          approved_customers: [
                            ...approved_customers.filter(
                              (_item) => _item !== item
                            ),
                          ],
                        })
                      }
                    />
                  ))
                ) : (
                  <Alert
                    severity="info"
                    color="warning"
                    style={{ width: "100%", boxSizing: "border-box" }}
                  >
                    <b>
                      {this.context.t(
                        `['sms/selective'].approved.notFound.alertBoldText`
                      )}
                    </b>
                    <br />
                    {this.context.t(
                      `['sms/selective'].approved.notFound.alertText`
                    )}
                  </Alert>
                )}
              </ChipContainer>
            ) : (
              <Alert
                severity="success"
                style={{ width: "100%", boxSizing: "border-box" }}
              >
                <b>
                  {this.context.t(`['sms/selective'].approved.alertBoldText`)}
                </b>
              </Alert>
            )}
          </div>

          {request.all && (
            <div>
              <SectionTitle className="mb-1">
                {this.context.t(`['sms/selective'].excepted.title`)}
              </SectionTitle>
              <ChipContainer>
                {excepted_customers.length > 0 ? (
                  excepted_customers.map((item) => (
                    <Chip
                      style={{ height: 46, borderRadius: 5 }}
                      className="mr-2 mb-2 bg-red-opacity fc-red fw-500"
                      label={item.full_name}
                      onDelete={() =>
                        this.setState({
                          excepted_customers: [
                            ...excepted_customers.filter(
                              (_item) => _item !== item
                            ),
                          ],
                        })
                      }
                    />
                  ))
                ) : (
                  <Alert
                    severity="info"
                    style={{ width: "100%", boxSizing: "border-box" }}
                  >
                    <b>
                      {this.context.t(
                        `['sms/selective'].excepted.notFound.alertBoldText`
                      )}
                    </b>
                    <br />
                    {this.context.t(
                      `['sms/selective'].excepted.notFound.alertText`
                    )}
                  </Alert>
                )}
              </ChipContainer>
            </div>
          )}

          {step === 0 && (
            <Button
              className="mt-3"
              title={this.context.t(`['sms/selective'].continueButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              disabled={
                this.context.state.company_sms_settings_loaded !== true ||
                (approved_customers.length === 0 && request.all === false)
              }
              fullWidth
              pushEffect
              onClick={() => this.setState({ step: 1 })}
            />
          )}

          {step === 1 && (
            <Button
              className="mt-3"
              title={this.context.t(
                `['sms/selective'].backToSelectButtonTitle`
              )}
              backgroundColor="primary-opacity"
              textColor="primary"
              fullWidth
              pushEffect
              onClick={() => this.setState({ step: 0 })}
            />
          )}
        </div>
      </>
    );
  };

  // Bu aşamda müşteri seçimleri yapılmaktadır.
  CustomerSettingsStep = (props) => {
    const { request, customers, approved_customers, excepted_customers } =
      this.state;
    return (
      <>
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
            <SectionTitle className="mb-1">
              {this.context.t(`['sms/selective'].customer.title`)}
            </SectionTitle>
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-6">
                <Input
                  label={this.context.t(
                    `['sms/selective'].customer.searchInputLabel`
                  )}
                  onChange={(e) => {
                    this.setState({ search_key: e.target.value });
                    e.target.value
                      ? this.getCustomers(e.target.value)
                      : this.getCustomers();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-6">
                <Button
                  className="elevation-1"
                  icon={!request.all ? "check" : "close"}
                  title={
                    !request.all
                      ? this.context.t(`['sms/selective'].customer.selectAll`)
                      : this.context.t(`['sms/selective'].customer.unselectAll`)
                  }
                  textColor={!request.all ? "green" : "red"}
                  backgroundColor="white"
                  size="lg"
                  fullWidth
                  onClick={() =>
                    this.setState({
                      excepted_customers: [],
                      approved_customers: [],
                      request: { ...request, all: !request.all },
                    })
                  }
                />
              </Column>
            </Grid>

            <Table
              refreshAction={() =>
                this.getCustomers(
                  this.state.search_key,
                  this.state.customers.pagination.page
                )
              }
              loaded={customers.loaded}
              headings={{
                full_name: {
                  label: this.context.t(
                    `['sms/selective'].customer.headings.fullName`
                  ),
                  limited_line: 1,
                  style: { width: "33%" },
                },
                phone: {
                  label: this.context.t(
                    `['sms/selective'].customer.headings.phone`
                  ),
                  limited_line: 1,
                  style: { width: "33%" },
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              rows={customers.data}
              pagination={customers.pagination}
              showAlwaysPagination={true}
              buttons={[
                {
                  iconComponent: Check,
                  textColor: "green",
                  backgroundColor: "green-opacity",
                  fullWidth: true,
                  pushEffect: true,
                  disabled: (row) =>
                    request.all ||
                    approved_customers.filter((item) => item.id === row.id)
                      .length ||
                    excepted_customers.filter((item) => item.id === row.id)
                      .length,
                  onClick: (row) =>
                    this.setState({
                      approved_customers:
                        approved_customers.filter((item) => item.id === row.id)
                          .length === 0
                          ? [...approved_customers, row]
                          : [...approved_customers],
                    }),
                },
                {
                  iconComponent: Block,
                  textColor: "red",
                  backgroundColor: "red-opacity",
                  fullWidth: true,
                  pushEffect: true,
                  disabled: (row) =>
                    !request.all ||
                    approved_customers.filter((item) => item.id === row.id)
                      .length ||
                    excepted_customers.filter((item) => item.id === row.id)
                      .length,
                  onClick: (row) =>
                    this.setState({
                      excepted_customers:
                        excepted_customers.filter((item) => item.id === row.id)
                          .length === 0
                          ? [...excepted_customers, row]
                          : [...excepted_customers],
                    }),
                },
              ]}
            />
          </Column>

          <Column className={`xs-12 sm-12 md-12 lg-12 xl-6`}>
            <this.Sidebar />
          </Column>
        </Grid>
      </>
    );
  };

  SmsSettingsStep = (props) => {
    const { request, sms_request_status } = this.state;
    const { message } = this.state.request;
    return (
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-8">
          <Column className="xs-12 sm-12 md-12 lg-2 mt-1">
            <SectionTitle className="mb-1">
              {this.context.t(`sms.send.messageInputLabel`)}
            </SectionTitle>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-8">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={request.future_date}
                  onChange={async (e) => {
                    await this.setState({
                      request: { ...request, future_date: e.target.checked },
                    });
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{this.context.t(`sms.send.futureDate`)}</span>
                </FCLabel>
              }
            />
          </Column>
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
              <Select
                label={this.context.t(`sms.send.template`)}
                items={[...this.state.templates]}
                selected={this.state.selected_template?.name ?? ""}
                labelKey="name"
                valueKey="name"
                returnFullObject
                handler={(template) =>
                  this.setState({
                    selected_template: template,
                    request: {
                      ...request,
                      message: template.content,
                    },
                  })
                }
              />
            </Column>
            {request.future_date && (
              <>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <DatePickerInput
                    label={this.context.t(`sms.send.sendDate`)}
                    value={request.send_date ? new Date(request.send_date).toISOString() : ""}
                    onChange={(date) => {
                      this.setState({
                        request: {
                          ...request,
                          send_date: new Date(date).toISOString(),
                        },
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <TimeSelect
                    fullTime={true}
                    label={this.context.t(
                      `['settings/hours'].startInputLabelHour`
                    )}
                    type="hour"
                    value={
                      request.selected_time !== null
                        ? request.selected_time.split(":")[0]
                        : ""
                    }
                    fullWidth={true}
                    size="medium"
                    companyShiftTime={true}
                    // minuteStep={5}
                    handler={async (value) => {
                      this.setState({
                        request: {
                          ...request,
                          selected_time:
                            request.selected_time !== null
                              ? `${value}:${request.selected_time.split(":")[1]
                              }`
                              : `${value}:00`,
                        },
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <TimeSelect
                    label={this.context.t(
                      `['settings/hours'].startInputLabelMinute`
                    )}
                    type="minute"
                    value={
                      request.selected_time !== null
                        ? request.selected_time.split(":")[1]
                        : ""
                    }
                    selectedHour={request.selected_time}
                    lastHour={request.hours[request.hours.length - 1]}
                    fullWidth={true}
                    size="medium"
                    handler={async (time) => {
                      this.setState({
                        request: {
                          ...request,
                          selected_time:
                            request.selected_time !== null
                              ? `${request.selected_time.split(":")[0]}:${time}`
                              : null,
                        },
                      });
                    }}
                  />
                </Column>
              </>
            )}
          </Grid>
          <Input
            label={this.context.t(`sms.send.messageInputLabel`)}
            multiline={true}
            rows={10}
            value={request.message}
            onChange={(e) =>
              this.setState({
                request: {
                  ...request,
                  message: e.target.value,
                },
              })
            }
            helperText={CalculateMessageCharCount(
              request.message,
              `${this.state.checkedSMS && this.state.checkedWP
                ? "1"
                : !this.state.checkedSMS && this.state.checkedWP
                  ? "3"
                  : "1"
              } `
            )}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              className="mb-1 ml-1"
              title={this.context.t(`sms.send.autoText.shortCode`)}
              size="xs"
              textColor="purple"
              outlined
              disabled={this.state.shortCode === ""}
              onClick={() => {
                this.setState({
                  request: {
                    ...request,
                    message: `${message}SMS iptali için ${this.state.shortCode} yazın 4607'a gönderin.`,
                  },
                });
              }}
            />

            {this.state.checkedWP && (
              <UploadInput fileHandlerProp={this.fileHandlerProp} />
            )}
          </div>

          <Button
            disabled={
              request.message.length === 0 ||
              !CalculateMessageCount(
                request.message,
                `${this.state.checkedSMS && this.state.checkedWP
                  ? "1"
                  : !this.state.checkedSMS && this.state.checkedWP
                    ? "3"
                    : "1"
                } `
              )
            }
            icon="send"
            title={this.context.t(`sms.send.buttonTitle`)}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            onClick={() => {
              this.getSMSReview();
            }}
          />
        </Column>

        <Column className={`xs-12 sm-12 md-12 lg-12 xl-4`}>
          <this.Sidebar />
        </Column>
      </Grid>
    );
  };
}

export default SendSelective;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsHeader = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #aaa;
  padding-bottom: 10px;
`;

const StatsTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #a0a0a0;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;
