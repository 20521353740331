import React, { Component } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Select from "../../../theme/CustomMUI/Select";
import Axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import { InputAdornment } from "@material-ui/core";
import Validate from "../../../functions/Validate";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import Loader from "../../../assets/images/circular.gif";
import { flushSync } from "react-dom";

let typingTimeout = null;
class PackageDetail extends Component {
  static contextType = AppContext;
  state = {
    services: [],
    staffs: [],

    delete_modal: false,

    total: 0,
    total_loader: false,

    new_package: {
      name: null,
      all_amount: null,
      services: {},
    },

    __generated_package: {},
    selected_service_color: false,
    selected_service_id: null,
  };

  getPackageDetail = () => {
    const { params } = this.props.match;

    Axios.get(`${this.context.api_endpoint}/company/packet/${params.id}`)
      .then(({ data }) => {
        // console.log(data)

        this.setState({
          new_package: {
            name: data.data.name,
            all_amount: data.data.all_amount,
            services: Object.assign(
              ...(data.data.packet_details
                ? data.data.packet_details.map((item, index) => ({
                    [index]: { ...item },
                  }))
                : {}),
              {}
            ),
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  _total = () => {
    const { new_package } = this.state;

    return {
      amount: Object.keys(new_package.services)
        .filter((key) => new_package.services[key] !== undefined)
        .map((key) =>
          new_package.services[key].amount !== null
            ? new_package.services[key].amount *
              new_package.services[key].period
            : 0
        )
        .reduce((a, b) => a + b)
        .toFixed(2),

      getRatioList: () => {
        const SingleRatio = {};
        Object.keys(new_package.services)
          .filter((key) => new_package.services[key] !== undefined)
          .map(
            (key) =>
              (SingleRatio[key] =
                new_package.services[key].amount !== null
                  ? (new_package.services[key].amount *
                      new_package.services[key].period) /
                    this._total().amount
                  : 0)
          );
        return SingleRatio;
      },
    };
  };

  editTotal = () => {
    const { new_package, total } = this.state;

    if (total !== 0 || total !== null) {
      let services = { ...new_package.services };
      [...Object.keys(this._total().getRatioList())].map(
        (key) =>
          (services = {
            ...services,
            [key]: {
              ...new_package.services[key],
              amount: parseFloat(
                (
                  (total * this._total().getRatioList()[key]) /
                  new_package.services[key].period
                ).toFixed(2)
              ),
            },
          })
      );

      this.setState({
        total_loader: false,
        new_package: {
          ...new_package,
          services,
        },
      });
    }
  };

  componentDidMount() {
    this.getReqArrays();
    this.getPackageDetail();
  }

  render() {
    const { new_package, total_loader } = this.state;

    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[0, 1]}
        limited_permission="sales"
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-9">
            <Input
              className="mb-1"
              label={this.context.t(`['packages/detail/id'].nameInputLabel`)}
              value={new_package.name ?? ""}
              onChange={(e) =>
                this.setState({
                  new_package: { ...new_package, name: e.target.value ?? null },
                })
              }
            />
            <SectionContainer>
              {Object.keys(new_package.services).filter(
                (key) => new_package.services[key] !== undefined
              ).length > 0 ? (
                Object.keys(new_package.services)
                  .filter((key) => new_package.services[key] !== undefined)
                  .map((item, index) => (
                    <Grid key={index} className="no-gutters-row">
                      <Column className="xs-12 sm-12 md-auto">
                        <Select
                          is_passive={{
                            color: this.state.services.filter(
                              (m) =>
                                m.id == new_package.services[item].service_id
                            )[0]?.is_passive
                              ? "red"
                              : "",
                          }}
                          label={this.context.t(
                            `['packages/detail/id'].serviceInputLabel`
                          )}
                          items={[...this.state.services]}
                          labelKey="name"
                          valueKey="id"
                          selected={
                            new_package.services[item].service_id ?? null
                          }
                          returnFullObject
                          handler={(service) => {
                            console.log(item);
                            this.fieldChanger("services", item, {
                              service_id: service.id,
                              amount: service.amount,
                            });

                            if (service.is_passive) {
                              this.setState({
                                selected_service_color: true,
                                selected_service_id: service.id,
                              });
                            } else {
                              this.setState({
                                selected_service_color: false,
                                selected_service_id: null,
                              });
                            }

                            console.log(
                              new_package.services[item].service_id,
                              this.state.services.filter(
                                (m) =>
                                  m.id == new_package.services[item].service?.id
                              )
                            );
                          }}
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-auto">
                        <Select
                          label={this.context.t(
                            `['packages/detail/id'].countInputLabel`
                          )}
                          items={[...new Array(36).keys()].map((i) => ({
                            value: i + 1,
                          }))}
                          labelKey="value"
                          valueKey="value"
                          selected={new_package.services[item].period ?? null}
                          handler={(period) =>
                            this.fieldChanger("services", item, {
                              period: parseInt(period),
                            })
                          }
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-auto">
                        <Input
                          label={
                            this.context.t(
                              `['packages/detail/id'].periodAmountInputLabel`
                            ) +
                            (this.context.state.currency
                              ? " (" + this.context.state.currency + ")"
                              : "(₺)")
                          }
                          type="number"
                          value={`${new_package.services[item].amount}`}
                          onChange={(e) =>
                            this.fieldChanger("services", item, {
                              amount:
                                e.target.value &&
                                /[0-9,.]+/.test(e.target.value)
                                  ? parseFloat(e.target.value)
                                  : 0,
                            })
                          }
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-1">
                        <Button
                          icon="delete_forever"
                          iconSize={20}
                          backgroundColor="red-opacity"
                          textColor="red"
                          size="lg"
                          fullWidth
                          onClick={() => this.deleteServiceInPackage(item)}
                        />
                      </Column>
                    </Grid>
                  ))
              ) : (
                <NoDataContainer>
                  <img
                    src={require("../../../assets/images/manypixels/click_action.svg")}
                    alt="Malesef bulunamadı!"
                  />
                  <p>{this.context.t(`['packages/detail/id'].noDataText`)}</p>
                </NoDataContainer>
              )}

              {Object.keys(new_package.services).filter(
                (key) => new_package.services[key] !== undefined
              ).length > 0 && (
                <Grid className="no-gutters-row">
                  <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                  <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                  <Column className="xs-12 sm-12 md-12 lg-auto">
                    {/* <Input
                      label={this.context.t(`['packages/add'].totalInputLabel`)}
                      type="number"
                      value={this.getTotal().amount}
                    /> */}
                    <Input
                      label={
                        this.context.t(`['packages/add'].totalInputLabel`) +
                        (this.context.state.currency
                          ? " (" + this.context.state.currency + ")"
                          : "(₺)")
                      }
                      // type="number"
                      value={
                        this.state.total
                          ? this.state.total
                          : this.getTotal().amount
                      }
                      onChange={async (e) => {
                        await this.setState({
                          total:
                            e.target.value && e.target.value !== "0"
                              ? parseFloat(e.target.value)
                              : 1,
                          total_loader: true,
                        });
                      }}
                      InputProps={{
                        endAdornment: total_loader && (
                          <InputAdornment
                            position="end"
                            style={{ marginRight: 0 }}
                          >
                            <img
                              src={Loader}
                              width="100"
                              height="100"
                              alt="loading"
                            />
                          </InputAdornment>
                        ),
                        onKeyUp: (e) => {
                          const value = e.target.value;
                          clearTimeout(typingTimeout);
                          typingTimeout =
                            value.length > 0 &&
                            setTimeout(() => this.editTotal(), 1250);
                        },
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-1"></Column>
                </Grid>
              )}
            </SectionContainer>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-3">
            <SectionContainer>
              <Button
                disabled={
                  Object.keys(new_package.services).filter(
                    (key) => new_package.services[key] !== undefined
                  ).length === 0
                }
                icon="check"
                title={this.context.t(
                  `['packages/detail/id'].updatePackageButtonTitle`
                )}
                backgroundColor="teal-opacity"
                textColor="teal"
                size="lg"
                fullWidth
                onClick={this.updatePackage}
              />

              <Button
                className="mt-2"
                icon="delete_forever"
                title={this.context.t(
                  `['packages/detail/id'].deletePackageButtonTitle`
                )}
                backgroundColor="red-opacity"
                textColor="red"
                size="lg"
                fullWidth
                onClick={() => this.setState({ delete_modal: true })}
              />

              <Button
                className="mt-2"
                icon="add"
                title={this.context.t(
                  `['packages/detail/id'].addServiceButtonTitle`
                )}
                backgroundColor="blue-opacity"
                textColor="blue"
                size="lg"
                fullWidth
                onClick={this.addServiceInPackage}
              />
            </SectionContainer>
          </Column>
        </Grid>

        <AlertDialog
          title={this.context.t(`['packages/detail/id'].delete.title`)}
          open={this.state.delete_modal}
          closeHandler={() => this.setState({ delete_modal: false })}
          buttons={[
            {
              title: this.context.t(
                `['packages/detail/id'].delete.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              timer: 5,
              onClick: () => this.deletePackage(),
            },
            {
              title: this.context.t(
                `['packages/detail/id'].delete.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            {this.context.t(`['packages/detail/id'].delete.alertText`)}
          </Alert>
        </AlertDialog>
      </AuthContainer>
    );
  }

  getReqArrays = () => {
    Axios.get(`${this.context.api_endpoint}/company/receipt/index`)
      .then(({ data }) => {
        this.setState({
          staffs: [...data.data.staffs],
          services: [...data.data.services],
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  addServiceInPackage = () => {
    const { new_package } = this.state;
    this.setState({
      new_package: {
        ...new_package,
        services: {
          ...new_package.services,
          [Object.keys(new_package.services).length + 1]: {
            service_id: null,
            amount: null,
            period: 1,
            current_period: 0,
          },
        },
      },
    });
  };

  deleteServiceInPackage = (key) => {
    const { new_package } = this.state;
    this.setState({
      new_package: {
        ...new_package,
        services: {
          ...new_package.services,
          [key]: undefined,
        },
      },
    });
  };

  updatePackage = () => {
    const { new_package } = this.state;
    const { params } = this.props.match;

    this.validatePackage() &&
      Axios.put(`${this.context.api_endpoint}/company/packet/update`, {
        id: parseInt(params.id),
        ...new_package,
        services: undefined,
        all_amount: this.getTotal().amount,
        packet_details: [
          ...Object.keys(new_package.services)
            .filter((key) => new_package.services[key] !== undefined)
            .map((item) => Object.assign(new_package.services[item], {})),
        ],
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(this.context.t(`['packages/detail/id'].updateToast`));
            this.getPackageDetail();
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  deletePackage = () => {
    const { params } = this.props.match;

    Axios.delete(
      `${this.context.api_endpoint}/company/packet/delete/${params.id}`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(this.context.t(`['packages/detail/id'].deleteToast`));
          this.props.history.push("/packages/list");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  validatePackage = () => {
    const { new_package } = this.state;
    let undefined_service_count = 0;
    Object.keys(new_package.services).filter(
      (key) =>
        new_package.services[key] !== undefined &&
        new_package.services[key].service_id === null &&
        ++undefined_service_count
    );

    if (undefined_service_count > 0) {
      toast.warning(
        this.context.t(`['packages/detail/id'].validateServiceToast`)
      );
      return false;
    }

    return Validate([
      {
        field: "Paket adı",
        value: new_package.name,
        condition: /\w+/,
      },
    ]);
  };

  getTotal = () => {
    const { new_package } = this.state;
    return {
      amount: Math.round(
        parseFloat(
          Object.keys(new_package.services)
            .filter((key) => new_package.services[key] !== undefined)
            .map((key) =>
              new_package.services[key].amount !== null
                ? new_package.services[key].amount *
                  new_package.services[key].period
                : 0
            )
            .reduce((a, b) => a + b)
        )
      ),
    };
  };

  fieldChanger = (section, key, payload) => {
    const { new_package } = this.state;
    flushSync(async () => {
      await this.setState({
        new_package: {
          ...new_package,
          [section]: {
            ...new_package[section],
            [key]: {
              ...new_package[section][key],
              ...payload,
            },
          },
        },
      });
      await this.setState({
        total: this._total().amount,
      });
    });
  };
}

export default PackageDetail;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.04);
`;
