/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
const CitiesAndDistricts = [
  {
    il: "Adana",
    plaka: 1,
    ilceleri: [
      "Aladağ",
      "Ceyhan",
      "Çukurova",
      "Feke",
      "İmamoğlu",
      "Karaisalı",
      "Karataş",
      "Kozan",
      "Pozantı",
      "Saimbeyli",
      "Sarıçam",
      "Seyhan",
      "Tufanbeyli",
      "Yumurtalık",
      "Yüreğir",
    ],
  },
  {
    il: "Adıyaman",
    plaka: 2,
    ilceleri: [
      "Besni",
      "Çelikhan",
      "Gerger",
      "Gölbaşı",
      "Kahta",
      "Merkez",
      "Samsat",
      "Sincik",
      "Tut",
    ],
  },
  {
    il: "Afyonkarahisar",
    plaka: 3,
    ilceleri: [
      "Başmakçı",
      "Bayat",
      "Bolvadin",
      "Çay",
      "Çobanlar",
      "Dazkırı",
      "Dinar",
      "Emirdağ",
      "Evciler",
      "Hocalar",
      "İhsaniye",
      "İscehisar",
      "Kızılören",
      "Merkez",
      "Sandıklı",
      "Sinanpaşa",
      "Sultandağı",
      "Şuhut",
    ],
  },
  {
    il: "Ağrı",
    plaka: 4,
    ilceleri: [
      "Diyadin",
      "Doğubayazıt",
      "Eleşkirt",
      "Hamur",
      "Merkez",
      "Patnos",
      "Taşlıçay",
      "Tutak",
    ],
  },
  {
    il: "Amasya",
    plaka: 5,
    ilceleri: [
      "Göynücek",
      "Gümüşhacıköy",
      "Hamamözü",
      "Merkez",
      "Merzifon",
      "Suluova",
      "Taşova",
    ],
  },
  {
    il: "Ankara",
    plaka: 6,
    ilceleri: [
      "Altındağ",
      "Ayaş",
      "Bala",
      "Beypazarı",
      "Çamlıdere",
      "Çankaya",
      "Çubuk",
      "Elmadağ",
      "Güdül",
      "Haymana",
      "Kalecik",
      "Kızılcahamam",
      "Nallıhan",
      "Polatlı",
      "Şereflikoçhisar",
      "Yenimahalle",
      "Gölbaşı",
      "Keçiören",
      "Mamak",
      "Sincan",
      "Kazan",
      "Akyurt",
      "Etimesgut",
      "Evren",
      "Pursaklar",
    ],
  },
  {
    il: "Antalya",
    plaka: 7,
    ilceleri: [
      "Akseki",
      "Alanya",
      "Elmalı",
      "Finike",
      "Gazipaşa",
      "Gündoğmuş",
      "Kaş",
      "Korkuteli",
      "Kumluca",
      "Manavgat",
      "Serik",
      "Demre",
      "İbradı",
      "Kemer",
      "Aksu",
      "Döşemealtı",
      "Kepez",
      "Konyaaltı",
      "Muratpaşa",
    ],
  },
  {
    il: "Artvin",
    plaka: 8,
    ilceleri: [
      "Ardanuç",
      "Arhavi",
      "Merkez",
      "Borçka",
      "Hopa",
      "Şavşat",
      "Yusufeli",
      "Murgul",
    ],
  },
  {
    il: "Aydın",
    plaka: 9,
    ilceleri: [
      "Merkez",
      "Bozdoğan",
      "Efeler",
      "Çine",
      "Germencik",
      "Karacasu",
      "Koçarlı",
      "Kuşadası",
      "Kuyucak",
      "Nazilli",
      "Söke",
      "Sultanhisar",
      "Yenipazar",
      "Buharkent",
      "İncirliova",
      "Karpuzlu",
      "Köşk",
      "Didim",
    ],
  },
  {
    il: "Balıkesir",
    plaka: 10,
    ilceleri: [
      "Altıeylül",
      "Ayvalık",
      "Merkez",
      "Balya",
      "Bandırma",
      "Bigadiç",
      "Burhaniye",
      "Dursunbey",
      "Edremit",
      "Erdek",
      "Gönen",
      "Havran",
      "İvrindi",
      "Karesi",
      "Kepsut",
      "Manyas",
      "Savaştepe",
      "Sındırgı",
      "Gömeç",
      "Susurluk",
      "Marmara",
    ],
  },
  {
    il: "Bilecik",
    plaka: 11,
    ilceleri: [
      "Merkez",
      "Bozüyük",
      "Gölpazarı",
      "Osmaneli",
      "Pazaryeri",
      "Söğüt",
      "Yenipazar",
      "İnhisar",
    ],
  },
  {
    il: "Bingöl",
    plaka: 12,
    ilceleri: [
      "Merkez",
      "Genç",
      "Karlıova",
      "Kiğı",
      "Solhan",
      "Adaklı",
      "Yayladere",
      "Yedisu",
    ],
  },
  {
    il: "Bitlis",
    plaka: 13,
    ilceleri: [
      "Adilcevaz",
      "Ahlat",
      "Merkez",
      "Hizan",
      "Mutki",
      "Tatvan",
      "Güroymak",
    ],
  },
  {
    il: "Bolu",
    plaka: 14,
    ilceleri: [
      "Merkez",
      "Gerede",
      "Göynük",
      "Kıbrıscık",
      "Mengen",
      "Mudurnu",
      "Seben",
      "Dörtdivan",
      "Yeniçağa",
    ],
  },
  {
    il: "Burdur",
    plaka: 15,
    ilceleri: [
      "Ağlasun",
      "Bucak",
      "Merkez",
      "Gölhisar",
      "Tefenni",
      "Yeşilova",
      "Karamanlı",
      "Kemer",
      "Altınyayla",
      "Çavdır",
      "Çeltikçi",
    ],
  },
  {
    il: "Bursa",
    plaka: 16,
    ilceleri: [
      "Gemlik",
      "İnegöl",
      "İznik",
      "Karacabey",
      "Keles",
      "Mudanya",
      "Mustafakemalpaşa",
      "Orhaneli",
      "Orhangazi",
      "Yenişehir",
      "Büyükorhan",
      "Harmancık",
      "Nilüfer",
      "Osmangazi",
      "Yıldırım",
      "Gürsu",
      "Kestel",
    ],
  },
  {
    il: "Çanakkale",
    plaka: 17,
    ilceleri: [
      "Ayvacık",
      "Bayramiç",
      "Biga",
      "Bozcaada",
      "Çan",
      "Merkez",
      "Eceabat",
      "Ezine",
      "Gelibolu",
      "Gökçeada",
      "Lapseki",
      "Yenice",
    ],
  },
  {
    il: "Çankırı",
    plaka: 18,
    ilceleri: [
      "Merkez",
      "Çerkeş",
      "Eldivan",
      "Ilgaz",
      "Kurşunlu",
      "Orta",
      "Şabanözü",
      "Yapraklı",
      "Atkaracalar",
      "Kızılırmak",
      "Bayramören",
      "Korgun",
    ],
  },
  {
    il: "Çorum",
    plaka: 19,
    ilceleri: [
      "Alaca",
      "Bayat",
      "Merkez",
      "İskilip",
      "Kargı",
      "Mecitözü",
      "Ortaköy",
      "Osmancık",
      "Sungurlu",
      "Boğazkale",
      "Uğurludağ",
      "Dodurga",
      "Laçin",
      "Oğuzlar",
    ],
  },
  {
    il: "Denizli",
    plaka: 20,
    ilceleri: [
      "Acıpayam",
      "Buldan",
      "Çal",
      "Çameli",
      "Çardak",
      "Çivril",
      "Merkez",
      "Merkezefendi",
      "Pamukkale",
      "Güney",
      "Kale",
      "Sarayköy",
      "Tavas",
      "Babadağ",
      "Bekilli",
      "Honaz",
      "Serinhisar",
      "Baklan",
      "Beyağaç",
      "Bozkurt",
    ],
  },
  {
    il: "Diyarbakır",
    plaka: 21,
    ilceleri: [
      "Kocaköy",
      "Çermik",
      "Çınar",
      "Çüngüş",
      "Dicle",
      "Ergani",
      "Hani",
      "Hazro",
      "Kulp",
      "Lice",
      "Silvan",
      "Eğil",
      "Bağlar",
      "Kayapınar",
      "Sur",
      "Yenişehir",
      "Bismil",
    ],
  },
  {
    il: "Edirne",
    plaka: 22,
    ilceleri: [
      "Merkez",
      "Enez",
      "Havsa",
      "İpsala",
      "Keşan",
      "Lalapaşa",
      "Meriç",
      "Uzunköprü",
      "Süloğlu",
    ],
  },
  {
    il: "Elazığ",
    plaka: 23,
    ilceleri: [
      "Ağın",
      "Baskil",
      "Merkez",
      "Karakoçan",
      "Keban",
      "Maden",
      "Palu",
      "Sivrice",
      "Arıcak",
      "Kovancılar",
      "Alacakaya",
    ],
  },
  {
    il: "Erzincan",
    plaka: 24,
    ilceleri: [
      "Çayırlı",
      "Merkez",
      "İliç",
      "Kemah",
      "Kemaliye",
      "Refahiye",
      "Tercan",
      "Üzümlü",
      "Otlukbeli",
    ],
  },
  {
    il: "Erzurum",
    plaka: 25,
    ilceleri: [
      "Aşkale",
      "Çat",
      "Hınıs",
      "Horasan",
      "İspir",
      "Karayazı",
      "Narman",
      "Oltu",
      "Olur",
      "Pasinler",
      "Şenkaya",
      "Tekman",
      "Tortum",
      "Karaçoban",
      "Uzundere",
      "Pazaryolu",
      "Köprüköy",
      "Palandöken",
      "Yakutiye",
      "Aziziye",
    ],
  },
  {
    il: "Eskişehir",
    plaka: 26,
    ilceleri: [
      "Çifteler",
      "Mahmudiye",
      "Mihalıççık",
      "Sarıcakaya",
      "Seyitgazi",
      "Sivrihisar",
      "Alpu",
      "Beylikova",
      "İnönü",
      "Günyüzü",
      "Han",
      "Mihalgazi",
      "Odunpazarı",
      "Tepebaşı",
    ],
  },
  {
    il: "Gaziantep",
    plaka: 27,
    ilceleri: [
      "Araban",
      "İslahiye",
      "Nizip",
      "Oğuzeli",
      "Yavuzeli",
      "Şahinbey",
      "Şehitkamil",
      "Karkamış",
      "Nurdağı",
    ],
  },
  {
    il: "Giresun",
    plaka: 28,
    ilceleri: [
      "Alucra",
      "Bulancak",
      "Dereli",
      "Espiye",
      "Eynesil",
      "Merkez",
      "Görele",
      "Keşap",
      "Şebinkarahisar",
      "Tirebolu",
      "Piraziz",
      "Yağlıdere",
      "Çamoluk",
      "Çanakçı",
      "Doğankent",
      "Güce",
    ],
  },
  {
    il: "Gümüşhane",
    plaka: 29,
    ilceleri: ["Merkez", "Kelkit", "Şiran", "Torul", "Köse", "Kürtün"],
  },
  {
    il: "Hakkari",
    plaka: 30,
    ilceleri: ["Çukurca", "Merkez", "Şemdinli", "Yüksekova", "Derecik"],
  },
  {
    il: "Hatay",
    plaka: 31,
    ilceleri: [
      "Altınözü",
      "Arsuz",
      "Defne",
      "Dörtyol",
      "Hassa",
      "Antakya",
      "İskenderun",
      "Kırıkhan",
      "Payas",
      "Reyhanlı",
      "Samandağ",
      "Yayladağı",
      "Erzin",
      "Belen",
      "Kumlu",
    ],
  },
  {
    il: "Isparta",
    plaka: 32,
    ilceleri: [
      "Atabey",
      "Eğirdir",
      "Gelendost",
      "Merkez",
      "Keçiborlu",
      "Senirkent",
      "Sütçüler",
      "Şarkikaraağaç",
      "Uluborlu",
      "Yalvaç",
      "Aksu",
      "Gönen",
      "Yenişarbademli",
    ],
  },
  {
    il: "Mersin",
    plaka: 33,
    ilceleri: [
      "Anamur",
      "Erdemli",
      "Gülnar",
      "Mut",
      "Silifke",
      "Tarsus",
      "Aydıncık",
      "Bozyazı",
      "Çamlıyayla",
      "Akdeniz",
      "Mezitli",
      "Toroslar",
      "Yenişehir",
    ],
  },
  {
    il: "İstanbul",
    plaka: 34,
    ilceleri: [
      "Adalar",
      "Bakırköy",
      "Beşiktaş",
      "Beykoz",
      "Beyoğlu",
      "Çatalca",
      "Eyüp",
      "Fatih",
      "Gaziosmanpaşa",
      "Kadıköy",
      "Kartal",
      "Sarıyer",
      "Silivri",
      "Şile",
      "Şişli",
      "Üsküdar",
      "Zeytinburnu",
      "Büyükçekmece",
      "Kağıthane",
      "Küçükçekmece",
      "Pendik",
      "Ümraniye",
      "Bayrampaşa",
      "Avcılar",
      "Bağcılar",
      "Bahçelievler",
      "Güngören",
      "Maltepe",
      "Sultanbeyli",
      "Tuzla",
      "Esenler",
      "Arnavutköy",
      "Ataşehir",
      "Başakşehir",
      "Beylikdüzü",
      "Çekmeköy",
      "Esenyurt",
      "Sancaktepe",
      "Sultangazi",
    ],
  },
  {
    il: "İzmir",
    plaka: 35,
    ilceleri: [
      "Aliağa",
      "Bayındır",
      "Bergama",
      "Bornova",
      "Çeşme",
      "Dikili",
      "Foça",
      "Karaburun",
      "Karşıyaka",
      "Kemalpaşa",
      "Kınık",
      "Kiraz",
      "Menemen",
      "Ödemiş",
      "Seferihisar",
      "Selçuk",
      "Tire",
      "Torbalı",
      "Urla",
      "Beydağ",
      "Buca",
      "Konak",
      "Menderes",
      "Balçova",
      "Çiğli",
      "Gaziemir",
      "Narlıdere",
      "Güzelbahçe",
      "Bayraklı",
      "Karabağlar",
    ],
  },
  {
    il: "Kars",
    plaka: 36,
    ilceleri: [
      "Arpaçay",
      "Digor",
      "Kağızman",
      "Merkez",
      "Sarıkamış",
      "Selim",
      "Susuz",
      "Akyaka",
    ],
  },
  {
    il: "Kastamonu",
    plaka: 37,
    ilceleri: [
      "Abana",
      "Araç",
      "Azdavay",
      "Bozkurt",
      "Cide",
      "Çatalzeytin",
      "Daday",
      "Devrekani",
      "İnebolu",
      "Merkez",
      "Küre",
      "Taşköprü",
      "Tosya",
      "İhsangazi",
      "Pınarbaşı",
      "Şenpazar",
      "Ağlı",
      "Doğanyurt",
      "Hanönü",
      "Seydiler",
    ],
  },
  {
    il: "Kayseri",
    plaka: 38,
    ilceleri: [
      "Bünyan",
      "Develi",
      "Felahiye",
      "İncesu",
      "Pınarbaşı",
      "Sarıoğlan",
      "Sarız",
      "Tomarza",
      "Yahyalı",
      "Yeşilhisar",
      "Akkışla",
      "Talas",
      "Kocasinan",
      "Melikgazi",
      "Hacılar",
      "Özvatan",
    ],
  },
  {
    il: "KKTC",
    plaka: 82,
    ilceleri: ["Girne", "Lefkoşa", "Magosa"],
  },
  {
    il: "Kırklareli",
    plaka: 39,
    ilceleri: [
      "Babaeski",
      "Demirköy",
      "Merkez",
      "Kofçaz",
      "Lüleburgaz",
      "Pehlivanköy",
      "Pınarhisar",
      "Vize",
    ],
  },
  {
    il: "Kırşehir",
    plaka: 40,
    ilceleri: [
      "Çiçekdağı",
      "Kaman",
      "Merkez",
      "Mucur",
      "Akpınar",
      "Akçakent",
      "Boztepe",
    ],
  },
  {
    il: "Kocaeli",
    plaka: 41,
    ilceleri: [
      "Gebze",
      "Gölcük",
      "Kandıra",
      "Karamürsel",
      "Körfez",
      "Derince",
      "Başiskele",
      "Çayırova",
      "Darıca",
      "Dilovası",
      "İzmit",
      "Kartepe",
    ],
  },
  {
    il: "Konya",
    plaka: 42,
    ilceleri: [
      "Akşehir",
      "Beyşehir",
      "Bozkır",
      "Cihanbeyli",
      "Çumra",
      "Doğanhisar",
      "Ereğli",
      "Hadim",
      "Ilgın",
      "Kadınhanı",
      "Karapınar",
      "Kulu",
      "Sarayönü",
      "Seydişehir",
      "Yunak",
      "Akören",
      "Altınekin",
      "Derebucak",
      "Hüyük",
      "Karatay",
      "Meram",
      "Selçuklu",
      "Taşkent",
      "Ahırlı",
      "Çeltik",
      "Derbent",
      "Emirgazi",
      "Güneysınır",
      "Halkapınar",
      "Tuzlukçu",
      "Yalıhüyük",
    ],
  },
  {
    il: "Kütahya",
    plaka: 43,
    ilceleri: [
      "Altıntaş",
      "Domaniç",
      "Emet",
      "Gediz",
      "Merkez",
      "Simav",
      "Tavşanlı",
      "Aslanapa",
      "Dumlupınar",
      "Hisarcık",
      "Şaphane",
      "Çavdarhisar",
      "Pazarlar",
    ],
  },
  {
    il: "Malatya",
    plaka: 44,
    ilceleri: [
      "Akçadağ",
      "Arapgir",
      "Arguvan",
      "Darende",
      "Doğanşehir",
      "Hekimhan",
      "Merkez",
      "Pütürge",
      "Yeşilyurt",
      "Battalgazi",
      "Doğanyol",
      "Kale",
      "Kuluncak",
      "Yazıhan",
    ],
  },
  {
    il: "Manisa",
    plaka: 45,
    ilceleri: [
      "Akhisar",
      "Alaşehir",
      "Demirci",
      "Gördes",
      "Kırkağaç",
      "Kula",
      "Merkez",
      "Salihli",
      "Sarıgöl",
      "Saruhanlı",
      "Selendi",
      "Soma",
      "Şehzadeler",
      "Yunusemre",
      "Turgutlu",
      "Ahmetli",
      "Gölmarmara",
      "Köprübaşı",
    ],
  },
  {
    il: "Kahramanmaraş",
    plaka: 46,
    ilceleri: [
      "Afşin",
      "Andırın",
      "Dulkadiroğlu",
      "Onikişubat",
      "Elbistan",
      "Göksun",
      "Merkez",
      "Pazarcık",
      "Türkoğlu",
      "Çağlayancerit",
      "Ekinözü",
      "Nurhak",
    ],
  },
  {
    il: "Mardin",
    plaka: 47,
    ilceleri: [
      "Derik",
      "Kızıltepe",
      "Artuklu",
      "Merkez",
      "Mazıdağı",
      "Midyat",
      "Nusaybin",
      "Ömerli",
      "Savur",
      "Dargeçit",
      "Yeşilli",
    ],
  },
  {
    il: "Muğla",
    plaka: 48,
    ilceleri: [
      "Bodrum",
      "Datça",
      "Fethiye",
      "Köyceğiz",
      "Marmaris",
      "Menteşe",
      "Milas",
      "Ula",
      "Yatağan",
      "Dalaman",
      "Seydikemer",
      "Ortaca",
      "Kavaklıdere",
    ],
  },
  {
    il: "Muş",
    plaka: 49,
    ilceleri: ["Bulanık", "Malazgirt", "Merkez", "Varto", "Hasköy", "Korkut"],
  },
  {
    il: "Nevşehir",
    plaka: 50,
    ilceleri: [
      "Avanos",
      "Derinkuyu",
      "Gülşehir",
      "Hacıbektaş",
      "Kozaklı",
      "Merkez",
      "Ürgüp",
      "Acıgöl",
    ],
  },
  {
    il: "Niğde",
    plaka: 51,
    ilceleri: ["Bor", "Çamardı", "Merkez", "Ulukışla", "Altunhisar", "Çiftlik"],
  },
  {
    il: "Ordu",
    plaka: 52,
    ilceleri: [
      "Akkuş",
      "Altınordu",
      "Aybastı",
      "Fatsa",
      "Gölköy",
      "Korgan",
      "Kumru",
      "Mesudiye",
      "Perşembe",
      "Ulubey",
      "Ünye",
      "Gülyalı",
      "Gürgentepe",
      "Çamaş",
      "Çatalpınar",
      "Çaybaşı",
      "İkizce",
      "Kabadüz",
      "Kabataş",
    ],
  },
  {
    il: "Rize",
    plaka: 53,
    ilceleri: [
      "Ardeşen",
      "Çamlıhemşin",
      "Çayeli",
      "Fındıklı",
      "İkizdere",
      "Kalkandere",
      "Pazar",
      "Merkez",
      "Güneysu",
      "Derepazarı",
      "Hemşin",
      "İyidere",
    ],
  },
  {
    il: "Sakarya",
    plaka: 54,
    ilceleri: [
      "Akyazı",
      "Geyve",
      "Hendek",
      "Karasu",
      "Kaynarca",
      "Sapanca",
      "Kocaali",
      "Pamukova",
      "Taraklı",
      "Ferizli",
      "Karapürçek",
      "Söğütlü",
      "Adapazarı",
      "Arifiye",
      "Erenler",
      "Serdivan",
    ],
  },
  {
    il: "Samsun",
    plaka: 55,
    ilceleri: [
      "Alaçam",
      "Bafra",
      "Çarşamba",
      "Havza",
      "Kavak",
      "Ladik",
      "Terme",
      "Vezirköprü",
      "Asarcık",
      "Ondokuzmayıs",
      "Salıpazarı",
      "Tekkeköy",
      "Ayvacık",
      "Yakakent",
      "Atakum",
      "Canik",
      "İlkadım",
    ],
  },
  {
    il: "Siirt",
    plaka: 56,
    ilceleri: [
      "Baykan",
      "Eruh",
      "Kurtalan",
      "Pervari",
      "Merkez",
      "Şirvan",
      "Tillo",
    ],
  },
  {
    il: "Sinop",
    plaka: 57,
    ilceleri: [
      "Ayancık",
      "Boyabat",
      "Durağan",
      "Erfelek",
      "Gerze",
      "Merkez",
      "Türkeli",
      "Dikmen",
      "Saraydüzü",
    ],
  },
  {
    il: "Sivas",
    plaka: 58,
    ilceleri: [
      "Divriği",
      "Gemerek",
      "Gürün",
      "Hafik",
      "İmranlı",
      "Kangal",
      "Koyulhisar",
      "Merkez",
      "Suşehri",
      "Şarkışla",
      "Yıldızeli",
      "Zara",
      "Akıncılar",
      "Altınyayla",
      "Doğanşar",
      "Gölova",
      "Ulaş",
    ],
  },
  {
    il: "Tekirdağ",
    plaka: 59,
    ilceleri: [
      "Çerkezköy",
      "Çorlu",
      "Ergene",
      "Hayrabolu",
      "Malkara",
      "Muratlı",
      "Saray",
      "Süleymanpaşa",
      "Kapaklı",
      "Şarköy",
      "Marmaraereğlisi",
    ],
  },
  {
    il: "Tokat",
    plaka: 60,
    ilceleri: [
      "Almus",
      "Artova",
      "Erbaa",
      "Niksar",
      "Reşadiye",
      "Merkez",
      "Turhal",
      "Zile",
      "Pazar",
      "Yeşilyurt",
      "Başçiftlik",
      "Sulusaray",
    ],
  },
  {
    il: "Trabzon",
    plaka: 61,
    ilceleri: [
      "Akçaabat",
      "Araklı",
      "Arsin",
      "Çaykara",
      "Maçka",
      "Of",
      "Ortahisar",
      "Sürmene",
      "Tonya",
      "Vakfıkebir",
      "Yomra",
      "Beşikdüzü",
      "Şalpazarı",
      "Çarşıbaşı",
      "Dernekpazarı",
      "Düzköy",
      "Hayrat",
      "Köprübaşı",
    ],
  },
  {
    il: "Tunceli",
    plaka: 62,
    ilceleri: [
      "Çemişgezek",
      "Hozat",
      "Mazgirt",
      "Nazımiye",
      "Ovacık",
      "Pertek",
      "Pülümür",
      "Merkez",
    ],
  },
  {
    il: "Şanlıurfa",
    plaka: 63,
    ilceleri: [
      "Akçakale",
      "Birecik",
      "Bozova",
      "Ceylanpınar",
      "Eyyübiye",
      "Halfeti",
      "Haliliye",
      "Hilvan",
      "Karaköprü",
      "Siverek",
      "Suruç",
      "Viranşehir",
      "Harran",
    ],
  },
  {
    il: "Uşak",
    plaka: 64,
    ilceleri: ["Banaz", "Eşme", "Karahallı", "Sivaslı", "Ulubey", "Merkez"],
  },
  {
    il: "Van",
    plaka: 65,
    ilceleri: [
      "Başkale",
      "Çatak",
      "Erciş",
      "Gevaş",
      "Gürpınar",
      "İpekyolu",
      "Muradiye",
      "Özalp",
      "Tuşba",
      "Bahçesaray",
      "Çaldıran",
      "Edremit",
      "Saray",
    ],
  },
  {
    il: "Yozgat",
    plaka: 66,
    ilceleri: [
      "Akdağmadeni",
      "Boğazlıyan",
      "Çayıralan",
      "Çekerek",
      "Sarıkaya",
      "Sorgun",
      "Şefaatli",
      "Yerköy",
      "Merkez",
      "Aydıncık",
      "Çandır",
      "Kadışehri",
      "Saraykent",
      "Yenifakılı",
    ],
  },
  {
    il: "Zonguldak",
    plaka: 67,
    ilceleri: ["Çaycuma", "Devrek", "Ereğli", "Merkez", "Alaplı", "Gökçebey"],
  },
  {
    il: "Aksaray",
    plaka: 68,
    ilceleri: [
      "Ağaçören",
      "Eskil",
      "Gülağaç",
      "Güzelyurt",
      "Merkez",
      "Ortaköy",
      "Sarıyahşi",
    ],
  },
  {
    il: "Bayburt",
    plaka: 69,
    ilceleri: ["Merkez", "Aydıntepe", "Demirözü"],
  },
  {
    il: "Karaman",
    plaka: 70,
    ilceleri: [
      "Ermenek",
      "Merkez",
      "Ayrancı",
      "Kazımkarabekir",
      "Başyayla",
      "Sarıveliler",
    ],
  },
  {
    il: "Kırıkkale",
    plaka: 71,
    ilceleri: [
      "Delice",
      "Keskin",
      "Merkez",
      "Sulakyurt",
      "Bahşili",
      "Balışeyh",
      "Çelebi",
      "Karakeçili",
      "Yahşihan",
    ],
  },
  {
    il: "Batman",
    plaka: 72,
    ilceleri: ["Merkez", "Beşiri", "Gercüş", "Kozluk", "Sason", "Hasankeyf"],
  },
  {
    il: "Şırnak",
    plaka: 73,
    ilceleri: [
      "Beytüşşebap",
      "Cizre",
      "İdil",
      "Silopi",
      "Merkez",
      "Uludere",
      "Güçlükonak",
    ],
  },
  {
    il: "Bartın",
    plaka: 74,
    ilceleri: ["Merkez", "Kurucaşile", "Ulus", "Amasra"],
  },
  {
    il: "Ardahan",
    plaka: 75,
    ilceleri: ["Merkez", "Çıldır", "Göle", "Hanak", "Posof", "Damal"],
  },
  {
    il: "Iğdır",
    plaka: 76,
    ilceleri: ["Aralık", "Merkez", "Tuzluca", "Karakoyunlu"],
  },
  {
    il: "Yalova",
    plaka: 77,
    ilceleri: [
      "Merkez",
      "Altınova",
      "Armutlu",
      "Çınarcık",
      "Çiftlikköy",
      "Termal",
    ],
  },
  {
    il: "Karabük",
    plaka: 78,
    ilceleri: [
      "Eflani",
      "Eskipazar",
      "Merkez",
      "Ovacık",
      "Safranbolu",
      "Yenice",
    ],
  },
  {
    il: "Kilis",
    plaka: 79,
    ilceleri: ["Merkez", "Elbeyli", "Musabeyli", "Polateli"],
  },
  {
    il: "Osmaniye",
    plaka: 80,
    ilceleri: [
      "Bahçe",
      "Kadirli",
      "Merkez",
      "Düziçi",
      "Hasanbeyli",
      "Sumbas",
      "Toprakkale",
    ],
  },
  {
    il: "Düzce",
    plaka: 81,
    ilceleri: [
      "Akçakoca",
      "Merkez",
      "Yığılca",
      "Cumayeri",
      "Gölyaka",
      "Çilimli",
      "Gümüşova",
      "Kaynaşlı",
    ],
  },
];
export default CitiesAndDistricts;

//Fonksiyonlar
const getCities = () => {
  const arr = [];
  CitiesAndDistricts.map((single) => {
    const city = {
      id: single.plaka,
      name: single.il,
    };
    arr.push(city);
  });

  return arr;
};

const getDistrictsByCityID = (cityID) => {
  const arr = [];

  cityID &&
    [...CitiesAndDistricts.filter((city) => city.plaka == cityID)][0][
      "ilceleri"
    ]
      .sort((a, b) => {
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      })
      .map((single) => {
        const district = { name: single };
        arr.push(district);
      });

  return arr;
};

export { getCities, getDistrictsByCityID };
