import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";
import Axios from "axios";
import { TimerOff, Launch } from "@material-ui/icons";
import { TextField, MenuItem } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { toast } from "react-toastify";
import { createEventId } from "./event-utils";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import Input from "../../../theme/CustomMUI/Input";
import styled from "styled-components";
import Button from "../../../theme/Button";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import { Column, Grid } from "../../../theme/Grid";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import TimeSelect from "../../../theme/TimeSelect";
import Select from "../../../theme/CustomMUI/Select";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";
import CenterDialogModal from "../../../components/Alerts/CenterDialogModal";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";

import HomeChcekGreen from "../../../assets/images/svg/home-check-green.svg";
import ApprovedBlue from "../../../assets/images/svg/approved-blue.svg";
import NotComeRed from "../../../assets/images/svg/not-come-red.svg";
import WaitingOrange from "../../../assets/images/svg/waiting-orange.svg";
import ProcessPurple from "../../../assets/images/svg/process-purple.svg";
import Quit from "../../../assets/images/svg/quit.svg";

import HomeChcek from "../../../assets/images/svg/home-check.svg";
import Approved from "../../../assets/images/svg/approved.svg";
import NotCome from "../../../assets/images/svg/not-come.svg";
import Waiting from "../../../assets/images/svg/waiting.svg";
import Process from "../../../assets/images/svg/process.svg";

import Loader from "../../../assets/images/circular.gif";
import Switch from "@material-ui/core/Switch";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import { AccessAlarm } from "@material-ui/icons";

let filter_data = [];
let appointment_data = [];
let staff_event = [];

const MonthAppointment = (props) => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [appointment, setAppointment] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [selected_clock, setSelectedClock] = useState(null);
  const [appointment_detail_modal_clock, setAppointmentDetailModalClock] =
    useState(false);
  const [create_appointment_modal_clock, setCreateAppointmentModalClock] =
    useState(false);

  const [appointment_detail_modal, setAppointmentDetailModal] = useState(false);
  const [cancelAppointmentDialog, setCancelAppointmentDialog] = useState(false);
  const [time_upgrade, setTimeUpgrade] = useState(false);
  const [open_update_appointment_dialog, setOpenUpdateAppointmentDialog] =
    useState(false);
  const [create_appointment_modal, setCreateAppointmentModal] = useState(false);
  const [insertData, setInsertData] = useState({
    selected_staff: null,
    selected_service: null,
    selected_package: null,
    selected_customer: null,
    selected_date: new Date().toISOString().split("T")[0],
    selected_time_clock_off_start: null,
    selected_time_clock_off_end: null,
    selected_note: "",
    services_by_staff: [],
    selected_description: "",
    selected_time: null,
    hours: [...BetweenTimeGenerator(context.state.company_shift)],
  });
  // const [services, setServices] = useState([]);
  const [_staffs, setStaffs] = useState([]);

  const [customer_add_modal, setCustomerAddModal] = useState(false);
  const [
    create_appointment_modal_collective,
    setCreateAppointmentModalCollective,
  ] = useState(false);
  const [dialogModal, setDialogModal] = useState({
    isOpen: false,
    message: "",
    type: "warning",
  });
  const [new_appointment, setNewAppointment] = useState({
    services: {},
  });
  const [send_sms, setSendSMS] = useState(true);
  const [turn_off_all_day, setTurnOffAllDay] = useState(false);
  const [repetition_frequency_modal, setRepetitionFrequencyModal] =
    useState(false);
  const [repetition] = useState([
    {
      id: 1,
      name: "Her gün",
      count: 1,
    },
    {
      id: 2,
      name: "2 günde bir",
      count: 2,
    },
    {
      id: 3,
      name: "3 günde bir",
      count: 3,
    },
    {
      id: 4,
      name: "4 günde bir",
      count: 4,
    },
    {
      id: 5,
      name: "5 günde bir",
      count: 5,
    },
    {
      id: 6,
      name: "Haftada bir",
      count: 7,
    },
    {
      id: 7,
      name: "2 haftada bir",
      count: 14,
    },
    {
      id: 8,
      name: "3 haftada bir",
      count: 21,
    },
    {
      id: 9,
      name: "4 haftada bir",
      count: 28,
    },
    {
      id: 10,
      name: "Her ay",
      count: 30,
    },
    {
      id: 11,
      name: "45 günde bir",
      count: 45,
    },
    {
      id: 12,
      name: "2 ayda bir",
      count: 60,
    },
    {
      id: 13,
      name: "3 ayda bir",
      count: 90,
    },
    {
      id: 14,
      name: "6 ayda bir",
      count: 180,
    },
  ]);
  const [update_appoint_disable, setUpdateAppointDisable] = useState(true);
  const [hour_detail, setHourDetail] = useState({
    start_hour: context.state.company_shift.start_hour,
    end_hour: context.state.company_shift.end_hour,
  });
  const [change_end_hour, setChangeEndHour] = useState(false);
  const [appointment_note, setAppointmentNote] = useState(null);
  const [selected_repetition, setSelectedRepetition] = useState(null);
  const [repetition_count, setRepetitionCount] = useState(0);
  const [online_appointment, setOnlineAppointment] = useState(false);
  const [recurenct_modal, setRecurrentModal] = useState(false);
  const [appointment_date, setAppointmentDate] = useState(new Date());
  // Aylık randevu görünümünü tekrar render etmek için kullanılan state (useEffect parametresi)
  const [count, setCount] = useState(0);
  const [serviceOrPackage, setServiceOrPackage] = useState(1);
  const [payloadObj, setPayloadObj] = useState([]);
  const [openAppointmentPackage, setOpenAppointmentPackage] = useState(false);
  const [cancelAppointmentArray, setCancelAppointmentArray] = useState([]);
  const [closedTime, setClosedTime] = useState([]);
  //TODO : Month Appointment 五条悟
  const getMonthAppointments = async () => {
    const appoinment_array = [];
    const event_array = [];
    await Axios.post(`${context.api_endpoint}/company/appointments/week`, {
      start_date: currentDate,
      choice: 1,
      staff_id: [],
    })
      .then(({ data }) => {
        // month appointment
        setCount(1);
        for (let i = 0; i < data.data.length; i++) {
          for (let j = 0; j < data.data[i].appointments.length; j++) {
            let event = {
              id: data.data[i].appointments[j]
                ? data.data[i].appointments[j].id
                : createEventId(),
              title: data.data[i].appointments[j]
                ? data.data[i].appointments[j].customer?.full_name
                : "",
              start:
                data.data[i].appointments[j].appointment_start_date.split(
                  "+"
                )[0],
              end: data.data[i].appointments[j].appointment_end_date.split(
                "+"
              )[0],
              eventColor: data.data[i].appointments[j]
                ? data.data[i].appointments[j].staff?.color
                : "green",
              note: data.data[i].appointments[j]
                ? data.data[i].appointments[j].note
                : "",
              staff_name: data.data[i].appointments[j]
                ? data.data[i].appointments[j].staff?.full_name
                : "",
              staff_id: data.data[i].appointments[j]
                ? data.data[i].appointments[j].staff?.id
                : "",
              service_name: data.data[i].appointments[j]
                ? data.data[i].appointments[j].service?.name
                : "",
              state: data.data[i].appointments[j]
                ? data.data[i].appointments[j].customer_state
                : "",
              customer_phone: data.data[i].appointments[j]
                ? data.data[i].appointments[j].customer.phone
                : "",
            };
            appoinment_array.push(data.data[i].appointments[j]);
            event_array.push(event);
          }
        }
        setAppointment([...appoinment_array]);
        setCurrentEvents([...event_array]);
        setLoaded(true);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  //TODO: Get Close Appointment Clock Time
  const getAppointmentClockTime = async () => {
    let closed_time = [];
    await Axios.get(
      `${context.api_endpoint}/company/appointment/staff/active/date`,
      {
        params: {
          date: `${currentDate}T00:00:00.000Z`,
          choice: 2,
        },
      }
    )
      .then(({ data }) => {
        for (let i = 0; i < data.data.length; i++) {
          for (
            let j = 0;
            j < data.data[i].staff_availability_per_day.length;
            j++
          ) {
            let eventClose = {
              id: data.data[i].staff_availability_per_day[j]
                ? data.data[i].staff_availability_per_day[j].id
                : createEventId(),
              title: data.data[i].staff_availability_per_day[j]
                ? data.data[i].staff_availability_per_day[j].staff_id
                : null,
              start:
                data.data[i].date +
                "T" +
                data.data[i].staff_availability_per_day[j].start_hour.split(
                  "T"
                )[1],
              end:
                data.data[i].date +
                "T" +
                data.data[i].staff_availability_per_day[j].end_hour.split(
                  "T"
                )[1],
              start_hour: data.data[i].staff_availability_per_day[j]
                ? data.data[i].staff_availability_per_day[j].start_hour
                : null,
              end_hour: data.data[i].staff_availability_per_day[j]
                ? data.data[i].staff_availability_per_day[j].end_hour
                : null,
              eventColor: "black",
              is_recurrent: data.data[i].staff_availability_per_day[j]
                ? data.data[i].staff_availability_per_day[j].is_recurrent
                : false,
              note: data.data[i].staff_availability_per_day[j]
                ? data.data[i].staff_availability_per_day[j].note
                : null,
            };
            //currentEvents.push(eventClose);
            closed_time.push(eventClose);
          }
        }
        setClosedTime([...closed_time]);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Close Appointment Delete
  const deleteAppointmentClockTime = async (clock_id) => {
    setLoaded(false);
    await Axios.delete(
      `${context.api_endpoint}/company/appointment/staff/active/date/${clock_id}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success(data.data);
          setAppointmentDetailModalClock(false);
          getAppointmentClockTime();
          getMonthAppointments();
        } else {
          toast.error(data.message);
          setAppointmentDetailModalClock(false);
          getAppointmentClockTime();
          getMonthAppointments();
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
          setAppointmentDetailModalClock(false);

          getAppointmentClockTime();
        } else {
          toast.warning(e.response.data.message);
          setAppointmentDetailModalClock(false);

          getAppointmentClockTime();
        }
      })
      .finally(() => setLoaded(true));
  };

  //TODO: Close modal when user clicks outside of it
  const closeAppointmentClockTime = async () => {
    let appointment_date_clock_off_start = new Date(insertData.selected_date);
    let appointment_time_start =
      insertData.selected_time_clock_off_start.split(":");
    appointment_date_clock_off_start = `${moment(
      appointment_date_clock_off_start
    ).format("YYYY-MM-DD")}T${appointment_time_start[0]}:${appointment_time_start[1]
      }:00Z`;

    let appointment_date_clock_off_end = new Date(insertData.selected_date);
    let appointment_time_end =
      insertData.selected_time_clock_off_end.split(":");
    appointment_date_clock_off_end = `${moment(
      appointment_date_clock_off_end
    ).format("YYYY-MM-DD")}T${appointment_time_end[0]}:${appointment_time_end[1]
      }:00Z`;

    await Axios.post(
      `${context.api_endpoint}/company/appointment/staff/active/date`,
      {
        staff_id: insertData.selected_staff.id,
        start_hour: appointment_date_clock_off_start,
        end_hour: appointment_date_clock_off_end,
        all_hours_closed: turn_off_all_day === false ? false : true,
        day_closed: false,
        note: insertData.selected_note ?? "",
        repetition_frequency: selected_repetition
          ? selected_repetition.count
          : 0,
        repetition_count: repetition_count ? parseInt(repetition_count) : 0,
        is_platform_included: online_appointment === true ? true : false,
      }
    )
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success("Başarıyla eklendi.");
          setCreateAppointmentModalClock(false);
          getAppointmentClockTime();
        } else {
          toast.error("Bir hata oluştu");
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO GET Staff
  const beforeInsertRequiredFields = async () => {
    await Axios.get(`${context.api_endpoint}/company/appointments/index2`)
      .then(({ data }) => {
        const staffs = [
          ...data.data.map((staff) => {
            staff.services.forEach((service, index) => {
              if (
                service.service.category_name !== null &&
                service.service.category_name.toLowerCase().includes("erkek")
              ) {
                service.service.name += " (E)";
              } else if (
                service.service.category_name !== null &&
                service.service.category_name.toLowerCase().includes("kadın")
              ) {
                service.service.name += " (K)";
              }
            });
            return staff;
          }),
        ];
        // setServices([...data.data.services]);
        setStaffs([
          ...staffs
            .map((staff) => {
              return staff;
            })
            .filter((staff) => staff !== null),
        ]);
        staff_event.push(
          ...staffs
            .map((staff) => {
              return staff;
            })
            .filter((staff) => staff !== null)
        );
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : İnsert Appointment
  const insertAppointment = () => {
    let appointment_time = insertData.selected_time
      ? insertData.selected_time.split(":")
      : [props.hour_detail.start_hour.split(":")[0], "00"];
    let appointment_date = `${moment(insertData.selected_date).format(
      "YYYY-MM-DD"
    )}T${appointment_time[0]}:${appointment_time[1]}:00Z`;

    Axios.post(`${context.api_endpoint}/company/appointment/insert`, {
      staff_id: insertData.selected_staff.id,
      customer_id: insertData.selected_customer.id,
      [serviceOrPackage === 1 ? "service_id" : "packet_id"]:
        serviceOrPackage === 1
          ? insertData.selected_service.id
          : insertData.selected_package.id,
      send_sms: insertData.selected_customer
        ? insertData.selected_customer.send_sms === false
          ? false
          : true
        : false,
      appointment_date,
      note: insertData.selected_note,
      is_web: true,
    })
      .then(async (response) => {
        if (response.status === 201) {
          setInsertData({
            ...insertData,
            selected_staff: null,
            selected_customer: null,
            selected_service: null,
            selected_package: null,
            selected_note: "",
            selected_time: null,
            selected_date: null,
          });
          toast.success(context.t(`appointments.insertToast`));
          await getMonthAppointments();
          setCreateAppointmentModal(false);
          setLoaded(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : İnsert Appointment
  const bulkInsertAppointment = () => {
    let appointment_time = insertData.selected_time
      ? insertData.selected_time.split(":")
      : ["09", "00"];
    let appointment_date = `${moment(insertData.selected_date).format(
      "YYYY-MM-DD"
    )}T${appointment_time[0]}:${appointment_time[1]}:00Z`;

    Axios.post(`${context.api_endpoint}/company/appointment/bulk/insert`, {
      customer_id: insertData.selected_customer.id,
      services: [
        ...Object.keys(new_appointment.services)
          .filter((key) => new_appointment.services[key] !== undefined)
          .map((item) =>
            Object.assign({ ...new_appointment.services[item] }, {})
          ),
      ],
      send_sms: insertData.selected_customer
        ? insertData.selected_customer.send_sms === false
          ? false
          : true
        : false,
      appointment_date,
      note: insertData.selected_note,
      is_web: true,
    })
      .then(async (response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.insertToast`));
          await getMonthAppointments();
          setCreateAppointmentModal(false);
          setLoaded(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Update Appointment
  const updateAppoint = async (apoint_id) => {
    const time = parseInt(time_upgrade);

    const time_hours =
      parseInt(
        Math.floor(
          moment(filter_data[0].appointment_end_date)
            .format("HH:mm")
            .split(":")[0] * 60
        ) +
        parseInt(
          moment(filter_data[0].appointment_end_date)
            .format("HH:mm")
            .split(":")[1]
        )
      ) -
      parseInt(
        Math.floor(
          moment(filter_data[0].appointment_start_date)
            .format("HH:mm")
            .split(":")[0] * 60
        ) +
        parseInt(
          moment(filter_data[0].appointment_start_date)
            .format("HH:mm")
            .split(":")[1]
        )
      );

    let time_appointment_start = new Date(appointment_date);
    time_appointment_start = `${moment(time_appointment_start).format(
      "YYYY-MM-DD"
    )}T${hour_detail.start_hour}:00Z`;

    let time_appointment_end = new Date(appointment_date);
    time_appointment_end = `${moment(time_appointment_end).format(
      "YYYY-MM-DD"
    )}T${hour_detail.end_hour}:00Z`;

    if (hour_detail.start_hour > hour_detail.end_hour) {
      toast.warning("Bitiş saati Başlangıç saatinden küçük olamaz!");
    } else {
      await Axios.put(
        `${context.api_endpoint}/company/appointment/update`,
        isNaN(time)
          ? {
            id: apoint_id ? apoint_id : 1,
            appointment_start_date: time_appointment_start,
            appointment_end_date: time_appointment_end,
            send_sms: send_sms,
            note: appointment_note,
          }
          : {
            id: apoint_id ? apoint_id : 1,
            appointment_start_date: time_appointment_start,
            send_sms: send_sms,
            appointment_time: time ? time : time_hours,
            note: appointment_note,
          }
      )
        .then(({ data }) => {
          if (data.status === 201) {
            setAppointmentDetailModal(false);
            setTimeUpgrade(null);
            getMonthAppointments();
            toast.success(
              this.context.t(
                `appointments.updateAppointment.updateAppointmentSuccessToast`
              )
            );
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }
  };

  //TODO : Search Customer
  const searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    })
      .then(({ data }) => {
        arr = [...data.data.records];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item?.full_name} (*** ${item.phone.slice(6, 11)})`,
        send_sms: item.send_sms,
        description: item.description,
      };
    });
  };

  const searchServicesByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/services/filter`, {
      params: {
        id: insertData.selected_staff ? insertData.selected_staff.id : null,
        key: key === " " ? "" : key,
        paginate: 1,
      },
    })
      .then(({ data }) => {
        const services = data.data.filter(
          (m) => m && m.is_passive !== undefined && m.is_passive === false
        ).map((service) => {
          if (
            service.category_name !== null &&
            service.category_name.toLowerCase().includes("erkek")
          ) {
            service.name += " (E)";
          } else if (
            service.category_name !== null &&
            service.category_name.toLowerCase().includes("kadın")
          ) {
            service.name += " (K)";
          }
          return service;
        });
        arr = [...services];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr;
  };

  const searchPackagesById = async (key) => {
    let arr = [];
    console.log("key", key);
    await Axios.get(
      `${context.api_endpoint}/company/packet/byuser/${insertData.selected_staff ? insertData.selected_staff.id : null
      }`,
      {
        params: {
          page: 1,
        },
      }
    )
      .then(({ data }) => {
        console.log("packagesData: ", data);
        const packages = data.data
          .filter((m) => m.packet !== null)
          .map((p) => {
            console.log("p", p);
            if (
              p.packet.name !== null &&
              p.packet.name.toLowerCase().includes("erkek")
            ) {
              p.packet.name += " (E)";
            } else if (
              p.packet.name !== null &&
              p.packet.name.toLowerCase().includes("kadın")
            ) {
              p.packet.name += " (K)";
            }
            return p.packet;
          });
        arr = [...packages];
      })
      .catch((e) => {
        console.log(e);
      });
    return arr;
  };

  //TODO: Handle open of warning modal
  const handleOpen = (message, type) => {
    setDialogModal({
      isOpen: true,
      message: message,
      type: type,
    });
  };
  //TODO: Handle close of warning modal
  const handleClose = () => {
    setDialogModal({
      ...dialogModal,
      isOpen: false,
    });
  };

  //TODO : transfer to a function
  const transferAppointment = async (appoint_id) => {
    const originalDate = new Date(appointment_data[0].appointment_start_date); // "2024-02-25T06:30:00.000Z"
    const newDate = new Date(originalDate.getTime() + 3 * 60 * 60 * 1000); // Üç saat ekleyerek yeni tarihi oluşturuyoruz
    Axios.post(`${context.api_endpoint}/company/appointment/transfer`, {
      id: appoint_id,
      staff_id: appointment_data[0].staff.id,
      appointment_date: newDate,
      send_sms: true,
    })
      .then(async () => {
        toast.success(
          context.t(
            `appointments.updateAppointment.updateAppointmentSuccessToast`
          )
        );
        getAppointmentClockTime();
        getMonthAppointments();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : go to receipt detail page
  const openReceiptByAppointment = (appointment_id) => {
    console.log("appointment", filter_data[0]);
    Axios.post(
      `${context.api_endpoint}/company/receipt/insert/by/appointment`,
      filter_data[0].packet !== null
        ? {
          appointment_id,
          period_day_between: payloadObj,
        }
        : {
          appointment_id,
        }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`dashboard.openReceiptOnSuccessToast`));
          history.push(`/receipts/detail/${response.data.data.id}`);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : Customer state change to cancel
  const updateAppointmentState = (appointmentId, customerState) => {
    Axios.put(`${context.api_endpoint}/company/appointment/customer/state`, {
      appointment_id: appointmentId,
      customer_state: customerState,
    })
      .then((response) => {
        if (response.status === 200) {
          getMonthAppointments();
          toast.success(
            context.t(
              `appointments.detail.appointmentStatus.changeStatusSuccessToast`
            )
          );
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // //TODO : time upgrade modal
  // const upgradeAppointmentTime = (timeId) => {
  //   const time = parseInt(time_upgrade);
  //   Axios.post(`${context.api_endpoint}/company/appointment/update/enddate`, {
  //     appointment_id: timeId ?? null,
  //     time,
  //   })
  //     .then(() => {
  //       setAppointmentDetailModal(false);
  //       setTimeUpgrade(null);
  //       toast.success(
  //         context.t(`appointments.detail.appointmentalAlertMessage`)
  //       );
  //     })
  //     .catch((e) => {
  //       if (e.response.status === 404) {
  //         toast.error(e.response.data.message);
  //       } else {
  //         toast.warning(e.response.data.message);
  //       }
  //     });
  // };

  //TODOD: Cancel Appointment
  const cancelAppointment = (appointment_id) => {
    Axios.post(`${context.api_endpoint}/company/appointment/cancel`, {
      appointment_id,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.deleteToast`));
          getMonthAppointments();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        getAppointmentClockTime();
        getMonthAppointments();
        beforeInsertRequiredFields();
      });
  };

  //TODO: Insert Random Customer
  const insertRandomCustomer = () => {
    Axios.post(`${context.api_endpoint}/company/customer/insert/walkin`)
      .then((response) => {
        // console.log(response)
        if (response.status === 201) {
          // console.log(response.data.data.id)
          setInsertData({
            ...insertData,
            selected_customer: response.data.data.id,
          });
        }
      })
      .catch((e) => {
        // console.log(e.response.data.message)
        toast.warning(e.response.data.message);
      });
  };
  const addServiceInPackageSale = () => {
    setNewAppointment({
      ...new_appointment,
      services: {
        ...new_appointment.services,
        [Object.keys(new_appointment.services).length + 1]: {
          service_id: null,
          staff_id: context.state.user.id,
        },
      },
    });
  };

  const deleteServiceInPackageSale = (key) => {
    setNewAppointment({
      ...new_appointment,
      services: {
        ...new_appointment.services,
        [key]: undefined,
      },
    });
  };

  const fieldChanger = (section, key, payload) => {
    setNewAppointment({
      ...new_appointment,
      [section]: {
        ...new_appointment[section],
        [key]: {
          ...new_appointment[section][key],
          ...payload,
        },
      },
    });
  };

  //TODO : appointment detail modal
  const handleEventClick = (clickInfo) => {
    const color = clickInfo.event.extendedProps.eventColor;
    const event_id = parseInt(clickInfo.event.id);
    const staff_id = parseInt(clickInfo.event.title);
    if (color === "black") {
      setSelectedClock(
        ...closedTime.filter((item) => {
          return item.id === event_id;
        })
      );
      setInsertData({
        ...insertData,
        selected_staff: _staffs.filter((item) => {
          return item.id === staff_id;
        })[0],
      });
      setAppointmentDetailModalClock(true);
    } else {
      filter_data = appointment.filter(
        (appointment) => appointment.id === event_id
      );
      setHourDetail({
        start_hour: moment(filter_data[0].appointment_start_date)
          .tz("Europe/Istanbul")
          .format("HH:mm"),
        end_hour: moment(filter_data[0].appointment_end_date)
          .tz("Europe/Istanbul")
          .format("HH:mm"),
      });
      setAppointmentDate(
        moment(filter_data[0].appointment_start_date)
          .tz("Europe/Istanbul")
          .format("YYYY-MM-DD HH:mm")
      );
      setAppointmentDetailModal(true);
    }
  };

  //TODO : appointment transfer modal
  const handleTransferAppointment = (appointInfo) => {
    if (isNaN(appointInfo.event._def.title)) {
      const appoint_id = parseInt(appointInfo.event.id);
      appointment_data = appointment.filter(
        (appoint) => appoint.id === appoint_id
      );
      appointment_data[0].appointment_start_date = appointInfo.event.start;
      setOpenUpdateAppointmentDialog(true);
    } else {
      toast.warn("Kapalı saatlerin transferi yapılamaz.");
    }
  };

  //TODO : appointment insert to calendar event
  const handleDateSelect = (selectInfo) => {
    setInsertData({
      ...insertData,
      selected_date: new Date(selectInfo.startStr),
    });
    setCreateAppointmentModal(true);
  };

  //TODO : useEffect
  useEffect(() => {
    getMonthAppointments();
    beforeInsertRequiredFields();
    getAppointmentClockTime();
  }, [currentDate, count]);

  useEffect(() => {
    if (insertData.selected_package?.is_passive) {
      toast.warn(context.t("appointments.create.select_package_alert"));
      setInsertData((prev) => ({
        ...prev,
        selected_package: null,
      }));
    }
  }, [insertData.selected_package]);
  return (
    <>
      {/* Calendar Component */}
      {loaded ? (
        <div className="demo-app">
          <div className="demo-app-main">
            <FullCalendar
              locale={context.state.default_locale === "tr" ? "tr" : "en"}
              buttonText={{
                today: "Bu Ay",
              }}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              datesSet={(info) => {
                setCurrentDate(
                  moment(info.view.currentStart).format("YYYY-MM-DD")
                );
                //   setCurrentEvents([]);
              }}
              events={[
                ...(currentEvents?.filter((el) =>
                  props?.selected_id?.includes(el.staff_id)
                ) ?? []),
                ...closedTime,
              ]}
              initialEvents={[...currentEvents, ...closedTime]} // alternatively, use the `events` setting to fetch from a feed
              select={handleDateSelect} // add item to the selection
              eventContent={(eventInfo) => {
                const theme = createTheme({
                  overrides: {
                    MuiTooltip: {
                      tooltip: {
                        zIndex: "999999999",
                        backgroundColor: isNaN(parseInt(eventInfo.event.title))
                          ? eventInfo.event.extendedProps.state === 0
                            ? "#CBECC9"
                            : eventInfo.event.extendedProps.state === 1
                              ? "#ED8286"
                              : eventInfo.event.extendedProps.state === 3
                                ? "#D6FAFA"
                                : eventInfo.event.extendedProps.state === 4
                                  ? "#EDDEFC"
                                  : "#FFDDB0"
                          : "rgba(189, 195, 199)",
                        borderRadius: "10px",
                        width: "250px",
                        boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                      },
                    },
                  },
                });

                const color = eventInfo.event.extendedProps.eventColor;
                return (
                  <>
                    <MuiThemeProvider theme={theme}>
                      <Tooltip
                        title={
                          <AppointmentCardInner>
                            <AppointmentRow
                              style={{
                                justifyContent: "normal",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                className="limited-line-1"
                                style={{
                                  fontSize: "20px",
                                  marginTop: "5px",
                                }}
                              >
                                {!isNaN(parseInt(eventInfo.event.title))
                                  ? _staffs.filter(
                                    (m) => m.id == eventInfo.event.title
                                  )[0]?.name
                                  : eventInfo.event.title}
                              </span>
                              {isNaN(parseInt(eventInfo.event.title)) ? (
                                eventInfo.event.extendedProps.state === 0 ? (
                                  <img src={HomeChcek} alt="home-check" />
                                ) : (
                                  <>
                                    {eventInfo.event.extendedProps.state ===
                                      1 ? (
                                      <img src={NotCome} alt="note-come" />
                                    ) : (
                                      <>
                                        {eventInfo.event.extendedProps.state ===
                                          3 ? (
                                          <img src={Approved} alt="approved" />
                                        ) : (
                                          <>
                                            {eventInfo.event.extendedProps
                                              .state === 4 ? (
                                              <img
                                                style={{
                                                  transform: "scaleX(-1)",
                                                }}
                                                src={Process}
                                                alt="process"
                                              />
                                            ) : (
                                              <img
                                                src={Waiting}
                                                alt="waiting"
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )
                              ) : (
                                <AccessAlarm />
                              )}
                            </AppointmentRow>
                            <AppointmentRow>
                              <span
                                style={{
                                  marginTop: "5px",
                                }}
                              >
                                {moment(eventInfo.event.start).format("HH:mm")}-
                                {moment(eventInfo.event.end).format("HH:mm")}
                              </span>
                            </AppointmentRow>
                            <div
                              style={{
                                border: "1px solid rgba(162, 162, 162, 0.12)",
                                marginBottom: "5px",
                              }}
                            ></div>

                            <AppointmentRow>
                              {/* <Layers />  */}
                              <span className="limited-line-1">
                                {eventInfo.event.extendedProps &&
                                  eventInfo.event.extendedProps.service_name}
                              </span>
                              {((context.state.user.permission === 2 &&
                                context.state.limited_permission
                                  .customer_info !== 0) ||
                                context.state.user.permission !== 2) && (
                                  <span
                                    style={{
                                      marginLeft: "105px",
                                    }}
                                  >
                                    {eventInfo.event.extendedProps
                                      .customer_phone && "0"}
                                    {eventInfo.event.extendedProps
                                      .customer_phone &&
                                      eventInfo.event.extendedProps
                                        .customer_phone}
                                  </span>
                                )}
                            </AppointmentRow>

                            <AppointmentRow>
                              {/* <Layers />  */}
                              <span className="limited-line-1">
                                {eventInfo.event.extendedProps.staff_name}
                              </span>
                            </AppointmentRow>
                            <div
                              style={{
                                border: "1px solid rgba(162, 162, 162, 0.12)",
                                marginBottom: "5px",
                                marginTop: "5px",
                              }}
                            ></div>
                            <AppointmentRow>
                              {/* <Description /> */}
                              <p>Not: </p>
                              <span
                                style={{
                                  marginLeft: "10px",
                                }}
                                className="limited-line-1"
                              >
                                {eventInfo.event.extendedProps
                                  ? eventInfo.event.extendedProps.note
                                  : "-"}
                              </span>
                            </AppointmentRow>
                          </AppointmentCardInner>
                        }
                        placement="top"
                        arrow
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {color === "black" ? (
                            <AccessAlarm
                              style={{ width: "20px", color: "black" }}
                            />
                          ) : (
                            <div
                              style={{ borderColor: color }}
                              className="fc-daygrid-event-dot"
                            ></div>
                          )}

                          <div
                            style={{ color: color }}
                            className="fc-event-time"
                          >
                            {eventInfo.timeText.split(":")[1]
                              ? eventInfo.timeText
                              : eventInfo.timeText.split(":")[0] + ":00"}
                          </div>
                          {color === "black" ? (
                            <>
                              <div
                                style={{ color: "black" }}
                                className="fc-event-title"
                              >
                                {
                                  staff_event.filter(
                                    (item) =>
                                      item.id ===
                                      parseInt(eventInfo.event.title)
                                  )[0]?.full_name
                                }
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="fc-event-title">
                                {eventInfo.event.title}
                              </div>
                            </>
                          )}
                        </div>
                      </Tooltip>
                    </MuiThemeProvider>
                  </>
                );
              }} // custom render function
              eventClick={handleEventClick}
              eventChange={handleTransferAppointment}
              eventAdd={function () {
                console.log("eventAdd");
              }}
              eventRemove={function () {
                console.log("eventRemove");
              }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img src={Loader} width="100" height="100" alt="loading" />
        </div>
      )}
      {/* Apoointment detail dialog */}
      {filter_data[0] && (
        <AlertDialog
          style={{ zIndex: "12 !important" }}
          fullWidth={true}
          maxWidth="md"
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h2 className="MuiTypography-root MuiTypography-h6">
                  {context.t(`appointments.detail.title`)}
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {filter_data[0] && (
                  <>
                    {filter_data[0].customer_state === 0 ? (
                      <DetailDiv
                        style={{
                          backgroundColor: "rgba(181, 206, 180, 0.65)",
                        }}
                      >
                        <img src={HomeChcekGreen} alt="home-check-green" />
                        <span
                          style={{
                            color: "#628F5F",
                          }}
                        >
                          Geldi
                        </span>
                      </DetailDiv>
                    ) : (
                      <>
                        {filter_data[0].customer_state === 1 ? (
                          <DetailDiv
                            style={{
                              backgroundColor: "rgba(220, 145, 148, 0.65)",
                            }}
                          >
                            <img src={NotComeRed} alt="note-come-red" />
                            <span
                              style={{
                                color: "#D4242B",
                              }}
                            >
                              Gelmedi
                            </span>
                          </DetailDiv>
                        ) : (
                          <>
                            {filter_data[0].customer_state === 3 ? (
                              <DetailDiv
                                style={{
                                  backgroundColor: "rgba(208, 233, 233, 0.65)",
                                }}
                              >
                                <img src={ApprovedBlue} alt="approved-blue" />
                                <span
                                  style={{
                                    color: "#54AEAE",
                                  }}
                                >
                                  Onaylı
                                </span>
                              </DetailDiv>
                            ) : (
                              <>
                                {filter_data[0].customer_state === 4 ? (
                                  <DetailDiv
                                    style={{
                                      backgroundColor:
                                        "rgba(198, 163, 233, 0.65)",
                                    }}
                                  >
                                    <img
                                      style={{
                                        transform: "scaleX(-1)",
                                      }}
                                      src={ProcessPurple}
                                      alt="process-purple"
                                    />
                                    <span
                                      style={{
                                        color: "#7A45AE",
                                      }}
                                    >
                                      İşlemde
                                    </span>
                                  </DetailDiv>
                                ) : (
                                  <DetailDiv
                                    style={{
                                      backgroundColor:
                                        "rgba(255, 226, 188, 0.65)",
                                    }}
                                  >
                                    <img
                                      src={WaitingOrange}
                                      alt="waiting-orange"
                                    />
                                    <span
                                      style={{
                                        color: "#FFB95E",
                                      }}
                                    >
                                      Yeni
                                    </span>
                                  </DetailDiv>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                <Button
                  title={"Kaydet"}
                  backgroundColor="primary-opacity"
                  disabled={update_appoint_disable}
                  textColor="primary"
                  style={{
                    width: "120px",
                    marginLeft: "15px",
                  }}
                  fullWidth
                  onClick={() => {
                    updateAppoint(filter_data[0].id);
                    setAppointmentNote(null);
                  }}
                />
                <img
                  src={Quit}
                  alt="quit"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "35px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setAppointmentNote(null);
                    if (
                      update_appoint_disable === false &&
                      updateAppoint(filter_data[0].id)
                    ) {
                      toast.warning(
                        "Yapmış olduğunuz değişiklikler kaydetmeyi unutmayınız!"
                      );
                    } else {
                      setAppointmentDetailModal(false);
                    }
                  }}
                />
                {/* <Button
                  icon="close"
                  disabled={
                    this.context.state.FLAGS.EN_RANDEVU |
                    this.context.state.FLAGS.SALON_RANDEVU
                  }
                  title={this.context.t(`appointments.detail.closeButtonTitle`)}
                  backgroundColor="black-opacity"
                  textColor="grey"
                  style={{
                    width: "150px",
                  }}
                  fullWidth
                  onClick={() => {
                    this.setState({ time_upgrade: null });
                  }}
                /> */}
              </div>
            </div>
          }
          open={appointment_detail_modal}
          closeHandler={() => {
            setAppointmentDetailModal(false);
            setAppointmentNote(null);
          }}
          disableBackdropClick={false}
          buttons={[
            {
              show:
                context.state.FLAGS.EN_RANDEVU |
                context.state.FLAGS.SALON_RANDEVU,
              title: `${filter_data[0].receipt ? "Adisyona Git" : "Adisyon Aç"
                }`,
              iconComponent: Launch,
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () =>
                filter_data[0]?.receipt
                  ? history.push(
                    `/receipts/detail/${filter_data[0]?.receipt.id}`
                  )
                  : context.state.company_license.accounting === 1 ||
                    context.state.company_license.accounting === 3
                    ? filter_data[0].packet !== null
                      ? (setOpenAppointmentPackage(true),
                        setPayloadObj(
                          filter_data[0].packet.packet_details.map((m) => ({
                            id: m?.service?.id,
                            time: 7,
                          }))
                        ))
                      : openReceiptByAppointment(filter_data[0].id)
                    : toast.warning(
                      "Bu özelliği kullanabilmek için Premium paket satın almalısınız."
                    ),
            },
            {
              show:
                context.state.FLAGS.EN_RANDEVU |
                context.state.FLAGS.SALON_RANDEVU,
              title: context.t(`appointments.detail.deleteButtonTitle`),
              backgroundColor: "red-opacity",
              iconComponent: TimerOff,
              textColor: "red",
              onClick: () => setCancelAppointmentDialog(true),
            },
            {
              show:
                context.state.FLAGS.EN_RANDEVU |
                context.state.FLAGS.SALON_RANDEVU,
              title: "Randevu Geçmişi",
              icon: "event",
              backgroundColor: "purple-opacity",
              textColor: "purple",
              onClick: () =>
                history.push(
                  `/customers/detail/${filter_data[0].customer.id}`,
                  { tab: 2 }
                ),
            },
          ].filter((btn) => btn.show & context.app.ID)}
        >
          {filter_data[0] && (
            <>
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <DatePickerInput
                    label={context.t(`appointments.detail.dateInputLabel`)}
                    value={appointment_date}
                    format="DD MMMM dddd HH:mm"
                    onChange={async (selected_date) => {
                      setAppointmentDate(selected_date);
                      setUpdateAppointDisable(false);
                    }}
                  />
                </Column>
                {/* `${moment(_appointment.appointment_start_date)
                    .tz("Europe/Istanbul")
                    .format("LLL")} - ${moment(
                    _appointment.appointment_end_date
                  )
                    .tz("Europe/Istanbul")
                    .format("HH:mm")}` */}
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  {/* <TimeSelect
                    label={this.context.t(
                      `['settings/hours'].startInputLabelHour`
                    )}
                    type="hour"
                    value={
                      this.state.selected_time_appointment !== null
                        ? this.state.selected_time_appointment.split(":")[0]
                        : moment(this.state.appointment_date).format(
                            "HH:mm".split(":")[0]
                          )
                    }
                    fullWidth={true}
                    size="medium"
                    companyShiftTime={true}
                    startHour={this.context.state.company_shift.start_hour}
                    endHour={this.context.state.company_shift.end_hour}
                    // minuteStep={5}
                    handler={(time) => {
                      // After 7 PM restrict Minute selection to 30 max
                      this.setState({
                        selected_time_appointment: moment(
                          this.state.appointment_date
                        )
                          .set("hours", time)
                          .set(
                            "minutes",
                            this.state.selected_time_appointment.split(":")[1]
                          )
                          .format("HH:mm"),
                      });
                    }}
                  /> */}
                  <TimeSelect
                    label={context.t(`['settings/hours'].startHour`)}
                    type="all"
                    value={
                      hour_detail.start_hour !== null
                        ? hour_detail.start_hour
                        : ""
                    }
                    fullWidth={true}
                    size="medium"
                    minuteStep={5}
                    handler={(time) => {
                      setHourDetail({ ...hour_detail, start_hour: time });
                      setUpdateAppointDisable(false);
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <Input
                    type="number"
                    disabled={change_end_hour}
                    label={context.t(`appointments.detail.appointmentTime`)}
                    placeholder={context.t(
                      `appointments.detail.appointmentTimeExtension`
                    )}
                    value={
                      time_upgrade !== null
                        ? time_upgrade
                        : parseInt(
                          Math.floor(
                            moment(filter_data[0].appointment_end_date)
                              .tz("Europe/Istanbul")
                              .format("HH:mm")
                              .split(":")[0] * 60
                          ) +
                          parseInt(
                            moment(filter_data[0].appointment_end_date)
                              .tz("Europe/Istanbul")
                              .format("HH:mm")
                              .split(":")[1]
                          )
                        ) -
                        parseInt(
                          Math.floor(
                            moment(filter_data[0].appointment_start_date)
                              .tz("Europe/Istanbul")
                              .format("HH:mm")
                              .split(":")[0] * 60
                          ) +
                          parseInt(
                            moment(filter_data[0].appointment_start_date)
                              .tz("Europe/Istanbul")

                              .format("HH:mm")
                              .split(":")[1]
                          )
                        )
                    }
                    onChange={(e) => {
                      setTimeUpgrade(e.target.value);
                      setUpdateAppointDisable(false);
                    }}
                  />
                  {/* <Column className="xs-12 sm-12 md-12 lg-12 xl-2">
                      <Button
                        className="ml-1 time-add-button"
                        icon="add"
                        color="primary"
                        backgroundColor="primary-opacity"
                        onClick={() => {
                          this.state.time_upgrade !== null
                            ? this.upgradeAppointmentTime(_appointment.id)
                            : toast.warning(
                                this.context.t(
                                  `appointments.detail.appointmentalWarningMessage`
                                )
                              );
                        }}
                      />
                    </Column> */}
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  {/* <TimeSelect
                    label={this.context.t(
                      `['settings/hours'].startInputLabelMinute`
                    )}
                    type="minute"
                    value={
                      this.state.selected_time_appointment !== null
                        ? this.state.selected_time_appointment.split(":")[1]
                        : moment(this.state.appointment_date).format(
                            "HH:mm".split(":")[1]
                          )
                    }
                    selectedHour={this.state.selected_time_appointment}
                    lastHour={this.state.hours[this.state.hours.length - 1]}
                    fullWidth={true}
                    size="medium"
                    handler={(time) => {
                      this.setState({
                        selected_time_appointment: moment(
                          this.state.appointment_date
                        )
                          .set(
                            "hours",
                            this.state.selected_time_appointment.split(":")[0]
                          )
                          .set("minutes", time)
                          .format("HH:mm"),
                      });
                    }}
                  /> */}
                  <TimeSelect
                    label={context.t(`['settings/hours'].endHour`)}
                    type="all"
                    disabled={time_upgrade === null ? false : true}
                    value={
                      hour_detail.end_hour !== null ? hour_detail.end_hour : ""
                    }
                    fullWidth={true}
                    size="medium"
                    minuteStep={5}
                    handler={(time) => {
                      setHourDetail({ ...hour_detail, end_hour: time });
                      setChangeEndHour(true);
                      setUpdateAppointDisable(false);
                    }}
                  />
                </Column>
              </Grid>

              <Column className="xs-12 sm-12 md-4 mb-2">
                <CSwitch
                  label={context.t(`appointments.create.sendSmsInputLabel`)}
                  checked={send_sms}
                  color="primary"
                  onChange={(send_sms) => {
                    setSendSMS(send_sms.target.checked);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>

              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  {filter_data[0].service && (
                    <DetailCard
                      icon="layers"
                      color="purple"
                      label={context.t(`appointments.detail.serviceInputLabel`)}
                      value={filter_data[0].service?.name ?? "-"}
                      sabValue={
                        filter_data[0].service?.amount
                          ? `${filter_data[0].service?.amount} TL`
                          : "0"
                      }
                    />
                  )}
                  {filter_data[0].packet && (
                    <DetailCard
                      icon={<LocalMallIcon />}
                      color="pink"
                      label="Ugulanacak Paket"
                      value={filter_data[0].packet?.name ?? "-"}
                      sabValue={
                        filter_data[0].packet?.all_amount
                          ? `${filter_data[0].packet?.all_amount} TL`
                          : "0"
                      }
                    />
                  )}
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  <DetailCard
                    icon="how_to_reg"
                    color="red"
                    label={context.t(`appointments.detail.staffInputLabel`)}
                    value={filter_data[0].staff?.full_name ?? "-"}
                  />
                </Column>
              </Grid>

              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  <DetailCard
                    icon="person"
                    color="blue"
                    label={context.t(`appointments.detail.customerInputLabel`)}
                    value={filter_data[0].customer?.full_name ?? "-"}
                    iconValue={"edit"}
                    click={() => {
                      history.push(
                        `/customers/detail/${filter_data[0].customer.id}`
                      );
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  {" "}
                  <DetailCard
                    icon="call"
                    color="green"
                    label={context.t(
                      `appointments.detail.customerPhoneInputLabel`
                    )}
                    value={
                      context.state.user.permission === 2 &&
                        context.state.limited_permission.customer_info === 0
                        ? phoneNumberDisplay(filter_data[0].customer.phone)
                        : filter_data[0].customer
                          ? `0${filter_data[0].customer.phone}`
                          : "-"
                    }
                  />
                </Column>
              </Grid>

              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  <Input
                    label={context.t(`appointments.detail.noteInputLabel`)}
                    placeholder={"Randevu Notu Giriniz"}
                    value={
                      appointment_note !== null
                        ? appointment_note
                        : filter_data[0].note
                    }
                    onChange={(e) => {
                      setAppointmentNote(e.target.value);
                      setUpdateAppointDisable(false);
                    }}
                  />
                  {/* <DetailCard
                      icon="description"
                      color="orange"
                      label={this.context.t(
                        `appointments.detail.noteInputLabel`
                      )}
                      value={
                        _appointment.note !== null &&
                        _appointment.note !== undefined &&
                        _appointment.note !== ""
                          ? _appointment.note
                          : "-"
                      }
                      iconValue={"edit"}
                    /> */}
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  <DetailCard
                    icon="description"
                    color="black"
                    label={context.t(`appointments.detail.customerNote`)}
                    value={
                      filter_data[0].customer.description !== null &&
                        filter_data[0].customer.description !== undefined &&
                        filter_data[0].customer.description !== ""
                        ? filter_data[0].customer.description
                        : "-"
                    }
                    iconValue={"edit"}
                    click={() => {
                      history.push(
                        `/customers/detail/${filter_data[0].customer.id}`
                      );
                    }}
                  />
                </Column>
              </Grid>

              <FormLabel component="legend">
                {context.t(`appointments.detail.appointmentStatus.title`)}
              </FormLabel>
              {/* <RadioGroup
                aria-label="gender"
                name="gender1"
                defaultValue="number"
                value={`${
                  _appointment.customer_state ?? _appointment.customer_state
                }`}
                onChange={async (event) => {
                  this.setState({
                    _appointment: {
                      ..._appointment,
                      customer_state: parseInt(event.target.value),
                    },
                  });
                  this.updateAppointmentState(
                    _appointment.id,
                    parseInt(event.target.value)
                  );
                }}
              > */}
              <DivStatus className="mt-2 mb-2">
                {filter_data[0].customer_state === 0 ? (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        backgroundColor: "rgba(181, 206, 180, 0.65)",
                      }}
                    >
                      <img src={HomeChcekGreen} alt="home-check-green" />
                      <span
                        style={{
                          color: "#628F5F",
                        }}
                      >
                        Geldi
                      </span>
                    </DetailDivOption>
                  </Column>
                ) : (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        filter_data[0].customer_state = 0;
                        updateAppointmentState(filter_data[0].id, 0);
                      }}
                    >
                      <img src={HomeChcekGreen} alt="home-check-green" />
                      <span
                        style={{
                          color: "#628F5F",
                        }}
                      >
                        Geldi
                      </span>
                    </DetailDivOption>
                  </Column>
                  // <FormControlLabel
                  //   style={{
                  //     width: "24%",
                  //   }}
                  //   value="female"
                  //   control={<Radio value="0" />}
                  //   label={

                  //   }
                  // />
                )}
                {filter_data[0].customer_state === 1 ? (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        backgroundColor: "rgba(220, 145, 148, 0.65)",
                      }}
                    >
                      <img src={NotComeRed} alt="note-come-red" />
                      <span
                        style={{
                          color: "#D4242B",
                        }}
                      >
                        Gelmedi
                      </span>
                    </DetailDivOption>
                  </Column>
                ) : (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        filter_data[0].customer_state = 1;
                        updateAppointmentState(filter_data[0].id, 1);
                      }}
                    >
                      <img src={NotComeRed} alt="note-come-red" />
                      <span
                        style={{
                          color: "#D4242B",
                        }}
                      >
                        Gelmedi
                      </span>
                    </DetailDivOption>
                  </Column>
                  // <FormControlLabel
                  //   style={{
                  //     width: "24%",
                  //   }}
                  //   value="male"
                  //   control={<Radio value="1" />}
                  //   label={

                  //   }
                  // />
                )}
                {filter_data[0].customer_state === 2 ? (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        backgroundColor: "rgba(255, 226, 188, 0.65)",
                      }}
                    >
                      <img src={WaitingOrange} alt="waiting-orange" />
                      <span
                        style={{
                          color: "#FFB95E",
                        }}
                      >
                        Yeni
                      </span>
                    </DetailDivOption>
                  </Column>
                ) : (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        filter_data[0].customer_state = 2;
                        updateAppointmentState(filter_data[0].id, 2);
                      }}
                    >
                      <img src={WaitingOrange} alt="waiting-orange" />
                      <span
                        style={{
                          color: "#FFB95E",
                        }}
                      >
                        Yeni
                      </span>
                    </DetailDivOption>
                  </Column>
                  // <FormControlLabel
                  //   style={{
                  //     width: "24%",
                  //   }}
                  //   value="other"
                  //   control={<Radio value="2" />}
                  //   label={

                  //   }
                  // />
                )}
                {filter_data[0].customer_state === 3 ? (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        backgroundColor: "rgba(208, 233, 233, 0.65)",
                      }}
                    >
                      <img src={ApprovedBlue} alt="approved-blue" />
                      <span
                        style={{
                          color: "#54AEAE",
                        }}
                      >
                        Onaylı
                      </span>
                    </DetailDivOption>
                  </Column>
                ) : (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        filter_data[0].customer_state = 3;
                        updateAppointmentState(filter_data[0].id, 3);
                      }}
                    >
                      <img src={ApprovedBlue} alt="approved-blue" />
                      <span
                        style={{
                          color: "#54AEAE",
                        }}
                      >
                        Onaylı
                      </span>
                    </DetailDivOption>
                  </Column>
                  // <FormControlLabel
                  //   style={{
                  //     width: "24%",
                  //   }}
                  //   value="other"
                  //   control={<Radio value="3" />}
                  //   label={

                  //   }
                  // />
                )}
                {filter_data[0].customer_state === 4 ? (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        backgroundColor: "rgba(198, 163, 233, 0.65)",
                      }}
                    >
                      <img
                        style={{
                          transform: "scaleX(-1)",
                        }}
                        src={ProcessPurple}
                        alt="process-purple"
                      />
                      <span
                        style={{
                          color: "#7A45AE",
                        }}
                      >
                        İşlemde
                      </span>
                    </DetailDivOption>
                  </Column>
                ) : (
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <DetailDivOption
                      style={{
                        width: "25%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        filter_data[0].customer_state = 4;
                        updateAppointmentState(filter_data[0].id, 4);
                      }}
                    >
                      <img
                        style={{
                          transform: "scaleX(-1)",
                        }}
                        src={ProcessPurple}
                        alt="process-purple"
                      />
                      <span
                        style={{
                          color: "#7A45AE",
                        }}
                      >
                        İşlemde
                      </span>
                    </DetailDivOption>
                  </Column>
                  // <FormControlLabel
                  //   style={{
                  //     width: "24%",
                  //   }}
                  //   value="other"
                  //   control={<Radio value="4" />}
                  //   label={

                  //   }
                  // />
                )}
              </DivStatus>
              {/* </RadioGroup> */}

              {/* <Select
                label={"Randevu Durumu"}
                items={[
                  { label: "Geldi", value: 0 },
                  { label: "Gelmedi", value: 1 },
                  { label: "Bekleniyor", value: 2 },
                ]}
                selected={`${_appointment.customer_state}`}
                labelKey="label"
                valueKey="value"
                handler={(value) => {
                  this.setState({
                    _appointment: {
                      ..._appointment,
                      customer_state: parseInt(value),
                    },
                  });
                  this.changeAppointmentStatus(
                    _appointment.id,
                    parseInt(value)
                  );
                }}
              /> */}
            </>
          )}
        </AlertDialog>
      )}

      {/* Transfer Appointment Dialog */}
      {appointment_data[0] && (
        <AlertDialog
          style={{ zIndex: "12 !important" }}
          maxWidth="sm"
          fullWidth={true}
          title={context.t(`appointments.updateAppointment.title`)}
          open={open_update_appointment_dialog}
          closeHandler={() => setOpenUpdateAppointmentDialog(false)}
          buttons={[
            {
              title: context.t(
                `appointments.updateAppointment.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              timer: 5,
              onClick: async () => {
                transferAppointment(appointment_data[0].id);
              },
            },
            {
              title: context.t(
                `appointments.updateAppointment.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>{context.t(`appointments.updateAppointment.alertBoldText`)}</b>
            <br />
            {context.t(`appointments.updateAppointment.alertText`)} <br />
            {`${context.t(`appointments.updateAppointment.service`)}: ${appointment_data[0].service?.name
              }`}
            <br />
            {`${context.t(`appointments.updateAppointment.staff`)}: ${appointment_data[0].staff?.full_name
              }`}
            <br />
            {`${context.t(`appointments.updateAppointment.time`)}: ${moment(
              appointment_data[0].appointment_start_date
            ).format("DD/MM/YYYY HH:mm")}`}{" "}
            <br />
            {`${context.t(`appointments.updateAppointment.customer`)}: ${appointment_data[0].customer?.full_name
              }`}
          </Alert>
        </AlertDialog>
      )}

      {/* İnsert Appointment Dialog */}
      {insertData && (
        <AlertDialog
          style={{ zIndex: "12 !important" }}
          maxWidth="md"
          fullWidth={true}
          open={create_appointment_modal}
          closeHandler={() => {
            setCreateAppointmentModal(false);
            setInsertData({
              ...insertData,
              selected_staff: null,
              selected_customer: null,
              selected_service: null,
              selected_package: null,
              selected_note: "",
              selected_time: null,
              selected_date: null,
            });
          }}
          className="bringBack"
          buttonDisabledTimeout
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h2 className="MuiTypography-root MuiTypography-h6">
                  {context.t(`appointments.create.title`)}
                </h2>
                <h5
                  style={{
                    color: "#4293ed",
                  }}
                >
                  {moment(insertData.selected_date).format("LL")}
                </h5>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Button
                    title={context.t(`appointments.create.groupAppointment`)}
                    className="bg-purple-opacity"
                    textColor="purple"
                    fullWidth
                    onClick={() => {
                      setCreateAppointmentModal(false);
                      setCreateAppointmentModalCollective(true);
                    }}
                  />
                </div>
                <div>
                  <Button
                    title={context.t(`appointments.create.clockOff`)}
                    className="bg-grey-opacity ml-1"
                    fullWidth
                    textColor="grey"
                    onClick={() => {
                      setCreateAppointmentModal(false);
                      setCreateAppointmentModalClock(true);
                    }}
                  />
                </div>
                <div>
                  <Button
                    icon="add"
                    title={context.t(
                      `appointments.create.createNewCustomerButtonTitle`
                    )}
                    backgroundColor="primary-opacity"
                    textColor="primary"
                    className="ml-2"
                    fullWidth
                    onClick={() => {
                      setCreateAppointmentModal(false);
                      setCustomerAddModal(true);
                    }}
                  />
                </div>
              </div>
            </div>
          }
          buttons={[
            {
              title: context.t(`appointments.create.completeButtonTitle`),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              disabledHandleClose: true,
              onClick: () => {
                (insertData.selected_service || insertData.selected_package) &&
                  insertData.selected_customer
                  ? insertAppointment()
                  : this.handleOpen(
                    context.t(`appointments.create.onWarningToast`),
                    "warning"
                  );
              },
            },
            {
              title: context.t(`appointments.create.discardButtonTitle`),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
                  <Select
                    label={context.t(
                      `appointments.create.selectedStaffInputLabel`
                    )}
                    items={_staffs}
                    labelKey="full_name"
                    valueKey="id"
                    selected={
                      insertData.selected_staff !== null
                        ? `${insertData.selected_staff.id}`
                        : ""
                    }
                    returnFullObject
                    handler={(selected_staff) =>
                      setInsertData({
                        ...insertData,
                        selected_staff,
                        selected_service: null,
                        selected_package: null,
                        services_by_staff: selected_staff.services?.map(
                          (service) => service.service
                        ),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                      gap: "1px",
                      alignItems: "center",
                    }}
                  >
                    {context.t(`appointments.create.selecting_service`)}
                    <Switch
                      checked={serviceOrPackage === 2 ? true : false}
                      onChange={(e) => {
                        setServiceOrPackage(
                          (prev) => (prev = e.target.checked ? 2 : 1)
                        );
                      }}
                      color="default"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                    {context.t(`appointments.create.selecting_package`)}
                  </div>
                  {serviceOrPackage === 1 && (
                    <AutocompleteInput
                      label={context.t(
                        `appointments.create.selectedServiceInputLabel`
                      )}
                      labelKey="name"
                      valueKey="id"
                      selected={
                        insertData.selected_service !== null
                          ? insertData.selected_service
                          : ""
                      }
                      onRemove={() => {
                        setInsertData({
                          ...insertData,
                          selected_service: null,
                        });
                      }}
                      returnFullObject
                      selectedHandler={(selected_service) => {
                        setInsertData({
                          ...insertData,
                          selected_service,
                        });
                      }}
                      asyncDataService={async (keyword) =>
                        keyword.length > 0 ? searchServicesByKey(keyword) : []
                      }
                    />
                  )}
                  {serviceOrPackage === 2 && (
                    <AutocompleteInput
                      label={context.t(
                        `appointments.create.selectedPackageInputLabel`
                      )}
                      labelKey="name"
                      valueKey="id"
                      selected={
                        insertData.selected_package !== null
                          ? insertData.selected_package
                          : ""
                      }
                      onRemove={() => {
                        setInsertData({
                          ...insertData,
                          selected_package: null,
                        });
                      }}
                      returnFullObject
                      selectedHandler={(selected_package) => {
                        setInsertData({
                          ...insertData,
                          selected_package,
                        });
                      }}
                      asyncDataService={async (keyword) =>
                        keyword.length > 0 ? searchPackagesById(keyword) : []
                      }
                    />
                  )}
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
                  <AutocompleteInput
                    label={context.t(
                      `appointments.create.selectedCustomerInputLabel`
                    )}
                    placeholder={context.t(
                      `appointments.create.selectedCustomerInputPlaceholder`
                    )}
                    labelKey="full_name"
                    valueKey="id"
                    selected={
                      insertData.selected_customer !== null
                        ? insertData.selected_customer
                        : ""
                    }
                    returnFullObject
                    selectedHandler={(selected_customer) => {
                      setInsertData({
                        ...insertData,
                        selected_customer,
                        send_sms: selected_customer.send_sms,
                        selected_description: selected_customer.description,
                      });
                    }}
                    asyncDataService={async (keyword) =>
                      keyword.length > 2 ? searchCustomerByKey(keyword) : []
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12">
                  <Input
                    label={context.t(`appointments.create.noteInputLabel`)}
                    multiline
                    rows={1}
                    onChange={(e) =>
                      setInsertData({
                        ...insertData,
                        selected_note: e.target.value,
                      })
                    }
                  />
                </Column>
              </Grid>
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                  <TimeSelect
                    label={context.t(`['settings/hours'].startInputLabelHour`)}
                    type="hour"
                    value={
                      insertData.selected_time !== null
                        ? insertData.selected_time.split(":")[0]
                        : ""
                    }
                    fullWidth={true}
                    size="medium"
                    companyShiftTime={true}
                    startHour={context.state.company_shift.start_hour}
                    endHour={context.state.company_shift.end_hour}
                    // minuteStep={5}
                    handler={async (value) => {
                      await setInsertData({
                        ...insertData,
                        selected_time:
                          insertData.selected_time !== null
                            ? `${value}:${insertData.selected_time.split(":")[1]
                            }`
                            : `${value}:00`,
                      });
                    }}
                  />
                  <TimeSelect
                    label={context.t(
                      `['settings/hours'].startInputLabelMinute`
                    )}
                    type="minute"
                    value={
                      insertData.selected_time !== null
                        ? insertData.selected_time.split(":")[1]
                        : ""
                    }
                    selectedHour={insertData.selected_time}
                    lastHour={insertData.hours[insertData.hours.length - 1]}
                    fullWidth={true}
                    size="medium"
                    handler={async (time) => {
                      await setInsertData({
                        ...insertData,
                        selected_time:
                          insertData.selected_time !== null
                            ? `${insertData.selected_time.split(":")[0]
                            }:${time}`
                            : null,
                      });
                    }}
                  />
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-2">
                  <Input
                    disabled
                    label={context.t(`appointments.create.description`)}
                    multiline
                    rows={5}
                    value={
                      insertData.selected_customer &&
                        insertData.selected_description != null
                        ? insertData.selected_description
                        : context.t(`appointments.create.defaultValue`)
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 mt-1">
                  <CSwitch
                    label={context.t(`appointments.create.sendSmsInputLabel`)}
                    checked={insertData.send_sms}
                    color="primary"
                    disabled={
                      insertData.selected_customer &&
                      insertData.selected_customer.send_sms === false
                    }
                    onChange={(checked) => {
                      setInsertData({
                        ...insertData,
                        send_sms: checked.target.checked,
                      });
                    }}
                  />
                </Column>
              </Grid>
            </Column>
          </Grid>
        </AlertDialog>
      )}
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="sm"
        title={context.t(`appointments.detail.clockOffDetail`)}
        open={appointment_detail_modal_clock}
        closeHandler={() => setAppointmentDetailModalClock(false)}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.deleteButton`),
            icon: "check",
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () =>
              selected_clock.is_recurrent === true
                ? setRecurrentModal(true)
                : deleteAppointmentClockTime(selected_clock.id),
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.closeButtonTitle`),
            icon: "close",
            backgroundColor: "black-opacity",
            textColor: "grey",
            onClick: () => setTimeUpgrade(null),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        {selected_clock && (
          <>
            <div style={{ display: "flex" }}>
              <DetailCard
                icon="alarm"
                color="teal"
                label={context.t(`appointments.detail.clockOffDateInputLabel`)}
                value={`${moment(selected_clock.start_hour)
                  .tz("Europe/Istanbul")
                  .format("LLL")} - ${moment(selected_clock.end_hour)
                    .tz("Europe/Istanbul")
                    .format("HH:mm")}`}
              />
            </div>

            <DetailCard
              icon="how_to_reg"
              color="blue"
              label={context.t(`appointments.detail.clockOffStaffInputLabel`)}
              value={insertData.selected_staff?.full_name ?? "-"}
            />

            <DetailCard
              icon="description"
              color="orange"
              label={context.t(`appointments.detail.clockOffNoteInputLabel`)}
              value={
                selected_clock.note !== null &&
                  selected_clock.note !== undefined &&
                  selected_clock.note !== ""
                  ? selected_clock.note
                  : "-"
              }
            />
          </>
        )}
      </AlertDialog>
      {create_appointment_modal_collective === true ? (
        <AlertDialog
          style={{ zIndex: "12 !important" }}
          isWarningDialogOpen={dialogModal.isOpen}
          maxWidth="md"
          fullWidth={true}
          className="bringBack"
          buttonDisabledTimeout
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h2 className="MuiTypography-root MuiTypography-h6">
                  {context.t(`appointments.create.groupAppointment`)}
                </h2>
                <h5
                  style={{
                    color: "#4293ed",
                  }}
                >
                  {insertData.selected_time
                    ? moment(insertData.selected_date)
                      .set("hours", insertData.selected_time.split(":")[0])
                      .set("minutes", insertData.selected_time.split(":")[1])
                      .format("DD MMMM dddd YYYY - HH:mm")
                    : null}
                </h5>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Button
                    title={context.t(
                      `appointments.create.addEmployeeButtonTitle`
                    )}
                    className="bg-green-opacity"
                    textColor="green"
                    fullWidth
                    onClick={() => addServiceInPackageSale()}
                  />
                </div>
                <div>
                  <Button
                    icon="add"
                    title={context.t(
                      `appointments.create.createNewCustomerButtonTitle`
                    )}
                    backgroundColor="primary-opacity"
                    className="ml-1"
                    textColor="primary"
                    fullWidth
                    onClick={() => {
                      setCreateAppointmentModal(false);
                      setCustomerAddModal(true);
                    }}
                  />
                </div>
              </div>
            </div>
          }
          open={create_appointment_modal_collective}
          closeHandler={() => setCreateAppointmentModalCollective(false)}
          buttons={[
            {
              title: context.t(`appointments.create.completeButtonTitle`),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              disabledHandleClose: true,
              onClick: () => {
                insertData.selected_customer &&
                  Object.keys(new_appointment.services).filter(
                    (key) => new_appointment.services[key] !== undefined
                  ).length >= 2
                  ? bulkInsertAppointment()
                  : handleOpen(
                    context.t(`appointments.create.noDataText`),
                    "warning"
                  );
                setCreateAppointmentModalCollective(false);
              },
            },
            {
              title: context.t(`appointments.create.discardButtonTitle`),
              backgroundColor: "grey-opacity",
              icon: "close",
              textColor: "grey",
              onClick: () => {
                setCreateAppointmentModalCollective(false);
              },
            },
          ]}
        >
          <>
            {Object.keys(new_appointment.services).filter(
              (key) => new_appointment.services[key] !== undefined
            ).length > 0 ? (
              Object.keys(new_appointment.services)
                .filter((key) => new_appointment.services[key] !== undefined)
                .map((item, index) => (
                  <Grid key={index} className="no-gutters-row">
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-5">
                      <Select
                        label={context.t(
                          `['packages/sales/add'].staffInputLabel`
                        )}
                        items={_staffs}
                        labelKey="full_name"
                        valueKey="id"
                        selected={
                          new_appointment.services[item].staff_id ?? null
                        }
                        handler={(staff_id) => {
                          const selected_staff = _staffs.filter(
                            (item) => item.id === parseInt(staff_id)
                          )[0];
                          fieldChanger("services", item, {
                            staff_id: parseInt(staff_id),
                          });
                          setInsertData({
                            ...insertData,
                            selected_staff,
                          });
                        }}
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                      <AutocompleteInput
                        label={context.t(
                          `appointments.create.selectedServiceInputLabel`
                        )}
                        labelKey="name"
                        valueKey="id"
                        selected={
                          insertData.selected_service !== null
                            ? insertData.selected_service
                            : ""
                        }
                        onRemove={() => {
                          setInsertData({
                            ...insertData,
                            selected_service: null,
                          });
                        }}
                        returnFullObject
                        selectedHandler={(service) => {
                          fieldChanger("services", item, {
                            service_id: parseInt(service.id),
                          });
                        }}
                        asyncDataService={async (keyword) =>
                          keyword.length > 0 ? searchServicesByKey(keyword) : []
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-1">
                      <Button
                        icon="delete_forever"
                        iconSize={35}
                        backgroundColor="red-opacity"
                        textColor="red"
                        size="lg"
                        fullWidth
                        onClick={() => deleteServiceInPackageSale(item)}
                      />
                    </Column>
                  </Grid>
                ))
            ) : (
              <NoDataContainer>
                <img
                  src={require("../../../assets/images/manypixels/click_action.svg")}
                  alt="Malesef bulunamadı!"
                />
                <p>{context.t(`appointments.create.noDataText`)}</p>
              </NoDataContainer>
            )}
          </>

          <Grid className="no-gutters-row mb-2">
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <AutocompleteInput
                label={context.t(
                  `appointments.create.selectedCustomerInputLabel`
                )}
                placeholder={context.t(
                  `appointments.create.selectedCustomerInputPlaceholder`
                )}
                labelKey="full_name"
                valueKey="id"
                selected={
                  insertData.selected_customer !== null
                    ? insertData.selected_customer
                    : ""
                }
                returnFullObject
                selectedHandler={(selected_customer) => {
                  setSendSMS(selected_customer.send_sms);
                  setInsertData({
                    ...insertData,
                    selected_customer,
                    selected_description: selected_customer.description,
                  });
                }}
                asyncDataService={async (keyword) =>
                  keyword.length > 2 ? searchCustomerByKey(keyword) : []
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6 flex">
              <TimeSelect
                label={context.t(`['settings/hours'].startInputLabelHour`)}
                type="hour"
                value={
                  insertData.selected_time !== null
                    ? insertData.selected_time.split(":")[0]
                    : ""
                }
                fullWidth={true}
                size="medium"
                companyShiftTime={true}
                startHour={context.state.company_shift.start_hour}
                endHour={context.state.company_shift.end_hour}
                // minuteStep={5}
                handler={(time) => {
                  // After 7 PM restrict Minute selection to 30 max
                  setInsertData({
                    ...insertData,
                    selected_time: moment(insertData.selected_date)
                      .set("hours", time)
                      .set("minutes", insertData.selected_time.split(":")[1])
                      .format("HH:mm"),
                  });
                }}
              />
              <TimeSelect
                label={context.t(`['settings/hours'].startInputLabelMinute`)}
                type="minute"
                value={
                  insertData.selected_time !== null
                    ? insertData.selected_time.split(":")[1]
                    : ""
                }
                selectedHour={insertData.selected_time}
                lastHour={insertData.hours[insertData.hours.length - 1]}
                /*lastMinute={
                    this.state.selected_time
                      ? this.state.selected_time.split(":")[0] ===
                        this.state.hours[this.state.hours.length - 1].split(
                          ":"
                        )[0]
                        ? parseInt(
                            this.state.hours[this.state.hours.length - 1].split(
                              ":"
                            )[1]
                          )
                        : null
                      : null
                  }*/
                fullWidth={true}
                size="medium"
                handler={(time) => {
                  setInsertData({
                    ...insertData,
                    selected_time: moment(insertData.selected_date)
                      .set("hours", insertData.selected_time.split(":")[0])
                      .set("minutes", time)
                      .format("HH:mm"),
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <Input
                label={context.t(`appointments.create.noteInputLabel`)}
                onChange={(e) =>
                  setInsertData({
                    ...insertData,
                    selected_note: e.target.value,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <Input
                disabled
                label={context.t(`appointments.create.description`)}
                value={
                  insertData.selected_customer &&
                    insertData.selected_description != null
                    ? insertData.selected_description
                    : context.t(`appointments.create.defaultValue`)
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 mt-1">
              <CSwitch
                label={context.t(`appointments.create.sendSmsInputLabel`)}
                checked={send_sms}
                color="primary"
                disabled={
                  insertData.selected_customer &&
                  insertData.selected_customer.send_sms === false
                }
                onChange={(checked) => {
                  setSendSMS(checked.target.checked);
                }}
              />
            </Column>
          </Grid>
        </AlertDialog>
      ) : (
        <></>
      )}

      <AlertDialog
        style={{ zIndex: "12 !important" }}
        isWarningDialogOpen={dialogModal.isOpen}
        maxWidth="sm"
        fullWidth={true}
        className="bringBack"
        buttonDisabledTimeout
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 className="MuiTypography-root MuiTypography-h6">
                {context.t(`appointments.create.clockOff`)}
              </h2>
            </div>
            <div>
              {turn_off_all_day === false ? (
                <Button
                  title={context.t(`appointments.create.closeAllDayTitle`)}
                  className="bg-blue-opacity"
                  fullWidth
                  onClick={async () => {
                    setTurnOffAllDay(true);
                  }}
                />
              ) : (
                <Button
                  title={"İptal"}
                  className="bg-red-opacity"
                  textColor="red"
                  fullWidth
                  onClick={async () => {
                    setTurnOffAllDay(false);
                  }}
                />
              )}
            </div>
            <div>
              {repetition_frequency_modal === false ? (
                <Button
                  title={"Tekrarlayan"}
                  className="bg-blue-opacity"
                  fullWidth
                  onClick={async () => {
                    setRepetitionFrequencyModal(true);
                  }}
                />
              ) : (
                <Button
                  title={"İptal"}
                  className="bg-red-opacity"
                  textColor="red"
                  fullWidth
                  onClick={async () => {
                    setRepetitionFrequencyModal(false);
                  }}
                />
              )}
            </div>
          </div>
        }
        open={create_appointment_modal_clock}
        closeHandler={() => setCreateAppointmentModalClock(false)}
        buttons={[
          {
            title: context.t(`appointments.create.completeButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            disabledHandleClose: true,
            onClick: () => {
              insertData.selected_staff?.name
                ? closeAppointmentClockTime()
                : toast.warning("Personel Seçimi Yapınız.");
            },
          },
          {
            title: context.t(`appointments.create.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setCreateAppointmentModalClock(false);
            },
          },
        ]}
      >
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
            <Select
              label={context.t(`appointments.create.selectedStaffInputLabel`)}
              items={_staffs}
              labelKey="full_name"
              valueKey="id"
              selected={
                insertData.selected_staff !== null
                  ? `${insertData.selected_staff.id}`
                  : ""
              }
              returnFullObject
              handler={(selected_staff) =>
                setInsertData({
                  ...insertData,
                  selected_staff,
                })
              }
            />
          </Column>
        </Grid>
        {turn_off_all_day === false ? (
          <>
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelHour`)}
                  type="hour"
                  value={
                    insertData.selected_time_clock_off_start !== null
                      ? insertData.selected_time_clock_off_start.split(":")[0]
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  companyShiftTime={true}
                  startHour={context.state.company_shift.start_hour}
                  endHour={context.state.company_shift.end_hour}
                  // minuteStep={5}
                  handler={(time) => {
                    // After 7 PM restrict Minute selection to 30 max
                    setInsertData({
                      ...insertData,
                      selected_time_clock_off_start:
                        insertData.selected_time_clock_off_start !== null
                          ? `${time}:${insertData.selected_time_clock_off_start.split(
                            ":"
                          )[1]
                          }`
                          : `${time}:00`,
                    });
                  }}
                />
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelMinute`)}
                  type="minute"
                  value={
                    insertData.selected_time_clock_off_start !== null
                      ? insertData.selected_time_clock_off_start.split(":")[1]
                      : ""
                  }
                  selectedHour={insertData.selected_time_clock_off_start}
                  lastHour={insertData.hours[insertData.hours.length - 1]}
                  fullWidth={true}
                  size="medium"
                  handler={(time) => {
                    setInsertData({
                      ...insertData,
                      selected_time_clock_off_start:
                        insertData.selected_time_clock_off_start !== null
                          ? `${insertData.selected_time_clock_off_start.split(
                            ":"
                          )[0]
                          }:${time}`
                          : null,
                    });
                  }}
                />
              </Column>
            </Grid>
            <Grid className="no-gutters-row mt-2">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                <TimeSelect
                  label={context.t(`['settings/hours'].endInputLabelHour`)}
                  type="hour"
                  value={
                    insertData.selected_time_clock_off_end !== null
                      ? insertData.selected_time_clock_off_end.split(":")[0]
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  companyShiftTime={true}
                  startHour={context.state.company_shift.start_hour}
                  endHour={context.state.company_shift.end_hour}
                  // minuteStep={5}
                  handler={(time) => {
                    // After 7 PM restrict Minute selection to 30 max
                    setInsertData({
                      ...insertData,
                      selected_time_clock_off_end:
                        insertData.selected_time_clock_off_end !== null
                          ? `${time}:${insertData.selected_time_clock_off_end.split(
                            ":"
                          )[1]
                          }`
                          : `${time}:00`,
                    });
                  }}
                />
                <TimeSelect
                  label={context.t(`['settings/hours'].endInputLabelMinute`)}
                  type="minute"
                  value={
                    insertData.selected_time_clock_off_end !== null
                      ? insertData.selected_time_clock_off_end.split(":")[1]
                      : ""
                  }
                  selectedHour={insertData.selected_time_clock_off_end}
                  lastHour={insertData.hours[insertData.hours.length - 1]}
                  fullWidth={true}
                  size="medium"
                  handler={(time) => {
                    setInsertData({
                      ...insertData,
                      selected_time_clock_off_end:
                        insertData.selected_time_clock_off_end !== null
                          ? `${insertData.selected_time_clock_off_end.split(
                            ":"
                          )[0]
                          }:${time}`
                          : null,
                    });
                  }}
                />
              </Column>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid className="no-gutters-row mt-2">
          <Column className="xs-12 sm-12 md-4 mb-2 mt-2">
            <CSwitch
              label={"Online Randevu"}
              checked={online_appointment}
              color="primary"
              onChange={(online_appointment) => {
                setOnlineAppointment(online_appointment.target.checked);
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-8">
            <Input
              label={context.t(`appointments.create.noteInputLabel`)}
              multiline
              rows={1}
              onChange={(e) =>
                setInsertData({
                  ...insertData,
                  selected_note: e.target.value,
                })
              }
            />
          </Column>
        </Grid>
        {repetition_frequency_modal ? (
          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-6">
              <Select
                label={"Tekrar Sıklığı"}
                items={repetition}
                labelKey="name"
                valueKey="id"
                selected={
                  selected_repetition !== null
                    ? `${selected_repetition.id}`
                    : ""
                }
                returnFullObject
                handler={async (selected_repetition) => {
                  setSelectedRepetition(selected_repetition);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-6">
              <Input
                label={"Tekrar Sayısı"}
                type="number"
                onChange={(e) => setRepetitionCount(e.target.value)}
              />
            </Column>
          </Grid>
        ) : (
          <></>
        )}
      </AlertDialog>

      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="sm"
        title={context.t(`appointments.detail.clockOffDetail`)}
        open={appointment_detail_modal_clock}
        closeHandler={() => setAppointmentDetailModalClock(false)}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.deleteButton`),
            icon: "check",
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () =>
              selected_clock.is_recurrent === true
                ? setRecurrentModal(true)
                : deleteAppointmentClockTime(selected_clock.id),
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.closeButtonTitle`),
            icon: "close",
            backgroundColor: "black-opacity",
            textColor: "grey",
            onClick: () => setTimeUpgrade(null),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        {selected_clock && (
          <>
            <div style={{ display: "flex" }}>
              <DetailCard
                icon="alarm"
                color="teal"
                label={context.t(`appointments.detail.clockOffDateInputLabel`)}
                value={`${moment(selected_clock.start_hour)
                  .tz("Europe/Istanbul")
                  .format("LLL")} - ${moment(selected_clock.end_hour)
                    .tz("Europe/Istanbul")
                    .format("HH:mm")}`}
              />
            </div>

            <DetailCard
              icon="how_to_reg"
              color="blue"
              label={context.t(`appointments.detail.clockOffStaffInputLabel`)}
              value={insertData.selected_staff?.full_name ?? "-"}
            />

            <DetailCard
              icon="description"
              color="orange"
              label={context.t(`appointments.detail.clockOffNoteInputLabel`)}
              value={
                selected_clock.note !== null &&
                  selected_clock.note !== undefined &&
                  selected_clock.note !== ""
                  ? selected_clock.note
                  : "-"
              }
            />
          </>
        )}
      </AlertDialog>

      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="sm"
        open={recurenct_modal}
        closeHandler={() => setRecurrentModal(false)}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: "Evet",
            icon: "check",
            backgroundColor: "green-opacity",
            textColor: "green",
            onClick: () => {
              deleteAppointmentClockTime(selected_clock.id, 1);
            },
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: "Hayır",
            icon: "close",
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () => {
              deleteAppointmentClockTime(selected_clock.id);
            },
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: "Vazgeç",
            icon: "close",
            backgroundColor: "grey-opacity",
            textColor: "grey",
            onClick: () => setTimeUpgrade(null),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        <Alert severity="warning">
          <p>
            Bu tekrarlayan bir etkinliktir. Diğer tekrarlayan etkinlikleri de
            silmek ister misiniz?
          </p>
        </Alert>
      </AlertDialog>
      <CenterDialogModal
        open={dialogModal.isOpen}
        handleClose={handleClose}
        message={dialogModal.message}
        type={dialogModal.type}
      />
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="sm"
        fullWidth={true}
        title={context.t(`appointments.deleteConfirm.title`)}
        open={cancelAppointmentDialog}
        closeHandler={() => setCancelAppointmentDialog(false)}
        buttons={[
          {
            title: context.t(`appointments.deleteConfirm.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => {
              setCancelAppointmentArray((prevArray) => [
                ...prevArray,
                filter_data[0].id,
              ]);
              if (!cancelAppointmentArray.includes(filter_data[0].id)) {
                cancelAppointment(filter_data[0].id);
              }
            },
          },
          {
            title: context.t(`appointments.deleteConfirm.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>{context.t(`appointments.deleteConfirm.alertBoldText`)}</b>
          <br />
          {context.t(`appointments.deleteConfirm.alertText`)}
        </Alert>
      </AlertDialog>
      {/* Adisyondaki paketin içerisindeki hizmetleri listeler ve seans aralığını seçtirir */}
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="xs"
        fullWidth={true}
        className="bringBack"
        buttonDisabledTimeout
        open={openAppointmentPackage}
      >
        <Alert severity="warning">
          <p style={{ fontSize: "14px" }}>
            Adisyon açabilmek için paket içerisindeki hizmetlerin seans
            aralığını belirlemeniz gerekmektedir.
          </p>
        </Alert>
        {filter_data[0]?.packet?.packet_details.map((m, i) => (
          <Grid
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Column className="sx-12 sm-12 md-12 lg-7">
              <p style={{ fontSize: "10px" }}>Hizmet Adı</p>

              <h4>{m?.service?.name}</h4>
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-5">
              <TextField
                select
                fullWidth
                label="Seans Aralığı (Gün)"
                value={payloadObj[i]?.time}
                onChange={(e) => {
                  const updatedPayload = [...payloadObj];
                  updatedPayload[i] = {
                    ...updatedPayload[i],
                    time: Number(e.target.value),
                  };
                  setPayloadObj(updatedPayload);
                }}
              >
                {Array.from({ length: 50 }, (_, index) => index + 1).map(
                  (number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Column>
          </Grid>
        ))}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Button
            title="Onayla"
            textColor="primary"
            backgroundColor="primary-opacity"
            fullWidth
            onClick={() => {
              context.state.company_license.accounting === 1 ||
                context.state.company_license.accounting === 3
                ? filter_data[0].packet !== null &&
                openReceiptByAppointment(filter_data[0].id)
                : toast.warning(
                  "Bu özelliği kullanabilmek için Premium paket satın almalısınız."
                );
            }}
          />

          <Button
            onClick={() => openAppointmentPackage(false)}
            fullWidth
            backgroundColor="red-opacity"
            textColor="red"
            title="Vazgeç"
          />
        </div>
      </AlertDialog>

      <CustomerAddDialog
        open={customer_add_modal}
        createdCustomerHandler={async (data) => {
          setInsertData({
            ...insertData,
            selected_customer: {
              ...data,
              full_name: `${data?.name} ${data.surname}`,
            },
          });
          setCreateAppointmentModal(true);
        }}
        closeHandler={() => setCustomerAddModal(false)}
      />
    </>
  );
};

const DetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
`;

const DetailIcon = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  i {
    font-size: 21px;
  }
`;

const DetailInner = styled.div`
  flex: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;

  h4 {
    color: #808080;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 4px;
  }
  span {
    color: #202020;
    font-size: 14px;
  }
`;

const AppointmentCardInner = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  box-sizing: border-box;
`;

const AppointmentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  font-weight: 500;

  svg {
    height: 16px;
    margin-right: 4px;
  }
  span {
    flex: 1;
    font-size: 12px;
    line-height: 16px;
  }
`;
const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 120px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const DivStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DetailDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 120px;
  height: 42px;
  border-radius: 5px;
`;

const DetailDivOption = styled.div`
  width: 170px !important;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #ffffff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149));
  border-radius: 5px;
`;

const DetailCard = (props) => (
  <DetailBox>
    <DetailIcon className={`bg-${props.color}-opacity fc-${props.color}`}>
      <i className="material-icons">{props.icon}</i>
    </DetailIcon>
    <DetailInner>
      <h4>{props.label}</h4>
      <span>{props.value}</span>
    </DetailInner>
  </DetailBox>
);

export default React.memo(MonthAppointment);
